import React, { Component } from 'react'
import Caption from './Caption'
import Region from './Region'
class Locate extends Component {
    constructor(props) {
        super(props)
        this.state = {
            captions1 : {
                title: [{ id: 31, text: '地域' }, { id: 32, text: '企业数量' }],
                list: [
                    
                ]
            }
        }
    }
    componentDidMount() {
        
        let pl = []
        Object.values(this.props.data).map(item=>{
            pl.push({id:item.name,unit:item.name,nums:item.num})
            return pl
        })

        this.setState({
            captions1: {
                title: [{ id: 31, text: '地域' }, { id: 32, text: '企业数量' }],
                list: pl
            }
        })
        
    }
    render() { 
        return (
            <div className="locate">
                <Region data={this.props.data} />
                <div className="figure">
                    <Caption {...this.state.captions1} cate="area" />
                </div>
            </div>
        )
    }
}
 
export default Locate