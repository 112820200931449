import React, { Component } from 'react'
import BreadComp  from 'comps/bread'
import Patent from './Patent'
import PageCustom from 'comps/PageCustom'
import { EmptyComp } from 'comps/common/EmptyComp'
import Pyear from './Pyear'
import classNames from 'classnames'
import { Tootip3 } from 'comps/tootip/Tootip'
import { getStorage } from 'utils/storage'
import { TEST_USER } from 'utils/type'

class PatentList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tabs: [
                { tabID: 1, active: true, title: '专利权人' },
                { tabID: 2, active: false, title: '发明人' }
            ],
            activeTab: 1,
            list: [],
            // 图标数据
            chart: [],
            name: '',
            page: 1,
            total: 0,   // 分专利权人 和 发明人两种
            total_num: 0, // 专利所有数量
            BreadData: [{url:'/home',title:'首页'},{url:'/person',title:'人物列表'},{url:'',title:''},{url:'',title:'专利'}]
        }
    }


    componentDidMount() {
        this.requestPatent({ id: this.props.match.params.id })
        this.axios.post('/expert/index', { id: this.props.match.params.id })
            .then(res => {
                if (res.code === 10000) {
                    this.setState({
                        name: res.data.name_cn ? res.data.name_cn : res.data.name_en
                    },()=>{
                        document.title = this.state.name
                        let Bs = [{url:'/home',title:'首页'},{url:'/person',title:'人物列表'},{url:'/person/detail/' + this.props.match.params.id,title: this.state.name},{url:'',title:'专利'}]
                        this.setState({BreadData: Bs})
                    })
                }
            })
    }

    clickTab(tabID) {
        this.setState({
            tabs: this.state.tabs.map(item => item.tabID === tabID ? { ...item, active: true } : { ...item, active: false }),
            activeTab: tabID,
            chart: [],
            page:1
        })
        this.requestPatent({ id: this.props.match.params.id, page: 1, type: tabID })
    }

    requestPatent(ops) {
        this.axios.post('/expert/patentlist', ops)
            .then(res => {
                this.setState({
                    list: res.data.data,
                    total: res.data.total[0],
                    total_num: res.data.total_num,
                    chart: res.data.yearInfo
                })
            })
    }
    change = page => {
        this.setState({ page })
        this.requestPatent({ id: this.props.match.params.id, page: page,type: this.state.activeTab })
    }
    render() {
        return (
            <div className="content-list">
                <BreadComp data={this.state.BreadData} />
                <div className="content-title">专利列表<span>共{this.state.total_num}篇</span></div>
                {this.state.list.length === 0 ? <EmptyComp /> :
                    <section className="content-box patent-box">
                        <div className="filter">
                            <div className="left">角色类型</div>
                            <div className="right">
                                <div className="action-bar">
                                    {this.state.tabs.map(item => <div key={item.tabID}
                                        onClick={this.clickTab.bind(this, item.tabID)}
                                        className={classNames({ active: item.active })}
                                    >{item.title}</div>)}
                                </div>
                            </div>
                        </div>
                        <div className="filter">
                            <div className="left">按年份排序</div>
                            <div className="right">
                                {this.state.chart.length !== 0 &&
                                    <Pyear id="patY" data={this.state.chart} />}
                            </div>
                        </div>
                        {this.state.list.length === 0 ? <EmptyComp /> :
                            this.state.list.map((item, index) => <Patent key={item.id} {...item} botmBorder={index + 1 === this.state.list.length ? true : false}/>)}
                        {
                            TEST_USER.includes(getStorage('level'))  && <Tootip3 page={this.state.page} aim={1} text='升级完整版，查看全部数据' />
                        }
                        <PageCustom
                            currentPage={this.state.page}
                            pagesize={10}
                            total={this.state.total}
                            changeStatePage={this.change}
                        />
                    </section>}
            </div>
        )
    }
}


export default PatentList