import React from 'react'
import CompareItem from './CompareItem'

const Content = props => {
    return (
        <>
            <div className="left">
                <div className="side-title">
                    <div className="top">
                        <p>任职机构</p>
                        <p>职位职称</p>
                        <p>研究领域</p>
                        <p>奖项及荣誉称号</p>
                    </div>
                    <div className="bottom">
                        <p>TI人才指数</p>
                        <p>H-index指数</p>
                        <p>总发文数</p>
                        <p>总被引数</p>
                        <p>篇均被引数</p>
                        <p>专利总数</p>
                        <p>有效专利数</p>
                    </div>
                </div>
            </div>
            <div className="content">
                {props.list.map(item => <CompareItem id={item.id} />)}
            </div>
        </>
    )
}

export default Content