
const addItem = item => ({
    type: 'ADD_ITEM',
    id: item.id,
    name: item.name
})

const clearItem = () => ({
    type: 'CLEAR_ITEM',
})

const deleteItem = id => ({
    type: 'DELETE_ITEM',
    id
})


const addLabel = label => ({
    type: 'ADD_LABEL',
    id: label.id,
    name: label.name,
    cate: label.cate
})

const deleteLabel = (id,cate) => ({
    type: 'DELETE_LABEL',
    id,cate
})

const clearCate = cate => ({
    type: 'CLEAR_CATE',
    cate
})

const clearAll = () => ({
    type: 'CLEAR_ALL'
})
export { addItem, clearItem, deleteItem, addLabel, deleteLabel, clearCate, clearAll }