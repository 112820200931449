export const DT_COMPANY_INDEX = 'TI企业指数依据 DPTC 体系（Depth、Popularity、Tendency、Collaboration），从四个维度全方位评估企业的科技实力、市场表现、企业发展趋势和合作表现。“Depth”关注企业的科学技术人员占比、专利、科研投入等科技要素；“Popularity”关注的是企业的市场表现、投融资情况、舆情等市场反馈信息；“Tendency”主要发掘企业在时间维度下的发展潜力和提升可能，关注的指标包括众多财务数据和企业生命周期等；“Collaboration”则关注企业在整个产业格局下所起到的协同作用大小，包括其对上下游的把控能力以及对外合作、市场竞争情况等。'

// 园区详情 侧边导航
export const PARKSIDE = [
    { href:'#basic',title:'概况'},
    // { href:'#index',title:'DT指数'},
    // { href:'#analysis',title:'园区分析'},
    // { href:'#investment',title:'投融资'},
    // { href:'#settled',title:'入驻企业'},
    // { href:'#invest-trend',title:'投资动向'},
    { href:'#service',title:'园区服务'},
    { href:'#effect',title:'园区影响力'},
    // { href:'#news',title:'园区新闻'},
    // { href:'#policy',title:'政策'}
]

// 产业详情侧边 导航
export const INDUSTRYSIDE = [
    { href:'#basic',title:'概况'},
    // { href:'#index',title:'TI指数'},
    { href:'#finance',title:'投融资'},
    { href:'#chain',title:'产业链'},
    { href:'#geography',title:'地理情况'},
    { href:'#market',title:'上市企业'},
    // { href:'#news',title:'产业新闻'},
    // { href:'#policy',title:'相关政策'},
]

// 公司详情侧边 导航
export const COMPANYSIDE = [
    { href:'#basic',title:'概况'},
    { href:'#index',title:'TI指数'},
    { href:'#finance',title:'融资'},
    { href:'#stock',title:'上市信息'},
    { href:'#chain',title:'产业链'},
    { href:'#patent',title:'科技专利'},
    { href:'#member',title:'团队成员'},
    { href:'#compete',title:'竞业关系'},
]