import React, { Component } from 'react'
import * as echarts from 'echarts'
import { COLORS } from 'utils/type'

class Pie extends Component {
    constructor(props) {
        super(props)
        this.state = {  }
    }

    componentDidMount() {
        let pie = echarts.init(document.getElementById(this.props.id))
        pie.setOption({
            tooltip: {
                trigger: 'item'
            },
            legend: {
                orient: 'vertical',
                right: 160,
                bottom: 62,
                icon: 'circle',
                itemHeight: 10,
                itemWidth: 10,
                data: ["<10", "10-20", "20~30", "30-50", ">50"]
            },
            series: [
                {
                    type: 'pie',
                    radius: '70%',
                    center: ['24%','50%'],
                    label: {show: false},
                    hoverAnimation: false,
                    data: [
                        {value: 33,name:'<10'},
                        {value: 3,name:'10-20'},
                        {value: 0,name:'20-30'},
                        {value: 1,name:'30-50'},
                        {value: 1,name:'>50'},
                    ]
                }
            ],
            color:COLORS
        })
    }

    render() { 
        return ( <div id={this.props.id} style={{ width: '480px', height: '320px' }} ></div> )
    }
}
 
export default Pie