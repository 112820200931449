import React from 'react'
import EchartReact from 'comps/Sign/EchartReact'

export default function Pyear(props) {
    return (<EchartReact
        id={props.id}
        options={getOption(props.data)}
        style={{ width: props.data.length * 60 >= 850 ? 850 : props.data.length * 60 + 'px', height: '180px' }}
    />)
}

function getOption(data) {
    let nums = []
    let  year = []
    data.map(item=>{
        nums.push(item.num)
        year.push(item.year)
        return nums
    })    
    return {
        tooltip: {
            show: true
        },
        xAxis: {
            axisLine:{
                show: false
            },
            axisTick:{
                show: false
            },
            type: 'category',
            data: year
        },
        yAxis: {
            show: false, 
            type: 'value',
            
        },
        series: [{
            data: nums,
            type: 'bar',
            showBackground: true,
            barWidth: 20,
            barGap: '10%',
            barCategoryGap : '10%',
            backgroundStyle: {
                color: 'rgba(180, 180, 180, 0.2)'
            },
            
            emphasis: {
                itemStyle: {
                    color: '#A6D0E4'
                }
            }
        }],
        color:['#02375A']
    }
}