import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Modal, Button, Tooltip, Table } from 'antd'
import { connect } from 'react-redux'
import { clearComItem, delComItem, addComItem } from '@redux/company/action'
import CompareItem from './CompareItem'
import PageCustom from 'comps/PageCustom'
import { Tootip3, Tootip1 } from 'comps/tootip/Tootip'
import cs from 'classnames'
import Axios from 'axios'
import { CompanyListColumns, GraphList, GroupList } from 'utils/columns'
import { Msg } from 'utils'
import { getStorage } from 'utils/storage'
import { TEST_USER } from 'utils/type'

import 'styles/comps/compare.scss'

@connect(state => state, { clearComItem, delComItem, addComItem })
@withRouter
class CompareBtn extends Component {
    state = {
        openCompare: this.props.compareCompanyItems.length > 0,
        modalVisible: false,
        DataArray: [],
        CompareLoading: true,
        idsData: [],// 基本数据
        ChainData: [],
        ChainLoading: true, // 产业链
        FinanceData: [],
        FinanceLoading: true, // 融资数据
        DtIndex: [],
        DtLoading: true, // DT指数数据
        PatentData: [],
        PatentLoading: true, // 专利数据
        visible: false
    }
    // 切换显示对比列表框
    toggleActive = () => {
        this.setState({ openCompare: !this.state.openCompare })
    }
    // 删除选中的对比列表中的某个id
    deleteOneId = id => {
        this.props.delComItem(id)
    }
    closeCompareModal = () => {
        this.setState({
            modalVisible: false,
            DataArray: [], CompareLoading: true, idsData: [],// 基本数据
            ChainData: [], ChainLoading: true, // 产业链
            FinanceData: [], FinanceLoading: true, // 融资数据
            DtIndex: [], DtLoading: true, // DT指数数据
            PatentData: [], PatentLoading: true // 专利数据
        })
    }
    //添加企业到对比工具里面
    addComItem = (id, name) => {
        if (this.isCompared(id)) {
            this.props.delComItem(id)
            return
        }
        if (this.props.compareCompanyItems.length < 3) {
            this.props.addComItem({ id, name })
        } else {
            Msg('info', '最多支持三家企业对比哟！')
        }
    }
    isCompared = id => {
        let flag = false
        this.props.compareCompanyItems.forEach(item => {
            if (item.id === id) {
                flag = true
            }
        })
        return flag
    }
    // 开始对比
    openCompareModal = async () => {
        await this.getDetail()
        await this.setState({
            modalVisible: true,
        })
    }
    getBasic = id => this.axios.post('/company', { id })
    getScore = id => this.axios.post('/company/score', { id })
    getInvest = id => this.axios.post('/company/invest', { id })
    getDomain = id => this.axios.post('/company/domain', { id })
    getPatent = id => this.axios.post('/company/patent', { id })
    // 获取对比公司的数据
    getDetail = () => {
        const ids = this.props.compareCompanyItems.map(l => l.id)
        // 公司基本信息
        ids.forEach((id, i) => {
            Axios.all([this.getBasic(id), this.getScore(id), this.getInvest(id), this.getDomain(id), this.getPatent(id)])
                .then(Axios.spread((basic, score, invest, domain, patent) => {
                    this.setState({
                        DataArray: this.state.DataArray.concat(basic.data),
                        idsData: this.state.idsData.concat([{ id, name: basic.data.other_name }]),
                        DtIndex: this.state.DtIndex.concat([{ id, ...score.data }]),
                        FinanceData: this.state.FinanceData.concat({ id: id, name: basic.data.other_name, lists: invest.data }),
                        ChainData: this.state.ChainData.concat(findChainLists(id, domain.data)),
                        PatentData: this.state.PatentData.concat({ id, type: patent.data.type }),
                    }, () => {
                        this.setState({
                            CompareLoading: !(this.state.DataArray.length === ids.length),
                            DtLoading: !(this.state.DtIndex.length === ids.length),
                            FinanceLoading: !(this.state.FinanceData.length === ids.length),
                            ChainLoading: !(this.state.ChainData.length === ids.length),
                            PatentLoading: !(this.state.PatentData.length === ids.length)
                        })
                    })
                }))
        })
    }
    // 继续添加 去试试的按钮
    goAddCompare = () => {
        const pathname = this.props.location.pathname, matchId = this.props.match.params.id
        // 只有在公司详情页 继续添加按钮才会跳转到公司列表页面
        if (pathname.includes('company') && matchId) {
            this.props.history.push('/company')
        } else { // 其他对比列表页面 公司对比 我的专辑公司 科创板
            this.setState({ openCompare: false })
        }
    }
    componentDidUpdate(prevProps, prevState,) {
        if (prevProps.compareCompanyItems.length !== this.props.compareCompanyItems.length) {
            this.setState({ openCompare: true })
        }
    }
    // 权限弹窗
    changeModal = value => {
        this.setState({ visible: value })
    }
    changeColumns = compareOff => {
        switch (compareOff) {
        case 2:
            return CompanyListColumns
        case 3:
            return GraphList
        case 4:
            return GroupList
        default:
            break
        }
    }
    render() {
        const { DataArray, ChainData, FinanceData, idsData,
            CompareLoading, FinanceLoading, ChainLoading,
            DtIndex, DtLoading,
            PatentData, PatentLoading, openCompare, visible
        } = this.state
        const {
            id,
            other_name,
            compareOff,
            rowSelection,
            tableData,
            total,
            currentPage,
            changeStatePage,
            className
        } = this.props

        // 表格中的对比
        let compareTable = [{
            title: '对比',
            dataIndex: 'compare',
            key: 'compare',
            render: (text, record) => {
                // 是否是选中的状态
                const isActive = this.props.compareCompanyItems.filter(item => item.id === record.id || item.id === record.company_id).length > 0
                return <span className='list-compare'
                    onClick={ TEST_USER.includes(getStorage('level')) ? () => { this.changeModal(true) } : () => {
                        if (compareOff === 2) {
                            this.addComItem(record.id, record.other_name)
                        }
                        if (compareOff === 3) {
                            this.addComItem(record.id, record.name)
                        }
                        if (compareOff === 4) {
                            this.addComItem(record.company_id, record.other_name)
                        }
                    }}>
                    <i className={cs('iconfont icon-compare', { 'active': isActive })}></i>
                </span >
            }
        }]
        const compareLists = this.props.compareCompanyItems, compareL = compareLists.length
        // 公司对比需要传id 跟名字才能触发弹窗  
        // compareOff 1:公司详情右上角  2:公司列表页面表格中的对比 3:科创板产业图谱表格的对比  4:科创板申报企业表格的对比  5:我的专辑里面收藏的公司列表 待整合
        return (
            <>
                {
                    compareOff === 1 && <Tooltip placement="top" title={'对比'} onClick={ TEST_USER.includes(getStorage('level')) ? ()=> this.changeModal(true) : () => this.addComItem(String(id), other_name)}>
                        <i className='iconfont icon-compare'></i>
                    </Tooltip>
                }
                {/* 权限弹窗 */}
                <Tootip1 value={visible} changeModal={this.changeModal} />
                {
                    compareOff > 1 && <>
                        <Table
                            rowSelection={rowSelection} // 行内收藏
                            dataSource={tableData}
                            columns={this.changeColumns(compareOff).concat(compareTable)}
                            pagination={ compareOff === 4 }
                            className={className}
                            rowKey={record => record.id}
                        />
                        {
                            compareOff === 2 && TEST_USER.includes(getStorage('level')) && <Tootip3 page={currentPage} aim={3} text= '升级完整版，查看全部企业'/>
                        }
                        {
                            compareOff !== 4 && <PageCustom
                                total={total > 5000 ? 5001 : total}
                                currentPage={currentPage}
                                pagesize={20}
                                changeStatePage={changeStatePage}
                            />
                        }
                    </>
                }

                {/* 当选中数量大于1出现弹窗 */}
                {
                    compareL > 0 && <>
                        <div className={cs('compare-box', { 'active': openCompare })}>
                            <h1 className='compare-title' onClick={this.toggleActive}>
                                公司对比（{`${compareL}`}/3）
                            </h1>
                            <ul className='body'>
                                {
                                    compareLists.map(list => <li key={list.id}>
                                        <span className='iconfont icon-modal-close' onClick={() => this.deleteOneId(list.id)}></span>
                                        {list.name}
                                    </li>)
                                }
                            </ul>
                            {
                                <div className='footer'>
                                    <Button onClick={() => this.props.clearComItem()}>清空</Button>
                                    {
                                        compareL === 1 ? <Button onClick={this.goAddCompare} type='primary'>继续添加</Button>
                                            : 
                                            <Button type='primary' onClick={()=>this.props.history.push('/companycomparison')}>开始对比</Button>
                                            // <Button type='primary' onClick={this.openCompareModal}>开始对比</Button>
                                    }
                                </div>
                            }
                        </div>
                        <Modal
                            title="公司对比"
                            visible={this.state.modalVisible}
                            onCancel={this.closeCompareModal}
                            footer={null}
                            className='compare-items'
                            width={'80%'}
                            style={{ top: 120 }}
                            closeIcon={<i className="iconfont icon icon-modal-close"></i>}
                        >
                            <CompareItem ids={compareLists.map(l => l.id)}
                                DataArray={DataArray} ChainData={ChainData}
                                FinanceData={FinanceData} idsData={idsData}
                                CompareLoading={CompareLoading} FinanceLoading={FinanceLoading}
                                ChainLoading={ChainLoading}
                                DtIndex={DtIndex} DtLoading={DtLoading} // Dt指数
                                PatentData={PatentData} PatentLoading={PatentLoading}
                            />
                        </Modal>
                    </>
                }
            </>
        )
    }
}

export default CompareBtn

function findChainLists(id, data) {
    let Chain = { id: id, lists: [] }
    data.forEach(chain => {
        for (let [k, v] of Object.entries(chain)) {
            typeof v === 'object' && v.forEach(vkey => {
                vkey.items.forEach(item => {
                    if (item.id === String(id)) {
                        let obj = {
                            first: chain.name,
                            second: vkey.name,
                            position: k
                        }
                        Chain['lists'].push(obj)
                    }
                })
            })
        }
    })
    return Chain
}