import React,{ Component } from 'react'
import { Skeleton,Button,Tag } from 'antd'
import { SelectTags } from 'comps/select-tags'
import { arrayTurnState,filterRemoveTag,filterId } from 'comps/select-tags/Fn'

const allObj = [{id:0,name:'全部'}]

class FilterList extends Component{
    state = {
        FilterLoading : true,OriginLevels:allObj,OriginTypes:allObj,OriginArea:allObj,
        displayTags:{
            'levelTags':allObj, 'typeTags':allObj,'areaTags':allObj,'techTags':allObj
        }
    }
    componentDidMount(){
        this.getLevelType()
        this.propsGetParkList()
    }
    // 选中tag
    tagsChange = (id, checked,tag,type)=>{
        const { displayTags,scopesList } = this.state
        let nextSelectedTags,nextDisplayTags = {...displayTags},
            state = displayTags[`${type}Tags`] // 取当前type对应的state
        if (id){ // 不是全部 id不是0
            // 多选
            nextSelectedTags = arrayTurnState(checked,state,tag,type)
            nextSelectedTags = nextSelectedTags.length === 0 ? allObj : nextSelectedTags
        } else { // 选择全部 选择ID是0
            nextSelectedTags = allObj
        }
        let obj = {}
        obj[`${type}Tags`] = nextSelectedTags
        this.setState({
            displayTags:Object.assign(displayTags,obj)
        },()=>{ this.propsGetParkList() })
    }
    // 单个tag close
    tagClose = removeTag =>{
        const { displayTags,subScopesId } = this.state
        let nextDisplayTags  = JSON.parse(JSON.stringify(displayTags)) // 深拷贝
        for (let key of Object.keys(displayTags)){ // 匹配到removeTag 然后过滤掉
            if ( nextDisplayTags[key].some(tag => tag.id === removeTag.id) && nextDisplayTags[key].some(tag => tag.name === removeTag.name)){
                nextDisplayTags[key] = filterRemoveTag(nextDisplayTags[key],removeTag)
            }
        }        
        this.setState({
            displayTags:nextDisplayTags
        },()=>{ this.propsGetParkList() })
    }
    // 清空筛选项
    clearFilter = ()=>{
        this.setState({
            displayTags:{
                'levelTags':allObj, 'typeTags':allObj,'areaTags':allObj,'techTags':allObj
            }
        },()=>{
            this.propsGetParkList()
        })
    }
    // 获取技术标签的id
    getSearchId = v =>{
        const { displayTags } = this.state
        this.setState({ 
            displayTags:{...displayTags,'techTags':[{id:v.split('-')[0],name:v.split('-')[1]}]}
        },()=>{
            this.propsGetParkList()
        })
    }
    propsGetParkList = ()=>{
        this.props.getParkList(this.state.displayTags,true)
    }
    // 获取园区类型和等级的数据
    getLevelType = ()=>{
        this.axios.post('/park/level').then(res=>{
            this.setState({
                OriginLevels:this.state.OriginLevels.concat(res.data)
            })
        })
        this.axios.post('/park/type').then(res=>{
            if (res.code === 10000){
                this.setState({
                    FilterLoading:false,
                    OriginTypes:this.state.OriginTypes.concat(res.data)
                })
            }
        })
        this.axios.post('/area',{ pid:100000,type:1 }).then(res=>{
            if (res.code === 10000){
                this.setState({
                    FilterLoading:false,
                    OriginArea:this.state.OriginArea.concat(res.data).filter(l => l.id !== 900000) // 过滤钓鱼岛
                })
            }
        })
    }
    render(){
        const { displayTags } = this.state
        // 显示选中的tag 过滤全部
        let checkedBox = []
        Object.values(displayTags).forEach( v => checkedBox.push(...( v.filter(tag=> tag.id !== 0) )) )
        return (
            <Skeleton active paragraph={{ rows: 5 }} loading={this.state.FilterLoading}>
                <SelectTags
                    TagsData={this.state.OriginTypes} 
                    handleChange={(id,checked,tag,type) => this.tagsChange(id, checked,tag,'type')} 
                    selectedTags={displayTags.typeTags}
                    text='园区类型'
                    isFold = {true}
                />
                <SelectTags
                    TagsData={this.state.OriginLevels} 
                    handleChange={(id,checked,tag,type) => this.tagsChange(id, checked,tag,'level')} 
                    selectedTags={displayTags.levelTags}
                    text='园区等级'
                    isFold = {false}
                />
                <SelectTags
                    TagsData={this.state.OriginArea} 
                    handleChange={(id,checked,tag,type) => this.tagsChange(id, checked,tag,'area')} 
                    selectedTags={displayTags.areaTags}
                    text='所在地区'
                    isFold = {true}
                />
                {/* 显示选中的tag */}
                {
                    checkedBox.length > 0 && 
                    <div className='checked-box flex_box'>
                        <p>
                            {checkedBox.map(tag=>{
                                return <Tag closable key={`${tag.id}${tag.name}`} 
                                    className={tag.type === 'sub' ? 'sub-tag' : ''}
                                    onClose={e => {
                                        e.preventDefault()
                                        this.tagClose(tag)
                                    }}>
                                    {tag.name || tag.ShortName}
                                </Tag>
                            })}
                        </p>
                        {/* 清除筛选项 */}
                        <Button onClick={this.clearFilter}>清除筛选</Button>
                    </div>
                }
            </Skeleton>
        )
    }
}
export default FilterList
