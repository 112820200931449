import React from 'react'
import className from 'classnames'
const Thesis = props => {
    return <div className={className("list",{
        'list-no-border-btom': props.botmBorder
    })}>
        <div className="item">
            <div className="title">{props.title}</div>
            <div className="body">
                {props.authors && <div className="info">【作者】{props.authors}</div>}
                {props.journal_cn && <div className="info">【刊物】{props.journal_cn}</div>}
                {props.pub_date && <div className="info">【时间】{props.pub_date}</div>}
                <div className="info">【被引用数】{props.citations}</div>
            </div>
        </div>
    </div>
}

export default Thesis