import React from 'react'
import { Skeleton } from 'antd'
import classNames from 'classnames'

// 产业链匹配文案
const ChainPostion = {
    '1':"上游-hight",'2':'中游-middle','3':'下游-low',
}

const ChainCompare = props => {
    const { ChainData,ChainLoading } = props
    return (
        ChainData.some(l => l.lists.length > 0) && <section className='chain index'>
            <h1>产业链对比</h1> 
            <ul className='index-ul'>
                <div className='flex_box'>
                    <Skeleton active paragraph={{ rows: 5 }} loading={ChainLoading}>
                        {
                            ChainData.map(chain =>{
                                return <dl key={chain.id}>
                                    {
                                        chain.lists.map(list=>{
                                            return <dd className={classNames( ChainPostion[list.position].split('-')[1])}>
                                                <h1>{ChainPostion[list.position].split('-')[0]}</h1>
                                                <h1>{list.first} - {list.second}</h1>
                                            </dd>
                                        })
                                    }
                                </dl>
                            })
                        }
                    </Skeleton>
                </div>
            </ul>
        </section>
    )
}
export default ChainCompare