import React, { useState } from 'react'
import EchartReact from 'comps/Sign/EchartReact'


export default function Bend(props) {
    const [und,] = useState(undefined)
    if (props.company !== und) {
        let year = Object.keys(props.company)
        let company = Object.values(props.company)
        let paper = Object.values(props.paper)
        return (<EchartReact
            id={props.id}
            options={getOption(year, company, paper)}
            style={{ width: '820px', height: '420px' }}
        />)
    } else {
        return false
    }
}

function getOption(year, company, paper) {
    return {
        legend: {
            show: true,
            orient: 'vertical',
            right: 0,
            bottom: 100,
            itemHeight: 10,
            itemWidth: 10,
            icon: 'circle',
            data: [
                { name: '论文数' },
                { name: '企业成立数' }
            ]
        },
        xAxis: {
            name: '年份',
            type: 'category',
            data: year,
            axisLine: { show: false },
            axisLabel: {
                textStyle: {
                    color: '#9AA1A9'
                }
            },
            axisTick: {
                show: false
            }
        },
        yAxis: {
            name: '数量',
            type: 'value',
            splitLine: {
                show: false
            },
            axisLine: { show: false },
            axisLabel: {
                textStyle: {
                    color: '#9AA1A9'
                }
            },
            axisTick: {
                show: false
            }
        },
        series: [
            {
                data: paper,
                type: 'line',
                name: '论文数',
                smooth: true
            },
            {
                data: company,
                type: 'line',
                name: '企业成立数',
                smooth: true
            }
        ],
        color: ['#6A72B9', '#EAEAFF']
    }
}