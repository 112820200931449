import React from 'react'
import moment from 'moment'
const Policy = props => {
    return (
        <div className="policy">
            <span className="time">{moment(props.notice_date * 1000).format('YYYY-MM-DD')}</span>
            <a href={'/board/general/policy/' + props.id} className="link">{props.title}</a>
        </div>
    )
}

export default Policy