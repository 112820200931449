import React from 'react'
import moment from 'moment'
const Piece = props => {
    return (
        <div className="item">
            <div className="title">{props.title}</div>
            <div className="content" dangerouslySetInnerHTML={{__html:props.content}}></div>
            <div className="time">{moment(props.start_time * 1000).format('YYYY年MM月DD日')}</div>
        </div>
    )
    
}

const News = props => {
    return (
        <div className="news">
            <div className="head">
                <span className="text">资讯</span>
                <a href="/home/news" className="more">查看更多</a>
            </div>
            <div className="box">
                {
                    props.list.map((item,index)=>(index < 10 && <Piece key={item.id} {...item} />))
                }
            </div>
        </div>
    )
}
 
export default News