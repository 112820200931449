import React, { Component, useEffect, useState } from 'react'
import axios from 'libs/axios'
import Legal from './Legal'
import Pyear from './Pyear'
import { CompareEmptyEmage } from 'comps/common/EmptyComp'

function PatentContent(props) {
    const { id, name } = props
    const [legal, setLegal] = useState([]) // 饼图信息
    const [trend, setTrend] = useState([]) // 专利趋势

    useEffect(() => {
        // 饼图
        axios.post('/expert/academic', { id }).then(res => {
            if (res.code === 10000) {
                setLegal(res.data.patentLegal)
            }
        })
        // 专利趋势
        axios.post('/expert/patentlist', { id }).then(res => {
            if (res.code === 10000) {
                setTrend(res.data.yearInfo)
            }
        })
    }, [id])

    return (
        <div className='patent-item'>
            <div className="patent-bottom">
                <div className="patent-left">
                    {
                        Object.keys(legal).length > 0 ? <Legal id={`legal${id}`} data={legal} /> : <CompareEmptyEmage />
                    }
                </div>
                <div className="patent-right">
                    {
                        Object.keys(trend).length > 0 ? <Pyear id={`pyear${id}`} data={trend} /> : <CompareEmptyEmage />
                    }
                </div>
            </div>
        </div>
    )
}

export default PatentContent