import React, { Component } from 'react'
import * as echarts from 'echarts'

class Radar extends Component {

    componentDidUpdate() {
        let radar = echarts.init(document.getElementById(this.props.id))
        radar.setOption({
            color: ['#359EFF'],
            tooltip:{
                show: true,
            },
            radar: {
                name: {
                    textStyle: {
                        color: '#7c8793',
                    }
                },
                
                indicator: [
                    { name: '论文', max: 45 },
                    { name: '专利', max: 30 },
                    { name: '成果转化', max: 5 },
                    { name: '荣誉/任职', max: 10 },
                    { name: '教育', max: 10 },
                ],
                splitArea: {
                    areaStyle: {color:['rgba(255,255,255,1)']}
                }
            },
            series: [{
                type: 'radar',
                areaStyle: {
                    color: "rgba(58,157,248,0.4)"
                },
                data: [
                    {
                        value: this.props.score,
                        name: '专家指数'
                    }
                ]
            }]
        })
    }

    render() {
        return (<div id={this.props.id} style={{ width: '240px', height: '220px' }} ></div>)
    }
}

export default Radar