import React from 'react'
import classNames from 'classnames'

const footerNav = [
    { href:'#info',name:'产品介绍'},
    { href:'#solve',name:'解决方案'},
    // { href:'#about',name:'关于我们'}
]

const Footer = props =>{
    const { hasAside } = props
    return (
        <footer className={classNames('flex_box container layout-footer',{'has-aside':hasAside})} id='about'>
            <div className='flex_box'>
                {/* <img src='http://deeptechchina.com/static/media/logo-black.19ce4473.svg' alt='home-logo' />
                <p>
                    {
                        footerNav.map(nav => <a href={nav.href}>{nav.name}</a>)
                    }
                </p>
                <span>Copyright@2020 北京演绎科技有限公司 京 ICP 备 16022657 号</span> */}
            </div>
            <dl>
                <dt>联系我们</dt>
                <dd>电话：18693418964</dd>
                {/* <dd>邮箱：service@deeptechchina.com</dd>
                <dd>地址：北京朝阳区东三环北路好世界广场三层</dd> */}
            </dl>
        </footer>
    )
}
export default Footer