import React, { Component } from 'react'
class Research extends Component {
    constructor(props) {
        super(props)
        this.state = {
            list: {
            }
        }
    }
    componentDidMount() {
        // this.axios.post('/expert/researcharea', { id: this.props.id })
        //     .then(res => {
        //         this.setState({
        //             list: res.data
        //         })
        //     })
    }
    render() {
        return (
            <div className="academic">
                {Object.values(this.props.researchData).map(item => <div key={item.id} className="part">
                    <div className="head">{item.name}</div>
                    <ul className="body">
                        {item.items.map(item => <li key={item.id}>{item.name}</li>)}
                    </ul>
                </div>)}
            </div>
        )
    }
}

export default Research
