import React, { useEffect, useState } from 'react'
import { Input, Row, Col, Skeleton  } from 'antd'
import { RightOutlined } from '@ant-design/icons'
import EffectScatter from 'pages/echarts/EffectScatter'
import { IndiEffectSchoolBar } from 'pages/echarts/bar'
import { CustomEmptyImage } from 'comps/common/EmptyComp'
import axios from 'libs/axios'

const PersonEffect = props => {
    const [ stepNum, setStep ] = useState(-1)    // 当前的步骤 0-省份，1-城市，2-领域
    const [ province, setProvince ] = useState([])  // 初始化的省份数组
    const [ cities, setCities ] = useState([])  // 筛选出的城市数组
    const [ proObj, setProObj] = useState({name:''})    // 选中的省份
    const [ cityObj, setCityObj ] = useState({name:''})     // 选中的城市
    const [ fields, setFields ] = useState([])  // 获取选中城市下的领域列表
    const [ fieldObj, setFiledObj ] = useState({name:''})   // 选中的领域
    const [ inputValue, setInputValue ] = useState('')      // input 值
    const [ defaultValue, setDefaultValue ] = useState('请选择地区领域') // 默认的input值
    const [ scholarData, setScholarData ] = useState(null)     // 学者分布数据
    const [ schoolData, setSchoolData ] = useState(null)      // 院校分布数据

    /**
     * 获取影响力排行的 学者分布 院区分布的数据
     * @param {*} fieldID 选中领域的ID
     * @param {*} localID 选中的城市或者全部城市的省份ID
     */
    const getSchoolScholar = (fieldID,localID) => {
        setScholarData(null)
        setSchoolData(null)
        // 学者分布
        axios.post('/expert/distribution',{ field:fieldID, local:localID }).then(res=>{
            if (res.code === 10000){
                let arr = []
                res.data.forEach(item => {
                    // [专利数量，论文数量，学者名字]
                    arr.push([item.total_patent, item.total_paper, item.name_cn])
                })
                setScholarData(arr)
            }
        })
        // 获取院校分布
        axios.post('/expert/school',{ field:fieldID, local:localID, num:10 }).then(res=>{
            if (res.code === 10000){
                setSchoolData(res.data)
            }
        })
    }
    /**
     * 获取城市下面的领域列表
     * @param {*} id 城市的ID或者全部城市的省份的ID
     * @param {*} name 城市或者省份的name
     * @param {*} first 是否初始渲染
     */
    const getFieldData = (id,name,first)=> {
        setFields([])
        axios.post('/expert/field',{local:id}).then(res=>{
            if (res.code === 10000){
                // 如果是初始渲染页面 
                if (first){
                    setFiledObj({id:res.data[0].id, name:res.data[0].name})
                    setDefaultValue(`${name}#${res.data[0].name}`)
                    setInputValue(`${name}#${res.data[0].name}`)
                    getSchoolScholar(res.data[0].id,id)
                }
                setFields(res.data) // 领域下的数据
            }
        })
    }
    
    useEffect(()=>{
        // 获取省份和城市
        axios.post('expert/local').then(res=>{
            if (res.code === 10000){
                setCityObj({id:res.data[0].items[0].id, name:res.data[0].items[0].name}) // 设置默认城市ID
                setProvince(res.data)
                return {id:res.data[0].items[0].id, name:res.data[0].items[0].name} // 返回城市的ID
            }
        }).then(({id,name})=>{
            getFieldData(id,name,true)
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    // 关闭选择框
    const closeSteps = () => {
        setStep(-1)
        setInputValue(defaultValue)
    }

    // 选择省份
    const selectProvince = (id,name) =>{
        setProObj({id,name})
        setInputValue(`${name}`)
        // 筛选省份下的城市
        setCities([{id:0,name:'全部'}].concat(province.filter(p => p.id === id)[0].items))
        next()
    }

    // 选择城市
    const selectCity = (id,name) => {
        let localID = proObj.id
        // 区分是否选择的全部
        // id===0 选择全部的情况 - 默认是选择当前的省份
        if (id === 0){
            setInputValue(`${proObj.name}`)
        } else {
        // 选择子城市的情况
            localID = id
            setInputValue(`${name}`)
        }
        setCityObj({id,name})
        next()
        getFieldData(localID,name,false)
    }

    // 选中领域
    const selectField = (id,name) => {
        const localID = cityObj.id !== 0 ? cityObj.id : proObj.id   // 确定城市的ID
        getSchoolScholar(id, localID)     // 获取选中城市下的领域的数据 
        setFiledObj({id,name})
        setInputValue(`${cityObj.id !== 0 ? cityObj.name : proObj.name}#${name}`)  // input value设置
        setStep(-1) // 选择完领域 弹框消失
    }
    // input 事件
    const inputChange = () => {
        if (stepNum === -1){
            setStep(0)
        }
    }
    // 完成一步，进行下一步
    const next = () =>  setStep(stepNum + 1)
    // 回到上一步
    const prev = () => setStep(stepNum - 1)
    return <>
        <h1 className='indi-title'>人才发掘</h1>
        <div className='select-box'>
            <div id='input-mask' 
                onClick={closeSteps}
                className={stepNum < 0 ? 'hidden-mask input-mask' : 'input-mask'}></div>
            <Input  placeholder={defaultValue}  value={inputValue}  onClick={inputChange}/>
            {/* 选择城市和领域的框 */}
            { !(stepNum < 0) && <div className="steps-content select-steps-custom">
                {/* 省份 */}
                {
                    stepNum === 0 && <div className='select-list'>
                        <h1 className='flex_box'> 
                            <span>请选择地区</span>
                        </h1>
                        <ul>
                            {
                                province.map(pro => <li 
                                    className={proObj.id === pro.id ? 'active flex_box' : 'flex_box'}
                                    key={pro.id} onClick={()=>{selectProvince(pro.id,pro.name)}}>
                                    {pro.name}  <RightOutlined />
                                </li>)
                            }
                        </ul>
                    </div>
                }
                {/* 城市 */}
                {
                    stepNum === 1 && <div className='select-list'>
                        <h1 className='flex_box'> 
                            <span>{proObj.name}</span>
                            <span onClick={()=> setStep(0)}>重选</span>
                        </h1>
                        <ul>
                            {
                                cities.map(pro => <li 
                                    className={cityObj.id === pro.id ? 'active flex_box' : 'flex_box'}
                                    key={pro.id} onClick={()=>{selectCity(pro.id,pro.name)}}>
                                    {pro.name}  <RightOutlined />
                                </li>)
                            }
                        </ul>
                    </div>
                }
                {/* 领域 */}
                {
                    stepNum === 2 && <div className='select-list'>
                        <h1 className='flex_box'> 
                            <span>{cityObj.name || proObj.name}</span>
                            <span onClick={()=> setStep(0)}>重选地区</span>
                        </h1>
                        <ul>
                            <Skeleton active paragraph={{ rows: 10 }} loading={fields.length === 0}>
                                {
                                    fields.map(pro => <li 
                                        className={fieldObj.id === pro.id ? 'active flex_box' : 'flex_box'}
                                        key={pro.id} onClick={()=>{selectField(pro.id,pro.name)}}>
                                        {pro.name}
                                    </li>)
                                }
                            </Skeleton>
                        </ul>
                    </div>
                }
            </div>}
        </div>
        <Row className='effect-person-charts'>
            <Col span={12}>
                <Skeleton active paragraph={{ rows: 10 }} loading={ schoolData === null }> 
                    {
                        schoolData !== null && schoolData.length > 0 
                            ? <IndiEffectSchoolBar data={schoolData} id={`indi-bar${cityObj.id}-${fieldObj.id}`} />
                            : <CustomEmptyImage />
                    }
                </Skeleton>
            </Col>
            <Col span={12}>
                <Skeleton active paragraph={{ rows: 10 }} loading={ scholarData === null }> 
                    {
                        scholarData !== null && scholarData.length > 0 
                            ? <EffectScatter isTitle={true} data={scholarData} id={`indi-scatter${cityObj.id}-${fieldObj.id}`}/>
                            : <CustomEmptyImage />
                    }
                </Skeleton>
            </Col>
        </Row>
        
    </>
}

export default PersonEffect