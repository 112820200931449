import React, { Component } from 'react'
import { RightOutlined } from '@ant-design/icons'
import { Layout, Table, Button } from 'antd'
// 组件
import BreadComp from 'comps/bread'  // 面包屑导航
import FilterList from './FilterList'
import PageCustom from 'comps/PageCustom'
import { filterId } from 'comps/select-tags/Fn'
import { ListColumns } from 'utils/columns'
import { getStorage } from 'utils/storage'
import { TEST_USER } from 'utils/type'
import 'styles/comps/collect.scss'
import { Tootip3 } from 'comps/tootip/Tootip'
const { Content } = Layout
const BreadData = [{ url: '/home', title: '首页' }, { url: '', title: '地理' }]

class ParkList extends Component {
    state = {
        ListData: [], total: 0, currentPage: 1, displayTags: {},
        isParkUser: getStorage('level') === 2 || getStorage('level') === 6
    }
    componentDidMount() {
        document.title = '地理'
    }
    // 请求园区列表 第一次改变筛选项请求时（first:true）当前页面归1；改变页码时(first:false)页码会变化
    changeDisplayTags = (displayTags, first) => {
        if (first) {
            this.setState({
                displayTags, currentPage: 1
            }, () => {
                this.getParkList()
            })
        } else {
            this.getParkList()
        }
    }
    getParkList = () => {
        const { currentPage, displayTags } = this.state
        let level = filterId(displayTags.levelTags),
            category = filterId(displayTags.typeTags), location = filterId(displayTags.areaTags)
        this.axios.post('/park/list', { level, category, location, page: currentPage })
            .then(res => {
                if (res.code === 10000) {
                    this.setState({
                        ListData: res.data.items,
                        total: res.data.count,
                    })
                }
            })
    }
    // 改变当前页码
    changeStatePage = page => {
        this.setState({
            currentPage: page
        }, () => {
            this.changeDisplayTags(this.state.displayTags, false)
        })
    }
    render() {
        const { ListData, isParkUser } = this.state
        return (
            <Layout className='park-container company-con'>
                <Layout className='layout-rt'>
                    <BreadComp data={BreadData} />
                    <Content className='park-main'>
                        <section id='parklist' className='parklist'>
                            <h1 className='flex_box'>
                                园区列表
                                {
                                    isParkUser && <Button type="link" href='/platform'>
                                        我要招商 <RightOutlined />
                                    </Button>
                                }
                            </h1>
                            {/* 筛选项列表 */}
                            <FilterList getParkList={this.changeDisplayTags} />
                            <p className='top-border'></p>
                            <Table
                                dataSource={ListData} columns={ListColumns}
                                className='custom-table park-table'
                                bordered={false}
                                pagination={false}
                                rowKey={record => record.id}
                            ></Table>
                            {
                                TEST_USER.includes(getStorage('level')) && <Tootip3 page={this.state.currentPage} aim={3} text='升级完整版，查看全部园区' />
                            }
                            <PageCustom total={this.state.total} currentPage={this.state.currentPage} pagesize={20}
                                changeStatePage={this.changeStatePage}
                            />
                            {/* </div> */}

                        </section>
                    </Content>
                </Layout>
            </Layout>
        )
    }
}
export default ParkList