import React, { Component } from 'react'
import * as echarts from 'echarts'
import EchartReact from 'comps/Sign/EchartReact'
class Region extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    getOption = data => {
        let areas = []
        data.map(item=>{
            areas.push({name:item.name,value:item.num})
            return areas
        })
        return {
            visualMap: {
                show: true,
                type: 'piecewise',
                min: 0,
                max: 2000,
                align: 'right',
                top: '40%',
                right: 0,
                left: 'auto',
                pieces: [
                    { min: 20, color: '#02375A' },
                    { min: 16, max: 20, color: '#044E79' },
                    { min: 10, max: 15, color: '#0F6A94' },
                    { min: 7, max: 10, color: '#2583AF'},
                    { min: 3, max: 6, color: '#2583AF' },
                    { min: 1, max: 3, color: '#3FA3D2' },
                    { value: 0, color: '#A6D0E4'}
                ],
                padding: 5,
                orient: 'vertical',
                showLabel: true,
                text: ['高', '低'],
                itemWidth: 10,
                itemHeight: 10,
                textStyle: {
                    fontSize: 10
                }
            },
            series: [{
                type: 'map',
                name: '地图',
                silent: false,
                map: 'china',
                label: {
                    show: true,
                    position: 'inside',
                    fontSize: 6
                },
                data: areas,
                zoom: 1.2,
                roam: false,
                showLegendSymbol: false,
                itemStyle: {
                    areaColor: '#80BCD9',
                    emphasis: {//鼠标移入高亮显颜色
                        areaColor: '#3DE1E6'
                    }
                },
                rippleEffect: {
                    show: true,
                    brushType: 'stroke',
                    scale: 2.5,
                    period: 4
                }
            }]
        }
    }
    render() {
        return (<EchartReact id="china" options={this.getOption(this.props.data)} style={{ width: '600px', height: '600px'}} />)
    }
}

export default Region