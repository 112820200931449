import React, { Component } from 'react'
import { Layout, Skeleton, Button } from 'antd'
import moment from 'moment'
import BreadComp from 'comps/bread'  // 面包屑导航
import MyFavarTable from './MyFavarTable' //收藏内容列表
// import EditBtn from './EditBtn' // 编辑 删除按钮
import { COL_TYPE } from 'utils/type'
// import CreateBtn from './CreateBtn'
// import SwiperComponent from './SwiperComponent'
import { withRouter } from 'react-router-dom'
import { Tootip1 } from 'comps/tootip/Tootip'

import 'styles/pages/collection.scss'

const { Content } = Layout

@withRouter
class MyCollectionDetail extends Component {
    state = {
        BreadData: [{ url: '/home', title: '首页' }, { url: '/mycollection/0', title: '专辑列表' },{ url: '', title: '专辑详情' }],
        editData: {},
        page: 1,
        total: null,
        myCollectReq: {
            page: 1,
            size: 10,
            data_type: null
        },
        myCollectDetailData: [],
        myCollectDetailLoading: true,
        MyCollectDetailId: this.props.match.params.id,
        visible: false // vip权限弹窗
    }
    componentDidMount() {
        this.setState({
            myCollectReq: this.props.location.state && this.props.location.state.myCollectReq ? this.props.location.state.myCollectReq : {
                page: 1,
                size: 10,
                data_type: null
            }
        })
        this.getMyCollectDetail()
    }

    //取消详情
    handleCancle = () => {
        this.props.history.push('/mycollection/0')
    }

    // 获取我的专辑详情
    getMyCollectDetail = () => {
        const id = this.state.MyCollectDetailId
        this.axios.post('/collection/mydetail', { id }).then(res => {
            if (res.code === 10000) {
                this.setState({
                    myCollectDetailData: res.data,
                    myCollectDetailLoading: false
                })
            }
        })
        this.props.history.push({ pathname: `/mycollection/detail/${id}`, state: { id: id, myCollectReq: this.state.myCollectReq } })
    }

    // vip权限弹窗
    changeModal = value => {
        this.setState({ visible: value })
    }
    render() {
        const { infoLoading, myCollectDetailData } = this.state
        return (
            <Layout className='collect-box mydetail-box'>
                {/* 权限弹窗 */}
                <Tootip1 value={this.state.visible} changeModal={this.changeModal} />
                <Skeleton active loading={this.state.myCollectDetailLoading}>
                    <div className="cancel-btn" onClick={this.handleCancle}>
                        <i className="iconfont icon-guanbi"></i>
                    </div>
                    {/* <SwiperComponent></SwiperComponent> */}
                    <BreadComp data={this.state.BreadData} />
                    <Layout className='collect-box  collection-con mycollect-con' id={this.state.MyCollectDetailId}>
                        {/* 创建新的专辑 */}
                        {/* <div className="creat-btn-box"> <CreateBtn /> </div> */}
                        <div className="mycollecttab">
                            {/* 右侧详情 */}
                            <Layout className='collection-con'>
                                <Layout className='collect-rt'>
                                    <Content className='collect-main'>

                                        {/* 基本信息 */}
                                        <section className="my-basic">
                                            {
                                                Object.keys(myCollectDetailData).length > 0 && <Skeleton active paragraph={{ rows: 4 }} loading={infoLoading}>
                                                    <h1>{myCollectDetailData.name}</h1>
                                                    <p className='des'>{myCollectDetailData.describe}</p>
                                                    <p className='statistics'>
                                                        <span>共<strong>{myCollectDetailData.items.length}</strong>
                                                            {COL_TYPE[`${myCollectDetailData.data_type}`].unit}{COL_TYPE[`${myCollectDetailData.data_type}`].name}
                                                        </span>
                                                        <span>创建于：{moment(myCollectDetailData.create_time * 1000).format('YYYY年MM月DD日')}</span>
                                                    </p>
                                                    {/* <EditBtn id={this.props.id} editData={editData} getDetail={this.getDetail}/> */}
                                                </Skeleton>
                                            }

                                        </section>
                                        {/* 列表 */}
                                        <section className="my-lists">
                                            <Skeleton active paragraph={{ rows: 10 }} loading={infoLoading}>
                                                <h1 className="flex_box title">
                                                    <div>
                                                        {myCollectDetailData.data_type && `${COL_TYPE[`${myCollectDetailData.data_type}`].other_name}`}列表
                                                    </div>
                                                    <section className='add-lists'>
                                                        <Button href={myCollectDetailData.data_type && `${COL_TYPE[`${myCollectDetailData.data_type}`].more}`}>添加新条目</Button>
                                                    </section>
                                                </h1>

                                                <MyFavarTable data={myCollectDetailData.items} type={myCollectDetailData.data_type} getDetail={this.getMyCollectDetail}/>
                                            </Skeleton>
                                        </section>

                                    </Content>
                                </Layout>
                            </Layout>
                        </div>
                    </Layout>
                </Skeleton>
            </Layout>


        )
    }
}

export default MyCollectionDetail
