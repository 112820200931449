import React from 'react'
import LabelBox from './LabelBox'
import { Link } from 'react-router-dom'
const Rank = props => {
    return (
        <div className="rank">
            <div className="label-wrap"><LabelBox label="green" text="榜单" color="#088878" /></div>
            <a href={'/collection/' + props.id} className="rank-title" style={{borderColor:'#088878'}}>{props.title}</a>
            <div className="list">
                {props.list.map((item, index)=> <Cell key={index} order={index} {...item} dataType={props.dataType}/>)}
            </div>
        </div>
    )
}
 

const Cell = props => {
    return (
        <div className="cell">
            <div className="order">{(props.order > 2 ) ? props.order + 1 : ''}</div>
            {/* <div className="name">{props.object_name}</div> */}
            {
                <Link to={props.is_normal === 1 ? (props.dataType && props.dataType === 2 ? '/person/detail/' + props.object_id : '/company/' + props.object_id) : undefined}>
                    <div className="name">{props.object_name}</div>
                </Link> 
            }
            <div className="school">{props.desc}</div>
        </div>
    )
}

export default Rank