import React from 'react'

const Cell = props => {
    return (
        <a href={props.link} className="cell">
            <svg className="symbol_icon" aria-hidden="true">
                <use xlinkHref={`#${props.icon}`}></use>
            </svg>
            <p className="text">{props.text}</p>
        </a>
    )
}

const Heading = props => {
    return (
        <div className={props.list.length === 4 ? 'heading nopark' : 'heading'}>
            {props.list.map(item=><Cell key={item.id} {...item} />)}
        </div>
    )
}
 
export default Heading 