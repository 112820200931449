import React, { PureComponent } from 'react'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import { COL_TYPE } from 'utils/type'

class FavarList extends PureComponent{
    state = {  open  : false }
    isOpen = ()=>{
        const { open } = this.state
        this.setState({ open: !open })
    }
    componentDidUpdate(prevProps,prevState){
        if (prevState.open){
            this.setState({ open : false })
        }
    }
    // 渲染名单列表  data:列表数据 type:专辑类型 决定跳转地址
    renderLinkHTML = (data,type) => {
        const lists = this.state.open ? data : data && data.slice(0,24)
        return lists.map((list,i) => <Link 
            to={ list.is_normal === 1 ? 
                `${COL_TYPE[`${type}`].href}/${list.object_id}` 
                : undefined } 
            key={list.object_id || i} 
            title={list.show_name || list.object_name}>
            {list.show_name || list.object_name}
        </Link>)
    }
    render(){
        const { data,type } = this.props
        return (
            <ul className='list_ul'>
                { this.renderLinkHTML(data,type) }
                {
                    data && data.length > 24 && <span onClick={this.isOpen} className='open_btn'>
                        { this.state.open ? '收起' : '展开'} 
                        <span className={classnames('icon iconfont',
                            {'icon-top-arrow':this.state.open,'icon-down-arrow':!this.state.open})}></span>
                    </span>
                }
            </ul>
        )
    }
}
export default FavarList