import React from 'react'
import moment from 'moment'
import formatType from 'utils/formatType'

class MyCollectionItem extends React.Component {

    render() {
        const {MyCollectionItemData} = this.props
        return (
            <div>
                {
                    this.props.isMyDetail ? <div className="mycollect-item mycollect-detail-item">
                        <div>{MyCollectionItemData.name}</div>
                    </div> : <div className="mycollect-item">
                        <div className="introduce">{MyCollectionItemData.name}</div>
                        <div className="all">{formatType(MyCollectionItemData.obj_num, MyCollectionItemData.data_type)}</div>
                        <div className="date">创建日期：{moment(MyCollectionItemData.create_time * 1000).format('YYYY年MM月DD日')}</div>
                    </div>
                }
            </div>
        )
    }
}

export default MyCollectionItem
