import React, { useEffect, useState } from 'react'
import { Layout } from 'antd'
import 'styles/official/demand.scss'
import Nav from './common/Nav'
import NewFooter from './common/NewFooter'
// import Footer from 'comps/navbar/Footer'
import { demandList, demandProfile } from 'utils/columns'
function Demand(){
    const timeStamp = new Date().getTime()
    const [activeIndex, setAvtiveIndex] = useState(0)
    const [isFlag, setIsFlag] = useState(false)
    const handleScroll = ()=>{
        const moduleOne = document.getElementById('moduleOne')
        const moduleTwo = document.getElementById('moduleTwo')
        const moduleThree = document.getElementById('moduleThree')
        if (moduleThree) {
            // console.log('document.documentElement.scrollTop - moduleThree.offsetTop---------',document.documentElement.scrollTop, moduleThree.offsetTop, moduleOne.offsetHeight, moduleTwo.offsetHeight)
            if (document.documentElement.scrollTop >= moduleOne.offsetHeight) {
                // let num = document.documentElement.scrollTop - moduleTwo.offsetTop < 150 ? 1 : Math.floor((document.documentElement.scrollTop - moduleThree.offsetTop - 130 * activeIndex ) / 280)
                let num = 0
                let mathNum = (document.documentElement.scrollTop - moduleThree.offsetTop + 150 - 200 * activeIndex ) / 200
                if (document.documentElement.scrollTop - moduleTwo.offsetTop < 150) {
                    num = 1
                    setAvtiveIndex(num > activeIndex ? num : activeIndex)
                } else
                if (mathNum > 1) {
                    if (num < 7) {
                        // setAvtiveIndex(num > activeIndex ? num : activeIndex)
                        if (activeIndex < 4) {
                            if (mathNum - activeIndex > 0.4) {
                                num = Math.floor(mathNum)
                                // console.log('--------------------', num, activeIndex)
                                setAvtiveIndex(num)
                            }
                        } else {
                            if (mathNum - activeIndex > 2) {
                                num = Math.floor(mathNum)
                                // console.log('--------------------', num, activeIndex)
                                setAvtiveIndex(num)
                            }
                        }
                    } else {
                        clearInterval(timer)
                        window.onscroll = null
                    }
                    // console.log('end---------', num, activeIndex)
                }
                
                // console.log('activeIndex------------------', num, activeIndex, document.documentElement.scrollTop - moduleOne.offsetHeight - moduleTwo.offsetHeight)
            }
            
        }
             
    }
    
    const timer = setTimeout(() => {
        window.onscroll = handleScroll
    })

    const imgLoad = e => {
        console.log('e-------------', e)
        const imgDom = new Image()
        imgDom.src = 'https://image.sciphi.cn/dt_insights/demand/demand-banner.jpg'
        // 图片加载完成使用正常的图片
        imgDom.onload = function () {
            setTimeout(() => {
                setIsFlag(true)
            }, 500)
            // setSrc(props.src)
            console.log('------------------')
        }
    }
    return (
        <Layout className='all-box-demand'>
            <Layout className='all-box-t'>
                <Nav />
                <img src={'https://image.sciphi.cn/dt_insights/demand/demand-banner.jpg?v=' +  timeStamp} alt='banner' className="main-banner" onLoad={imgLoad}/>
                <section className='module-one' id='moduleOne'>
                    <div className='one-body'>
                        <div className='title'>
                            <div>所有行业都是科技行业</div>
                            <div>都需要数据智能化升级</div>
                        </div>
                        <div className='intro-box'>
                            <img src={'https://image.sciphi.cn/dt_insights/demand/intro1.png?v=' +  timeStamp} alt='' className="intro-ban"/>
                            <img src={'https://image.sciphi.cn/dt_insights/demand/intro2.png?v=' +  timeStamp} alt='' className="intro-ban"/>
                        </div>
                    </div>
                </section>
            </Layout>
            <section className='module-two' id='moduleTwo'>
                {
                    isFlag ? <div className='two-body'>
                        <div className='title'>我们提供创新的前沿科技信息开放获取服务</div>
                        <div className='intro-box'>
                            {
                                demandList.map(item => (
                                    <div className="item"  key={item.type}>
                                        <div className='default-item'>
                                            <img src={item.type_icon + '?v=' +  timeStamp} alt='' className="type-icon"/>
                                            <div className="little-title">{item.type}</div>
                                            <div className="intro">
                                                {
                                                    item.intro.map(ite => (
                                                        <div key={ite}>{ite}</div>
                                                    ))
                                                }
                                            </div>
                                            <img src={'https://image.sciphi.cn/dt_insights/demand/symbol.png?v=' +  timeStamp} alt='' className="symbol-icon"/>
                                        </div>
                                        <div className='active-item animate__animated animate__fadeInUp'>
                                            <div className="little-title">{item.type}</div>
                                            <div className="intro">
                                                {
                                                    item.intro.map(ite => (
                                                        <div key={ite}>{ite}</div>
                                                    ))
                                                }
                                            </div>
                                            <div className='info'>
                                                {
                                                    item.info.map(ite => (
                                                        <div className="info-item" key={ite}>
                                                            <div className="lable"></div>
                                                            <div className='text'>{ite}</div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        
                        </div>
                    </div> : null
                }
                {/* <div className='zhedang'></div> */}
            </section>
            <section className='module-three' id='moduleThree'>
                <div className='three-body'>
                    {
                        demandProfile.map((item, index) => (
                            item.imgPosition === 'right' &&  activeIndex >= index + 1  ? <div className='item animate__animated animate__fadeInUp' key={item.title}>
                                <div>
                                    <div className="title">{item.title}</div>
                                    <div className="profile">{item.profile}</div>
                                </div>
                                <img src={item.img + '?v=' +  timeStamp} alt='' className="right-img"/>
                            </div> : item.imgPosition === 'left' && activeIndex >= index + 1 ? <div className='item left-item animate__animated animate__fadeInUp' key={item.title}>
                                <img src={item.img + '?v=' +  timeStamp} alt='' className="left-img"/>
                                <div>
                                    <div className="title">{item.title}</div>
                                    <div className="profile">{item.profile}</div>
                                </div>
                            </div> : null
                        ))
                    }
                   
                </div>
            </section>
            <NewFooter />
        </Layout>
    )
}

export default Demand