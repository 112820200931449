import React, { useState } from 'react'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { Row, Col, Radio } from 'antd'
import { Tootip1 } from 'comps/tootip/Tootip'
import { getStorage } from 'utils/storage'
import { TEST_USER } from 'utils/type'

/**
 * props
 * @param {Boolean} isDispalyTitle - 是否显示底部title eg:xx图表2/6
 * @param {Boolean} isDispalyRadio - 是否显示类型切换图标 table or line
 * @param {Boolean} isDispalyDown - 是否显示下载按钮
 * @param {Array} TabsTitle - tabs的name和key类型 eg:[{name:'投资趋势',key:'invest'}]
 * @param {Number} currentNum - 当前选择的位置
 * @param {String} showMode - 'line'/'table' 表现形式
 * @param {String} fullID - 判断是否显示放大按钮标识
 * @param {Function} toggleChartType - 底部title左右切换方法
 * @param {Function} savaTableCsv - 保存为图片和Excel的方法
 * @param {Function} showChange - 切换表现形式 
 * @param {Function} fullScreen - 放大功能
 */



const BtmFcBtn = props => {

    const [ visible, setVisible ] = useState(false)  // 权限弹窗是否打开
    const { TabsTitle, currentNum, showMode, fullID, isDispalyTitle, isDispalyRadio, isDispalyDown } = props
    const { toggleChartType, savaTableCsv, showChange, fullScreen } = props

    // 权限弹窗
    const changeModal = value => setVisible(value)

    return <Row type="flex" align="middle" className='charts-footer'>
        {/* 底部数字切换tab */}
        <Col span={8} offset={8} className='ct-col'>
            {
                isDispalyTitle && <p>
                    <LeftOutlined onClick={() => toggleChartType('reduce')} />
                    <span> {`${TabsTitle[currentNum].name}图表`} {currentNum + 1}/{TabsTitle.length}</span>
                    <RightOutlined onClick={() => toggleChartType('add')} />
                </p>
            }
        </Col>
        {/* 功能按钮 */}
        <Col span={8} className='rt-col'>
            {
                isDispalyRadio ? <Radio.Group onChange={showChange} value={showMode} className='toggle-btn'>
                    <Radio.Button value="line"> <i className="iconfont icon-charts"></i></Radio.Button>
                    <Radio.Button value="table"><i className="iconfont icon-charts-list"></i></Radio.Button>
                    {
                        !fullID && <Radio.Button value='full'>
                            <span onClick={e => {
                                e.preventDefault()
                                e.stopPropagation()
                                fullScreen()
                            }}><i className="iconfont icon-fullscreen"></i></span>
                        </Radio.Button>
                    }
                    {
                        isDispalyDown && <Radio.Button value='down'>
                            <span onClick={ e => {
                                e.preventDefault() // 组织默认行为 不执行showChange 不改变showMode
                                e.stopPropagation()
                                TEST_USER.includes(getStorage('level')) ? changeModal(true) : savaTableCsv()
                            }}><i className="iconfont icon-download"></i></span>
                        </Radio.Button>
                    }
                </Radio.Group>
                    :
                    <Radio.Group onChange={showChange} value={showMode} className='toggle-btn'>
                        {
                            !fullID && <Radio.Button value='full'>
                                <span onClick={e => {
                                    e.preventDefault()
                                    fullScreen()
                                }}><i className="iconfont icon-fullscreen"></i></span>
                            </Radio.Button>
                        }
                        {
                            isDispalyDown && <Radio.Button value='down'>
                                <span onClick={ TEST_USER.includes(getStorage('level')) ? 
                                    () => changeModal(true) : savaTableCsv}><i className="iconfont icon-download"></i></span>
                            </Radio.Button>
                        }
                    </Radio.Group>
            }
        </Col>
        {/* 权限弹窗 */}
        <Tootip1 value={visible} changeModal={changeModal} />
    </Row>
}

export default BtmFcBtn