import React, { Component } from 'react'
import * as echarts from 'echarts'
import { COLORS } from 'utils/type'

// 绝对值 纯数字
function absNum(num) {
    return Math.abs(Number(num))
}
const valKeys = {
        avg_profit: '最近财年利润(亿元)',
        avg_revenue: '最近财年收入(亿元)',
        avg_pb: '静态市销率(PB)',
        avg_pe: '静态市盈率(PE)',
        avg_patent: '专利信息',
        avg_invest: '历史融资(亿元)',
        avg_value: '平均市值(亿元)',
    },
    legends = {
        kc: '科创板_kc', cy: '创业板_cy', hk: '港股_hk'
    }

class Valuation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id:this.props.id,
            data:this.props.data
        }
    }
    // 创建echart
    setOptions = ()=>{
        let bar = echarts.init(document.getElementById(this.state.id))
        let barDatas = { // bar需要渲染的值 百分比
                cy: [], kc: [], hk: []
            }, originDatas = { // 原始值
                cy: [], kc: [], hk: []
            }
        // 根据 valKeys 的键值处理props的数据
        Object.keys(valKeys).forEach(key => {
            const propsVal = this.state.data[`${key}`] // this.props.avg_profit等
            // 绝对值 求和 
            const sum = absNum(propsVal.cy) + absNum(propsVal.kc) + absNum(propsVal.hk)
            let s = 0
            Object.keys(legends).forEach((k, i) => {
                s += Number((absNum(propsVal[`${k}`]) / sum * 100).toFixed(2))
                barDatas[`${k}`].push((absNum(propsVal[`${k}`]) / sum * 100).toFixed(2)) // 百分比保留两位
                originDatas[`${k}`].push(propsVal[`${k}`])
            })
        })
        bar.setOption({
            tooltip: {
                show: true,
                formatter: function (n) { // 例如： 科创板 平均市值 200.87
                    const legend = n.seriesName.split('_')
                    return legend[0] + ' ' + originDatas[`${legend[1]}`][`${n.dataIndex}`]
                }
            },
            grid: {
                left: 180
            },
            legend: {
                orient: 'vertical',
                right: 10,
                bottom: 50,
                itemHeight: 10,
                itemWidth: 10,
                icon: 'circle',
                formatter: function (l) {
                    return l.split('_')[0]
                }
            },
            xAxis: {
                show: false,
                type: 'value',
                max:100,
            },
            yAxis: {
                type: 'category',
                data: Object.values(valKeys),
                axisLabel: { align: 'left', margin: 42 },
                offset: 68,
                axisLine: { show: false },
                axisTick: { show: false }
            },
            series: [
                { stack: '总量', name: '科创版_kc', type: 'bar', data: barDatas.kc },
                { stack: '总量', name: '创业版_cy', type: 'bar', data: barDatas.cy },
                { stack: '总量', name: '港股_hk', type: 'bar', data: barDatas.hk }
            ],
            color: COLORS
        })
    }
    componentDidMount() {
        this.setOptions()
    }
    // 数据更新时
    static getDerivedStateFromProps(nextProps,prevState){
        if (nextProps.id !== prevState.id) {
            return {
                id:nextProps.id,
                data:nextProps.data
            }
        }
        return null
    }
    componentDidUpdate(prevProps, prevState) {
        this.setOptions()
    }
    render() {
        return (<div id={this.state.id} style={{ width: '860px', height: '440px' }}></div>)
    }
}

export default Valuation