import React, { Component } from 'react'
import * as echarts from 'echarts'
import { COLORS } from 'utils/type'

class Finance extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    componentDidMount() {
        // 新一代信息技术
        let data1 = this.props.data[1].map(item => item.num)
        let data107 = this.props.data[107].map(item=>item.num)
        let data192 = this.props.data[192].map(item=>item.num)
        let data319 = this.props.data[319].map(item=>item.num)
        let data425 = this.props.data[425].map(item=>item.num)
        let data489 = this.props.data[489].map(item=>item.num)
        let bar = echarts.init(document.getElementById(this.props.id))
        bar.setOption({
            tooltip: {
                show: true
            },
            legend: {
                orient: 'horizontal',
                bottom: 0,
                itemHeight: 10,
                itemWidth: 10,
                icon: 'circle',
                data: ['新一代技术', '高端装备', '新材料', '新能源', '节能环保', '生物医药']
            },
            yAxis: {
                type: 'value'
            },
            xAxis: {
                type: 'category',
                data: ['2015', '2016', '2017', '2018', '2019', '2020'],
            },
            series: [
                { barWidth: 60, stack: '总量', name: '新一代技术', type: 'bar', data: data1 },
                { barWidth: 60, stack: '总量', name: '高端装备', type: 'bar', data: data107 },
                { barWidth: 60, stack: '总量', name: '新材料', type: 'bar', data: data192 },
                { barWidth: 60, stack: '总量', name: '新能源', type: 'bar', data: data319 },
                { barWidth: 60, stack: '总量', name: '节能环保', type: 'bar', data: data425 },
                { barWidth: 60, stack: '总量', name: '生物医药', type: 'bar', data: data489 },
            ],
            color: COLORS
        })
    }

    render() {
        return (
            <div id={this.props.id} style={{ width: '920px', height: '480px' }}></div>
        )
    }
}

export default Finance