import React  from 'react'
import EchartReact from 'comps/Sign/EchartReact'

export default function Patent(props) {
    return (<EchartReact
        id={props.id}
        options={getOption(props.data)}
        style={{ width: '260px', height: '240px' }}
    />)
}

function getOption(data) {
    return {
        tooltip: {
            show: true
        },
        xAxis: {
            type: 'category',
            data: Object.keys(data),
            axisLabel: {
                show: true,
                textStyle: {
                    color: '#000',  //更改坐标轴文字颜色
                    fontSize : 10     //更改坐标轴文字大小
                }
            },
        },
        yAxis: {
            type: 'value',
            splitLine: {
                show: false
            },
            axisLabel: {
                show: true,
                textStyle: {
                    color: '#000',  //更改坐标轴文字颜色
                    fontSize : 10     //更改坐标轴文字大小
                }
            },
        },
        series: [{
            data: Object.values(data),
            type: 'bar',
            barMaxWidth:50
        }],
        color:['#826AF9']
    }
}
