import React, { Component, useCallback, useEffect, useState } from 'react'
import { Layout, Tabs } from 'antd'
import Nav from './common/Nav'
// import Footer from 'comps/navbar/Footer'
import NewFooter from './common/NewFooter'
import 'styles/official/digitization.scss'
import LeaderLine from 'react-leader-line'

function Digitization() {

    let lines = {}
    useEffect(() => {
        lines['sitanfu'] = new LeaderLine(setAnchor(document.getElementById('yuanzhang'),1), document.getElementById('sitanfu'), setOptions('联系', 'bottom', 'left'))
        lines['sailingsi'] = new LeaderLine(setAnchor(document.getElementById('sailingsi'),2), document.getElementById('yingweida'), setOptions('竞争对手', 'bottom', 'left'))
        lines['liuqing'] = new LeaderLine(setAnchor(document.getElementById('liuqing'),3), document.getElementById('beida'), setOptions('毕业', 'bottom', 'left'))
        lines['nest'] = new LeaderLine(setAnchor(document.getElementById('google'),4), document.getElementById('nest'), setOptions('收购', 'bottom', 'left'))
        lines['search'] = new LeaderLine(setAnchor(document.getElementById('google'),4), document.getElementById('search'), setOptions('收购', 'bottom', 'left'))
        lines['hongshan'] = new LeaderLine(setAnchor(document.getElementById('hongshan')), document.getElementById('zijie'), setOptions('投资', 'bottom', 'left'))
        lines['study'] = new LeaderLine(setAnchor(document.getElementById('study')), document.getElementById('zijie'), setOptions('任职', 'bottom', 'left'))
        lines['plm'] = new LeaderLine(setAnchor(document.getElementById('plm')), document.getElementById('tiqu'), setOptions('基于', 'bottom', 'left'))
        lines['moxing'] = new LeaderLine(setAnchor(document.getElementById('moxing')), document.getElementById('tiqu'), setOptions('基于', 'bottom', 'left'))
        lines['arm'] = new LeaderLine(setAnchor(document.getElementById('arm')), document.getElementById('jiagou'), setOptions('竞品', 'left', 'left'))
        lines['studygoogle'] = new LeaderLine(setAnchor(document.getElementById('study')), document.getElementById('google'), setOptions('投资', 'bottom', 'left'))
        lines['zdjs'] = new LeaderLine(setAnchor(document.getElementById('tsl')), document.getElementById('zdjs'), setOptions('行业领头羊', 'bottom', 'left'))
        lines['qihou'] = new LeaderLine(setAnchor(document.getElementById('tsl')), document.getElementById('qihou'), setOptions('替代汽油车', 'bottom', 'left'))
        lines['heqinglin'] = new LeaderLine(setAnchor(document.getElementById('heqinglin')), document.getElementById('beida'), setOptions('教授', 'bottom', 'left'))
    }, [lines])
    
    const [showInsight, setInsight] = useState(false)
    const [showinsightsBox, setinsightsBox] = useState(false)
    const [isFlag, setIsFlag] = useState(false)
    const setAnchor = (startId, endId) => {
        
        return (
            LeaderLine.mouseHoverAnchor(startId, 'draw', {
                animOptions: {
                    duration: 500
                },
                hoverStyle: {
                    backgroundColor: null
                },
                style: {
                    paddingTop: null,
                    paddingRight: null,
                    paddingBottom: null,
                    paddingLeft: null,
                    cursor: null,
                    backgroundColor: null,
                    backgroundImage: null,
                    backgroundSize: null,
                    backgroundPosition: null,
                    backgroundRepeat: null
                }
            })
        )
    }

    const setOptions = (value, start, end) => {
        return {
            middleLabel: value,
            dash: {
                animation: true
            },
            laberColor: 'red',
            color: '#000', // 指引线颜色
            endPlug: "disc", // 指引线结束点的样式
            size: 1, // 线条尺寸
            startSocket: start, //在指引线开始的地方从元素左侧开始
            endSocket: end, //在指引线开始的地方从元素右侧结束
            startSocketGravity: [50, -50],
            endSocketGravity: [- 50, 50],
            hide: true // 绘制时隐藏，默认为false，在初始化时可能会出现闪烁的线条
        }
    }
    const timeStamp = new Date().getTime()
    const insightsList = [
        {id:1, title:"传统时代", content1:"数量信息有限 ", content2:"信息无法有效整合，可获取价值低认知边际无法拓展，决定选择成本高"},
        {id:2, title:"Intelligence", content1:"数据生产量级提升并快速膨胀", content2:"区别于结构化数据，非结构化数据飞速增长数据资产化，数据的能力和价值的开始体现"},
        {id:3, title:"Machine Intelligence", content1:"智能算法快速迭代驱动机器智能时代来临", content2:"帮助激发数据要素价值，探知深层次精准需求提升认知洞察，拥有自己的“智能决策大脑”"},
    ]

    // componentDidMount() {
    //     // this.getChain({ id: 44, name: '人工智能' }) // 默认请求的产业
    //     debugger
    // }
    const handleScroll = ()=>{
        // if (document.documentElement.scrollTop > 800){
        //     setInsight(true)
        // }
        const dtinsBox = document.getElementById('dtinsBox')
        const digitOne = document.getElementById('digitOne')
        if (dtinsBox && digitOne) {
            if (document.documentElement.scrollTop > digitOne.offsetHeight + 100) {
                setInsight(true)
            }
        }
        const insightsBox = document.getElementById('insightsBox')
        if (insightsBox && digitOne) {
            if (document.documentElement.scrollTop >= digitOne.offsetHeight / 2) {
                setinsightsBox(true)
            }
             
        }
    }
    window.addEventListener('scroll', handleScroll)
    const imgLoad = e => {
        console.log('e-------------', e)
        const imgDom = new Image()
        imgDom.src = 'https://image.sciphi.cn/dt_insights/demand/demand-banner.jpg'
        // 图片加载完成使用正常的图片
        imgDom.onload = function () {
            setTimeout(() => {
                setIsFlag(true)
            }, 500)
            // setSrc(props.src)
            console.log('------------------')
        }
    }
    
    return (
        <Layout className='all-box-dig' >
            <Nav />
            <section className='digit-body'>
                <div className='digit-one' id='digitOne'>
                    <img src={'https://image.sciphi.cn/dt_insights/demand/demand-banner.jpg?v=' +  timeStamp} alt='main-banner' className="main-banner" onLoad={imgLoad}/>
                    <div className="data_top">
                        <div className="data_top_con animate__animated animate__fadeInLeftBig">
                            <div className="machine_title">从Intelligence到 <br></br>Machine Intelligence<br></br>的进化</div>
                            <div className="change_title">“机器智能”时代已经来临，改变正在发生</div>
                            <div className="age_title">随着数字化的普及和计算能力的提升，机器不仅能按照指令完成待定工作，还能够进行自主学习和设定整体目标，可以说，机器智能正在重新定义这个时代</div>
                        </div>
                    </div>
                    <img className='data_top_img animate__animated animate__fadeInRight' src={`https://image.sciphi.cn/dt_insights/data_top_img.png?v=` +  timeStamp}  alt='chatu' />
                </div>
                
                <div id='insightsBox'>
                    {
                        showinsightsBox ? <div className="insights_box">
                            {
                                insightsList.map(item => {
                                    return <div className={`ins_item insItem${item.id}  animate__animated animate__fadeInRight  animate__delay-${item.id - 1}s`} key={item.id}>
                                        <div className={`ins_pic inspic${item.id}`}></div>
                                        <div className="ins_item_title">{item.title}</div>
                                        <div className={`ins_item_line insLine${item.id}`}></div>
                                        <div className='ins_item_con'>{item.content1}</div>
                                        <div className='ins_item_con'>{item.content2}</div>
                                    </div>
                                })
                            }
                        </div> : null
                    }
                </div>
                <div className="dtins_box" id='dtinsBox'>
                    <div className="dtins_box_con">
                        {
                            showInsight ? <div className="dtins_con">
                                <div className="dtins_title" id='dtInsights'>DT Insights数据结构示例</div>
                                <div className="dtins_content">多维度自然语言处理技术将跨越人类与机器间的鸿沟，让机器具备文字阅读能力，深入理解文字背后的含义，从关键性的人才资源、新兴前沿的技术脉络，到引领成果转化的公司园区，加速重构的产业链新形态，全面满足科技生产链条的各主体的知识、认知和结果需求</div>
                            </div> : null
                        }
                    
                        {
                            showInsight ? <div className='dtins_con_two'>
                                <img  className='circle_middle animate__animated animate__fadeIn' src={`https://image.sciphi.cn/dt_insights/circle_middle.svg?v=` +  timeStamp}  alt='chatu' />
                                <img className='circle_left animate__animated animate__fadeInLeft animate__delay-1s' src={`https://image.sciphi.cn/dt_insights/circle_left.svg?v=` +  timeStamp}  alt='chatu' />
                                <img className='circle_right animate__animated animate__fadeInRight animate__delay-1s' src={`https://image.sciphi.cn/dt_insights/circle_right.svg?v=` +  timeStamp}  alt='chatu' />
                            </div>
                                : null
                        }
                    </div>

                </div>
                
                <p>从单项数据处理挖掘到多维度自然语言处理</p>
                <p className='sec3_body_one'>TI指数专业视角洞察，通过海量数据与数据分析方法结合，针对不同科技要素分别设置主流的、多维度、关键性指标，反应改要素在整体科技行业中的形态</p>
            </section>

            <section className='digit-body-end' style={{ height: 1232 }}>
                <div className='pic-all'>
                    <div className='cen'>Tiny AI 微型人工智能</div>
                    <div id='jishu' className='around red_center'>2020MIT TR10<br />全球十大突破性技术</div>
                    <div id='imec' className='around'>imec</div>
                    <div id='zdjs' className='around'>自动驾驶汽车</div>
                    <div id='yingjian' className='around'>智能硬件</div>
                    <div><div id='google' className='around'>谷歌</div></div>
                    {/* <div id='google' className='around'>谷歌</div> */}
                    <div><div id='study' className='around'>深度学习之父<br /> Geoffrey Hinton</div></div>
                    {/* <div id='study' className='around'>深度学习之父<br /> Geoffrey Hinton</div> */}
                    <div id='huawei' className='around'>华为</div>
                    <div id='cailiao' className='around'>新材料</div>
                    <div id='jisuan' className='around'>边缘计算</div>
                    <div id='huanbao' className='around'>环保</div>
                </div>
                <div className='sec-1' id='sec-1'>
                    <div className='c'>
                        2019 MIT TR50全球最聪明公司
                    </div>
                    <div className='a'>
                        <span>SiFive</span>
                        <span id='jiagou'>RISC-V架构</span>
                        <span id='tsl'>特斯拉</span>
                    </div>
                    <div className='b'>
                        <span>百济神州</span>
                        <span id='zijie'>字节跳动</span>
                        <span>药明康德</span>
                    </div>
                    {/* <div className='c'>
                        2019 MIT TR50全球最聪明公司
                    </div> */}
                </div>
                <div className='sec-2' id='sec-2'>
                    <div className='c'>科学顾问团</div>
                    <div className='a'>
                        <span id='yuanzhang'>斯坦福工程学院院长 Prof.James.D.Plummer</span>
                    </div>
                    <div className='b'>
                        <span>赛灵思 CTO Dr.lvo Bolsens</span>
                        <i id='sailingsi'>赛灵思</i>
                    </div>
                </div>
                <div className='sec-3' id='sec-3'>
                    <span id='yingweida'>英伟达</span>
                    <span>Waymo</span>
                    <span id='liuqing'>柳青 滴滴</span>
                </div>
                <div className='sec-4' id='sec-4'>
                    <span>亚马逊Echo智能音箱</span>
                    <span id='nest'>谷歌Nest</span>
                </div>
                <div className='sec-5' id='sec-5'>
                    <span id='plm' className='plm'>谷歌高级研发科学家Jacob Devlin<br />预训练语言模型（PLM）</span>
                    <div className="sec_5_child">
                        <span id='sitanfu' className='sed_span'>斯坦福大学</span>
                        <span id='hongshan' className='sed_span'>红杉资本</span>
                    </div>
                </div>
                <div className='sec-6' id='sec-6'>
                    <div className='a'>
                        <span>Yann LeCun</span>
                        <span>CNN卷积神经网络</span>
                    </div>
                    <div className='b'>
                        <span id='tiqu'>知识提取</span>
                        <span id='search'>DNNResearch</span>
                        <span>深度学习</span>
                    </div>
                </div>
                <div className='sec-7' id='sec-7'>
                    <span id='moxing'>微型伯特模型（Tiny Bret）</span>
                    <span>任正非</span>
                </div>
                <div className='sec-8' id='sec-8'>
                    <span>纳米发电机 王中林</span>
                    <div className='a'>
                        <span className='xian'>西安电子科技大学</span>
                        <span className='nami'>中国科技院北京纳米能源与系统研究</span>
                        <div className="sec_8_child">
                            <span className='child_spn_left'>佐治亚理工学院</span>
                            <span className='child_spn_right' id='beida'>北京大学</span>
                        </div>
                        
                    </div>

                </div>
                <div className='sec-9' id='sec-9'>
                    <span className='sec_title9'>微型人工智能芯片</span>
                    <div className='a'>
                        <span>成像芯片</span>
                        <span>微软</span>
                        <span>索尼</span>
                    </div>
                    <span id='arm'>Cortex M55架构芯片 ARM</span>
                </div>
                <div className='sec-10' id='sec-10'>
                    <span id='qihou'>气候变化 碳排放量</span>
                </div>
                <div className='sec-11' id='sec-11'>
                    <span className='sec_tip_label'>MIT TR35中国区35以下科技创新35人</span>
                    <div className='a'>
                        <p>西北工业大学</p>
                    </div>
                    <div className='b'>
                        <p>拓扑量子计算</p>
                        <p>复杂性神经</p>
                        <p>寒武纪</p>
                    </div>
                    <div className='c'>
                        <span id='heqinglin'>何庆林</span>
                        <span>王震</span>
                        <span>陈云霁</span>
                    </div>
                    
                </div>
            </section>
            <NewFooter />
        </Layout>
    )
}

export default Digitization