import React, { Component } from 'react'
import { Skeleton } from 'antd'

class Sum extends Component {
    constructor(props) {
        super(props)
        this.state = {
            info: {},
            off: true
        }
    }
    componentDidMount() {
        this.axios.post('/starmarket/index')
            .then(res => {
                this.setState({
                    info: res.data,
                    off: false
                })
            })
    }
    render() {
        const { stock_num, total_stock, flow_stock, market_value, ratio, total_value } = this.state.info
        return (
            <Skeleton active paragraph={{ rows: 4 }} loading={this.state.off}>
                <div className="wrap-box">
                    <div className="left">
                        <div className="item">
                            <div className="desc">上市股票</div>
                            <div className="nums">{stock_num}<span>只</span></div> 
                        </div>
                        <div className="item">
                            <div className="desc">总股本</div>
                            <div className="nums">{total_stock}<span>亿股</span></div>
                        </div>
                        <div className="item">
                            <div className="desc">流通股本</div>
                            <div className="nums">{flow_stock}<span>亿股</span></div>
                        </div>
                        <div className="item">
                            <div className="desc">流通市值</div>
                            <div className="nums">{market_value}<span>亿元</span></div>
                        </div>
                        <div className="item">
                            <div className="desc">平均市盈率</div>
                            <div className="nums">{ratio}<span>倍</span></div>
                        </div>
                        <div className="item">
                            <div className="desc">总市值</div>
                            <div className="nums">{total_value}<span>亿元</span></div>
                        </div>
                    </div>
                    <a href="/board/group" className="view">查看申报企业列表</a>
                </div>
            </Skeleton>
        )
    }
}


export default Sum