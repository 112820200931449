import React, { Component } from 'react'
import EchartReact from 'comps/Sign/EchartReact'
import { COLORS } from 'utils/type'

export default function Relation(props) {
    let peopleData = [props.patent_rights, props.patent_inventors, props.paper_author, props.all_wokers, props.classmate].flat(Infinity)
    let peopleArr = [ ]    //想法判断对象中是否存在某个key
    let obj = { }
    peopleData.forEach(function(ele,index){  //ele 是数组中的每一个对象     index是指下标
        if (!obj[peopleData[index].expert_id]) {
            peopleArr.push(peopleData[index])
            obj[peopleData[index].expert_id]  = true
        } 
    })
    let peopleLen = peopleArr.length
    return (<EchartReact
        id={props.id}
        options={getOption(props.person, props.patent_rights, props.patent_inventors, props.paper_author, props.all_wokers, props.classmate)}
        style={{ width: '900px', height: peopleLen <= 10 ? '300px' : '500px' }}
    />)
}


function getOption(person, patent_rights, patent_inventors, paper_author, all_wokers, classmate) {

    let res = [{
        category: '专家',
        name: person,
        value: 10,
        symbolSize: 80,
    }]
    let link = []
    let cates = [{name: '专家'}]
    // 共同申请人
    if (patent_rights.length !== 0) {
        cates.push({ name: '共同申请人' })
        patent_rights.map(item => {
            if (item.expert_name !== person) {
                if (!res.find(el => el.name === item.expert_name)) {
                    res.push({ category: '共同申请人', name: item.expert_name, value: 10, symbolSize: 45, })
                }
            }
            return res
        })
    }

    //共同发明人
    if (patent_inventors.length !== 0) {
        cates.push({ name: '共同发明人' })
        patent_inventors.map(item => {
            if (item.expert_name !== person) {
                if (!res.find(el => el.name === item.expert_name)) {
                    res.push({ category: '共同发明人', name: item.expert_name, symbolSize: 45, value: 10 })
                }
            }
            return res
        })
    }
    
    //论文共著者
    if (paper_author.length !== 0) {
        cates.push({ name: '论文共著人' })
        paper_author.map(item => {
            if (item.expert_name !== person) {
                if (!res.find(el => el.name === item.expert_name)) {
                    res.push({ category: '论文共著人', name: item.expert_name, symbolSize: 45, value: 10 })
                }
            }
            return res
        })
    }

    //同事关系
    if (all_wokers.length !== 0) {
        cates.push({ name: '同事' })
        all_wokers.map(item => {
            if (item.expert_name !== person) {
                if (!res.find(el => el.name === item.expert_name)) {
                    res.push({ category: '同事', name: item.expert_name, symbolSize: 55, value: 10 })
                }
            }
            return res
        })
    }
 
   
    //同学关系
    if (classmate.length !== 0) {
        cates.push({ name: '同学' })
        classmate.map(item => {
            if (item.expert_name !== person) {
                if (!res.find(el => el.name === item.expert_name)) {
                    res.push({ category: '同学', name: item.expert_name, symbolSize: 55, value: 10 })
                }
            }
            return res
        })
    }
    
    let un = Array.from(new Set(res))
    un.map(item => {
        return link.push({ source: person, target: item.name })
    })

    return {
        color: COLORS,
        legend: {
            orient: 'vertical',
            right: 0,
            bottom: 100,
            icon: 'circle',
            data: ['共同申请人', '共同发明人', '论文共著人', '同事', '同学']
        },
        series: [
            {
                type: 'graph',
                layout: 'force',
                draggable: false,
                categories: cates,
                force: {
                    // initLayout: 'circular',
                    repulsion: 400,
                    gravity: 0.02,
                    edgeLength: 60,
                    friction: 0.1,
                },
                label: {
                    show: true
                },
                links: link,
                lineStyle: {
                    width: 2
                },
                data: res,
            }
        ]
    }
}
