import React from 'react'
import EchartReact from 'comps/Sign/EchartReact'

export default function Patent(props) {
    return (<EchartReact
        id={props.id}
        options={getOption(props.data)}
        style={props.style ? props.style : { width: '360px', height: '240px',paddingLeft: 50 }}
    />)
}

function getOption(data) {
    return {
        tooltip: {
            show: true
        },
        xAxis: {
            type: 'category',
            data: Object.keys(data)
        },
        yAxis: {
            type: 'value',
            splitLine: {
                show: false
            }
        },
        series: [{
            data: Object.values(data),
            type: 'bar',
            barMaxWidth: 40,
            backgroundStyle: {
                color: 'rgba(220, 220, 220, 0.9)',
                opacity: 1
            }
        }],
        color:['#02375A']
    }
}
