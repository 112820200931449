import React, { PureComponent } from 'react'
import { Table,Button,Modal } from 'antd'
import cs from 'classnames'
import { PlatformColumns,historyColums } from 'utils/columns'
import PageCustom from 'comps/PageCustom'
import { Msg } from 'utils'

class OneCart extends PureComponent{
    state = {
        total:0,order:'',tableData:[],
        cartIds:[],
        visible:false,historyLists:[]
    }
    // 请求一键招商
    addCart = () =>{
        const { cartIds } = this.state
        if (cartIds.length === 0){
            Msg('warning','请先在列表添加企业')
            return
        }
        // 判断是否可以招商，查看历史订单是否全部处理完成
        this.axios.post('/park/check').then(res=>{
            if (res.code === 10000){ return res.data } else { return 3 }
        }).then(code=>{
            if (code === 1){
                this.axios.post('/park/order',{ids:cartIds.join(',')}).then(res=>{
                    if (res.code === 10000){
                        Msg('success','您的招商请求已提交，如有进展我们会及时联系您！')
                        this.setState({ cartIds:[] })
                    }
                })
            } else if (code === 2){
                Msg('warning','您还有未处理完成的订单，不能继续招商！')
            } else {
                Msg('warning','网络错误，请稍后重试！')
            }
        })
    }
    // 加入一键招商id的限制
    isAddLimit = id =>{
        const { cartIds } = this.state
        // 判断是否存在到state cartIds，存在则提示，否则加入，最多5个
        if (cartIds.includes(id)){
            this.setState({
                cartIds:this.state.cartIds.filter(cartid => cartid !== id)
            })
            Msg('warning','已从一键招商中取消！')
        } else {
            if (cartIds.length === 5){
                Msg('warning','一键招商最多添加5个！')
            } else {
                this.setState({
                    cartIds:this.state.cartIds.concat([id])
                })
            }
        }
    }
    // 打开历史订单
    historyOrder = ()=>{
        this.setState({ visible:true })
        this.axios.post('/park/history').then(res=>{
            if (res.code === 10000){
                this.setState({
                    historyTotal : res.data.count,
                    historyLists : res.data.items
                })
            }
        })
    }
    // 关闭的历史订单
    closeHistory = ()=>{
        this.setState({ visible:false })
    }
    render(){
        const { cartIds,historyLists } = this.state
        const { total,tableData } = this.props
        // 购物车按钮 表头都要后加入进去
        let addCartCon = [{
            title:'一键招商', dataIndex:'cart', key:'cart',
            render:(text,record) => (
                <span onClick={this.isAddLimit.bind(this,record.id)}>
                    <i className={cs('iconfont icon-park-cart', { 'active' : this.state.cartIds.includes(record.id) })}></i>
                </span>
            )
        }] 
        return (
            <section className='company-list'>
                <h1 className='flex_box'> 
                    <span>当前展示{total > 5000 ? '前' : null}<span className='sums'>{total > 5000 ? '5000' : total}</span>条结果</span>
                    {/* 一键招商 */}
                    <span className='cart-btn'>
                        <span onClick={this.addCart}>一键招商({cartIds.length}/5)</span>
                        <span onClick={this.historyOrder}>历史订单</span>
                    </span>
                </h1>
                {/* 公司结果列表 */}
                <div>
                    <Table
                        dataSource={tableData} 
                        columns={PlatformColumns.concat(addCartCon)} 
                        pagination={false}
                        className='custom-table company-table' 
                        rowKey={ record => record.id }
                    />
                    <PageCustom 
                        total={total > 5000 ? 5001 : total} 
                        currentPage={this.props.currentPage} 
                        pagesize={20}
                        changeStatePage={this.props.changeStatePage}
                    />
                </div>
                <Modal
                    visible={this.state.visible}
                    title="历史订单" className='park-service-modal'
                    footer={null} width='60%'
                    onCancel={this.closeHistory}
                >
                    <Table 
                        className='custom-table'
                        dataSource={ historyLists } columns={ historyColums } 
                        rowKey={ record => record.company_id }
                    />
                </Modal>
            </section>
        )
    }
}
export default OneCart