import React from 'react'
import * as echarts from 'echarts'

export default class EffectLine  extends React.Component{
    componentDidMount(){
        const { title, yAxisName, data, other } = this.props
        const option = {
            color: ['#105B86', '#1C8B8F'],
            tooltip: {
                trigger: 'axis'
            },
            legend: {
                icon:'circle',
                top: 'bottom',
                left: 70,
                itemGap: 35,
                itemWidth: 10,
                data: ['当前排行', '领域均值'],
            },
            grid: {
                bottom:60,
                left:'60'
            },
            xAxis: {
                type: 'category',
                name: '年份',
                nameTextStyle: {
                    verticalAlign: 'top',
                    padding: [8,0]
                },
                axisLine: { show: false },
                axisTick: { show: false },
                splitLine: { show: false },
                boundaryGap: ['20%','20%'],
                data: title
            },
            yAxis: {
                type: 'value',
                name: yAxisName,
                nameGap: 20,
                nameTextStyle: {
                    align:'right',
                    padding: [10,10,10,0]
                },
                axisLine: { show: false },
                axisTick: { show: false },
                splitLine: { show: false },
            },
            series: [
                {
                    name: '当前排行',
                    type: 'line',
                    smooth: true,
                    data: data
                },
                {
                    name: '领域均值',
                    type: 'line',
                    smooth: true,
                    data: other
                }
            ]
        }
        let myChart = echarts.init(document.getElementById(this.props.id))
        myChart.setOption(option)
    }
    render(){
        return <div className='effect-scatter' id={this.props.id}></div>
    }
    
}