import React, { useEffect, useState } from 'react'
import { Input, Row, Col, Skeleton } from 'antd'
import { RightOutlined } from '@ant-design/icons'
import { IndustryInvestBarLine } from 'pages/echarts/bar'
import ScatterRound from 'pages/echarts/ScatterRound'
import { TopRightNums, BottomLeftNums } from './IndustryNums'
import axios from 'libs/axios'

const IndustryCharts = props => {
    const [ stepNum, setStep ] = useState(-1)    // 当前步骤 -1-未展开 0-产业一级，1-产业二级
    const [ inputValue, setInputValue ] = useState('')   // input框内的value
    const [ oneID, setOneID ] = useState({id:-1,name:''})    // 选中一级ID
    const [ induID, setInduID ] = useState({id:-1,name:''})  // 选中二级ID
    const [ induData, setInduData ] = useState([])  // 所有产业数据 一级和二级
    const [ lastLists, setLastLists ] = useState([])    // 产业二级数据列表
    const [ data, setData ] = useState({})  // 产业可视化数据
    const [ defaultValue, setDefaultValue ] = useState('请选择产业领域') // 默认的input值

    // 获取该ID产业下的数据
    const getIndustryData = id => {
        setData({})
        axios.post('/individuality/domainnew',{ id }).then(res=>{
            if (res.code === 10000){
                setData(res.data)
            }
        })
    }

    useEffect(()=>{
        // 产业导航数据不存在的情况去获取 产业导航数据
        axios.post('/nav/domain').then(res=>{
            if (res.code === 10000){
                setInputValue(`${res.data[0].name}#${res.data[0].items[0].name}`) // 设置默认的input value
                setDefaultValue(`${res.data[0].name}#${res.data[0].items[0].name}`)
                setInduID({id:res.data[0].items[0].id,name:res.data[0].items[0].name}) // 设置默认二级ID
                setOneID({id:res.data[0].id,name:res.data[0].name}) // 设置默认二级ID
                setInduData(res.data)
                return res.data[0].items[0].id
            }
        }).then(id => {
            getIndustryData(id)
        })
    },[])

    // 关闭选择框
    const closeSteps = () => {
        setStep(-1)     // 关闭产业选择弹框
        setInputValue(defaultValue)     // input值恢复默认值
    }

    // input框 点击事件
    const inputChange = () => {
        if (stepNum === -1){    // input 没有打开的情况下打开 产业弹框
            setStep(0)
        }
    }

    // 选中一级ID 之后
    const selectOneID = (id,name) => {
        setOneID({id,name})     // 设置一级产业 值
        setInduID({id:-1,name:''}) // 二级恢复默认
        setInputValue(`${name}`)    // 设置input值
        // 筛选一级产业下的二级产业
        setLastLists(induData.filter(p => p.id === id)[0].items)
        next()
    }

    // 选择二级产业的之后
    const selectInduID = (id,name) => {
        getIndustryData(id)     // 请求变化的产业数据
        setInduID({id,name})    // 设置二级产业的值
        setInputValue(`${oneID.name}#${name}`)  // input value设置
        setStep(-1) // 选择完领域 弹框消失
    }

    // 完成一步，进行下一步
    const next = () => setStep(stepNum + 1)
    // 回到上一步
    const prev = () => setStep(stepNum - 1)
    return <>
        <h1 className='indi-title'>产业透视</h1>
        <div className='select-box'>
            <div
                onClick={closeSteps}
                className={stepNum < 0 ? 'hidden-mask input-mask' : 'input-mask'}></div>
            <Input placeholder={defaultValue}  value={inputValue} onClick={inputChange} />
            { !(stepNum < 0) && <div className="steps-content select-steps-custom">
                {/* 产业一级 */}
                {
                    stepNum === 0 && <div className='select-list'>
                        <h1 className='flex_box'> 
                            <span>请选择产业</span>
                        </h1>
                        <ul>
                            {
                                induData.map(pro => <li 
                                    className={oneID.id === pro.id ? 'active flex_box' : 'flex_box'}
                                    key={pro.id} onClick={()=>{selectOneID(pro.id,pro.name)}}>
                                    {pro.name}  <RightOutlined />
                                </li>)
                            }
                        </ul>
                    </div>
                }
                {/* 产业二级 */}
                {
                    stepNum === 1 && <div className='select-list'>
                        <h1 className='flex_box'> 
                            <span>{oneID.name}</span>
                            <span onClick={prev}>重选</span>
                        </h1>
                        <ul>
                            {
                                lastLists.map(pro => <li 
                                    className={induID.id === pro.id ? 'active flex_box' : 'flex_box'}
                                    key={pro.id} onClick={()=>{selectInduID(pro.id,pro.name)}}>
                                    {pro.name}
                                </li>)
                            }
                        </ul>
                    </div>
                }
            </div>}
        </div>
        {/* 图表区域 投资趋势 */}
        <Skeleton active paragraph={{ rows: 10 }} loading={Object.keys(data).length === 0}>
            {
                Object.keys(data).length > 0 && <Row justify='space-between'>
                    <Col span={16}>
                        <IndustryInvestBarLine 
                            isIndex={true}
                            id={`indi-invest${induID.id}`} // 唯一echart id
                            data={ data.items.invest } title='投资趋势' unit='万元' type='投资'
                        /> 
                    </Col>
                    <TopRightNums nums={data.numbers}/>
                </Row>
            }
        </Skeleton>
        {/* 投资轮次 */}
        <Skeleton active paragraph={{ rows: 10 }} loading={Object.keys(data).length === 0}>
            {
                Object.keys(data).length > 0 && <Row justify='space-between' className='scatter-row'>
                    <BottomLeftNums nums={data.numbers} />
                    <Col span={14}>
                        <ScatterRound 
                            id={`indi-scatter${induID.id}`} // 唯一echart id
                            data={ data.items.round }
                        /> 
                    </Col>
                </Row>
            }
        </Skeleton>
    </>
}

export default IndustryCharts