import React from 'react'
import * as echarts from 'echarts'

export class TreeMap extends React.Component{
    componentDidMount(){
        const option = {
            color:["#D2E8E9","#E5EBEE","#CFDEE7","#E5EBEE","#D2E8E9","#E5EBEE","#CFDEE7","#E5EBEE"],
            tooltip: {
                formatter: function(v){
                    const treePathInfo = v.treePathInfo
                    return `${v.name} ${(v.value / treePathInfo[0].value * 100).toFixed(2)} %`
                },
            },
            series: [
                {
                    name: '',
                    type: 'treemap',
                    left: '2%',
                    top: 0,
                    right: '2%',
                    bottom: 20,
                    roam: false,
                    nodeClick: false,
                    label: {
                        show: true,
                        formatter: function(v){
                            const treePathInfo = v.treePathInfo
                            return `${v.name} ${(v.value / treePathInfo[0].value * 100).toFixed(2)} %`
                        },
                        color:'#000'
                    },
                    itemStyle: {
                        borderColor: '#fff'
                    },
                    breadcrumb:{
                        show:false
                    },
                    levels: [{
                        itemStyle: {
                            borderWidth: 0,
                            gapWidth: 5
                        }
                    }],
                    data: this.props.data
                }
            ]
        }
        let myChart = echarts.init(document.getElementById(this.props.id))
        myChart.setOption(option)
    }
    render(){
        return ( <div id={this.props.id} className='effect-tree'></div> )
    }
}
export default TreeMap
