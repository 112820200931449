import React, { Component, useEffect, useState } from 'react'
import { Modal, Button } from 'antd'
import 'styles/comps/tootips.scss'
import { useHistory } from "react-router-dom"

function Tootip1(props) {
    const { value, changeModal } = props
    let history = useHistory()
    const openVipPage = () => {
        changeModal(false)
        history.push('/vippage')
    }
    return (
        <div className='tip1' id='tip1'>
            <Modal
                wrapClassName='vip-modal'
                title={<img className='tip1-tit' src={require('assets/images/logofff.svg')} alt="logo" />}
                visible={value}
                onOk={() => { changeModal(false) }}
                onCancel={() => { changeModal(false) }}
                width={523}
                closeIcon={<img src={require('assets/images/official/shanchu.png')} alt='chatu' />}
                footer={null}
                centered={true}
                zIndex={1010}
                // getContainer={document.getElementById('tip1')}
            >
                <p>您好，该功能为DT Insights完整版专属，欢迎购买咨询</p>
                <p>咨询电话（李经理）：15044161849</p>
                <p>完整版用户享有</p>
                <p>更多维度数据查看权限，企业/人才对比/下载等功能</p>
                <Button onClick={ openVipPage } className='tip1-btn'>了解详情</Button>
            </Modal>
        </div>
    )
}

function Tootip2() {
    return (
        <div className='tip2'>
            <p className='tip2-body'>该内容为DT Insights完整版专属，点击<a className='tip2-a' href='/vippage'>了解详情</a></p>
        </div>
    )
}

// page页数对应限制页数aim  text 提示  如果不需要限制页数显示   page=aim=1

function Tootip3(props) {
    const { page, aim, text } = props
    let history = useHistory()
    return (
        <>
            {
                page === aim && <div className='tip3'>
                    <Button className='tip3-btn' onClick={() => { history.push('/vippage') }}>{text}</Button>
                </div>
            }
        </>

    )
}

export { Tootip1, Tootip2, Tootip3 }