import React, { Component } from 'react'
import { Skeleton } from 'antd'
import BreadComp from 'comps/bread'
import 'styles/search/expresslist.scss'
import moment from 'moment'
const BreadData = [{ url: '/home', title: '首页' }, { url: '/express', title: '快讯' }]
class ExpressList extends Component {
    state = { list:[],loading:true,page:1,total: 0}
    componentDidMount() {
        this.getList()
    }
    getList = () => {
        let page = this.state.page
        this.axios.post('/flash/list',{page})
            .then(res => {
                let arr = this.state.list
                arr.push(...res.data.items)
                this.setState({ list: arr,loading:false, total: res.data.count }, () => {
                    // 去除content行内自带scss
                    // let dom = document.getElementsByClassName('introduce')
                    // dom.forEach(item => {
                    //     item.childNodes && item.childNodes[0] && item.childNodes[0].removeAttribute('style')
                    // })
                })
            })
    }

    loadMore = () => {
        let page = this.state.page
        page ++
        this.setState({page}, () => {
            this.getList()
        })
    }
    render() {
        const {list, total} = this.state
        return (
            <div className="expresslist">
                <Skeleton active loading={this.state.loading}>
                    <BreadComp data={BreadData} />
                    <div className="contain">
                        {
                            list.map(item => {
                                return <div className="item">
                                    <div className="date">
                                        {moment(item.start_time * 1000).format('MM月DD日')}｜{moment(item.start_time * 1000).format('dddd')}
                                    </div>
                                    <div className="title-box">
                                        <span className="time">{moment(item.start_time * 1000).format('hh:mm')}</span>
                                        <span className="title">{item.title}</span>
                                    </div>
                                    <div className="introduce"  dangerouslySetInnerHTML={{ __html: item.content.replace(/<\/?.+?\/?>/g, '') }} >
                                    </div>
                                </div>
                            })
                        }
                        {
                            list.length < total ? <div className="btn-box">
                                <div className="btn" onClick={this.loadMore}>
                                    加载更多
                                </div>
                            </div> : null
                        }
                    </div>
                </Skeleton>
                
            </div>
        )
    }
}


export default ExpressList
