import React, { Component } from 'react'
import BasicCompare from './BasicCompare' // 基本信息
import IndexCompare from './IndexCompare' // DT指数
import ChainCompare from './ChainCompare' // 产业链
import FinanceCompare from './FinanceCompare' // 投融资对比
import PatentCompare from './PatentCompare'
import { COMP_STATUS } from 'utils/type'

import 'styles/comps/compare.scss'

class CompareItem extends Component{
    render(){
        const { DataArray,ChainData,FinanceData,idsData,
            CompareLoading,FinanceLoading,ChainLoading,
            DtIndex,DtLoading,
            PatentData,PatentLoading
        } = this.props
        return (
            <>
                {/* 基本信息 */}
                <BasicCompare DataArray={DataArray} CompareLoading={CompareLoading}/>
                {/* DT指数对比 */}
                <IndexCompare DataArray={DataArray} DtIndex={DtIndex} DtLoading={DtLoading}/>
                {/* 融资能力对比 */}
                {!(FinanceLoading) && 
                    <FinanceCompare 
                        FinanceData={FinanceData} 
                        idsData={idsData}
                        FinanceLoading={FinanceLoading}
                    />
                }
                <ChainCompare ChainData={ChainData} ChainLoading={ChainLoading} />
                {/* 专利对比 */}
                <PatentCompare DataArray={DataArray} PatentData={PatentData} PatentLoading={PatentLoading}/>
            </>
        )
    }
}
export default CompareItem