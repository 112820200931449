import React from 'react'
import { Modal, Input, Select,Form } from 'antd'

const { Option } = Select,{ TextArea } = Input

const CollectionCreateForm = class extends React.Component {
    formRef = React.createRef()
    render() {
        const { visible, onCancel, onCreate } = this.props        
        return (
            <Modal
                visible={visible}
                title="创建专辑"
                className='edit-collect-modal'
                okText='确定'
                cancelText='取消'
                onCancel={onCancel}
                onOk={onCreate}
                closeIcon={<i className="iconfont icon-modal-close"></i>}
            >
                <Form layout="vertical" 
                    initialValues={{
                        type:  this.props.type ? String(this.props.type) : undefined,
                        describe: ''
                    }}
                    validateTrigger="onBlur"
                    ref={this.formRef}
                >
                    <Form.Item rules={[{ required: true, message: '请选择专辑数据类型' }]} name="type">
                        <Select placeholder="选择专辑数据类型" disabled={this.props.type}>
                            <Option value="1">企业</Option>
                            <Option value="2">人才</Option>
                            <Option value="4">技术</Option>
                            <Option value="3">园区</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item rules={[{ required: true, message: '请填写专辑名称' },{max:30,message:'内容不超过30个字'}]} name="name">
                        <Input placeholder='专辑名称'/>
                    </Form.Item>
                    <Form.Item name="describe">
                        <TextArea autoSize={{ minRows: 6, maxRows: 10 }} placeholder="专辑描述(选填)" />
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

export default CollectionCreateForm