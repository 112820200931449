import React from 'react'
import LoadImg from '@/pages/person/list/LoadImg'
import LabelBox from './LabelBox'
import Radar from './Radar'
import Pantent from './Pantent'
import {RadarMap} from 'comps/radar-map/RadarMap'
const indicatorData = [
    { name: '论文', max: 45 },
    { name: '专利', max: 30 },
    { name: '成果转化', max: 5 },
    { name: '荣誉/任职', max: 10 },
    { name: '教育', max: 10 },
]
const Person = props => {
    return (
        <div className="rank">
            {props.list.map(item => (
                <React.Fragment key={item.id}>
                    <div className="label-wrap">
                        <LabelBox label="purple" text="人物" color="#784FED" />
                    </div>
                    <a href={'/person/detail/' + item.id} className="rank-title" style={{ borderColor: '#784FED' }}>
                        {item.name_cn || item.name_en}
                        <span>{item.comany_name}</span>
                        <span>{item.position}</span>
                    </a>
                    <div className="person-item">
                        <div className="left">
                            <LoadImg src={item.photo} />
                        </div>
                        <div className="right">
                            {/*<div className="index">DT指数：<span className="index_num">{item.dt}</span></div>*/}
                            <div className="labels">
                                <span className="lsign">{item.location_name}</span>
                                {item.field_info.map(item=><span key={item.id} className="lsign">{item.name}</span>)}
                            </div>
                            {/* <Radar 
                                id={item.id} 
                                data={[item.paper_score,item.patent_score,item.commerce_score,item.honor_score,item.education_score,]} 
                            /> */}
                            <RadarMap
                                RadarMapId={item.id}
                                radarData={[item.paper_score,item.patent_score,item.commerce_score,item.honor_score,item.education_score]}
                                indicatorData={indicatorData}
                                radarStyle={{width: '200px', height: '180px', tooltipIsShow: false}}
                            />
                        </div>
                        <div className="right">
                            <div className="head">历年专利情况</div>
                            <Pantent id={item.id + 'p'} data={item.yearInfo} />
                        </div>
                    </div>
                </React.Fragment>
            ))}
        </div>
    )
}

export default Person