import React from 'react'
import { Tag } from 'antd'
import cs from 'classnames'
// redux
import { connect } from 'react-redux'

const { CheckableTag } = Tag

@connect(state => state)
class SelectTags extends React.Component {
    state = {
        showAll: false,
    }
    
    handleContent = e => {
        e.stopPropagation()
        let { showAll } = this.state
        this.setState({
            showAll: !showAll
        })
    };

    // 当前tag是否高亮  return true->高亮 false->不高亮
    isHighLighted = (id,type) => {
        if (id){ // id !== 0 的情况 是否包含在redux中
            return this.props.comLabels.some(selectTag => selectTag.id === id && selectTag.cate === type )
        } else { // 全部选项  当redux的当前类没有数据时 高亮
            return this.props.comLabels.filter(selectTag => selectTag.cate === type ).length === 0
        }
    }

    render() {
        let { showAll } = this.state
        const { isFold } = this.props // 是否显示展开收起按钮
        const { TagsData,handleChange,text,type } = this.props
        return (
            <div className={cs('select-tag flex_box', {'hidden-text': !showAll && isFold },
                {'filter-box': type === 'tech'})}
            >
                <span className='title'>{text}</span>
                <div className='tags_box'>
                    {TagsData.map(tag => (
                        <CheckableTag key={tag.id} checked={this.isHighLighted(tag.id,type)}
                            onChange={checked => handleChange(tag.id, checked,tag)}
                        >
                            {tag.name || tag.ShortName}
                        </CheckableTag>
                    ))}
                </div>
                {/* 技术筛选项 增加 搜索框 */}
                {
                    isFold && <span className="toggle-btn-sel"
                        onClick={this.handleContent}>
                        {!showAll ? 
                            <>展开 <i className='iconfont icon-down-arrow'></i></> 
                            : 
                            <>收起 <i className='iconfont icon-top-arrow'></i></>}
                    </span>
                }
            </div>
        )
    }
}

// 行业的二级
function SubSelectTags(props){
    const { subData,subChange,comLabels,type } = props
    // 当前tag是否高亮  return true->高亮 false->不高亮
    const isHighLighted = (id,type) => {
        if (id){ // id !== 0 的情况 是否包含在redux中
            return comLabels.some(selectTag => selectTag.id === id && selectTag.cate === type )
        } else { // 全部选项  当redux的当前类没有数据时 高亮
            return comLabels.filter(selectTag => selectTag.cate === type ).length === 0
        }
    }
    return (
        <div className='sub-select-tag'>
            <span className='title'>细分行业</span>
            <div>
                {subData.map(tag => (
                    <CheckableTag key={tag.id} checked={isHighLighted(tag.id,type)}
                        onChange={checked => subChange(tag.id, checked,tag)}
                    >
                        {tag.name}
                    </CheckableTag>
                ))}
            </div>
        </div>
    )
}
export { SelectTags, SubSelectTags }