
/* eslint-disable */
let all_cates = [
    { cate: 'company', title: '公司', active: true },
    { cate: 'expert', title: '人物', active: false },
    { cate: 'tech', title: '技术', active: false },
    { cate: 'park', title: '地理', active: false },
]
function cates(state = all_cates, action) {
    const { type, cate } = action
    switch (type) {
        case 'SWITCH_CATE':
            return state.map(item => item.cate === cate ? { ...item, active: true } : { ...item, active: false })
        default:
            return state
    }
}

function activeCate(state = 'company',action) {
    const {type, cate}  = action
    switch (type) {
        case 'CHANGE_CATE':
            return action.cate
        default:
            return state
    }
}


function keywords(state = '', action) {
    const { type, key } = action
    switch (type) {
        case 'CHANGE':
            return action.keywords
        default:
            return state
    }
}

export {cates,keywords,activeCate}