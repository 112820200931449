import React, { Component } from 'react'
import * as echarts from 'echarts'
import { COLORS } from 'utils/type'

// https://gallery.echartsjs.com/editor.html?c=xje9EHeSjW

class ScatterRound extends Component{
    componentDidMount(){
        let ScarrerOption = {
            color:COLORS,
            tooltip: {
                position: 'top',
                formatter:function(t){
                    return `${t.name}: ${t.value[1]} 起`
                }
            },
            title: [],
            singleAxis: [],
            series: []
        }
        
        // AllData = {'2016':[[0,10],[1,9],[2,4]...],'2017':[[0,4],[1,10]...]}
        let rounds = [],years = [],AllData = {}
        const propsData = this.props.data
        for (let k of Object.keys(propsData)){
            let arr = []
            years.push(k)
            Object.values(propsData[`${k}`]).forEach((v,i)=>{
                arr.push([i,v.num])
            })
            AllData[`${k}`] = arr
        }
        Object.keys(propsData[`${years[0]}`]).forEach(round => rounds.push(round))
        echarts.util.each(years, function (year, idx) {
            ScarrerOption.title.push({
                textBaseline: 'middle',
                top: (idx + 0.5) * 100 / 5 + '%',
                text: year
            })
            ScarrerOption.singleAxis.push({
                left: 100,
                type: 'category',
                boundaryGap: false,
                data: rounds,
                top: (idx * 100 / 5 + 5) + '%',
                height: (100 / 5 - 10) + '%',
                axisLabel: { interval:0 }
            })
            ScarrerOption.series.push({
                singleAxisIndex: idx,
                coordinateSystem: 'singleAxis',
                type: 'scatter',
                data: AllData[`${year}`],
                // 气泡大小
                symbolSize: function (dataItem) {
                    const num = dataItem[1]
                    if (num > 240){
                        return 80   // 默认最大值
                    } else if (num > 200){
                        return num / 3
                    } else if (num > 150){
                        return num / 2.5 
                    } else if (num > 9){
                        return num / 2 
                    } else {
                        return 4    // 默认最小值
                    }
                }
            })
        })
        let myChart = echarts.init(document.getElementById(`${this.props.id}`))
        myChart.setOption(ScarrerOption)
    }
    render(){
        return <div id={`${this.props.id}`} className='barline'></div> 
    }
}
export default ScatterRound