import React, { Component, useEffect, useState } from 'react'
import axios from 'libs/axios'
import Year from '../common/Year'
import { CompareEmptyEmage } from 'comps/common/EmptyComp'

function FinancingContent(props) {
    const { id, name } = props
    const [fina, setFina] = useState([])
    const [finaInfo, setFinaInfo] = useState([]) // 融资信息
    const [finaNum, setFinaNum] = useState([]) // 融资数量

    useEffect(() => {
        // 融资
        axios.post('/company/cinvest', { id }).then(res => {
            if (res.code === 10000) {
                setFina(res.data.reverse())
            }
        })
        // 融资信息
        axios.post('/company/money', { id }).then(res => {
            if (res.code === 10000) {
                setFinaInfo(res.data.invest)
                setFinaNum(res.data.investor)
            }
        })
    }, [id])

    return (
        <div className='fina-item'>
            <div className="fina-bottom">
                <div className="fina-bottom-le">
                    {
                        fina.length > 0 ? <Year id={`fina${id}`} data={fina} width={577} height={162} show={false} title={'融资历程'} dw={'投资金额(万元)'} /> :
                            <img src={require('../../../assets/images/no_pic.png')} alt='no_pic' />
                    }
                </div>
                <div className="fina-bottom-ri">
                    <div className="fina-bottom-ri-top">
                        <span>融资总额：{props.invsetMoney}万</span>
                        <span>投资方：{Object.keys(finaNum).length > 0 ? finaNum.length : 0}家</span>
                        <span>最近融资：{Object.keys(finaInfo).length > 0 ? finaInfo[0].round : '无'}-{Object.keys(finaInfo).length > 0 ? `${finaInfo[0].t_amount}万${finaInfo[Object.keys(finaInfo).length - 1].currency}` : '无'}</span>
                    </div>
                    <div className="fina-bottom-ri-bottom">
                        <dd>
                            {
                                Object.keys(finaNum).length > 0 ? finaNum.map(item => {
                                    return <i key={item.id}>{item.name}</i>
                                }) : <CompareEmptyEmage />
                            }
                        </dd>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FinancingContent