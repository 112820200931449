import React, { Component, useState, useEffect } from 'react'
import axios from 'libs/axios'
import Paper from './Paper'
import PaperCloud from './PaperCloud'
import { CompareEmptyEmage } from 'comps/common/EmptyComp'

function ArticleContent(props) {
    const { id, name } = props
    const [picChart, setPicChart] = useState([]) // 饼图信息
    const [cloud, setCloud] = useState([]) // 词云

    useEffect(() => {
        axios.post('/expert/academic', { id }).then(res => {
            if (res.code === 10000) {
                setPicChart(res.data.paperPercent)
            }
        })
        axios.post('expert/paperstitle', { id }).then(res => {
            if (res.code === 10000) {
                let paperList = [...res.data]
                let list = []
                paperList.forEach((item, index) => {
                    list.push({ id: index, name: item, value: index })
                })
                setCloud(list)
            }
        })
    }, [id])
    return <div className='article-item'>
        <div className='article-bottom'>
            <div className='article-bottom-left'>
                {
                    picChart.items && picChart.items.length > 0 ? <Paper id={id} {...picChart} /> : <CompareEmptyEmage />
                }
            </div>
            <div className='article-bottom-right'>
                {
                    Object.keys(cloud).length > 0 ? <PaperCloud id={`cloud${id}`} data={cloud} /> : <CompareEmptyEmage />
                }
            </div>
        </div>
    </div>
}

export default ArticleContent