// 人物筛选项 增加 删除单个，清空某个类型所有，删除所有，
function perLabels(state = [], action) {
    switch (action.type) {
    case 'ADD_LABEL':
        return [
            ...state,
            {
                id: action.id,
                name: action.name,
                cate: action.cate
            }
        ]
    case 'DELETE_LABEL':
        // 过滤掉 id和cate都相同的lable
        return state.filter(label => !(label.id === action.id && label.cate === action.cate))
    case 'CLEAR_CATE':
        return state.filter(label => label.cate !== action.cate)
    case 'CLEAR_ALL':
        return []
    default:
        return state
    }

}


// 人物对比
function compareItem(state = [], action) {
    switch (action.type) {
    case 'ADD_ITEM':
        return [...state, { id: action.id, name: action.name }]
    case 'DELETE_ITEM':
        return state.filter(item=> item.id !== action.id)
    case 'CLEAR_ITEM':
        return []
    default:
        return state
    }
}

export { perLabels, compareItem }