import React, { Component, Children }  from 'react'
import { BrowserRouter, Switch,Route, Redirect } from 'react-router-dom'
import PublicRoutes from 'routes/PublicRoutes'
import PrivateRoutes from 'routes/PrivateRoutes'
import { getStorage } from 'utils/storage'
import 'animate.css'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'

class App extends React.Component {
    /**
	 * 根据路由表生成路由组件11
	 * @param {Array} routes - 路由配置表
	 * @param {String} contextPath - 父级路径
	 */
    renderRoutes(routes, contextPath) {
        const children = []
        const renderRoute = (item, routeContextPath) => {
            let newContextPath = item.path ? `${routeContextPath}/${item.path}` : routeContextPath
            newContextPath = newContextPath.replace(/\/+/g, '/')

            if (item.component && item.childRoutes) {
                const childRoutes = this.renderRoutes(item.childRoutes, newContextPath)
                children.push(<Route
                    key={newContextPath}
                    render={props => <item.component {...props}>{childRoutes}</item.component>}
                    path={newContextPath}
                />)
            } else if (item.component) {
                // 路由权限
                children.push(<PrivateRoute key={newContextPath} component={item.component} path={newContextPath} exact />)
            } else if (item.childRoutes) {
                item.childRoutes.forEach(r => renderRoute(r, newContextPath))
            }
        }
        routes.forEach(item => renderRoute(item, contextPath))
        return children
    }
    render() {
        const children = this.renderRoutes(PrivateRoutes, '/')
        return (
            <BrowserRouter>
                <Switch>
                    {/* 公共路由页面 */}
                    {PublicRoutes.map(({path, component, ...routes}) => <Route key={path} path={path} component={component} {...routes}/>)}
                    {/* 权限页面 */}
                    {children}
                </Switch>
            </BrowserRouter>
        )
    }
}

export default App

// 验证是否登录
const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props => getStorage('token') ? (
            <Component {...props} />
        ) : (
            <Redirect
                to={{
                    pathname: "/login",
                    state: { from: props.location }
                }}
            />
        )}
    />
)