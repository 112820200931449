import React from 'react'
import ReactDOM from 'react-dom'
import 'styles/official/test.scss'
import ReactTree from './Test' // 引入组件

const data = {
    "agntname": "Tiny AI 微型人工智能",
    "agtype": '',
    "child": [
        {
            "agntname": "新材料",
            "agtype": '1',
            "child": [
                {
                    "agntname": '王中林',
                    "agtype": '11',
                    "child": [
                        {
                            "agntname": '纳米发电机',
                            "agtype": '1111',
                            "child": [
                                {
                                    "agntname": '西安电子科技大学',
                                    "agtype": '11111',
                                    "child": []
                                },
                                {
                                    "agntname": '北京科学院纳米能源与系统研究',
                                    "agtype": '11112',
                                    "child": []
                                },
                                {
                                    "agntname": '佐治亚理工学院',
                                    "agtype": '11113',
                                    "child": []
                                },
                                {
                                    "agntname": '北京大学',
                                    "agtype": '11114',
                                    "child": []
                                },
                            ]
                        }

                    ]

                }
            ]
        },
        {
            "agntname": "环保",
            "agtype": '2',
            "child": [
                {
                    "agntname": "气候变化",
                    "agtype": '21',
                    "child": [
                        {
                            "agntname": '碳排放量',
                            'agtype': '',
                            'child': []
                        }
                    ]
                }
            ]
        },
        {
            "agntname": "自动驾驶汽车",
            "agtype": '3',
            "child": [
                {
                    "agntname": "英伟达",
                    "agtype": '31',
                    "child": []
                },
                {
                    "agntname": "Waymo",
                    "agtype": '32',
                    "child": []
                },
                {
                    "agntname": "柳青",
                    "agtype": '33',
                    "child": [
                        {
                            "agntname": '滴滴',
                            "agtype": '331',
                            "child": []
                        }
                    ]
                }
            ]
        },
        {
            "agntname": "imec",
            "agtype": '4',
            "child": [
                {
                    "agntname": "科学顾问团",
                    "agtype": '41',
                    "child": [
                        {
                            "agntname": "赛灵思 CTO Dr.Ivo Bolsens",
                            "agtype": '411',
                            "child": [
                                {
                                    "agntname": "赛灵思",
                                    "agtype": '',
                                    "child": []
                                }
                            ]
                        },
                        {
                            "agntname": "Prof.James.D.Plummer",
                            "agtype": '412',
                            "child": [
                                {
                                    "agntname": "斯坦福工程学院院长",
                                    "agtype": '',
                                    "child": []
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            "agntname": "智能硬件",
            "agtype": '5',
            "child": [
                {
                    "agntname": "亚马逊Echo智能音箱",
                    "agtype": '51',
                    "child": []
                },
                {
                    "agntname": "谷歌Nest",
                    "agtype": '52',
                    "child": []
                }
            ]
        },
        {
            "agntname": "2020 MIT TR10全球十大技术性突破",
            "agtype": '6',
            "child": [
                {
                    "agntname": "2019 MIT TR50全球50家最聪明公司",
                    "agtype": '61',
                    "child": [
                        {
                            "agntname": "百济神州",
                            "agtype": '611',
                            "child": []
                        },
                        {
                            "agntname": "字节跳动",
                            "agtype": '612',
                            "child": []
                        },
                        {
                            "agntname": "药明康德",
                            "agtype": '613',
                            "child": []
                        },
                        {
                            "agntname": "SiFive",
                            "agtype": '614',
                            "child": [
                                {
                                    "agntname": "RISC-V架构",
                                    "agtype": '6141',
                                    "child": []
                                }
                            ]
                        },
                        {
                            "agntname": "特斯拉",
                            "agtype": '615',
                            "child": []
                        }
                    ]
                },
                {
                    "agntname": "MIT TR35中国区35岁以下科技创新35人",
                    "agtype": '62',
                    "child": [
                        {
                            "agntname": "和庆林",
                            "agtype": '621',
                            "child": [
                                {
                                    "agntname": "拓扑量子计算",
                                    "agtype": '6211',
                                    "child": []
                                }
                            ]
                        },
                        {
                            "agntname": "王震",
                            "agtype": '6212',
                            "child": [
                                {
                                    "agntname": "西北林业大学",
                                    "agtype": '62121',
                                    "child": []
                                },
                                {
                                    "agntname": "复杂性神经",
                                    "agtype": '62122',
                                    "child": []
                                }
                            ]
                        },
                        {
                            "agntname": "陈云霁",
                            "agtype": '6213',
                            "child": [
                                {
                                    "agntname": "寒武纪",
                                    "agtype": '62131',
                                    "child": []
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            "agntname": "深度学习之父Geoffrey Hinton",
            "agtype": '7',
            "child": [
                {
                    "agntname": "Yann LeCun",
                    "agtype": '71',
                    "child": [
                        {
                            "agntname": "CNN卷积神经网络",
                            "agtype": '711',
                            "child": []
                        }
                    ]
                },
                {
                    "agntname": "深度学习",
                    "agtype": '72',
                    "child": [
                        {
                            "agntname": "知识提取",
                            "agtype": '721',
                            "child": []
                        }
                    ]
                },
                {
                    "agntname": "DNNResearch",
                    "agtype": '73',
                    "child": []
                }
            ]
        },
        {
            "agntname": "边缘计算",
            "agtype": '8',
            "child": [
                {
                    "agntname": "微型人工智能芯片",
                    "agtype": '81',
                    "child": [
                        {
                            "agntname": "成像芯片",
                            "agtype": '811',
                            "child": [
                                {
                                    "agntname": "微软",
                                    "agtype": '8111',
                                    "child": []
                                },
                                {
                                    "agntname": "索尼",
                                    "agtype": '8112',
                                    "child": []
                                }
                            ]
                        },
                        {
                            "agntname": "Cortex M55架构芯片",
                            "agtype": '812',
                            "child": [
                                {
                                    "agntname": "ARM",
                                    "agtype": '8121',
                                    "child": []
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            "agntname": "华为",
            "agtype": '9',
            "child": [
                {
                    "agntname": "微型伯特模型（Tiny Bert）",
                    "agtype": '91',
                    "child": []
                },
                {
                    "agntname": "任正非",
                    "agtype": '92',
                    "child": []
                }
            ]
        },
        {
            "agntname": "谷歌",
            "agtype": '10',
            "child": [
                {
                    "agntname": "谷歌高级研发科学家Jacob Devlin",
                    "agtype": '101',
                    "child": [
                        {
                            "agntname": "预训练语言模型（PLM）",
                            "agtype": '',
                            "child": []
                        }
                    ]
                },
                {
                    "agntname": "斯坦福大学",
                    "agtype": '102',
                    "child": []
                },
                {
                    "agntname": "红杉资本",
                    "agtype": '103',
                    "child": []
                }
            ]
        }
    ]
}

// 每一个子节点的结构和样式
function childLayout(props) {
    // bgcArr是每一层的图标颜色(就是demo的小圆圈的颜色)
    let bgcArr = ['#0DC0AF', '#FD71AA', '#41A3FC', '#D086EB', '#F8B551']
    return <div>
        <span>
            <div style={{
                '--background_color': bgcArr[props.tier % bgcArr.length] || '#fff',
            }}>
                <div className='shu_show_name' id={props.data.agtype} onClick={e => {
                    props.click(props.path, props.data)
                }} style={{ backgroundColor: 'var(--background_color)' }}>
                    {props.data.agtype}
                </div>
                <div className="shu_show_all_name">
                    <div>{props.data.agntname}</div>
                </div>
            </div>
        </span>
    </div>
}


class Test extends React.Component {
    // click = obj => {
    //     console.log(obj)
    // }

    componentDidMount() {
        let lists = document.getElementById('1')
    }

    render() {
        /* 
          必传   1.data是一个json数据
          必传   2.childLayout是react的函数组件,返回的dom元素是每个子节点,大小决定树状图的大小, 接受一个对象props(Object类型)
          props.data 是 每个子节点的数据 Object类型
          props.tier 是 第几层级 Number类型
          props.path 是 属于父节点子集的下标
          props.click 是 function,接受2个参数，第1个参数把props.path传入,第2个参数把props.data传入
          childLayout具体参考下面的childLayout函数
    
          非必传 3.click一个函数，接受一个参数，参数是点击每个子节点数据(是当前点击的节点的所有属性)
          非必传 4.showAll是一个Boolean类型(不是非要布尔类型,js会隐式转换),如果是true,把数据全部显示，如果是false或者不传，就会默认显示数据最左边一列, 只要点击就能切换节点显示
          非必传 5.size是一个Number,如果觉得每一层dom元素拥挤，可以传入数字进行调节每一层的间距
        */
        return <ReactTree data={data} showAll={true} childLayout={childLayout} size={1.5} />
    }
}

export default Test
