import React, { Component } from 'react'
import { message } from 'antd'
import PersonCompare from 'comps/person-compare'
import PageCustom from 'comps/PageCustom'
import BreadComp from 'comps/bread'
import Profile from './list/Profile'
import { EmptyComp } from 'comps/common/EmptyComp'
import ExportBtn from 'comps/common/ExportBtn'  // 导出按钮
import { saveWithALink } from 'utils'
import { getStorage } from 'utils/storage'
import { TEST_USER } from 'utils/type'
import { Tootip3, Tootip1 } from 'comps/tootip/Tootip'
import FilterList from 'comps/select-tags/FilterList'

import { connect } from 'react-redux'
import { addItem, clearItem, deleteItem,addLabel, deleteLabel, clearCate, clearAll } from '@redux/person/action'


import 'styles/person/personList.scss'
import 'styles/person/compare.scss'

const BreadData = [{ url: '/home', title: '首页' }, { url: '', title: '人物列表' }]

@connect(state => state, { addItem, clearItem, deleteItem,addLabel, deleteLabel, clearCate, clearAll })
class PersonList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            list: [],
            //复杂多条件筛选
            condition: {},
            count: 0,
            currentPage: 1,
            visible: false, // 弹窗开关
        }
        this.clickThump = this.clickThump.bind(this)
        this.clickOrder = this.clickOrder.bind(this)
        this.onChange = this.onChange.bind(this)
    }

    componentDidMount() {
        document.title = '人物'
        this.props.clearAll()
        this.getPersonList()
    }

    componentDidUpdate(preProps,preState){
        // redux有变化的情况
        if (preProps.perLabels.length !== this.props.perLabels.length) {
            this.getPersonList()
        }
    }

    // 请求人物数据
    getPersonList = () => {
        this.axios.post('/expert/list', this.filterTags())
            .then(res => {
                if (res.code === 10000) {
                    this.setState({
                        list: res.data.items,
                        count: res.data.count
                    })
                }
            })
    }

    //添加人物到对比工具里面
    clickThump(id, name) {
        message.destroy()
        if (this.isCompared(id)) { // 存在的情况则删除
            this.props.delete(id)
            return
        }
        if (this.props.compareItem.length < 3) {
            this.props.addItem({ id, name })
        } else {
            message.info('最多支持三位专家对比哟')
        }
    }

    isCompared(id) {
        let flag = false
        this.props.compareItem.forEach(item => {
            if (item.id === id) {
                flag = true
            }
        })
        return flag
    }

    // 排序处理
    clickOrder(order) {
        if (this.props.perLabels.length > 0) {
            let ops = this.complex(this.props.perLabels)
            ops.order = order
            this.request(ops)
        } else {
            this.request({ order, size: '18' })
        }
    }
    // 翻页处理
    onChange(page) {
        this.setState({  currentPage: page }, () => { this.getPersonList() })
    }
    
    // 权限弹窗
    changeModal = value => {
        this.setState({ visible: value })
    }

    // 导出人才数据Excel
    exportList = () => {
        this.axios.post('/expert/export',this.filterTags()).then(res => {
            if (res.code === 10000) {saveWithALink(res.data)}
        })
    }


    // 筛选项
    filterTags = () => {
        const { perLabels } = this.props
        const { currentPage } = this.state
        let first_letter = '', location = '', domain = '', field = '', expert_tech = '', schools = ''
        perLabels.forEach(l => {
            if (l.cate === 'tech') {expert_tech += `${l.id},`}
            if (l.cate === 'domain') {domain += `${l.id},`}
            if (l.cate === 'college') {schools += `${l.id},`}
            if (l.cate === 'field') {field += `${l.id},`}
            if (l.cate === 'area') {location += `${l.id},`}
            if (l.cate === 'alphabet') {first_letter += `${l.id},`}
        })
        return { first_letter, domain, location, field, schools, expert_tech, page: currentPage,size: 18 }
    }
    /**
     * @param {*} id 选项的ID id===0:全部
     * @param {*} checked 是否未被选中   true:没有选中的状态  false:选中的状态
     * @param {*} tag 选项的内容 {id:000,name:''}
     * @param {*} cate 筛选项的类型 'domain':产业  'industry':行业 'subindustry':二级行业 'round':融资轮次 'area':总部地区 'founded':成立事件
     */
    tagsChange = (id,checked,tag,cate) => {
        if (id) { // 选择单个选项，追加或者删除 redux
            if (checked) { // 追加
                this.props.addLabel({
                    id,name:tag.name || tag.ShortName,cate
                })
            } else { // 删除
                this.props.deleteLabel(id,cate)
            }
        } else { // id=0 选择全部，清空redux中当前类所有选项
            this.props.clearCate(cate)
        }
        this.setState({ currentPage : 1 })
    }
    // 单个tag close
    tagClose = (removeID,cate) => {
        this.setState({ currentPage: 1 })
        this.props.deleteLabel(removeID,cate)
    }
    // 清空筛选项
    clearFilter = () => {
        this.props.clearAll()
        this.setState({ currentPage: 1 })
    }

    render() {
        const { visible } = this.state
        return (
            <div className="person">
                <BreadComp data={BreadData} />

                {/* 筛选项 */}
                <FilterList
                    clearFilter={this.clearFilter} // 清空所有选项
                    tagClose={this.tagClose} // 删除单个tag
                    tagsChange={this.tagsChange} // 选项点击事件
                    title='人物列表'
                    filterPage='person'
                />

                <hr />
                {/* 人物对比 */}
                <PersonCompare />

                {/* 排序项 */}
                {/*<OrderBar order={this.clickOrder} />*/}

                {/* 导出 */}
                <h1 className='export'> <ExportBtn exportList={this.exportList}/> </h1>

                {/* 人才列表 */}
                <div className="personList">
                    {
                        this.state.list.length === 0 ? <EmptyComp /> :
                            this.state.list.map(item => <Profile
                                key={item.id} {...item}
                                click={this.clickThump}
                                active={this.isCompared(item.id)}
                                changeModal={this.changeModal}
                            />)
                    }
                </div>

                {/* 测试用户 正式用户的权限弹窗 */}
                <Tootip1 value={visible} changeModal={this.changeModal}/>
                {
                    TEST_USER.includes(getStorage('level')) && <Tootip3 page={this.state.page} aim={3} text='升级完整版，查看全部专家'/>
                }

                {/* 自定义页码 */}
                <PageCustom
                    total={(this.state.count > 3000) ? 3000 : this.state.count}
                    currentPage={this.state.currentPage}
                    pagesize={18}
                    changeStatePage={this.onChange}
                />
            </div>
        )
    }
}

export default PersonList