import React, { Component } from 'react'
import { Layout, Select } from 'antd'
// 组件
import FilterList from 'comps/select-tags/FilterList'
import BreadComp from 'comps/bread'  // 面包屑导航
import CompareBtn from 'comps/compare/CompareBtn'
import ExportBtn from 'comps/common/ExportBtn'  // 导出按钮
import { saveWithALink } from 'utils'
import AddCollectBtn from 'comps/collect/AddCollectBtn' // 收藏功能

// redux
import { connect } from 'react-redux'
import { addComLabel, delComLabel, clearComCate, clearComAll } from '@redux/company/action'

const { Content } = Layout, { Option } = Select

const BreadData = [{ url: '/home', title: '首页' }, { url: '', title: '公司列表' }]

@connect(state => state, { addComLabel, delComLabel, clearComCate, clearComAll })
class CompanyList extends Component {
    state = {
        subScopesId: 0,  // 选中的一级行业
        selectedRowKeys: [], // 收藏功能 选中的数量
        total: 0, // 公司列表总数量
        order: '', // 排序选项
        tableData: [], // 公司列表数据
        currentPage: 1 // 当前页码
    }
    componentDidMount() {
        document.title = '公司'
        // 重新渲染页面 清空redux里面的筛选项数据
        this.props.clearComAll()
        this.getCompanyList() // get listdata
    }

    /**
     * @param {*} id 选项的ID id===0:全部
     * @param {*} checked 是否未被选中   true:没有选中的状态  false:选中的状态
     * @param {*} tag 选项的内容 {id:000,name:''}
     * @param {*} cate 筛选项的类型 'domain':产业  'industry':行业 'subindustry':二级行业 'round':融资轮次 'area':总部地区 'founded':成立事件
     */
    tagsChange = (id,checked,tag,cate) => {
        if (id) { // 选择单个选项，追加或者删除 redux
            if (checked) { // 追加
                // 如果是行业  第一级只能是单选 先清除redux中scopes的数据，再添加进去
                cate === 'industry' && this.props.clearComCate(cate)
                this.props.addComLabel({
                    id,name:tag.name || tag.ShortName,cate
                })
            } else { // 删除
                this.props.delComLabel(id,cate)
            }
        } else { // id=0 选择全部，清空redux中当前类所有选项
            this.props.clearComCate(cate)
        }
        if (cate === 'industry') { 
            this.props.clearComCate('subindustry')
            this.setState({
                subScopesId: checked ? id : 0  // 选中的目标二级行业
            })
        }
        this.setState({ currentPage : 1 })
    }
    // 单个tag close
    tagClose = (removeID,cate) => {
        // 删除一级行业类型的tag 先清空当前子行业，再重置一级行业的选中ID
        cate === 'industry' && this.props.clearComCate('subindustry') && this.setState({
            subScopesId: 0
        })
        this.setState({ currentPage: 1 })
        this.props.delComLabel(removeID,cate)
    }
    // 清空筛选项
    clearFilter = () => {
        this.props.clearComAll()
        this.setState({ subScopesId: 0, currentPage: 1 })
    }
    // 排序选择操作
    selectChange = v => {
        this.setState({ order: v, currentPage: 1 }, () => { this.getCompanyList() })
    }
    // 更改页码
    changeStatePage = page => {
        this.setState({  currentPage: page }, () => { this.getCompanyList() })
    }

    // 筛选项
    filterTags = () => {
        const { comLabels } = this.props
        const { order, currentPage } = this.state
        let tech = '', industry = '', domain = '', round = '', area = '', date = ''
        // 如果含有二级行业 不要一级行业 做到细分化
        const sub_len = comLabels.filter(l => l.cate === 'subindustry').length
        comLabels.forEach(l => {
            if (l.cate === 'tech') {tech += `${l.id},`}
            if (sub_len > 0 && l.cate === 'subindustry') {industry += `${l.id},`}
            if (sub_len === 0 && l.cate === 'industry') {industry += `${l.id},`}
            if (l.cate === 'domain') {domain += `${l.id},`}
            if (l.cate === 'round') {round += `${l.id},`}
            if (l.cate === 'area') {area += `${l.id},`}
            if (l.cate === 'date') {date += `${l.id},`}
        })
        return { tech, industry, domain, round, area, date, order, page: currentPage }
    }

    // 收藏功能的选中事件
    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys })
    }

    componentDidUpdate(preProps,preState){
        function compare(origin, target) {
            //判断参数一是否为object类型
            if (typeof target === 'object') {
                //判断参数二是否不为object类型
                if (typeof origin !== 'object') {return false}
                //判断参数一和参数二的属性数量是否一致
                if (origin.length !== target.length) {return false}
                //遍历参数二的所有键
                for (let key in target)
                //判断参数二的键值和参数一的键值是否一致
                {if (!compare(origin[key], target[key])) {return false}}
                //参数1和参数2对比一致
                return true
            } else {return origin === target}//属性值的对比结果
        }
        // redux有变化的情况 或者 行业一级有变化 深层对比
        if (!compare(preProps.comLabels,this.props.comLabels)){
            this.getCompanyList()
        }
    }

    // 数据请求
    getCompanyList = () => {
        this.axios.post('/company/list', this.filterTags()).then(res => {
            if (res.code === 10000) {
                this.setState({
                    total: res.data.count, // 列表总数量
                    tableData: res.data.items,  // 列表内容
                    selectedRowKeys: []     // 重置选中的行 收藏功能
                })
            }
        })
    }

    // 导出公司列表 excel 文件
    exportList = () => {
        this.axios.post('/company/export',this.filterTags()).then(res => {
            if (res.code === 10000) { saveWithALink(res.data) }
        })
    }

    render() {
        const { subScopesId, selectedRowKeys, total, tableData } = this.state
        // table收藏功能
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        }
        return (
            <Layout className='company-con'>
                <BreadComp data={BreadData} />
                {/* main */}
                <Content className='park-main'>
                    {/* 公司筛选项列表 */}
                    <FilterList
                        subScopesId={subScopesId} // 选中一级行业 ID
                        clearFilter={this.clearFilter} // 清空所有选项
                        tagClose={this.tagClose} // 删除单个tag
                        tagsChange={this.tagsChange} // 选项点击事件
                        title='公司列表'
                        filterPage='company'
                    />
                    <hr />
                    {/* 搜索出的公司结果 */}
                    <section className='company-list'>
                        <h1>
                            当前展示{total > 5000 ? '前' : null}<span className='sums'>{total > 5000 ? '5000' : total}</span>条结果
                            {/* 收藏按钮 off=true 右上角图标  off=false 行内图标 */}
                            <AddCollectBtn off={false} selectKeys={selectedRowKeys} type={1} />
                            {/* 排序 */}
                            <Select placeholder="排序方式" style={{ width: 150 }} onChange={this.selectChange}>
                                <Option value="dt">TI指数</Option>
                                <Option value="date">成立时间</Option>
                            </Select>
                            {/* 导出功能 */}
                            <ExportBtn exportList={this.exportList}/>
                        </h1>
                        {/* 公司结果列表 */}
                        <CompareBtn
                            className={'custom-table company-table'}
                            compareOff={2}
                            rowSelection={rowSelection}
                            tableData={tableData}
                            total={total}
                            currentPage={this.state.currentPage}
                            changeStatePage={this.changeStatePage}
                        />
                    </section>
                </Content>
            </Layout>
        )
    }
}
export default CompanyList
