
import React, { Component } from 'react'
import { Button } from 'antd'
import FloorSection from './FloorSection'
import NumsSection from './NumsSection'
// import Evaluate from './Evaluate'
import Footer from './Footer'

import 'styles/pages/home.scss'

class HomeIndex extends Component {
    componentDidMount(){
        document.title = 'DT Insights——透视未来的科技本质'
    }
    render() {
        return (
            <div className='nologin-home'>
                {/* 导航 */}
                <section className='nav box_shadow' id='info'>
                    <div className='flex_box'>
                        <img src={require('../../assets/images/logo.svg')} alt='home-logo' />
                        <Button href='/login'>登录</Button>
                    </div>
                </section> 
                {/* banner */}
                <section className='top-banner flex_box container'>
                    <div>
                        <h1> 领先的<br />科技数据智能分析引擎</h1>
                        <h2> 多维数据趋势预测、机器智能决策辅助</h2>
                        <p>
                            {/* <Button href='/register'>体验Demo</Button> */}
                            {/* <Button className=''>
                                <img src={require('assets/images/home/play.svg')} alt='play'/>观看视频
                            </Button> */}
                        </p>
                    </div>
                    <img src={require('assets/images/home/top_banner.svg')} alt='top_banner' />
                </section>
                <FloorSection />
                <NumsSection />
                {/* 评价 */}
                {/* <Evaluate /> */}
                {/* 底部 */}
                <Footer />
            </div>
        )
    }
}
export default HomeIndex

