import { combineReducers } from 'redux'
// czy
import { compareCompanyItems,comLabels } from './company/reducer'
import { effectSelectedID } from './effect/reduce'
// heyuping
import { cates,keywords,activeCate } from './search/reducer'
import { perLabels, compareItem } from './person/reducer'

export default combineReducers({ 
    cates, keywords, activeCate,perLabels, 
    compareItem,compareCompanyItems,comLabels,
    effectSelectedID
})


