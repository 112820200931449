import React, { Component } from 'react'
import classNames from 'classnames'
class Filter extends Component {
    constructor(props) {
        super(props)
        this.state = {
            area: [],    // 地区
            domain: [],  // 领域
            doc: [],    // 上市进程类别 
            all: 0,    // 科创版上市公司全部数量
            selectedDoc: [],
            selectedDomain: [],
            selectedArea: []
        }
    }
    // 处理三个类型的条件 获取列表
    getRequest = () => {
        this.props.request({
            progress:   this.state.selectedDoc.join(),
            domain:     this.state.selectedDomain.join(),
            local:      this.state.selectedArea.join()
        })
    }
    // 是否选中状态 选择的id是否在已有的state中
    isAdded(id, cate) {
        if (cate === 'domain') {
            return this.state.selectedDomain.includes(id)
        } else if (cate === 'doc') {
            return this.state.selectedDoc.includes(id)
        } else {
            return this.state.selectedArea.includes(id)
        }
    }
    // 清除当前选择类型的条件 并重新发请求
    clearSelected(cate) {
        if (cate === 'domain') {
            this.setState({ selectedDomain: [] },() => {this.getRequest()})
        } else if (cate === 'doc') {
            this.setState({ selectedDoc: [] },() => {this.getRequest()})
        } else {
            this.setState({ selectedArea: [] },() => {this.getRequest()})
        }
    }
    // 处理点击上市进程
    clickDoc(id) {
        this.setState({
            selectedDoc: this.isAdded(id,'doc') ? [] : [id] //第一次则选择单个，二次点击则选择全部 
        },()=>{
            this.getRequest()
        })
    }
    // 处理点击领域
    clickDomain(id) {
        const selectArr = this.state.selectedDomain
        this.setState({
            selectedDomain: this.isAdded(id, 'domain') ? selectArr.filter(sid => sid !== id ) : [...selectArr, id]
        },() => {
            this.getRequest()
        })

    }
    // 区域条件点击处理
    clickArea(id) {
        const selectArr = this.state.selectedArea
        this.setState({
            selectedArea: this.isAdded(id, 'area') ? selectArr.filter(sid => sid !== id) : [...selectArr,id]
        },() => {
            this.getRequest()
        })
    }

    componentDidMount() {
        // 获取一级领域
        this.axios.post('/domain', { level: 1 })
            .then(res => this.setState({ domain: res.data }))
        // 获取可选择 地区列表
        this.axios.post('/area', { pid: 100000, type: 2 })
            .then(res => this.setState({ area: res.data.filter(item => item.id !== 900000) }))
        // 获取审批进程选择区
        this.axios.post('/starmarket/progress')
            .then(res => {
                this.setState({ 
                    doc: res.data, 
                    // 求和
                    all: res.data.reduce((total, currentValue) => {
                        return total + currentValue.count
                    }, 0) 
                })
            })
    }

    render() {
        return (
            <div className="filter">
                <div className="flex-box label-group">
                    {this.state.doc.map(item => (
                        <div 
                            key={item.id}
                            onClick={this.clickDoc.bind(this, item.id)}
                            className={classNames('label', { 'active': this.isAdded(item.id, 'doc')  })} >
                            {item.name}<span className="nums">{item.count}</span>家
                        </div>))}
                    {this.state.all && <div
                        className={classNames('label', { 'active': !this.state.selectedDoc.length })}
                        onClick={this.clearSelected.bind(this, 'doc')}
                    >
                        全部<span className="nums">{this.state.all}</span>家
                    </div>}
                </div>
                {/* 领域 选择区 */}
                <div className="column">
                    <div className="column-title">领域</div>
                    <div className="category">
                        <div
                            className={classNames('cate-label', { 'active': !this.state.selectedDomain.length })}
                            onClick={this.clearSelected.bind(this, 'domain')}
                        >
                            全部
                        </div>
                        {this.state.domain.map(item => <div
                            key={item.id}
                            onClick={this.clickDomain.bind(this, item.id)}
                            className={classNames('cate-label', { 'active': this.isAdded(item.id, 'domain') })}
                        >{item.name}</div>)}
                    </div>
                </div>
                {/* 地区选择区 */}
                <div className="column">
                    <div className="column-title">地区</div>
                    <div className="category">
                        <div
                            className={classNames('cate-label', { 'active': !this.state.selectedArea.length })}
                            onClick={this.clearSelected.bind(this, 'area')}
                        >
                            全部
                        </div>
                        {this.state.area.map(item => <div
                            key={item.id}
                            className={classNames('cate-label', { active: this.isAdded(item.id)})}
                            onClick={this.clickArea.bind(this, item.id)}
                        >{item.ShortName}</div>)}
                    </div>
                </div>
            </div>
        )
    }
}

export default Filter