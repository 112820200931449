import React, { Component } from 'react'
import { Skeleton } from 'antd'
import { AvatarLoad } from 'comps/common/AvatarLoad'

class RecommendLeft extends Component {
    state = { 
        personList:[],
        companyList: [],
        loading:true 
    }
    componentDidMount() {
        this.axios.post('/individuality/expert')
            .then(res => {
                if (res.code === 10000) {
                    let personList = this.getArrayItems(res.data, 2)
                    this.setState({ personList,loading:false })
                }
            })
        this.axios.post('individuality/company')
            .then(res => {
                if (res.code === 10000) {
                    let companyList = this.getArrayItems(res.data, 2)
                    this.setState({ companyList,loading:false })
                }
            })
    }

    getArrayItems = (arr, num) => { 
        //新建一个数组,将传入的数组复制过来,用于运算,而不要直接操作传入的数组; 
        var temp_array = []
        for (var index in arr) { 
            temp_array.push(arr[index])
        } 
        //取出的数值项,保存在此数组 
        var return_array = []
        for (var i = 0; i < num; i++) { 
        //判断如果数组还有可以取出的元素,以防下标越界 
            if (temp_array.length > 0) { 
                //在数组中产生一个随机索引 
                var arrIndex = Math.floor(Math.random() * temp_array.length)
                //将此随机索引的对应的数组元素值复制出来 
                return_array[i] = temp_array[arrIndex]
                //然后删掉此索引的数组元素,这时候temp_array变为新的数组 
                temp_array.splice(arrIndex, 1)
            } else { 
            //数组中数据项取完后,退出循环,比如数组本来只有10项,但要求取出20项. 
                break
            } 
        } 
        return return_array
    } 
    render() {
        const {personList,companyList } = this.state
        return (
            <>
                {
                    personList.map(item => {
                        return <div className="recommenditem" key={item.id}>
                            <div className="pic">
                                <AvatarLoad src={item.photo} type={2} />
                            </div>
                            <div className="introduce-con">
                                <a href={`/person/detail/${item.id}`}>
                                    <p className="title-box">
                                        <span>{item.name_cn}</span>
                                        <span>{item.comany_name}</span>
                                        <span>{item.position}</span>
                                    </p>
                                </a>
                                <p className="introduce">
                                    {item.bio}
                                </p>
                            </div>
                        </div>
                    })
                }
                {
                    companyList.map(item => {
                        return <div className="recommenditem" key={item.id}>
                            <div className="pic">
                                <AvatarLoad src={item.logo} type={1} />
                            </div>
                            <div className="introduce-con">
                                <a href={`/company/${item.id}`}>
                                    <p className="title-box">
                                        <span>{item.other_name || item.name}</span>
                                        <span>{item.one_line}</span>
                                    </p>
                                </a>
                                <p className="introduce">
                                    {item.intro}
                                </p>
                            </div>
                        </div>
                    })
                }
            
            </>
        )
    }
}


export default RecommendLeft