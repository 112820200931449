import React, { Component } from 'react'
import moment from 'moment'

class ExpressRight extends Component {
    state = { list:[],loading:true }
    componentDidMount() {
        this.axios.post('/flash/list')
            .then(res => {
                let list = res.data.items.slice(0,5)
                this.setState({ list, loading:false })
            })
    }
    render() {
        const {list} = this.state
        return (
            <>
                {
                    list.map(item => {
                        return <a href="/express" key={item.id}>
                            <div className="expressitem">
                                <div className="title-box">{item.title}</div>
                                <div className="date">{moment(item.start_time * 1000).format('YYYY-MM-DD')}</div>
                            </div> 
                        </a>
                    })
                }
            
            </>
        )
    }
}


export default ExpressRight