import React from 'react'
import { Tabs, Modal, Table } from 'antd'
// 可视化组件
import { PatentBasicBar } from 'pages/echarts/bar'
import BtmFcBtn from './BtmFcBtn'
import { toMoney,saveExcel,turnTableData,echartSavePic } from 'utils'
const { TabPane } = Tabs

// https://echarts.apache.org/examples/zh/editor.html?c=pie-legend

// 产品要求tabs展示的顺序 key值是对应后台接口关键字
const TabsTitle = [
    {name:'概况',key:'year'},
    {name:'趋势',key:'type'},
    // {name:'技术分布',key:'ipc'} // 2020-08-18 公司技术分布数据采集有问题，一期不做
]
const basicLegend = {
        '1':'有效','2':'失效','3':'审中','4':'未确定'
    },typeLegend = {
        '1':'发明','2':'实用新型','3':'外观设计','4':'其他'
    }
class CompanyPatentTabs extends React.Component{
    state = {
        showMode:'line',visible:false,currentNum:0,checkValue:0,
        echartId : !this.props.fullID ?  'patent' : `${this.props.fullID}-patent`, //echart 图表唯一ID
    }
    // 切换展示形式：line table
    showChange = e =>{
        this.setState({ showMode:e.target.value })
    }
    // tab切换：行业分布 企业形态 key是字符串
    tabChange = key =>{
        this.setState({ currentNum:Number(key) })
    }
    // 底部左右箭头 切换tab type:reduce or add
    toggleChartType = type =>{
        const { currentNum } = this.state
        const maxNum = TabsTitle.length - 1
        let num = currentNum
        if (type === 'add'){
            num = currentNum === maxNum ? 0 : ++ num
        } else {
            num = currentNum === 0 ? maxNum : -- num
        }
        this.setState({ currentNum:num })
    }
    // 切换全屏
    fullScreen = () => {
        this.setState({ visible: true })
    }
    hideModal = () => {
        this.setState({ visible: false })
    }
    // 下载功能 根据showMode下载的格式不同
    savaTableCsv = ()=>{
        const { showMode,echartId,currentNum } = this.state
        const { compName } = this.props
        const filename = `${compName}-${TabsTitle[currentNum].name}` // 文件名
        const type = TabsTitle[currentNum].key // 选择数据类型
        echartSavePic(filename,`${type}${echartId}`) // 下载png格式图片 文件名和唯一ID
    }
    render(){
        const { showMode,currentNum,echartId } = this.state
        const { fullID,data, compName } = this.props
        return (
            <div className='tabcharts'>
                <Tabs activeKey={String(currentNum)} onChange={this.tabChange}>
                    {
                        TabsTitle.map((tab,index) =>{
                            return (
                                <TabPane tab={tab.name} key={index} >
                                    {/* 概况 */}
                                    {
                                        tab.key === 'year' &&  <PatentBasicBar 
                                            data={data[`${tab.key}`]} 
                                            id={`${tab.key}${echartId}`} 
                                            legend={basicLegend} category={false} />            
                                    }
                                    {/* 趋势 */}
                                    {
                                        tab.key === 'type' &&  <PatentBasicBar category={true} 
                                            data={data[`${tab.key}`]} id={`${tab.key}${echartId}`} 
                                            legend={typeLegend} key={tab.key}/>        
                                    }
                                </TabPane>
                            )
                        })
                    }
                </Tabs>
                <BtmFcBtn isDispalyTitle={true} isDispalyRadio={false} isDispalyDown={true}
                    TabsTitle={TabsTitle} currentNum={currentNum} showMode={showMode} fullID={fullID}
                    toggleChartType={this.toggleChartType} savaTableCsv={this.savaTableCsv} 
                    showChange={this.showChange} fullScreen={this.fullScreen}
                />
                {/* 全屏 */}
                <Modal
                    title="科技专利"
                    visible={this.state.visible}
                    onOk={this.hideModal}
                    onCancel={this.hideModal}
                    className='echart-full'
                    width={'80%'}
                    footer={null}
                >
                    <CompanyPatentTabs fullID='full' data={data} compName={compName}/>
                </Modal>
            </div>
        )
    }
}
export default CompanyPatentTabs