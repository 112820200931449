import React from 'react'
import 'styles/official/foot.scss'

function Footer(){
    return (
        <footer className='footer-section'>
            <div className='foot-com'>
                <div className='left'>
                    <img src={require('../../assets/images/logofff.svg')} alt='logo'/>
                    <p>透视未来的科技本质</p>
                </div>
                <div className='center'>
                    <div className='top'>
                        <a href='/about'>关于我们</a>
                        <a href='/protocol'>隐私政策</a>
                    </div>
                    <div className='cen'>
                        <span>联系我们 limengqiang@deeptechchina.com</span>
                    </div>
                    <div className='bottom'>
                        {/* <span>联系地址:北京市朝阳区世茂大厦A座7层</span> */}
                    </div>
                </div>
                <div className='right'>
                    <img src={require('../../assets/images/wechat.jpg')} alt='wechat'/>
                    <p>关注DT Insights</p>
                </div>
            </div>
            <div className='footer-beian'>
                <a href=' https://beian.miit.gov.cn/' rel="noopener noreferrer" target='_blank'>
                    京ICP备16049925号-14
                </a>
                <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502043658" rel="noopener noreferrer" target='_blank'>
                    <img src={require('../../assets/images/jinghui.jpg')} alt='erweima'/>
                    京公网安备 11010502043658号 
                </a>
            </div>
        </footer>
    )
}

export default Footer