import React from 'react'
const Empty = props => {
    return (
        <div className="empty" style={{'display':(props.show ? 'block' : 'none')}}>
            <img src={require('assets/images/search/empty.png')} alt="empty" />
            <p className="title">抱歉，没有找到"<span>{props.keywords}</span>"相关结果</p>
            <p className="tips">请修改或者尝试其他搜索词</p>
        </div>
    )
}

export default Empty