import React from 'react'
import classNames from 'classnames'
import { CompareIndexBar } from 'pages/echarts/bar'

const PatentCompare = props =>{
    const { DataArray,PatentData,PatentLoading } = props
    return (
        !PatentLoading && <section className='index'>
            <h1><span>专利对比</span></h1>
            <ul className='index-ul'>
                <li>
                    <span></span>
                    <span>专利总数</span>
                    <span>发明</span>
                    <span>实用新型</span>
                    <span>外观设计</span>
                    <span>其他</span>
                </li>
                <div className={classNames('flex_box', DataArray.length === 2 ? 'two' : 'three')}>
                    {
                        DataArray.map((d,i) => <CompareIndexBar id={`${d.id}patent`} key={d.id} 
                            other_name={DataArray[i].other_name}
                            data={PatentData.filter(pd => pd.id === d.id)[0].type}
                            type='patent'
                        />)
                    }
                </div>
            </ul>
        </section>
    )
}
export default PatentCompare
