import React, { Component } from 'react'
import { Modal, Skeleton } from 'antd'
import BreadComp from 'comps/bread'
import Basic from './detail/Basic'
import Expert from './detail/Expert'
import Academic from './detail/Academic'
import Scholar from './detail/Scholar'
import Relation from './detail/Relation'
import Research from './detail/Research'
import Honor from './detail/Honor'
import Resume from './detail/Resume'
import SideMenu from './detail/SideMenu'
import { echartSavePic } from 'utils'
import { Tootip2 } from 'comps/tootip/Tootip'
import { getStorage } from 'utils/storage'
import { TEST_USER } from 'utils/type'

import 'styles/person/personDetail.scss'
import 'styles/utils/columns.scss'

const detail = [
    { target: 'basic', title: '个人信息', active: false },
    { target: 'expert', title: '专家指数', active: false },
    { target: 'academic', title: '影响力排行', active: false },
    { target: 'research', title: '研究方向', active: false },
    { target: 'statistics', title: '学术数据概览', active: false },
    { target: 'relations', title: '关系网络', active: false },
    { target: 'education', title: '教育经历', active: false },
    { target: 'employment', title: '工作经历', active: false },
    { target: 'honor', title: '个人荣誉', active: false },
]

class Detail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            barList: [
                {
                    title: "基本信息",
                    child: [
                        { title: '个人信息', active: false },
                        { title: '专家指数', active: false },
                        { title: '学术排行', active: false },
                        { title: '研究方向', active: false },
                        { title: '学术数据概览', active: false },
                        { title: '关系网络', active: false },
                        { title: '教育经历', active: false },
                        { title: '工作经历', active: false },
                        { title: '个人荣誉', active: false },
                    ]
                },
                { title: "论文", active: false },
                { title: "专利", active: false },
                { title: "项目", active: false },
            ],

            eduColumns: [
                {
                    dataIndex: 'school_name',
                    title: <div className='rw-name'>学校名称</div>,
                    key: 'school',
                    render: text => <span className='rw-con'>{text}</span>
                },
                {
                    dataIndex: 'major',
                    title: <div className='rw-name'>专业名称</div>,
                    key: 'major',
                    render: text => <span className='rw-con'>{text}</span>
                },
                {
                    dataIndex: 'start_y',
                    title: <div className='rw-name'>时间</div>,
                    key: 'graduation',
                    render: (text, record) => {
                        if (!record.start_y || !record.end_y) {
                            return <span className='rw-con'>-</span>
                        } else {
                            return <span className='rw-con'>{record.start_y + '年 至 ' + record.end_y + '年'}</span>
                        }
                    }
                },
                {
                    dataIndex: 'degree',
                    title: <div className='rw-name'>学位</div>,
                    key: 'degree',
                    render: text => <span className='rw-con'>{text}</span>
                },
            ],
            employColumns: [
                {
                    dataIndex: 'object_name',
                    title: <div className='rw-name'>机构名称</div>,
                    key: 'object_name',
                    render: text => <span className='rw-con'>{text}</span>
                },
                {
                    dataIndex: 'start_d',
                    title: <div className='rw-name'>时间</div>,
                    key: 'start_d',
                    render: (text, record) => {
                        if (record.start_d === '0000-00-00' || record.end_d === '0000-00-00' || !record.start_d || !record.end_d) {
                            return <span className='rw-con'>-</span>
                        } else {
                            // 在职的状态
                            if (record.status === 1){
                                return <span className='rw-con'>{ `${record.start_d} 年 至今 `}</span>
                            } else { // record.status === 2 离职状态
                                return <span className='rw-con'>{ `${record.start_d} 年 至 ${record.end_d} 年`}</span>
                            }
                        }
                    }
                },
                {
                    dataIndex: 'position',
                    title: <div className='rw-name'>职位</div>,
                    key: 'position',
                    render: text => <span className='rw-con'>{text}</span>
                }
            ],
            // 人物基本信息
            infos: {},
            // 人物荣誉
            awards: [],
            // 人物工作经历
            employment: [],
            // 人物工作经历
            education: [],
            // dtindex
            dtindex: {},
            // 学术数据
            academic: {},
            relation: {
                //专利共同申请人
                patent_rights: [],
                //专利共同发明人
                patent_inventors: [],
                //论文共著者
                paper_author: [],
                //同事
                all_wokers: [],
                //同学
                classmate: []
            },
            // 关系加载状态
            rels: false,
            visible: false,
            BreadData: [{ url: '/home', title: '首页' }, { url: '/person', title: '人物列表' }, { url: '', title: '' }, { url: '', title: '基本信息' }],
            infosLoading: true,
            dtindexLoading: true,
            researchData: [],  //研究方向数据
            researchLoading: true
        }
        this.save = this.save.bind(this)
    }
    save() {
        let name = this.state.infos.name_cn || this.state.infos.name_en
        echartSavePic(`${name}-关系网络`, 'relation')
    }
    fullScreen = () => {
        this.setState({ visible: true })
    }
    hideModal = () => {
        this.setState({ visible: false })
    }
    componentDidMount() {
        //人物基本信息
        this.axios.post('/expert/index', { id: this.props.match.params.id })
            .then(res => {
                this.setState({
                    infos: res.data,
                    infosLoading: false
                }, () => {
                    let Bs = [{ url: '/home', title: '首页' }, { url: '/person', title: '人物列表' }, { url: '', title: this.state.infos.name_cn || this.state.infos.name_en }, { url: '', title: '基本信息' }]
                    this.setState({ BreadData: Bs })
                    document.title = this.state.infos.name_cn || this.state.infos.name_en
                })
            })
        // DT指数
        this.axios.post('/expert/dtindex', { id: this.props.match.params.id })
            .then(res => {
                this.setState({
                    dtindex: res.data,
                    dtindexLoading: false
                })
            })
        // 学术数据概览
        this.axios.post('/expert/academic', { id: this.props.match.params.id })
            .then(res => {
                this.setState({ academic: res.data })
            })
        //教育经历_工作经历_个人荣誉
        this.axios.post('/expert/record', { id: this.props.match.params.id })
            .then(res => {
                if (res.data.awards.length === 0) {
                    detail.splice(detail.indexOf(detail.find(item => item.target === 'honor')), 1)
                }
                if (res.data.employment.length === 0) {
                    detail.splice(detail.indexOf(detail.find(item => item.target === 'employment')), 1)
                }
                if (res.data.education.length === 0) {
                    detail.splice(detail.indexOf(detail.find(item => item.target === 'education')), 1)
                }
                this.setState({
                    awards: res.data.awards,
                    employment: res.data.employment,
                    education: res.data.education
                })
            })
        // 关系网络
        this.axios.post('/expert/relation', { id: this.props.match.params.id })
            .then(res => {
                const { all_wokers, classmate, paper_author, patent_inventors, patent_rights } = res.data
                if (all_wokers && classmate && paper_author && patent_inventors && patent_rights) {
                    if (all_wokers.length === 0 && classmate.length === 0 && paper_author.length === 0 && patent_inventors.length === 0 && patent_rights.length === 0) {
                        detail.splice(detail.indexOf(detail.find(item => item.target === 'relations')), 1)
                    }
                    this.setState({ relation: res.data, rels: true })
                }
            })
        // 研究领域方向
        this.axios.post('/expert/researcharea', { id: this.props.match.params.id })
            .then(res => {
                if (res.data.length === 0) {
                    detail.splice(detail.indexOf(detail.find(item => item.target === 'research')), 1)
                }
                this.setState({
                    researchData: res.data,
                    researchLoading: false
                })
            })
    }
    isemptyRelate(relate) {
        /* eslint-disable */
        let flag = false
        Object.values(relate).map(item => {
            if (item.length !== 0) {
                flag = true
            }
        })
        return flag
    }
    render() {
        let { researchData } = this.state
        return (
            <div className="detail">
                <div className="aside person-aside">
                    <SideMenu id={this.props.match.params.id} detail={detail} />
                </div>
                <div className="main">
                    <BreadComp data={this.state.BreadData} />
                    <section id="basic">
                        <Skeleton active loading={this.state.infosLoading}>
                            { Object.keys(this.state.infos).length !== 0 &&
                                <Basic id={this.props.match.params.id} {...this.state.infos} />
                            }
                        </Skeleton>
                    </section>

                    <section id="expert">
                        <Skeleton active loading={this.state.dtindexLoading}>
                            <Expert {... this.state.dtindex} />
                        </Skeleton>
                    </section>

                    <section id="academicResearch">
                        <section id="academic">
                            {/* <div className="section-title academic-title">影响力排行</div> */}
                            <Academic id={this.props.match.params.id} />
                        </section>

                        <section id="research">
                            <Skeleton active loading={this.state.researchLoading}>
                                {
                                    researchData && researchData.length === 0 ? <>
                                                <div className="section-title">研究方向</div>
                                                <div className="emptyimg">
                                                    <img src={require("assets/images/person/empty.png")} alt="" />
                                                </div>
                                            </> :  researchData.map(item => {
                                                if (item.items.length) {
                                                    return <React.Fragment key={item.id}>
                                                        <div className="section-title">研究方向</div>
                                                        <Research id={this.props.match.params.id} researchData={researchData} />
                                                    </React.Fragment>
                                                }

                                            })
                                }
                            </Skeleton>
                        </section>
                    </section>
                    <section id="statistics">
                        <div className="section-title">学术数据概览</div>
                        {Object.keys(this.state.academic).length !== 0 &&
                            <Scholar id={this.props.match.params.id} {...this.state.academic} />}
                    </section>

                    {
                        TEST_USER.includes(getStorage('level')) ? 
                        <section id="relations">
                            <div className="section-title">关系网络</div>
                            <Tootip2 />
                        </section> 
                        :
                        (this.state.infos.name_cn || this.state.infos.name_en) && this.state.rels && this.isemptyRelate(this.state.relation) &&
                            <section id="relations">
                                <div className="section-title">关系网络</div>
                                    <div className="">
                                        <Relation
                                            id="relation"
                                            patent_rights={this.state.relation.patent_rights}
                                            patent_inventors={this.state.relation.patent_inventors}
                                            paper_author={this.state.relation.paper_author}
                                            all_wokers={this.state.relation.all_wokers}
                                            classmate={this.state.relation.classmate}
                                            person={this.state.infos.name_cn || this.state.infos.name_en}
                                        />
                                    </div>
                                    <Modal
                                        title="关系网络"
                                        visible={this.state.visible}
                                        onOk={this.hideModal}
                                        onCancel={this.hideModal}
                                        className='echart-full'
                                        width={1200}
                                        footer={null}
                                    >
                                        <Relation
                                            id="relation2"
                                            patent_rights={this.state.relation.patent_rights}
                                            patent_inventors={this.state.relation.patent_inventors}
                                            paper_author={this.state.relation.paper_author}
                                            all_wokers={this.state.relation.all_wokers}
                                            classmate={this.state.relation.classmate}
                                            person={this.state.infos.name_cn || this.state.infos.name_en}
                                        />
                                    </Modal>
                            </section>
                    }

                    {this.state.education.length > 0 &&
                        <section id="education">
                            <Resume
                                title="教育经历"
                                dataSource={this.state.education}
                                columns={this.state.eduColumns}
                            />
                        </section>
                    }
                    {this.state.employment.length > 0 &&
                        <section id="employment">
                            <Resume
                                title="工作经历"
                                dataSource={this.state.employment}
                                columns={this.state.employColumns}
                            />
                        </section>
                    }
                    {/* 个人荣誉 */}
                    {this.state.awards.length > 0 &&
                        <section id="honor">
                            <Honor list={this.state.awards} />
                        </section>
                    }
                </div>
            </div>
        )
    }
}

export default Detail
