import React, { Component } from 'react'
import { Tabs,Button } from 'antd'
import { clearStorage } from 'utils/storage'
// 组件
import BreadComp  from 'comps/bread'  // 面包屑导航
import Center from 'comps/user/UserCenter'
import PwdSetting from 'comps/user/UserPwd'
import DownHistory from 'comps/user/DownHistory'
import UserRecommend from 'comps/user/UserRecommend'

import 'styles/pages/user.scss'

const { TabPane } = Tabs

const UserSide = [
    { key:'Collect',title:'收藏夹'},
    { key:'Down',title:'我的下载'},
    { key:'Setting',title:'推荐设置'},
    { key:'Center',title:'个人中心'},
    { key:'Pwd',title:'修改密码'}
]
const CenterBread = [{url:'/home',title:'首页'},{url:'',title:'个人中心'}],
    PwdBread = [{url:'/home',title:'首页'},{url:'',title:'修改密码'}],
    RecommentBread = [{url:'/home',title:'首页'},{url:'',title:'推荐设置'}],
    DownBread = [{url:'/home',title:'首页'},{url:'',title:'我的下载'}]

class UserIndex extends Component{
    componentDidMount(){
        document.title = '个人中心'
    }
    // 退出按钮
    onLogout = e=>{
        this.axios.post('/logout').then(res=>{
            if (res.code === 10000){
                clearStorage()
                this.props.history.push('/')
            }
        })
    }
    render(){
        return (
            <div className='user-div'>
                <Tabs tabPosition='left' defaultActiveKey='3' className='user-con'>
                    {/* <TabPane tab='我的下载' key='1'>
                        <BreadComp data={DownBread} />
                        <DownHistory />
                    </TabPane> */}
                    <TabPane tab='推荐设置' key='2'>
                        {/* <BreadComp data={RecommentBread} /> */}
                        <UserRecommend />
                    </TabPane>
                    <TabPane tab='个人中心' key='3'>
                        {/* <BreadComp data={CenterBread} /> */}
                        <Center />
                    </TabPane>
                    <TabPane tab='修改密码' key='4'>
                        {/* <BreadComp data={PwdBread} /> */}
                        <PwdSetting />
                    </TabPane>
                </Tabs>
                <p className='logout'><Button onClick={this.onLogout}>退出</Button></p>
            </div>
            
        )
    }
}

export default UserIndex