import React from 'react'
import className from 'classnames'
const Patent = props => {
    return <div className={className("list",{
        'list-no-border-btom': props.botmBorder
    })}>
        <div className="item">
            <div className="title">{props.title}</div>
            <div className="body">
                {props.inventor && <div className="patent-info">【发明人】{props.inventor}</div>}
                {props.rights && <div className="patent-info">【专利权人】{props.rights}</div>}
                {props.apply_date && <div className="patent-info">【申请日】{props.apply_date}</div>}
                {props.pub_date && <div className="patent-info">【公开日】{props.pub_date}</div>}
                {props.pub_id && <div className="patent-info">【公开号】{props.pub_id}</div>}
                {props.ipc_code && <div className="patent-info">【IPC分类号】{props.ipc_code}</div>}
            </div>
        </div>
    </div>
}

export default Patent