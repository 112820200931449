import React, { PureComponent } from 'react'
import { RightOutlined } from '@ant-design/icons'
import { Button, List, Tabs, Row, Col, Skeleton, Empty } from 'antd'
import moment from 'moment'
const { TabPane } = Tabs

class StockInfo extends PureComponent{
    state = {
        StockData:[],loading:true,noticeData:{}
    }
    componentDidMount(){
        this.getNotice()
    }
    getNotice = ()=>{
        this.axios.post('/company/notice',{id:this.props.id}).then(res=>{
            this.setState({
                noticeData:res.data
            })
        })
    }
    render(){
        const { StockData,loading,id } = this.props
        const { noticeData } = this.state
        return (
            <section id='stock' className='stock'>
                <h1  className="parktitle">上市信息</h1>
                <div className='tabcharts stock-tab'>
                    <Skeleton active paragraph={{ rows: 4 }} loading={loading}>
                        <Tabs defaultActiveKey="1">
                            {
                                StockData.map((s,index) => {
                                    return (
                                        <TabPane tab={s.tabsname} key={index}>
                                            <Row gutter={40} type='flex'>
                                                <Col span={12}>
                                                    <dl>
                                                        <dt className='flex_box'>
                                                            <span>行情</span>
                                                            <span>数据更新时间: {s.date}</span>
                                                        </dt>
                                                        {
                                                            s.lists.map(l => <dd className='flex_box' key={l.k}>
                                                                <span>{l.k}</span>
                                                                <span>{l.v}</span>
                                                            </dd> )
                                                        }
                                                        {/* 参数 market=A US HK 决定跳转财务页面默认打开tabs */}
                                                        <Button href={`/company/stock/${id}`}>查看主要财务指标 <RightOutlined /></Button>
                                                    </dl>
                                                </Col>
                                                <Col span={12} className='report-col'>
                                                    <dl>
                                                        <dt className='flex_box'>
                                                            <span>公告</span>
                                                        </dt>
                                                        {
                                                            !noticeData[`${s.code}`] ?
                                                                <Empty description={'数据更新中...'} image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                                                :
                                                                <List
                                                                    dataSource={noticeData[`${s.code}`]}
                                                                    pagination={false}
                                                                    renderItem={item => (
                                                                        <a href={item.url} target='_blank' rel="noopener noreferrer">
                                                                            <span>{moment(item.date * 1000).format('YYYY-MM-DD')}</span><span title={item.title}>{item.title}</span>
                                                                            <i className='iconfont icon-downpdf'></i>
                                                                        </a>
                                                                    )}
                                                                />
                                                        }
                                                    </dl>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                    )
                                })
                            }
                        </Tabs>
                    </Skeleton>
                </div>
            </section>
        )
    }
    
}

export default StockInfo