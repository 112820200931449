import React from 'react'
import EchartReact from 'comps/Sign/EchartReact'

export default function Quote(props) {
    return (<EchartReact
        id={props.id}
        options={getOption(props.data)}
        style={{ width: '400px', height: '240px' }}
    />)
}


function getOption(data) {
    return {
        legend: {
            show: true,
            orient: 'vertical',
            right: 10,
            bottom: 20,
            icon: 'circle',
            itemHeight: 10,
            itemWidth: 10,
        },
        tooltip:{
            trigger: 'item',
            formatter: '{b}'
        },
        series: [
            {
                type: 'pie',
                radius: ['70%', '80%'],
                center: ['30%', '50%'],
                label: { show: false },
                hoverAnimation: false,
                data: [
                    {
                        value: data.h_index,
                        name: 'H-index指数 ' + data.h_index,
                    }
                ],

                color: ['#02375A']
            },
            {
                type: 'pie',
                radius: ['55%', '65%'],
                center: ['30%', '50%'],
                label: { show: false },
                hoverAnimation: false,
                data: [
                    { value: data.percent,name: '超过同领域' + data.percent + '%专家'},
                    { value: parseInt(100 - data.percent), name: ' ',tooltip:{show:false}},
                ],
                color: ['#044E79','#fff']
            }
        ],
    }
}
