import React, { Component } from 'react'
import Pie from './Pie'
import Caption from './Caption'
class Scale extends Component {
    constructor(props) {
        super(props)
        this.state = {
            captions1 : {
                title: [{ id: 31, text: '募资规模(亿)' }, { id: 32, text: '企业数量' }],
                list: [
                    { id: 311, nums: '31', unit: "<10" },
                    { id: 312, nums: '3', unit: "10~20" },
                    { id: 313, nums: '0', unit: "20~30" },
                    { id: 315, nums: '1', unit: "30~50" },
                    { id: 316, nums: '1', unit: ">50" }
                ]
            }
        }
    }
    componentDidMount() {

    }
    render() { 
        return (
            <div className="flex-box">
                <Pie id="fund" data={this.props.data} />
                <Caption {...this.state.captions1} />
            </div>
        )
    }
}
 
export default Scale
