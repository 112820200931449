import React, { Component } from 'react'
import Heading from './Heading'
import Industry from './Industry'
import News from './recommend/News'
import Rank from './recommend/Rank'
import Tech from './recommend/Tech'
import Company from './recommend/Company'
import Person from './recommend/Person'
import LabelModal from './LabelModal'
import { getStorage } from 'utils/storage'
import UserTypeModal from './UserTypeModal'
import 'styles/indiviation/page.scss'

class Individuation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            headlist : [
                {id: 754,link:'/board/general',icon:'icon-institution-hover',text:'科创板专题'},
                {id: 755,link:'/tech',icon:'icon-tech-default',text:'技术图谱'},
                {id: 756,link:'/company',icon:'icon-company-hover',text:'企业对标'},
                {id: 757,link:'/person',icon:'icon-person-hover',text:'人才对比'},
                {id: 758,link:'/platform',icon:'icon-park-hover',text:'招商工作台'},
            ],
            isParkUser: getStorage('level') === 2 || getStorage('level') === 6,
            Industry: [
                {id: 901,nums:'94.12',text:'新一代信息技术'},
                {id: 902,nums:'75.43',text:'高端装备领域'},
                {id: 903,nums:'71.5',text:'新材料领域'},
                {id: 904,nums:'63.2',text:'新能源领域'},
                {id: 905,nums:'68.9',text:'节能环保领域'},
                {id: 906,nums:'86.59',text:'生物医药领域'}
            ],
            RankList: [],
            Ranktitle: '',
            RankId: 0,
            domain: [],
            company: [],
            tech: [],
            expert: [],
            flash:[],
            dataType: null
        }
    }
    // 个性化首页请求公司 技术 人物
    request = ()=>{
        this.axios.post('/individuality/company',)
            .then(res=>{
                this.setState({
                    company: res.data
                })
            })
        this.axios.post('/individuality/tech',)
            .then(res=>{
                this.setState({
                    tech: res.data
                })
            })
        this.axios.post('/individuality/expert')
            .then(res=>{
                this.setState({
                    expert: res.data
                })
            })
    }

    componentDidMount() {
        this.request()
        document.title = 'DT Insights——透视未来的科技本质'
        //产业指数 取消后台接口 2020年8月30日
        /*
        this.axios.post('/individuality/domain',)
            .then(res=>{
                const Industry = this.state.Industry
                Object.values(res.data).map((num,index)=>{
                    Industry[index].nums = num
                    return Industry
                })
                this.setState({Industry})
            })
        */
        this.axios.post('/individuality/collection',)
            .then(res=>{
                this.setState({
                    Ranktitle: res.data.name,
                    RankList: res.data.items,
                    RankId : res.data.id,
                    dataType: res.data.data_type
                })
            })
        
        this.axios.post('/flash/list')
            .then(res => this.setState({flash:res.data.items}))
    }
    // 获取是否园区用户
    getUserType = ()=>{
        this.setState({
            isParkUser: getStorage('level') === 2 || getStorage('level') === 6
        })
    }
    render() { 
        return (
            <div className="individual">
                <Heading list={this.state.isParkUser ? this.state.headlist : this.state.headlist.filter(h=>h.id !== 758)} />
                <Industry list={this.state.Industry} data={this.state.domain} />
                <div className="main">
                    <div className="recommend">
                        <div className="section-title">推荐</div>
                        {this.state.company.length > 0 ? <Company list={this.state.company} /> : false}                         
                        {this.state.company.length > 0 ? <Tech list={this.state.tech} /> : false}
                        {this.state.company.length > 0 ? <Person list={this.state.expert} /> : false}
                        <Rank id={this.state.RankId} list={this.state.RankList} title={this.state.Ranktitle} dataType={this.state.dataType}/>
                    </div>
                    <News list={this.state.flash}  />
                    
                </div>
                <UserTypeModal getUserType={this.getUserType}/>
                <LabelModal getRecomList={this.request}/>
            </div>
        )
    }
}
 
export default Individuation