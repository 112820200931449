import React from 'react'
import * as echarts from 'echarts'
import { COLORS,COLORS_COMPETE } from 'utils/type'

// https://gallery.echartsjs.com/editor.html?c=x-dT65VWtP

// 创建数据
function setData(json, n, listdata) {
    json.forEach((p, i) => {
        listdata.push({
            x: (i + n) * 50,
            y: 100 + (n + i) * 10,
            "name": p.name,
            "showName": p.other_name,
            "symbolSize": setSize(p.real_amount),
            "category": n,
            "real_amount": p.real_amount,
            "draggable": "true",
        })
    })
}
// 取值范围 
function setSize(num) {
    // 50-400
    const min = 100, max = 400
    if (num < min) {
        num = 20
    } else if (num > max) {
        num = 50
    } else { num = (num - min) / 6 + 5 }
    return num
}

// 1.气泡的最小值100 2.中心点的公司的自定义一个产业链 3.hover状态显示公司简称和注册资金
export default class CompGraph extends React.Component {
    state = {
        echartId: !this.props.fullID ? this.props.id : `${this.props.fullID}-${this.props.id}`
    }
    componentDidMount() {
        let real_data = [], real_links = [], legends = [], target = [], other = [], texts = [], rel_nlist = []
        this.props.data.forEach(list => {
            if (!legends.includes(list.cname)) {
                legends.push(list.cname)
            }
            if (list.id === String(this.props.id)) {
                target.push(list)
            } else {
                // 去重
                if (other.filter(o => list.id === o.id).length === 0) {
                    other.push(list)
                    real_data.push({
                        name: `${list.other_name}+${list.id}`,
                        category: list.cname,
                        real_amount: list.real_amount,
                        symbolSize: setSize(list.real_amount),
                    })
                }
            }
        })
        real_data.push({
            name: target[0].other_name,
            category: target[0].cname,
            real_amount: target[0].real_amount,
            symbolSize: setSize(target[0].real_amount),
        })
        real_data.slice(0, real_data.length - 1).forEach(r => {
            real_links.push({
                source: r.name,
                target: target[0].other_name,
                name: r.category
            })
        })
        legends.forEach((l, i) => {
            texts.push({ "name": l })
        })
        let option = {
            tooltip: {
                show: true,
                padding: 8,
                formatter: function (t) {
                    let str = t.data.name.split('+')
                    if (t.dataType === 'node'){
                        return `${str[0]} <br/>(实缴资本:${Number(t.data.real_amount)}万元)`
                    }
                }
            },
            legend: {
                data: legends,
                textStyle: {
                    color: '#000000'
                },
                icon: 'circle',
                type: 'scroll',
                orient: 'vertical',
                left: 10,
                top: 20,
                bottom: 20,
            },
            series: [{
                type: 'graph',
                layout: 'force',
                color: COLORS_COMPETE,
                symbolSize: 50,
                draggable: true,
                // roam: true,
                focusNodeAdjacency: true,
                categories: texts,
                edgeSymbolSize: [80, 10],
                force: {
                    friction: 0.1,
                    repulsion: 240,
                    edgeLength: [5, 180]
                },
                data: real_data,
                links: real_links,
                lineStyle: {
                    normal: { opacity: 1, width: 1, curveness: 0 }
                },
                label: {
                    normal: {
                        show: true,
                        position: 'inside',
                        textStyle: { //标签的字体样式
                            color: '#000000', //字体颜色
                            fontWeight: 'normal', //'normal'标准'bold'粗的'bolder'更粗的'lighter'更细的或100 | 200 | 300 | 400...
                            fontSize: "12" //字体大小
                        },
                        formatter: function (params) {
                            let str = params.data.name.split('+')
                            return `${str[0]}` //此处为label转换 并转换颜色
                        },
                        fontSize: 12,
                        fontStyle: '400',
                    }
                }
            }]
        }
        let myChart = echarts.init(document.getElementById(`${this.state.echartId}-compete`))
        myChart.getOption(option)
        setTimeout(() => {
            myChart.setOption(option)
        }, 0)
    }
    render() {
        return <div id={`${this.state.echartId}-compete`} className="graph"></div>
    }
}
