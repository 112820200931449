import React from 'react'
import * as echarts from 'echarts'

export class RadarMap extends React.Component{
    componentDidMount(){
        const {RadarMapId, radarData, indicatorData, radarStyle} = this.props
        const option = {
            tooltip: {
                trigger: 'axis',
                showContent: radarStyle.tooltipIsShow ? true : false  //是否显示提示框浮层
            },
            radar: {
                nameGap: 5,
                radius: ["0%", "72%"],
                shape: radarStyle.radarShape ? radarStyle.radarShape : '', //雷达图类型，默认多边形
                splitArea: {
                    areaStyle: {
                        color: ["transparent"]
                    }
                },
                indicator: indicatorData  //坐标顶点限制，例[{name: "科技指标", max: 40},{name: "财务指标", max: 30},{name: "市场指数", max: 30}]
            },
            series: [{
                name: radarStyle.seriesName ? radarStyle.seriesName : '',  //悬浮框标题
                type: 'radar',
                symbol: "circle",
                symbolSize:2,
                tooltip: {
                    trigger: 'item'
                },        
                areaStyle: {
                    normal: {
                        color: "#02375A"
                    }
                },
                itemStyle:{
                    color:'#EDF0F4',
                    borderColor:'#02375A',
                    borderWidth:2,
                },
                lineStyle: {
                    normal: {
                        color: "#02375A",
                        width: 1
                    }
                },
                data: [radarData]  //坐标点，例[39, 28, 29]
            }]
        }
        let myChart = echarts.init(document.getElementById(RadarMapId))
        myChart.setOption(option)
    }
    render(){
        const {radarStyle, RadarMapId} = this.props
        return <div className='radar' id={RadarMapId} style={{ width: radarStyle.width ? radarStyle.width : '180px', height: radarStyle.height ? radarStyle.height : '130px' }} ></div>
    }
    
}
