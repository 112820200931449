import React from 'react'

const LabelBox = props => {
    return (
        <div className="label-box">
            <img className="label" src={require(`assets/images/board/${props.label}.png`)} alt="label" />
            <span className="text" style={{color: props.color}}>{props.text}</span>
        </div>
    )
}

export default LabelBox