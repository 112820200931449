import React, { Component } from 'react'
import * as echarts from 'echarts'
import moment from 'moment'
import { COLORS,COLORS_20 } from 'utils/type'
import 'styles/echarts/bar.scss'
// 公司科技专利
export class PatentBasicBar extends Component {
    componentDidMount() {
        let BarSource = []
        const propsDataKeys = Object.keys(this.props.data),
            propsLegend = this.props.legend,
            propsData = this.props.data
        for (let [k, v] of Object.entries(propsData)) {
            let obj = {}, sum = 0
            Object.keys(propsLegend).forEach(key => {
                obj[`${propsLegend[key]}`] = v[`${key}`] || 0
                sum += v[`${key}`] || 0
            })
            BarSource.push({ year: k, ...obj, sum })
        }
        let basicOption = {
            color: COLORS,
            legend: { left: 20 },
            tooltip: {
                trigger: 'axis',
                formatter: function (l) {
                    let msg = '',total = 0
                    msg += l[0].name + '<br />' // 年份
                    // for (let i in l) {
                    //     // eslint-disable-next-line no-useless-concat
                    //     msg += l[i].seriesName + ':' + l[i].data[`${l[i].seriesName}`] + ' 占比:' + `${isNaN((l[i].data[`${l[i].seriesName}`] / l[i].data['sum'] * 100).toFixed(2)) ? '0.00' : (l[i].data[`${l[i].seriesName}`] / l[i].data['sum'] * 100).toFixed(2)}` + '%<br />'
                    // }
                    // eslint-disable-next-line no-useless-concat
                    // msg += '总数:' + l[0].data['sum'] + ' 占比:' + `${isNaN((l[0].data['sum'] / l[0].data['sum'] * 100).toFixed(2)) ? '0.00' : (l[0].data['sum'] / l[0].data['sum'] * 100).toFixed(2)}` + '%'
                    
                    // 求总数
                    l.forEach(lItem => {
                        total += lItem.data[`${lItem.seriesName}`]
                    })
                    // 单条数据
                    l.forEach(lItem => {
                        // eslint-disable-next-line no-useless-concat
                        msg += lItem.seriesName + ':' + lItem.data[`${lItem.seriesName}`] + ' 占比:' + `${isNaN((lItem.data[`${lItem.seriesName}`] / total * 100).toFixed(2)) ? '0.00' : (lItem.data[`${lItem.seriesName}`] / total * 100).toFixed(2)}` + '%<br />'
                    })
                    msg += `总数: ${total} 占比: ${total === 0 ? '0.00%' : '100.00%'}`
                    return msg
                }
            },
            dataset: {
                dimensions: ['year', ...Object.values(propsLegend)],
                source: this.props.category ? BarSource.sort((a, b) => b.year - a.year) : BarSource
            },
            xAxis: { type: this.props.category ? 'value' : 'category', axisPointer: { type: 'none' } },
            yAxis: { type: this.props.category ? 'category' : 'value', axisPointer: { type: 'none' }},
            series: [
                { type: 'bar', stack: 'sum', itemStyle: { normal: { lineStyle: 3 } }},
                { type: 'bar', stack: 'sum', itemStyle: { normal: { lineStyle: 3 } }},
                { type: 'bar', stack: 'sum', itemStyle: { normal: { lineStyle: 3 } }},
                { type: 'bar', stack: 'sum', 
                    itemStyle: { 
                        normal: { 
                            lineStyle: 3, 
                            // barBorderRadius: this.props.category ? [0, 4, 4, 0] : [4, 4, 0, 0] 
                        } 
                    }
                }
            ]
        }
        let myChart = echarts.init(document.getElementById(`${this.props.id}`))
        myChart.setOption(basicOption)
    }
    render() {
        return (<div id={`${this.props.id}`} className='patent-bar' ></div>)
    }
}


// 产业投资趋势
export class IndustryInvestBarLine extends Component {
    state = {
        LineData: [], legends: ['vc', 'pe', '天使', '其他'], BarSource: []
    }
    componentDidMount() {
        this.dealData()
    }
    // 处理数据并初次渲染
    dealData = () => {
        let propsData = this.props.data, BarSource = [], LineData = []
        // 处理适合echart的数据格式
        for (let [k, v] of Object.entries(propsData)) {
            if (this.props.id.indexOf('invest') > -1){
                BarSource.push({
                    year: k, vc: v.vc_money, pe: v.pe_money, '天使': v.angeli_money, '其他': v.other_money,
                })
            } else {
                BarSource.push({
                    year: k, vc: parseInt(v.vc_money / 10000), pe: parseInt(v.pe_money / 10000), '天使': parseInt(v.angeli_money / 10000), '其他': parseInt(v.other_money / 10000),
                })
            }
            // 初始化折线图的数据 all
            LineData.push(v.vc_number + v.pe_number + v.other_number + v.angeli_number)
        }
        // 图例筛选更新折线的数据
        const _this = this
        let myChart = echarts.init(document.getElementById(`${this.props.id}`))
        // 图例change事件
        myChart.on('legendselectchanged', function (params) {
            const option = this.getOption()
            // 改变折线图的数据
            _this.setLineData(Object.values(params.selected))
            // 重新绘制option
            this.setOption(option)
        })
        this.setState({ LineData, BarSource }, () => {
            // 初次渲染图表
            this.setOptions()
        })
    }
    // 设置options
    setOptions = () => {
        const { legends, LineData, BarSource } = this.state
        const { type,isIndex } = this.props // isIndex:是否在个性化首页显示，title和图例的位置不一致
       
        let BarLineOption = {
            color: COLORS,
            title: {
                text: this.props.title,
                left: isIndex ? 'left' : "center",
                textStyle: { color: '#121F3D' }
            },
            dataset: {
                dimensions: ['year', ...legends],
                source: BarSource
            },
            legend: { 
                left: isIndex ? 'center' : 20
            },
            grid: { top: 80, bottom: 20 },
            tooltip: { 
                trigger: 'axis',
                formatter(params){
                    let year = params[0].name // 获取年份
                    let tooltip = `<div class="tooltip">${year}</br>`
                    params.forEach(item => {
                        // 柱状图的数据
                        if (item.seriesType === 'bar'){
                            tooltip += `<span></span> ${item.seriesName}: ${item.value[`${item.seriesName}`]}万元</br>`
                        } else { // 专门是 折线图的数据
                            tooltip += `<span></span> ${item.seriesName}: ${item.value}起</br>`
                        }
                    })
                    return tooltip + '</div>'
                }
            },
            xAxis: [
                { type: 'category', axisPointer: { type: 'shadow' }, axisLine: { show: false, textStyle: { color:'#9AA1A9'} }, axisTick: { show: false },axisLabel: {textStyle: {color:'#9AA1A9'}} }
            ],
            yAxis: [
                {
                    type: 'value',
                    name: `${this.props.type}金额(${this.props.unit})`,
                    splitLine: { show: false },
                    axisLine: { show: false },
                    axisTick: { show: false },
                    axisLabel: {textStyle: {color:'#9AA1A9'}}
                },
                {
                    type: 'value',
                    name: `${this.props.type}数量(起)`,
                    min: 0, max: Math.max(...LineData) + 3,
                    splitLine: { show: false },
                    axisLine: { show: false },
                    axisTick: { show: false },
                    axisLabel: {textStyle: {color:'#9AA1A9'}}
                }
            ],
            series: [
                { type: 'bar', stack: '总量', barWidth: 30 },
                { type: 'bar', stack: '总量', barWidth: 30 },
                { type: 'bar', stack: '总量', barWidth: 30 },
                { type: 'bar', stack: '总量', barWidth: 30 },
                { name: `${this.props.type}数量`, type: 'line', yAxisIndex: 1, smooth: true, data: LineData, itemStyle: { normal: { lineStyle: { width: 3 } } } }
            ]
        }
        let myChart = echarts.init(document.getElementById(`${this.props.id}`))
        myChart.setOption(BarLineOption)
    }
    // 根据改变图例返回的数组 改变投资数量的累加规则
    // [true,true,true,true,true] => ['vc','pe','天使','其他','投资数量']
    setLineData = arr => {
        const propsData = this.props.data
        let LineData = []
        for (let [k, v] of Object.entries(propsData)) {
            let sum = 0
            sum += arr[0] ? v.vc_number : 0
            sum += arr[1] ? v.pe_number : 0
            sum += arr[2] ? v.angeli_number : 0
            sum += arr[3] ? v.other_number : 0
            LineData.push(sum)
        }
        // 改变数据之后重新绘制图表
        this.setState({ LineData }, () => {
            this.setOptions()
        })
    }
    render() {
        return <div id={`${this.props.id}`} className='barline'></div>
    }
}

// 产业并购趋势
export class IndustryMergerBarLine extends Component {
    state = {
        legends: ['并购', '合并'], lineData: [], barData: []
    }
    componentDidMount() {
        let propsData = this.props.data, barData = [], lineData = []
        for (let [k, v] of Object.entries(propsData)) {
            barData.push({
                year: k, '并购': v.merger_money, '合并': v.aco_money,
            })
            lineData.push(v.merger_number + v.aco_number)
        }
        // 图例筛选更新折线的数据
        const _this = this
        let myChart = echarts.init(document.getElementById(`${this.props.id}`))
        // 图例change事件
        myChart.on('legendselectchanged', function (params) {
            const option = this.getOption()
            // 改变折线图的数据
            _this.setLineData(Object.values(params.selected))
            // 重新绘制option
            this.setOption(option)
        })
        this.setState({ lineData, barData }, () => {
            // 初次渲染图表
            this.setOptions()
        })
    }
    // 设置options
    setOptions = () => {
        const { legends, lineData, barData } = this.state
       
        let mergerOptions = {
            color: COLORS,
            title: {
                text: '并购趋势',
                left: "center",
                textStyle: { color: '#121F3D' }
            },
            dataset: {
                dimensions: ['year', ...legends],
                source: barData
            },
            legend: { left: 20 },
            tooltip: {
                trigger: 'axis',
                formatter(params){
                    let year = params[0].name // 获取年份
                    let tooltip = `<div class="tooltip">${year}</br>`
                    params.forEach(item => {
                        // 柱状图的数据
                        if (item.seriesType === 'bar'){
                            tooltip += `<span></span> ${item.seriesName}: ${item.value[`${item.seriesName}`]}万元</br>`
                        } else { // 专门是 折线图的数据
                            tooltip += `<span></span> ${item.seriesName}: ${item.value}起</br>`
                        }
                    })
                    return tooltip + '</div>'
                }
            },
            grid: { top: 80, bottom: 20 },
            xAxis: [
                {
                    type: 'category',
                    axisPointer: { type: 'shadow' },
                    axisLine: { show: false },
                    axisTick: { show: false },
                    axisLabel: {textStyle: { color: '#9AA1A9'}}
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    name: '并购金额（万元）',
                    min: 0,
                    // max: Math.max(...barData) * 1.1,
                    splitLine: { show: false },
                    axisLabel: { formatter: '{value}', textStyle: { color: '#9AA1A9'} },
                    axisLine: { show: false },
                    axisTick: { show: false },
                },
                {
                    type: 'value',
                    name: '并购数量（起）',
                    min: 0,
                    max: Math.max(...lineData) + 3,
                    interval: 1,
                    splitLine: { show: false },
                    axisLabel: { formatter: '{value}', textStyle: {color: '#9AA1A9'} },
                    axisLine: { show: false },
                    axisTick: { show: false }
                }
            ],
            series: [
                { type: 'bar', stack: '总量', barWidth: 30 },
                { type: 'bar', stack: '总量', barWidth: 30 },
                {
                    name: '并购数量', type: 'line', smooth: true, yAxisIndex: 1,
                    data: lineData,
                    itemStyle: { normal: { lineStyle: { width: 3 } } }
                }
            ]
        }
        let myChart = echarts.init(document.getElementById(`${this.props.id}`))
        myChart.setOption(mergerOptions)
    }
    // 根据改变图例返回的数组 改变投资数量的累加规则
    // [true,true,true] => ['并购','合并','并购数量']
    setLineData = arr => {
        const propsData = this.props.data
        let lineData = []
        for (let [k, v] of Object.entries(propsData)) {
            let sum = 0
            sum += arr[0] ? v.merger_number : 0
            sum += arr[1] ? v.aco_number : 0
            lineData.push(sum)
        }
        // 改变数据之后重新绘制图表
        this.setState({ lineData }, () => {
            this.setOptions()
        })
    }
    render() {
        return <div id={`${this.props.id}`} className='barline'></div>
    }
}

// 产业投退对比
export class IndustryInvestOut extends Component {
    state = {
        InvestLine: [], InvestBar: [], OutLine: [], OutBar: [],
        legends: ['vc', 'pe', '天使', '其他']
    }
    async componentDidMount() {
        await this.dealData()
        // 绘制
        await this.setOptions()
        await this.onLegendEvent()
    }
    // 处理数据格式
    dealData = () => {
        let propsInvest = this.props.invest, propsOut = this.props.out,
            InvestBar = [], InvestLine = [], OutLine = [], OutBar = []
        // 处理投资的柱状图和折线图 数据格式
        for (let [k, v] of Object.entries(propsInvest)) {
            InvestBar.push({
                year: k, vc: v.vc_money, pe: v.pe_money, '天使': v.angeli_money, '其他': v.other_money
            })
            // 初始化折线图的数据 all
            InvestLine.push(v.vc_number + v.pe_number + v.other_number + v.angeli_number)
        }

        // 处理退出的柱状图和折线图 数据格式
        for (let [k, v] of Object.entries(propsOut)) {
            OutBar.push({
                year: k, vc: parseInt(v.vc_money / 10000), pe: parseInt(v.pe_money / 10000), '天使': parseInt(v.angeli_money / 10000), '其他': parseInt(v.other_money / 10000),
            })
            // 初始化折线图的数据 all
            OutLine.push(v.vc_number + v.pe_number + v.other_number + v.angeli_number)
        }

        this.setState({ InvestLine, InvestBar, OutLine, OutBar })
    }
    // lengen事件监听
    onLegendEvent = () => {
        // 图例筛选更新折线的数据
        const _this = this
        let myChart = echarts.init(document.getElementById(`${this.props.id}`))
        // 图例change事件
        myChart.on('legendselectchanged', function (params) {
            const option = this.getOption()
            // 改变折线图的数据
            _this.setLineData(Object.values(params.selected))
            // 重新绘制option
            this.setOption(option)
        })
    }
    // 设置options
    setOptions = () => {
        const { legends, InvestLine, InvestBar, OutLine, OutBar } = this.state
        let BarLineOption = {
            color: COLORS,
            title: {
                text: this.props.title,
                left: "center",
                textStyle: { color: '#121F3D' }
            },
            dataset: [
                {
                    dimensions: ['year', ...legends],
                    source: InvestBar
                },
                {
                    dimensions: ['year', ...legends],
                    source: OutBar
                }
            ],
            legend: { left: 20, top: 20 },
            grid: [{
                top: 80,
                height: '36%'
            }, {
                bottom: 20,
                top: '60%',
                height: '36%'
            }],
            axisPointer: {
                link: { xAxisIndex: 'all' }
            },
            tooltip: {
                trigger: 'axis',
                formatter(params){

                    // 根据数据 输出tooptip 格式
                    function createTooltip(data){
                        let year = data[0].name // 获取年份
                        let tooltip = `<div class="tooltip">${year}</br>`
                        data.forEach(item => {
                            // 柱状图的数据
                            if (item.seriesType === 'bar'){
                                tooltip += `<span></span> ${item.seriesName}: ${item.value[`${item.seriesName}`]}万元</br>`
                            } else { // 专门是 折线图的数据
                                tooltip += `<span></span> ${item.seriesName}: ${item.value}起</br>`
                            }
                        })
                        return tooltip + '</div>'
                    }
                    let investArr,outArr
                    // 出现单数的情况 只选择一个投资数量或者退出数量
                    if (params.length % 2 !== 0){
                        // 只取 bar 数据
                        const filterArr = params.filter(item => item.seriesType === 'bar' )
                        // 取出line类型数据，投资数量和退出数量
                        const investLine = params.filter(item => item.seriesIndex === 4)
                        const outLine = params.filter(item => item.seriesIndex === 9)

                        investArr = filterArr.slice(0,filterArr.length / 2).concat(investLine)
                        outArr = filterArr.slice(filterArr.length / 2).concat(outLine)
                    } else {
                        investArr = params.slice(0,params.length / 2)
                        outArr = params.slice(params.length / 2)
                    }
                    let investTooltip = createTooltip(investArr),outToopltip = createTooltip(outArr)
                    return `${investTooltip}${outToopltip}`
                }
            },
            xAxis: [
                {
                    type: 'category',
                    axisLine: { show: false },
                    axisTick: { show: false },
                    axisLabel: {
                        textStyle: {
                            color: '#9AA1A9'
                        }
                    }
                },
                {
                    gridIndex: 1,
                    type: 'category',
                    position: 'top',
                    axisLine: { show: false },
                    axisTick: { show: false },
                    axisLabel: {
                        textStyle: {
                            color: '#9AA1A9'
                        }
                    }
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    name: '投资金额(万元)',
                    splitLine: { show: false },
                    axisLine: {
                        show: false
                    },
                    axisTick: { show: false},
                    axisLabel: {
                        textStyle: {
                            color: '#9AA1A9'
                        }
                    }
                },
                {
                    type: 'value',
                    name: '投资数量(起)',
                    min: 0, max: Math.max(...InvestLine) + 3,
                    splitLine: { show: false },
                    axisLine: {
                        show: false
                    },
                    axisTick: { show: false},
                    axisLabel: {
                        textStyle: {
                            color: '#9AA1A9'
                        }
                    }
                },
                {
                    type: 'value',
                    gridIndex: 1,
                    inverse: true,
                    name: '退出金额(万元)',
                    axisLabel: { 
                        formatter: '{value}',
                        textStyle: {
                            color: '#9AA1A9'
                        }
                    },
                    splitLine: { show: false },
                    axisLine: {
                        show: false
                    },
                    axisTick: { show: false},
                },
                {
                    gridIndex: 1,
                    type: 'value',
                    name: '退出数量(起)',
                    min: 0, max: Math.max(...OutLine) + 3,
                    inverse: true,
                    splitLine: { show: false },
                    axisLine: {
                        show: false
                    },
                    axisTick: { show: false},
                    axisLabel: {
                        textStyle: {
                            color: '#9AA1A9'
                        }
                    }
                }
            ],
            series: [
                // 投资趋势
                { type: 'bar', stack: 'invest', datasetIndex: 0, barWidth: 30 },
                { type: 'bar', stack: 'invest', datasetIndex: 0, barWidth: 30 },
                { type: 'bar', stack: 'invest', datasetIndex: 0, barWidth: 30 },
                { type: 'bar', stack: 'invest', datasetIndex: 0, barWidth: 30 },
                { name: '投资数量', type: 'line', xAxisIndex: 0, yAxisIndex: 1, smooth: true, data: InvestLine, itemStyle: { normal: { lineStyle: { width: 3 } } } },
                // 退出趋势
                { type: 'bar', stack: 'out', datasetIndex: 1, yAxisIndex: 2, xAxisIndex: 1, barWidth: 30 },
                { type: 'bar', stack: 'out', datasetIndex: 1, yAxisIndex: 2, xAxisIndex: 1, barWidth: 30 },
                { type: 'bar', stack: 'out', datasetIndex: 1, yAxisIndex: 2, xAxisIndex: 1, barWidth: 30 },
                { type: 'bar', stack: 'out', datasetIndex: 1, yAxisIndex: 2, xAxisIndex: 1, barWidth: 30 },
                { name: '退出数量', type: 'line', xAxisIndex: 1, yAxisIndex: 3, smooth: true, data: OutLine, itemStyle: { normal: { lineStyle: { width: 3 } } } },
            ]
        }
        let myChart = echarts.init(document.getElementById(`${this.props.id}`))
        myChart.setOption(BarLineOption)
    }
    // 根据改变图例返回的数组 改变投资数量的累加规则
    // [true,true,true,true,true] => ['vc','pe','天使','其他','投资数量']
    setLineData = arr => {
        const propsInvest = this.props.invest, propsOut = this.props.out
        let InvestLine = [], OutLine = []
        for (let v of Object.values(propsInvest)) {
            InvestLine.push(this.sumFilterArr(arr, v))
        }
        for (let v of Object.values(propsOut)) {
            OutLine.push(this.sumFilterArr(arr, v))
        }
        // 改变数据之后重新绘制图表
        this.setState({ InvestLine, OutLine }, () => {
            this.setOptions()
        })
    }
    sumFilterArr = (arr, v) => {
        let sum = 0
        sum += arr[0] ? v.vc_number : 0
        sum += arr[1] ? v.pe_number : 0
        sum += arr[2] ? v.angeli_number : 0
        sum += arr[3] ? v.other_number : 0
        return sum
    }
    render() {
        return <div id={`${this.props.id}`} className='barline invest_out'></div>
    }
}

// 公司指数对比的柱状图
export class CompareIndexBar extends Component {
    componentDidMount() {
        const typeLegend = {
            '0': '专利总数', '1': '发明', '2': '实用新型', '3': '外观设计', '4': '其他'
        }
        const pData = this.props.data
        let barData = []
        // 专利对比
        if (this.props.type === 'patent') {
            let first = 0, second = 0, third = 0, four = 0
            Object.entries(pData).forEach(([k, v]) => {
                Object.entries(v).forEach(([vk, vv]) => {
                    if (vk === '1') {
                        first += vv
                    } else if (vk === '2') {
                        second += vv
                    } else if (vk === '3') {
                        third += vv
                    } else if (vk === '4') {
                        four += vv
                    }
                })
            })
            barData = [
                four, third, second, first, four + third + second + first
            ]
        } else { // 指数对比
            barData = [
                pData.financial,  // 财务数据
                pData.market,  // 市场指数
                pData.scientific, // 科技指数
                pData.scientific + pData.financial + pData.market, // 总数
            ]
        }
        const investLineOption = {
            title: {
                text: this.props.other_name,
                left: "center",
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            grid: {
                top: 50,
                left: 20,
                right: 20,
                bottom: 0,
                containLabel: false
            },
            xAxis: {
                show: false,
                type: 'value',
                max: this.props.type === 'patent' ? null : 100
            },
            yAxis: {
                type: 'category',
                axisLine: { show: false },
                axisTick: { show: false },
                axisLabel: { show: false },
                splitLine: { show: false },
                data: this.props.type === 'patent' ? Object.values(typeLegend).reverse() : ['财务指标', '市场指标', '科技指标', 'TI指数']
            },
            series: [
                {
                    type: 'bar',
                    barWidth: 25,
                    label: { show: true, position: 'insideLeft', color: '#202031', fontWeight: 500, fontSize: 16 },
                    itemStyle: {
                        normal: {
                            color: params => {
                                return COLORS_20[params.dataIndex]
                            }
                        }
                    },
                    data: barData
                }
            ]
        }
        let myChart = echarts.init(document.getElementById(`bar-${this.props.id}`))
        myChart.setOption(investLineOption)
    }
    render() {
        return <div id={`bar-${this.props.id}`} className='compare-bar'></div>
    }
}

// 公司对比 融资对比
export class CompareFinanceBar extends Component {
    componentDidMount() {
        const { RoundData, barDatas, dates, investors, names, oriMon } = this.props
        let option = {
            tooltip: {
                show: true,
                backgroundColor: '#fff',
                textStyle: { color: '#505D6F', fontSize: 14 },
                padding: 10,
                // position: 'bottom',
                confine: true,
                formatter: function (p) {
                    const j = p.dataIndex, i = p.seriesIndex, investorsL = Object.keys(investors[i][j]).length
                    let str = '', investorsStr = ''
                    // 投资人的数据
                    if (investorsL > 0) {
                        for (let [k, v] of Object.entries(investors[i][j])) {
                            str += v
                            str += '、'
                        }
                        if (str.length - 1 > 50) {
                            investorsStr = `${str.substring(0, 50)}<br />${str.substring(50, str.length - 1)}`
                        } else {
                            investorsStr = `${str.substring(0, str.length - 1)}`
                        }
                    }
                    return `当前轮次：${p.seriesName}<br />本轮金额：${oriMon[i][j].m} 万${oriMon[i][j].c} <br />
                        本轮投资人： ${investorsStr}<br />融资时间：${moment(dates[i][j] * 1000).format('YYYY-MM-DD')}`
                },
            },
            legend: {
                data: RoundData, bottom: 20,
            },
            grid: {
                top: 20, left: 20, right: 50, bottom: 100,
                containLabel: false
            },
            xAxis: {
                type: 'value',
                axisLine: { show: false },
                axisTick: { show: false },
                splitLine: { show: false },
            },
            yAxis: {
                type: 'category',
                axisLine: { show: false },
                axisTick: { show: false },
                axisLabel: { show: false },
                splitLine: { show: false },
                data: names.reverse(),  // 对比的公司简称
            },
            series: []
        }
        for (var i = 0; i < RoundData.length; i++) {
            option.series.push({
                name: RoundData[i],
                type: 'bar',
                stack: '总量',
                label: {
                    show: true, position: 'insideRight',
                    // value = 0的时候不显示
                    formatter: function (params) {
                        if (params.value > 0) {
                            return params.seriesName
                        } else {
                            return ' '
                        }
                    }
                },
                itemStyle: {
                    normal: { color: COLORS_20[i] }
                },
                data: barDatas[i]
            })
        }
        let myChart = echarts.init(document.getElementById(`bar-${this.props.id}`))
        myChart.setOption(option)
    }
    render() {
        return <div id={`bar-${this.props.id}`} className='finance-bar'></div>
    }
}

// 人物影响力排行榜年龄分布 柱状图
export class EffectAgeBar extends Component {
    componentDidMount(){
        const { data, other, title} = this.props // data:当前排行的数据，other:领域均值，title:横坐标数据
        const option = {
            color: ['#105B86', '#1C8B8F'],
            tooltip: {
                trigger: 'axis',
            },
            
            legend: {
                icon:'circle',
                top: 'bottom',
                left: 70,
                itemGap: 35,
                itemWidth: 10,
                data: ['当前排行', '领域均值'],
            },
            grid: {
                bottom:60,
                right: '120',
                left:'80'
            },
            xAxis: [
                {
                    type: 'category',
                    name:'年龄区间',
                    axisLine: { show: false },
                    axisTick: { show: false },
                    splitLine: { show: false },
                    nameTextStyle: {
                        verticalAlign: 'top',
                        padding: [8,0]
                    },
                    data: title     // ['<30','30-39','40-49'...]
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    axisLine: { show: false },
                    axisTick: { show: false },
                    splitLine: { show: false },
                    max: function (value) {
                        return value.max + 5
                    },
                    nameTextStyle: {
                        align:'right',
                        padding: [10,10,10,0]
                    },
                    name:'专家数量(数)'
                }
            ],
            series: [
                {
                    name: '当前排行',
                    type: 'bar',
                    barGap: 0,
                    barWidth: 30,
                    // itemStyle:{
                    //     barBorderRadius: [8,8,0,0]
                    // },
                    data: data  // [0,120,20,...]
                },
                {
                    name: '领域均值',
                    type: 'bar',
                    barWidth: 30,
                    data: other // [0,120,20,...]
                }
            ]
        }
        let myChart = echarts.init(document.getElementById(this.props.id))
        myChart.setOption(option)
    }
    render(){
        return <div id={this.props.id} className='effect-bar'></div>
    }
}

// 个性化首页的人物影响力的院校分布bar
export class IndiEffectSchoolBar extends Component{
    componentDidMount(){
        const propsData = this.props.data
        let yAxisData = [],seriesData = []
        propsData.forEach( l => {
            yAxisData.push(l.name)
            seriesData.push(l.value)
        })
        const option = {
            color:COLORS,
            title: {
                text: '院校分布'
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            grid: {
                left:'20',
                containLabel: true
            },
            xAxis: {
                type: 'value',
                splitLine: { show: false },
                axisLine: { show: false },
                axisTick: { show: false },
                axisLabel: { show: false }
            },
            yAxis: {
                type: 'category',
                axisTick: { show: false },
                axisLabel:{
                    margin: 8,
                    formatter:function(params){
                        var val = ""
                        if (params.length > 8){
                            val = params.substr(0,8) + '...'
                            return val
                        } else {
                            return params
                        }
                    }
                },
                data: yAxisData
            },
            series: [
                {
                    name: '',
                    type: 'bar',
                    barMaxWidth: 40,
                    data: seriesData
                }
            ]
        }
        let myChart = echarts.init(document.getElementById(this.props.id))
        myChart.setOption(option)
    }
    render(){
        return <div id={this.props.id} className='effect-bar'></div>
    }
}
