import React, { useEffect } from 'react'
import { CompareFinanceBar } from 'pages/echarts/bar'

// {/* https://gallery.echartsjs.com/editor.html?c=xvxNRYUoT- */}
const FinanceCompare = props =>{

    const { DataArray,FinanceLoading,FinanceData,idsData } = props
    let noLists = FinanceData.every(l => l.lists.length === 0 )   // 所有的对比公司的融资数据全是空数组
    // 统一处理数据 金额 时间，投资人
    let RoundData = [],barDatas = [],dates = [],investors = [],names = [],oriMon = [] //原始金额
    if (FinanceData.length === idsData.length){
        FinanceData.forEach((item,index) => {
            names.push(item.name)
            item.lists.length > 0 && item.lists.forEach((list,i) =>{
                RoundData.push(list.round) // 投资轮次
                barDatas[i] = []
                dates[i] = []
                investors[i] = []
                oriMon[i] = []
            })
        })
        FinanceData.reverse().forEach((item,index) => {
            if (item.lists.length > 0 ){
                item.lists.forEach((list,i) =>{
                    barDatas[i].push(list.amount_rmb || 0) // 投资金额
                    dates[i].push(list.date || 0) // 投资时间
                    investors[i].push(list.investor || {}) // 投资人
                    oriMon[i].push({m:list.t_amount || 0,c:list.currency || ''})
                })
            } else {
                // 投资数据为空的公司处理
                Array.from(new Set(RoundData)).forEach((list,i) =>{
                    barDatas[i].push(0) // 投资金额
                    dates[i].push(0) // 投资时间
                    investors[i].push({}) // 投资人
                    oriMon[i].push({})
                })
            }
        })
    }
    // 多余数组去重
    let _RoundData = Array.from(new Set(RoundData))
    // echarts 值  名字  时间  投资人  原始数据
    let Evalue = [],Ename = [],Etime = [],Eman = [], Eyuan = []
    // 取出所有数据中有值的选项放入echarts中进行渲染
    barDatas.forEach((item, index)=>{
        if (JSON.stringify(item) !== '[0,0,0]' && JSON.stringify(item) !== '[0,0]'){
            Evalue.push(item)
            Ename.push(_RoundData[index])
            Etime.push(dates[index])
            Eman.push(investors[index])
            Eyuan.push(oriMon[index])
        }
    })
    
    return (
        !noLists && names.length === idsData.length && <section className='finance index'>
            <h1>融资能力对比</h1> 
            <ul className='index-ul'>
                <li>{ names.map(name => <span key={name}>{name}</span>) }</li>
                <div className='flex_box'>
                    {
                        !(FinanceLoading) && <CompareFinanceBar id='finance'
                            RoundData={Ename} barDatas={Evalue} dates={Etime} investors={Eman}
                            names={names} oriMon={Eyuan}
                        />
                    }
                </div>
            </ul>
        </section>
    )
}
export default FinanceCompare