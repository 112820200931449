import React from 'react'
import Cont from './Cont'
import moment from 'moment'

const TechItem = props => {
    
    return (
        <a href={`/tech/${props.id}`} target="_blank" rel="noopener noreferrer" className="item">
            <div className="item-box">
                <div className="title">{props.name}</div>
                <div className="body">
                    <div className="trend">
                        <Cont id={props.id} company={props.map_company} paper={props.map_paper} />
                    </div>
                    <div className="main">
                        <div className="list-i">
                            <span className="tips">技术发明者:</span>
                            <span className="text" title={props.inventor ? props.inventor : '未知'}>{props.inventor ? props.inventor : '未知'}</span>
                        </div>
                        <div className="list-i">
                            <span className="tips">首次出现时间:</span>
                            <span className="text">{props.date ? props.date : '未明确'}</span>
                        </div>
                        <div className="list-i">
                            <span className="tips">最新论文时间:</span>
                            <span className="text">{moment(props.new_paper_date * 1000).format('YYYY年MM月DD日')}</span>
                        </div>
                    </div>
                </div>
                <div className="application">
                    应用领域：<span title={props.application}> {props.application}</span>
                </div>
            </div>
        </a>
    )
}

export default TechItem