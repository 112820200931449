import React, { Component } from 'react'
import { Menu } from 'antd'
import CreateBtn from './CreateBtn'
import { withRouter,Link } from 'react-router-dom'

const { SubMenu } = Menu

@withRouter
class SideMenu extends Component{
    rootSubmenuKeys = ['recommend','my']
    state = {
        openKeys:['recommend'],MenuData : [],
        menuList: {
            recommend:{
                name:'推荐专辑',
                items:[
                    {id:"0", name:'全部专辑'},
                    {id:"1", name:'企业专辑'},
                    {id:"2", name:'人物专辑'}
                ]
            },
            my: {
                name:'我的专辑',
                items: [
                    {id:"0_4", name:'全部专辑'},
                    {id:"1_4", name:'企业专辑'},
                    {id:"2_4", name:'人物专辑'},
                    {id:"3_4", name:'园区专辑'},
                    {id:"4_4", name:'技术专辑'}

                ]
            }
        }
    }
    componentDidMount(){
        this.getMenu()
        document.title = '专辑'
        this.setState({openKeys:[this.props.propsKeys]})
    }

    // 获取专辑菜单
    getMenu = ()=>{
        Object.keys(this.state.menuList).forEach(k =>{
            // 根据props.current 确定默认打开菜单
            let key = this.state.menuList[`${k}`].items.filter(item =>  String(item.id) === this.props.current)
            if (key.length > 0){
                this.setState({ openKeys:[k] })
            }
        })
    }
    // 只允许打开一个菜单
    onOpenChange = openKeys => {
        const latestOpenKey = openKeys.find(key => this.state.openKeys.indexOf(key) === -1)
        if (latestOpenKey && latestOpenKey === 'my') {
            this.props.history.push(`/mycollection/0`)
        } else  if (latestOpenKey && latestOpenKey === 'recommend') {
            this.props.history.push(`/collection/0`)
        }
        if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            this.setState({ openKeys })
        } else {
            this.setState({
                openKeys: latestOpenKey ? [latestOpenKey] : [],
            })
        }
    }

    render(){
        const { menuList } = this.state
        return (
            <div className="sidemenu-box">
                <Menu mode="inline"
                    openKeys={ this.state.openKeys}
                    onOpenChange={this.onOpenChange}
                    selectedKeys={['sub' + this.props.current]}
                >
                    {
                        Object.keys(menuList).filter(c => c !== 'tech').map(k => {
                            return <SubMenu key={k} title={menuList[`${k}`].name}>
                                {
                                    menuList[`${k}`].items.map(item=> <Menu.Item key={'sub' + item.id}>
                                        <Link
                                            to={k === 'my' ? `/mycollection/${item.id.split('_')[0]}` : `/collection/${item.id}`}
                                            title={item.name}
                                            style={{marginLeft:'-25px'}}
                                        >{item.name}</Link>
                                    </Menu.Item>)
                                }
                            </SubMenu>
                        })
                    }
                    {/* 创建新的专辑 */}
                    <CreateBtn />
                </Menu>
            </div>
        )
    }
}

export default SideMenu
