import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'

class DownHistory extends PureComponent{
    state = {
        downData:[],total:0
    }
    componentDidMount(){
        this.axios.post('/user/down').then(res=>{
            if (res.code === 10000){
                this.setState({
                    downData:res.data.items,
                    total:res.data.count
                })
            }
        })
    }
    render(){
        const { downData,total } = this.state
        return (
            <div className='down-div'>
                <h1>下载历史</h1>
                <ul className='box_shadow'>
                    {
                        downData.map(list=> <li key={list.id}>
                            <Link to={list.type === 1 ? `/company/${list.company_id}` : `/person/${list.id}`}>
                                <span>
                                    { list.type === 1 && list.logo === '' ? <img src={require('assets/images/company/no-logo.svg')} alt='logo' />
                                        :
                                        (list.type === 2 && list.logo === '' ? <img src={require('assets/images/company/no-avatar.svg')} alt='logo' /> 
                                            : <img src={list.logo} alt='logo'/>
                                        )
                                    }
                                </span>
                                {list.other_name}
                            </Link>
                        </li>)
                    }
                </ul>
            </div>
        )
    }
}
export default DownHistory