import React, { Component } from 'react'
import { Skeleton } from 'antd'

class Industry extends Component {
    state = {
        chain: []
    }
    componentDidMount() {
        this.axios.post('/nav/domain')
            .then(res => {
                this.setState({
                    chain: res.data
                })
            })
    }
    render() {
        return (
            <Skeleton active paragraph={{ rows: 10 }} loading={this.state.chain.length > 0 ? false : true}>
                <div className="flex-box wrap-box industry-box">
                    {
                        this.state.chain.map((item, index) => {
                            return (
                                <div className="column" key={index}>
                                    <div className="primary">{item.name}</div>
                                    <div className="child">
                                        {item.items.map(child => <div
                                            className="secondary"
                                            key={child.id}
                                            onClick={e => { e.preventDefault(); this.props.click(child) }}
                                        >
                                            <span>{child.name}</span>
                                        </div>)}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </Skeleton>
        )
    }
}

export default Industry