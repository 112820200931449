import React, { useEffect, useState } from 'react'
import { Layout } from 'antd'
import { Button } from 'antd'
import 'styles/official/official.scss'
// import Footer from 'comps/navbar/Footer'
import NewFooter from './common/NewFooter'
import Nav from './common/Nav'
import { click } from 'dom7'

function Official() {
    // 鼠标划过nav变色
    const changeColor = () => {
        let titleHeight = document.getElementById('h1').offsetHeight
        const nav = document.getElementById('nav-box')
        nav.style.backgroundColor = 'transparent'
        document.addEventListener('scroll', function (e) {
            document.documentElement.scrollTop > titleHeight ? nav.style.backgroundColor = '#232F3F' : nav.style.backgroundColor = 'transparent'
        }, false)
    }
    const timeStamp = new Date().getTime()
    const bannerList = [
        {id:1,title:'精准友好的科 技信息搜索',image:"https://image.sciphi.cn/dt_insights/platform1.svg"},
        {id:2,title:'聚焦全球视野 的学术社区',image:"https://image.sciphi.cn/dt_insights/platform2.svg"},
        {id:3,title:'可靠的预印本 论文发表平台',image:"https://image.sciphi.cn/dt_insights/platform3.svg"},
        {id:4,title:'跨界融合的交 互型学术会议',image:"https://image.sciphi.cn/dt_insights/platform4.svg"},
        {id:5,title:'专注于科学智 能的媒体传播',image:"https://image.sciphi.cn/dt_insights/platform5.svg"},
    ]
    const serverList = [
        {id:1,title:"多领域人才资源深度挖掘",image:"https://image.sciphi.cn/dt_insights/service1.png"},
        {id:2,title:"全方位科技企业数据集成",image:"https://image.sciphi.cn/dt_insights/service2.png"},
        {id:3,title:"动态实时的科技要素评价",image:"https://image.sciphi.cn/dt_insights/service3.png"},
        {id:4,title:"系统性科技产业全景构建",image:"https://image.sciphi.cn/dt_insights/service4.png"},
    ]
    const [activeServerId, setServerId] = useState(1)
    const [activeServerBanner, setServerBanner] = useState("https://image.sciphi.cn/dt_insights/service1.png")
    //切换banner图
    const changeBanner = data =>{
        setServerId(data.id)
        setServerBanner(data.image)
    }

    const decisionList = [
        {id:1, title:"数据可视化",content:"通过具有自然语言处理功能的数据分析引擎，来挖掘大量的非结构化文档，用以识别有关新兴科技和颠覆性技术与商业趋势的信号，通过可视化模型来呈现。"},
        {id:2, title:"工具智能化",content:"横向对比包括技术、专家、企业、政策在内的科技要素，纵向构建关注要点，实时计算您所关注的科技要素指数及前景预测。"},
        {id:3, title:"分析结构化",content:"从业经验丰富的分析师从技术、商业、市场、专利等维度出发，机构化呈现科技要素在时空区间的竞争力，技术趋势及商业机会一目了然。"},
        {id:4, title:"情报结论化",content:"人才多维评论、企业经营状况、产业发展前景、技术迭代路径、地域成长空间，科技要素的多维度智能分析让决策更加果断。"},
    ]
    useEffect(() => {
        // changeBanner()
        // changeColor()
    })

    return (
        <Layout className='all'>
            <Layout className='all-box'>
                <Nav />
                <img src={'https://image.sciphi.cn/dt_insights/homeBgTop.jpg?v=' +  timeStamp} alt='banner' className="main-banner"/>
                <section className='official-img'>
                    <div className='cenetr-box'>
                        <div className='img-body'>
                            <h1 className='h1 top_title' id='h1'>透视未来的科技本质</h1>
                            <img className='intro_pic animate__animated animate__fadeInRight topIntro_img' src={`https://image.sciphi.cn/dt_insights/topIntro.png?v=` +  timeStamp}  alt='chatu' />
                        </div>
                        <div className="future_pic_box animate__animated animate__fadeInRight animate__delay-1s">
                            <img className='intro_pic' src={`https://image.sciphi.cn/dt_insights/futureGroup.svg?v=` +  timeStamp} alt='chatu' />
                        </div>
                    </div>
                </section>
            </Layout>
            <section className='official-body'>
                <div className="second_box">
                    <img className='intro_pic' src={`https://image.sciphi.cn/dt_insights/secondScreen.svg?v=` +  timeStamp} alt='chatu' />
                    <img className='second_label animate__animated animate__fadeInRight animate__delay-2s' src={`https://image.sciphi.cn/dt_insights/secondLabel.svg?v=` +  timeStamp} alt='chatu' />
                    <img className='second_cell animate__animated animate__fadeInRight animate__delay-3s' src={`https://image.sciphi.cn/dt_insights/secondCell.svg?v=` +  timeStamp} alt='chatu' />
                </div>
                
                <div className="third_box">
                    <div className="title_white">开放的前沿科技信息获取平台</div>
                    <div className="banner_box">
                        {
                            bannerList.map(item => {
                                return <div className='banner_item' key={item.id}>
                                    <img className='banner_pic' src={`${item.image}?v=` +  timeStamp} alt='chatu' />
                                    <div className='banner_title'>{item.title}</div>
                                </div>
                            })
                        }
                    </div>
                </div>
                
                <div className="four_box">
                    <div className="title_black">领先的科技数据智能分析和服务</div>
                    <div className="four_banner_box">
                        <div className="four_left">
                            {
                                serverList.map(item => {
                                    return <div className={item.id === activeServerId ? 'banner_btn_active' : 'banner_btn'} key={item.id}
                                        onMouseOver={() => { changeBanner(item) }} 
                                    >
                                        <div className='banner_title'>{item.title}  
                                            <img className='banner_active_icon' src={`https://image.sciphi.cn/dt_insights/icon/banner_active_icon.svg?v=` +  timeStamp} alt='chatu' />
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                        <div className="four_right">
                            {
                                serverList.map(item => {
                                    return <div key={item.id}>
                                        {
                                            item.id === activeServerId ? <img className={`four_pic ${item.id === activeServerId ? 'four_pic_active' : ''}`} src={`${activeServerBanner}?v=` +  timeStamp} alt='chatu' /> : null
                                        }
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className="five_box">
                    <div className="title_white">四大特性助力用户高效率科学决策</div>   
                    <div className="five_banner_box">
                        {
                            decisionList.map(item => {
                                return <div className={`des_item desItem${item.id}`} key={item.id}>
                                    <div className={`des_pic despic${item.id}`}></div>
                                    <div className="des_item_title">{item.title}</div>
                                    <div className={`des_item_line desLine${item.id}`}></div>
                                    <div className='des_item_con'>{item.content}</div>
                                </div>
                            })
                        }
                    </div>      
                </div>
            </section>
            <NewFooter />
        </Layout>
    )
}

export default Official