import React from 'react'

const Profile = props => {
    return (
        <div className="profile">
            {props.object_name && <div className="column"><span>机构：</span>{props.object_name}</div>}
            {props.position && <div className="column"><span>职位：</span>{props.position}</div>}
            {props.address && <div className="column"><span>地区：</span>{props.address}</div>}
        </div >
    )
}

export default Profile