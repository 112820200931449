import React from 'react'
import { Breadcrumb, Pagination } from 'antd'
import moment from 'moment'
import 'styles/indiviation/news.scss'
class NewsList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            list: [],
            count: 0
        }
        this.request = this.request.bind(this)
    }
    componentDidMount() {
        this.request()
    }
    request(page = 1) {
        this.axios.post('/flash/list',{page})
            .then(res => this.setState({ list: res.data.items, count: res.data.count}))
    } 
    
    render() {
        return (
            <div className="news-list">
                <Breadcrumb separator=">">
                    <Breadcrumb.Item><a href="/home">首页</a></Breadcrumb.Item>
                    <Breadcrumb.Item>资讯</Breadcrumb.Item>
                </Breadcrumb>
                {this.state.list.length !== 0 && this.state.list.map(item => (
                    <div className="item">
                        <div className="date">{moment(item.start_time * 1000).format('M月D日 | dddd')}</div>
                        <div className="bottom">
                            <div className="time">{moment(item.start_time * 1000).format('h:mm')}</div>
                            <div className="content">
                                <div className="title">{item.title}</div>
                                <div className="text" dangerouslySetInnerHTML={{__html:item.content}}></div>
                            </div>
                        </div>
                    </div>
                ))}
                <div className="page-box">
                    {this.state.count !== 0 &&
                        <Pagination
                            onChange={page => this.request(page)}
                            defaultCurrent={1}
                            defaultPageSize={20}
                            hideOnSinglePage={true}
                            total={this.state.count}
                            showSizeChanger={false}
                        />
                    }
                </div>
            </div>
        )
    }
}


export default NewsList
