import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Modal } from 'antd'
import { Tootip1 } from 'comps/tootip/Tootip'
import classNames from 'classnames'
const SubCell = props => {
    const [visible, setVisible] = useState(false)

    const changeModal = value => {
        setVisible(value)
    }
    let body
    if (props.hasOwnProperty('items')) {
        body = props.items.map(item => <li key={item.id}><Link to={`/industry/${item.id}`}> {item.name}</Link></li>)
    } else {
        body = null
    }
    const icon = props.icon ? props.icon : 'wave'
    return (
        <div className="item">
            <div className="head">
                <i className={`iconfont ${icon}`}></i>
                <span>{props.name}</span>
            </div>
            <ul className={classNames("body", { 'blur': props.hide })} onClick={props.hide ? () => props.changeModal(true) : null}>
                {body}
            </ul>
        </div>
    )
}

export default SubCell