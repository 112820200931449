import React, { PureComponent } from 'react'
import { Skeleton,Modal } from 'antd'

const IsOrNot = {1:'有',4:'无'},HigLow = {1:'高',2:'中',3:'低',4:'无'},SetObj = {1:'已设立',2:'未设立'},SetObj_rd = {1:'已设立',4:'未设立'}

class ParkService extends PureComponent{
    state = {
        visible:false,modalText:'',modalData:[]
    }
    openModal = type =>{
        if (type === 4){
            this.setState({ 
                visible:true, 
                modalText:'配套服务',
                modalData:this.props.InfoData.other
            })
        } else {
            this.setState({ 
                visible:true, 
                modalText:'实验平台',
                modalData:this.props.InfoData.laboratory
            })
        }
    }
    closeModal = ()=>{
        this.setState({ visible:false })
    }
    render(){
        const { InfoLoading,InfoData } = this.props
        const { visible,modalText } = this.state
        return (
            <section id='service' className='service'>
                <h1 className='parktitle'> 园区服务 </h1>
                <Skeleton active avatar paragraph={{row: 4 }} loading={InfoLoading}>
                    {
                        Object.keys(InfoData).length > 0 && <ul className='flex_box'>
                            <li>
                                {/* <svg className="symbol_icon" aria-hidden="true">
                                    <use xlinkHref='#icon-liucheng'></use>
                                </svg> */}
                                <i className='iconfont icon-liucheng1'></i>
                                <h2>科研能力</h2>
                                <ul className='service-ul'>
                                    <li>知识产权平台: {IsOrNot[`${InfoData.ip_services}`]}</li> 
                                    <li>科学成果转化平台: {IsOrNot[`${InfoData.rd_services}`]}</li> 
                                    <li>孵化器: {SetObj_rd[`${InfoData.incubator}`]}</li>
                                </ul>
                            </li>
                            <li>
                                {/* <svg className="symbol_icon" aria-hidden="true">
                                    <use xlinkHref='#icon-shiyanshi'></use>
                                </svg> */}
                                <i className='iconfont icon-shiyanshi1'></i>
                                <h2>实验平台</h2>
                                <ul className='service-ul'>
                                    {
                                        InfoData.laboratory.length > 0 ?
                                            InfoData.laboratory.slice(0,3).map(l => <li key={l.id}>{l.name}</li> )
                                            :
                                            <p className='no-service'>  暂无相关服务  </p>
                                    }
                                    {
                                        InfoData.laboratory.length > 3 && <li className='more' onClick={this.openModal.bind(this,2)}>查看更多</li>
                                    }
                                </ul>
                            </li>
                            <li>
                                {/* <svg className="symbol_icon" aria-hidden="true">
                                    <use xlinkHref='#icon-yuanquyuanquxiangqing'></use>
                                </svg> */}
                                <i className='iconfont icon-yuanqu'></i>
                                <h2>周边环境</h2>
                                <ul className='service-ul'>
                                    <li>配套高校数量: {HigLow[`${InfoData.colleges}`]}</li> 
                                    <li>配套医院数量: {HigLow[`${InfoData.hospitals}`]}</li> 
                                    <li>配套商业数量: {HigLow[`${InfoData.commerce}`]}</li> 
                                    <li>配套银行: {SetObj[`${InfoData.banks}`]}</li> 
                                </ul>
                            </li>
                            <li>
                                {/* <svg className="symbol_icon" aria-hidden="true">
                                    <use xlinkHref='#icon-shebei1'></use>
                                </svg> */}
                                <i className='iconfont icon-shebei'></i>
                                <h2>配套服务</h2>
                                <ul className='service-ul'>
                                    {
                                        InfoData.other.length > 0 ?
                                            InfoData.other.slice(0,3).map(l => <li key={l.id}>{l.name}</li> )
                                            :
                                            <p className='no-service'>  暂无相关服务  </p>
                                    }
                                    {
                                        InfoData.other.length > 3 && <li className='more' onClick={this.openModal.bind(this,4)}>查看更多</li>
                                    }
                                </ul>
                            </li>
                        </ul>
                    }
                </Skeleton>
                <Modal
                    visible={visible}
                    title={modalText}
                    className='park-service-modal'
                    onCancel={this.closeModal}
                    footer={null}
                    width='60%'
                >
                    <ul className="service-ul flex_box">
                        {
                            this.state.modalData.map(l => <li key={l.id}>{l.name}</li> )
                        }
                    </ul>
                </Modal>
            </section>
        )
    }
}

export default ParkService