import React from 'react'
import { Tabs,Row,Col,Card } from 'antd'

const { TabPane } = Tabs

export function ChainComp(props){
    return (
        <Tabs className={props.off ? 'chain-box no-chain-box' : 'chain-box'}> 
            {props.data.length > 0 && props.data.map((chain,index)=>{
                return (
                    <TabPane tab={chain.name} key={chain.id || chain.name} className="chain-tabs" >
                        <Row type='flex'>
                            <Col span={7}>
                                <h1><strong>上游</strong></h1>
                                <ListCard data={chain['1'] || []} id={props.currentId} />
                            </Col>
                            <Col span={1} className='arrow-col'>
                                <img src={require('../assets/images/company/chain.svg')} alt='chain' />
                            </Col>
                            <Col span={7}>
                                <h1><strong>中游</strong></h1>
                                <ListCard data={chain['2'] || []} id={props.currentId} />
                            </Col>
                            <Col span={1} className='arrow-col'>
                                <img src={require('../assets/images/company/chain.svg')} alt='chain' />
                            </Col>
                            <Col span={7}>
                                <h1><strong>下游</strong></h1>
                                <ListCard data={chain['3'] || []} id={props.currentId} />
                            </Col>
                        </Row>
                    </TabPane>
                )
            })}
        </Tabs>
    )
} 

function ListCard(props){
    return (
        props.data.map(list=>{
            return (
                <Card title={list.name} key={list.name}>
                    {
                        list.items.map((item,i)=>{
                            return <a key={item.id || i} href={`/company/${item.id}`} className={props.id === String(item.id) ? 'target' : ''}>{item.other_name || item.name}</a>
                        })
                    }
                </Card>
            )
        })
    )
    
}