import React, { Component } from 'react'
import SearchInput from './SearchInput'
import 'styles/search/searchform.scss'
import RecommendLeft from './RecommendLeft'
import ExpressRight from './ExpressRight'
class Searchform extends Component {
    constructor(props) {
        super(props)
        this.state = {
            suggestShow: false,
        }
        this.hideSuggest = this.hideSuggest.bind(this)
        this.showSuggest = this.showSuggest.bind(this)
    }
    // 显示联想内容
    showSuggest() {
        this.setState({ suggestShow: true })
    }
    hideSuggest() {
        this.setState({ suggestShow: false })
    }

    render() {
        return (
            <div className="search-layer-form" onClick={() => this.setState({ suggestShow: false })}>
                <SearchInput 
                    home={false}
                    hideModal={this.props.hide} 
                    hideSuggest={this.hideSuggest} 
                    showSuggest={this.showSuggest} 
                    suggestShow={this.state.suggestShow}/>
                <div className="recommend-express">
                    <div className="recommend-left">
                        <div className="title">相关推荐</div>
                        <RecommendLeft></RecommendLeft>
                    </div>
                    <div className="express-right">
                        <div className="title">络绎快讯</div>
                        <ExpressRight></ExpressRight>
                    </div>
                </div>
            </div>
        )
    }
}

export default Searchform