
const switchCate = cate => ({
    type: 'SWITCH_CATE',
    cate
})

const changeKeywords = keywords => ({
    type: 'CHANGE',
    keywords
})

const changeCate = cate => ({
    type: 'CHANGE_CATE',
    cate
})

export  {switchCate,changeKeywords,changeCate}