import React, { Component } from 'react'
import { Menu, Anchor } from 'antd'
import 'styles/person/sidemenu.scss'

const { SubMenu } = Menu
const { Link } = Anchor

const sideMenu = [
    { title: "论文", to: '/person/thesis' },
    { title: "专利", to: '/person/patent' },
    { title: "项目", to: '/person/project' },
]
class SideMenu extends Component {

    componentDidMount(){
        // 论文
        this.axios.post('/expert/paperslist', { id: this.props.id})
            .then(res=>{
                const { data, code } = res
                if (code === 10000){
                    if (data.data.length === 0){
                        sideMenu.splice(sideMenu.indexOf(sideMenu.find(item=>item.to === '/person/thesis')),1)
                    }
                }                
            })
        // 专利
        this.axios.post('expert/patentlist', { id: this.props.id})
            .then(res=>{
                const { data, code } = res
                if (code === 10000){
                    if (data.data.length === 0){
                        sideMenu.splice(sideMenu.indexOf(sideMenu.find(item=>item.to === '/person/patent')),1)
                    }  
                }
            })
        // 项目
        this.axios.post('expert/project', { id: this.props.id})
            .then(res =>{
                const { data, code } = res
                if (code === 10000){
                    if (data.data.length === 0){
                        sideMenu.splice(sideMenu.indexOf(sideMenu.find(item=>item.to === '/person/project')),1)
                    }  
                }
            })
    }
    onClickMenu = id => {
        console.log(id)
    }
    render() {
        return (
            <Menu
                defaultOpenKeys={['sub1']}
                mode="inline"
            >
                <SubMenu key="sub1"  title="基本信息" onTitleClick={this.onTitleClick}>
                    {
                        this.props.detail.map(item => {
                            return <Menu.Item key={item.target} onClick={()=>this.onClickMenu(item.target)}>
                                <a href={'#' + item.target}> {item.title}</a>
                            </Menu.Item>
                        })
                    }
                </SubMenu>
                {
                    sideMenu.map( menu => <Menu.Item key={menu.title}>
                        <a className="link" href={menu.to + '/' + this.props.id}>{menu.title}</a>
                    </Menu.Item>)
                }
            </Menu>
        )
    }
}

export default SideMenu