
function compareCompanyItems(state = [], action) {
    switch (action.type) {
    case 'ADD_COM_ITEM':
        return [...state, { id: action.id, name: action.name }]
    case 'DELETE_COM_ITEM':
        return state.filter(item=> item.id !== action.id)
    case 'CLEAR_COM_ITEM':
        return []
    default:
        return state
    }
}

// 公司列表筛选项 增加 删除单个，清空某个类型所有，删除所有，
function comLabels(state = [], action) {
    switch (action.type) {
    case 'ADD_COM_LABEL':
        return [
            ...state,
            { id: action.id, name: action.name, cate: action.cate }
        ]
    case 'DEL_COM_LABEL':
        // 过滤掉 id和cate都相同的lable
        return state.filter(label => !(label.id === action.id && label.cate === action.cate))
    case 'CLEAR_COM_CATE': // 删除一类
        return state.filter(label => label.cate !== action.cate)
    case 'CLEAR_COM_ALL':
        return []
    default:
        return state
    }
}

export { compareCompanyItems,comLabels }