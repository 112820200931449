import React, { useState } from 'react'
import { AvatarLoad } from 'comps/common/AvatarLoad'

const Person = props => {
    const location = props.local_info.filter(l => l.level === '2')
    return (
        <div className='search-list'>
            
            <div className='sear-detail flex_box'>
                <AvatarLoad src={props.photo} type={props.type} />
                {/* 内容 */}
                <dl>
                    <dd>
                        <a href={`/person/detail/${props.id}`} rel="noopener noreferrer" target="_blank">
                            <span className='type'>人物</span>
                            <span className='name'>{props.name_en || props.name_cn}</span>
                            <span className='des'>{props.company_name}</span>
                            <span className='des'>{props.position}</span>
                        </a>
                    </dd>
                    <dd className='intro'>{props.bio}</dd>
                    <dd>
                        {/* 地区 */}
                        {
                            location.length > 0 && <span className='sign orange'>{location[0].name}</span>
                        }
                        {/* 研究方向 */}
                        {
                            props.direction_info.map( d => <span className='sign orange' key={d.id}>{d.name}</span>)
                        }
                        {/* 研究领域 */}
                        {
                            props.field_info.map( f => <span className='sign blue' key={f.id}>{f.name}</span>)
                        }
                        {/* 标签 */}
                        {
                            props.labels.map( l => <span className='sign blue' key={l.id}>{l.name}</span>)
                        }
                        
                    </dd>
                </dl>
            </div>
        </div>
    )
}

export default Person

