import React, { useState } from 'react'
import { Row, Col } from 'antd'
import InfoCard from './effect/InfoCard'
import EffectTopTabs from 'comps/charts-tab/EffectTopTabs'
import SortLists from './effect/SortLists'
import { connect } from 'react-redux'

import 'styles/person/effect.scss'

const PersonEffect = props => {
    const [ titleState, setTitleState ] = useState(true) // 改变右侧标题的状态
    return (
        <Row className="person-effect-container" justify={"space-between"}>
            {/* 左侧排行 */}
            <SortLists setFatherTitleState={setTitleState}/>
            <Col span={16} className='effect-rt-detail'>
                <h1 className='detail-title' onClick={() => setTitleState(!titleState)}>
                    <span className={titleState ? 'active' : ''}>数据分析</span>
                    {
                        props.effectSelectedID !== '' ?  
                            <span className={!titleState ? 'active' : ''}>信息卡片</span> 
                            : null
                    }
                </h1>
                { titleState ? <section className='effect-tabs'><EffectTopTabs /></section> : <InfoCard /> }
            </Col>
        </Row>
    )
}
function mapStateToProps(state) {
    return {
        effectSelectedID: state.effectSelectedID
    }
}

export default connect(mapStateToProps)(PersonEffect)
