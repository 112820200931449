import React, { Component, useEffect, useState } from 'react'
import axios from 'libs/axios'
import Year from '../common/Year'
import PieChart from '../common/PieChart'
import { CompareEmptyEmage } from 'comps/common/EmptyComp'

function PatentContent(props) {
    const { id } = props
    const [yearData, setYearData] = useState([]) // 专利柱图
    const [pieData, setPieData] = useState([])  // 专利饼图

    useEffect(() => {
        axios.post('/company/patentyear', { id }).then(res => {
            if (res.code === 10000) {
                setYearData(res.data.reverse())
            }
        })
        axios.post('/company/patenttype', { id }).then(res => {
            if (res.code === 10000) {
                setPieData(res.data)
            }
        })
    }, [id])

    return (
        <div className='patent-item'>
            <div className="patent-bottom">
                <div className="patent-bottom-le">
                    {
                        yearData.length > 0 ? <Year id={`pat${id}`} data={yearData} width={800} height={160} show={true} title={'专利趋势'} dw={'专利数量(数)'} /> : <CompareEmptyEmage />
                    }
                </div>
                <div className="patent-bottom-cen">
                    {
                        pieData.length > 0 ? <PieChart id={`p${id}`} items={pieData} width={289} height={160} title={'专利分布'} /> : <CompareEmptyEmage />
                    }
                </div>
            </div>
        </div>
    )
}

export default PatentContent