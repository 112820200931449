/**
 * @param {Array} count  *必传，专辑包含公司/专家总数量
 * @param {Number} type  *必传，专辑类型
 * @param {Array} data   专辑包含的公司/专家数据
 * 
 */

export default function formatType(count, type, data ) {
    let data_type = ''
    if (type === 1){
        data_type = '家公司'
    } else if (type === 2) {
        data_type = '位专家'
    } else if (type === 3) {
        data_type = '个园区'
    } else if (type === 4) {
        data_type = '种技术'
    }

    if (data){
        let arr = []
        data.forEach( ite => {
            ite.object_name ?  arr.push(ite.object_name) : arr.push(ite.show_name)
        })
        if (arr.length > 5){
            arr = arr.slice(0,5)
        }
        let introItems = arr.join("、")
    
        return `该专辑包含${introItems}等${count}${data_type}`
    } else {
        return `共${count}${data_type}`
    }
}
