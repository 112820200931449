import React, {useState, useEffect} from 'react'
import 'styles/comps/random-img.scss'
export default class RandomImg extends React.Component{
    constructor(props) {
        super(props)
        this.state = {
            emptyImg: '',
            imgError: false
        }
    
    }
    componentDidMount () {
        this.formatData()
    }

    formatData = () => {
        let comArr = ['img2.jpg','img3.jpg','img4.jpg','img5.jpg','img6.jpg','img7.jpg']  //企业图片
        let pelArr = ['people1.png','people2.png','people3.png','people4.png','people5.png','people6.png','people7.png','people8.png','people9.png','people10.png'] //人物图片
        let mathComNum = Math.floor(Math.random() * comArr.length)
        let mathpelNum = Math.floor(Math.random() * pelArr.length)
        this.setState({
            emptyImg: (this.props.dataType && this.props.dataType === 2) ? pelArr[mathpelNum] : comArr[mathComNum]
        }, () => {
            // console.log(this.state.emptyImg)
        })
    }

    ImgError = () => {
        this.setState({imgError: true})
    }
    /**
     * 必传参数:
     * randomWidth, randomHeight(背景图宽高)，例：'120px'，'67px'，
     * randomName(图片上文字内容)
     * photoUrl(图片路径)
     * dataType(专辑类型)，企业为1，人物为2
     */
    render(){
        const {randomName, randomWidth, randomHeight, photoUrl} = this.props
        return  photoUrl && !this.state.imgError ? <div className="img-box"  style={{width: randomWidth, height: randomHeight}}> <img src={photoUrl} alt="" onError={this.ImgError}/> </div> :
            <div  className="emptybg" style={{width: randomWidth, height: randomHeight}}>
                {this.state.emptyImg && <img src={require('assets/images/comps/' + this.state.emptyImg)} alt=""/>}
                <div className="introducenone-box"><div>{this.state.imgError}{randomName}</div></div>
            </div>
        
    
    }
}
