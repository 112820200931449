import React, { Component } from 'react'
import { Layout, Skeleton, Modal, Tooltip } from 'antd'
// 组件
import  SideBar  from 'comps/sidebar'
import CompanyPatentTabs from 'comps/charts-tab/CompanyPatentTabs'
import { ChainComp } from 'comps/chain'
import BreadComp from 'comps/bread'  // 面包屑导航
import CompanyInvestTabs from 'comps/charts-tab/CompanyInvestTabs'
import CompanyCompeteTabs from 'comps/charts-tab/CompanyCompeteTabs'

import Members from './detail/Members'
import StockInfo from './detail/StockInfo'
import IndexData from './detail/IndexData'
import BasicInfo from './detail/BasicInfo'
import { getStorage } from 'utils/storage'
import { TEST_USER,BOARD_TYPE } from 'utils/type'
import { EmptyComp } from 'comps/common/EmptyComp'
import { toMoney, intlFormat } from 'utils'
import { DT_COMPANY_INDEX, COMPANYSIDE } from 'utils/constant'
import { Tootip2 } from 'comps/tootip/Tootip'

import 'styles/pages/company.scss'

const { Content } = Layout
const yiNum = 100000000, wanNum = 10000

const BreadData = [{ url: '/home', title: '首页' }, { url: '/company', title: '公司列表' }, { url: '', title: '详情' }]

class CompanyDetail extends Component {
    state = {
        id: this.props.match.params.id, // 公司的id
        BasicData: {}, contacts: {}, RoundData: [], labels: [], BasicLoading: true, // 基本信息的数据格式
        ChainLoading: true,
        MemberLoading: true, MembersData: [], // 高管数据
        InvestLoading: true, InvestData: {},
        CompeteData: [], CompeteLoading: true,
        visible: false,
        ChainData: [],
        patentData: {}, PatentLoading: true,
        ScoreData: {}, ScoreLoading: true,
        pptData: {}, StockData: [],
        CompanySide: COMPANYSIDE,
        StockLoading: true
    }
    componentDidMount() {
        this.getRound()     //融资轮次
        this.getDetail()    // 公司的基本信息
        this.getDtScore()   // 公司分数
        this.getInvest()    // 融资信息
        this.getChain()     // 产业链数据
        this.getPatent()    // 专利
        this.getMember()    // 高管信息
        this.getCompetition() // 竞争关系
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            window.location.reload()
        }
    }
    // 获取融资轮次
    getRound = () => {
        this.axios.post('/invest/round').then(res => {
            if (res.code === 10000) {
                this.setState({
                    RoundData: res.data,
                    pptData: Object.assign(this.state.pptData, { RoundData: res.data })
                })
            }
        })
    }
    // 获取公司基本信息
    getDetail = () => {
        this.axios.post('/company', { id: this.state.id }).then(res => {
            if (res.code === 10000) {
                // 联系方式数据格式
                let contacts = { phone: '', email: '', address: '', website: '' }, labels = []
                res.data.contacts !== null && res.data.contacts.forEach(list => {
                    contacts.phone += list.phone
                    contacts.email += list.email
                    contacts.address += list.address
                    contacts.website += list.website
                })
                // 行业层级数据格式 取2级和3级
                res.data.industryPaths && res.data.industryPaths.push(res.data.industryInfos)
                // 标签数据合并 行业-地区-标签
                res.data.domainInfos.forEach(label => {
                    label.type = 'scopes'
                    labels.push(label)
                })
                res.data.locations && res.data.locations.forEach(label => {
                    if (label.LevelType === '2') {
                        labels.push(label)
                    }
                })
                res.data.labelInfos.forEach(label => {
                    labels.push(label)
                })
                const { CompanySide } = this.state
                document.title = res.data.other_name // 浏览器的title
                this.setState({
                    BasicData: res.data, contacts, labels, BasicLoading: false,
                    pptData: Object.assign(this.state.pptData, res.data, { contacts: contacts }),
                    CompanySide: res.data._ipo_status_status === 2 ? CompanySide.filter(l => l.href !== '#stock') : CompanySide
                })
                // 返回是否上市的信息 决定是否请求股票信息
                return { status: res.data.ipo_status, stockInfo: res.data.roundInfo }
            } else {
                return { status: 2 }
            }
        }).then(stock => {
            // 如果是上市的公司 则请求上市信息 status=1 是上市 2是未上市
            if (stock.status === 1 && stock.stockInfo !== null) {
                this.getStock(stock.stockInfo)
                if (stock.stockInfo.length === 0) {
                    this.setState({
                        CompanySide: this.state.CompanySide.filter(l => l.href !== '#stock')
                    })
                }
            }
        })
    }
    // DT分数
    getDtScore = () => {
        this.axios.post('/company/score', { id: this.state.id }).then(res => {
            if (res.code === 10000) {
                this.setState({
                    ScoreData: res.data,
                    ScoreLoading: false,
                    pptData: Object.assign(this.state.pptData, res.data)
                })
            }
        })
    }
    // 融资信息
    getInvest = () => {
        this.axios.post('/company/money', { id: this.state.id }).then(res => {
            if (res.code === 10000) {
                const { invest, investor, merger } = res.data
                if (invest.length === 0 && investor.length === 0 && merger.length === 0) {
                    COMPANYSIDE.splice(COMPANYSIDE.indexOf(COMPANYSIDE.find(item => item.href === '#finance')), 1)
                }
                this.setState({
                    InvestData: res.data,
                    InvestLoading: false,
                    pptData: Object.assign(this.state.pptData, res.data)
                })
            }
        })
    }
    // 上市信息
    getStock = stockInfo => {
        typeof stockInfo !== 'string' && stockInfo.forEach(sl => {
            const code = sl.ticker.split('.')[0],// 股票代码
                board = sl.ticker.split('.')[1] // 主板 .SH SH/.SZ SZ/.O NASDAQ/.N NYSE/.HK HK/.A AMEX
            let postUrl
            if (board === 'HK') {
                postUrl = `http://qt.gtimg.cn/q=hk${code}`
            } else if (board === 'SH' || board === 'SZ') {
                postUrl = `http://qt.gtimg.cn/q=${board.toLowerCase()}${code}`
            } else {
                postUrl = `http://qt.gtimg.cn/q=us${code}`
            }
            // 股票代码需要处理格式  根据上市内容不同请求结果不同
            this.axios.get(postUrl).then(res => {
                let stockListObj = {}, lists = []
                const resArr = res.split('~')
                if (resArr.length > 1) {
                    if (board === 'HK') { // 港股
                        stockListObj['date'] = resArr[30].split(' ')[0].replace(/\//g, '-')
                        stockListObj['name'] = resArr[1]
                        lists.push({ k: '开盘', v: resArr[5] + ' 港元/股' })
                        lists.push({ k: '昨收', v: resArr[4] + ' 港元/股' })
                        lists.push({ k: '成交额（亿港元）', v: intlFormat(resArr[37] / yiNum) })
                        lists.push({ k: '成交量（万手）', v: intlFormat(resArr[36] / wanNum) })
                        lists.push({ k: '总市值（亿港元）', v: toMoney(resArr[44]) })
                        lists.push({ k: '市盈率（PE）', v: resArr[57] })
                        lists.push({ k: '市净率（PB）', v: resArr[58] })
                    } else if (board === 'SH' || board === 'SZ') { // A股
                        const date = `${resArr[30].substring(0, 4)}-${resArr[30].substring(4, 6)}-${resArr[30].substring(6, 8)}`
                        stockListObj['date'] = date
                        stockListObj['name'] = resArr[1]
                        lists.push({ k: '开盘', v: resArr[5] + ' 元/股' })
                        lists.push({ k: '昨收', v: resArr[4] + ' 元/股' })
                        lists.push({ k: '成交额（万元）', v: resArr[37] })
                        lists.push({ k: '成交量（万手）', v: intlFormat(resArr[36] / wanNum) })
                        lists.push({ k: '总市值（亿元）', v: resArr[45] })
                        lists.push({ k: '市盈率（PE）', v: resArr[52] })
                        lists.push({ k: '市净率（PB）', v: resArr[46] })
                    } else { // 美股
                        stockListObj['date'] = resArr[30].split(' ')[0].replace(/\//g, '-')
                        stockListObj['name'] = resArr[1]
                        lists.push({ k: '开盘', v: resArr[5] + ' 美元/股' })
                        lists.push({ k: '昨收', v: resArr[4] + ' 美元/股' })
                        lists.push({ k: '成交额（万美元）', v: intlFormat(resArr[37] / wanNum) })
                        lists.push({ k: '成交量（万手）', v: intlFormat(resArr[36] / wanNum) })
                        lists.push({ k: '总市值（亿美元）', v: toMoney(resArr[53]) })
                        lists.push({ k: '市盈率（PE）', v: resArr[39] })
                        lists.push({ k: '市净率（PB）', v: resArr[51] })
                    }
                    stockListObj['board'] = board
                    stockListObj['code'] = sl.ticker
                    stockListObj['tabsname'] = (BOARD_TYPE[`${board}`] || '') + ' ' + code
                    stockListObj['lists'] = lists

                    this.setState({
                        StockData: this.state.StockData.concat([stockListObj]),
                        StockLoading: false,
                        pptData: Object.assign(this.state.pptData, { StockData: this.state.StockData.concat([stockListObj]) })
                    })
                }
            })
        })
    }
    // 产业链
    getChain = () => {
        this.axios.post('/company/domain', { id: this.state.id }).then(res => {
            if (res.code === 10000) {
                // 只有数据为空 同时 是正式用户的情况下 去掉侧边栏的产业链 
                if (res.data.length === 0 && !TEST_USER.includes(getStorage('level'))) {
                    COMPANYSIDE.splice(COMPANYSIDE.indexOf(COMPANYSIDE.find(item => item.href === '#chain')), 1)
                }
                this.setState({
                    ChainLoading: false,
                    ChainData: res.data,
                    pptData: Object.assign(this.state.pptData, { ChainData: res.data })
                })
            }
        })
    }
    // 专利数据
    getPatent = () => {
        this.axios.post('/company/patent', { id: this.state.id }).then(res => {
            if (res.code === 10000) {
                const { ipc, type, year } = res.data
                if (ipc.length === 0 && type.length === 0 && year.length === 0) {
                    COMPANYSIDE.splice(COMPANYSIDE.indexOf(COMPANYSIDE.find(item => item.href === '#patent')), 1)
                }
                const isPatentNull = Object.values(res.data).every(v => v.length === 0)
                this.setState({
                    patentData: res.data,
                    PatentLoading: false,
                    pptData: Object.assign(this.state.pptData, { patentData: res.data })
                })
            }
        })
    }
    // 公司高管
    getMember = () => {
        this.axios.post('/company/person', { id: this.state.id }).then(res => {
            if (res.code === 10000) {
                if (res.data.length === 0) {
                    COMPANYSIDE.splice(COMPANYSIDE.indexOf(COMPANYSIDE.find(item => item.href === '#member')), 1)
                }
                this.setState({
                    MemberLoading: false,
                    MembersData: res.data,
                    pptData: Object.assign(this.state.pptData, { MembersData: res.data })
                })
            }
        })
    }
    // 竞争关系
    getCompetition = () => {
        this.axios.post('/company/competition', { id: this.state.id }).then(res => {
            if (res.code === 10000) {
                if (res.data.length === 0) {
                    COMPANYSIDE.splice(COMPANYSIDE.indexOf(COMPANYSIDE.find(item => item.href === '#compete')), 1)
                }
                this.setState({
                    CompeteData: res.data,
                    CompeteLoading: false,
                    pptData: Object.assign(this.state.pptData, { CompeteData: res.data })
                })
            }
        })
    }
    // 切换全屏
    fullScreen = () => {
        this.setState({ visible: true })
    };
    hideModal = () => {
        this.setState({ visible: false })
    };
    render() {
        const { BasicData, contacts, RoundData, labels, ChainData, MembersData, InvestData, CompeteData, patentData, ScoreData, StockData } = this.state
        return (
            <Layout className='park-container company-con' id='basic'>
                <SideBar data={!this.state.StockLoading ? COMPANYSIDE : COMPANYSIDE.filter(item => item.href !== '#stock')} />
                <Layout className='layout-rt'>
                    <BreadComp data={BreadData} />
                    <Content className='park-main'>
                        {/* 基本信息 */}
                        <BasicInfo BasicData={BasicData} RoundData={RoundData} labels={labels} contacts={contacts}
                            BasicLoading={this.state.BasicLoading}
                            pptData={this.state.pptData}
                        />
                        {/* 科技指标 */}
                        <section id='index' className='dt-index'>
                            <h1 className="parktitle" id='company-dt-index'>
                                TI指数
                                <Tooltip placement="bottomLeft" title={DT_COMPANY_INDEX}
                                    arrowPointAtCenter={true}
                                    getPopupContainer={() => document.getElementById('company-dt-index')}>
                                    <i className='iconfont icon-explain'></i>
                                </Tooltip>
                            </h1>
                            <Skeleton active paragraph={{ rows: 4 }} loading={this.state.ScoreLoading}>
                                {!this.state.BasicLoading && <IndexData data={ScoreData} ipo={BasicData.ipo_status} />}
                            </Skeleton>
                        </section>
                        {/* 融资 */}
                        {
                            InvestData.invest && InvestData.invest.length === 0 && InvestData.investor.length === 0 && InvestData.merger.length === 0 ? null :
                                <section id='finance' >
                                    <h1 className="parktitle">融资
                                        {/* 取两个接口的数据 拼接而成的  5笔/1000万 */}
                                        <span style={{ marginLeft: 15 }}>{
                                            `${Object.keys(InvestData).length > 0 ? InvestData.invest.length : 0}笔 / ${toMoney(Object.keys(BasicData).length > 0 && BasicData.invsetMoney)}万`
                                        }</span>
                                    </h1>
                                    <Skeleton active paragraph={{ rows: 6 }} loading={this.state.InvestLoading}>
                                        <CompanyInvestTabs data={InvestData} compName={BasicData.other_name} />
                                    </Skeleton>
                                </section>
                        }
                        {/* 上市信息 */}
                        {
                            StockData.length > 0 &&
                            <StockInfo StockData={StockData} loading={this.state.StockLoading} id={this.state.id} />
                        }
                        {/* 产业链 */}
                        {
                            TEST_USER.includes(getStorage('level')) ?
                                <section id='chain' >
                                    <h1 className="parktitle">产业链</h1>
                                    <Tootip2 />
                                </section>
                                : ChainData.length !== 0 &&
                            <section id='chain' >
                                <Skeleton active paragraph={{ rows: 8 }} loading={this.state.ChainLoading}>
                                    <h1 className="parktitle">产业链</h1>
                                    <div className=' tabcharts'>
                                        {
                                            ChainData.length > 0 ?
                                                <ChainComp data={ChainData} currentId={this.state.id} />
                                                : <EmptyComp />
                                        }
                                    </div>
                                </Skeleton>
                            </section>
                        }
                        {/* 科技专利 */}
                        {
                            patentData.ipc && patentData.ipc.length === 0 && patentData.type.length === 0 && patentData.year.length === 0 ? null :
                                <section id='patent' >
                                    <h1 className="parktitle">科技专利</h1>
                                    {
                                        Object.keys(patentData).length > 0 ? <Skeleton active paragraph={{ row: 6 }} loading={this.state.PatentLoading}>
                                            <CompanyPatentTabs data={patentData} compName={BasicData.other_name} />
                                        </Skeleton>
                                            : <div className=''><EmptyComp /></div>
                                    }
                                </section>
                        }
                        {/* 团队成员 */}
                        {
                            MembersData.length !== 0 &&
                            <Members MemberLoading={this.state.MemberLoading} MembersData={MembersData} />
                        }
                        {/* 竞业关系 */}
                        {
                            CompeteData.length !== 0 &&
                            <section id='compete' className='compete'>
                                <h1 className="parktitle">竞业关系</h1>
                                {
                                    CompeteData.length > 1 ? <Skeleton active paragraph={{ row: 6 }} loading={this.state.CompeteLoading}>
                                        {!this.state.CompeteLoading && <CompanyCompeteTabs data={CompeteData} compName={BasicData.other_name} hostId={this.state.id} fullScreen={this.fullScreen} />}
                                        {
                                            !this.state.CompeteLoading && <Modal
                                                title="竞业关系"
                                                visible={this.state.visible}
                                                onOk={this.hideModal}
                                                onCancel={this.hideModal}
                                                className='echart-full'
                                                width={'80%'}
                                                footer={null}
                                            >
                                                <CompanyCompeteTabs fullID='full' data={CompeteData} compName={BasicData.other_name} hostId={this.state.id} />
                                            </Modal>
                                        }
                                    </Skeleton>
                                        : <div className=''><EmptyComp /></div>
                                }
                            </section>
                        }

                    </Content>
                </Layout>
            </Layout>
        )
    }
}
export default CompanyDetail