import React, { Component, useEffect, useState, useMemo } from 'react'
import { Skeleton } from 'antd'
import BreadComp from 'comps/bread'
import moment from 'moment'
import 'styles/reserch/detail.scss'
import axios from 'libs/axios'
import { useHistory } from "react-router-dom"
const BreadData = [{ url: '/home', title: '首页' }, { url: '/research', title: '研究' }, { url: '', title: '研究报告' }]

function ResearchDetail(props) {
    const [data, setData] = useState({})
    const [list, setList] = useState([])
    useEffect(() => {
        const { id } = props.match.params
        document.title = '研究'
        axios.post('/study/detail', { id }).then(res => {
            const { code, data } = res
            if (code === 10000) {
                setData(data)
            }
        })
    })

    let history = useHistory()
    const jumpPage = id => {
        history.push(`/research/${id}`)
        axios.post('/study/detail', { id }).then(res => {
            setList(res.data)
        })
    }

    const { title, vice_title, start_time, author_ids, content, img, prv, next, authorInfo } = data
    return (
        <div className="research">
            <BreadComp data={BreadData} />
            <Skeleton active paragraph={{ rows: 10 }} loading={data !== {} ? false : true}>
                <div className="detail all">
                    <div className="leftbox">
                        <div className="title">{title}</div>
                        <div className="aut_time">
                            {
                                authorInfo && authorInfo.map(item => {
                                    return <span className='aut'>{item.name}</span>
                                })
                            }
                            <span>{moment.unix(start_time).format("YYYY-MM-DD HH:mm:ss")}</span>
                        </div>
                        <hr />
                        <div className="content" dangerouslySetInnerHTML={{ __html: content }} />
                        {
                            list.length > 0 && <>
                                <p>更多推荐</p>
                                <hr className="hr" />
                                <div className="footer">
                                    {
                                        list.length > 0 && <>
                                            {
                                                list.map((item, index) => {
                                                    return <div className='image' onClick={() => { jumpPage(item.id) }} key={index} >
                                                        {item.img && <img className="imgage" src={item.img} alt="header" />}
                                                        <div className='title'>{item.title}</div>
                                                        <div className='time'>{moment.unix(item.start_time).format("YYYY-MM-DD")}</div>
                                                    </div>
                                                })
                                            }
                                        </>
                                    }
                                </div>
                            </>

                        }
                    </div>
                </div>
            </Skeleton>
        </div>
    )
}

export default ResearchDetail