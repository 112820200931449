/**
 * 读取本地存储
 * @param {String} key
 */
export const getStorage = key => {
    // const value = localStorage.getItem(key)
    // if (!value) return null
    // return value.indexOf('{') === 0 || value.indexOf('[') === 0 ? JSON.parse(value) : value
    try {
        if (!localStorage) { return false }
        const cacheVal = localStorage.getItem(key)
        const result = JSON.parse(cacheVal)
        const now = new Date() - 1
        if (!result) { return null }// 缓存不存在
        if (now > result.exp) { // 缓存过期
            removeStorage(key)
            return null
        }
        return result.val
    } catch (e) {
        removeStorage(key)
        return null
    }
}

/**
 * 本地存储
 * @param {String} key
 * @param {any} value
 * @param {time} time 存储时间
 */
export const setStorage = (key, value, time) => {
    try {
        if (!localStorage) { return false }
        const day = 60 * 60 * 24 * 1
        // 若不设置存储的事件，则默认存储1天
        if (!time || isNaN(time)) { 
            time = day 
        } else {
            time = time * day
        }
        const cacheExpireDate = (new Date() - 1) + time * 1000
        const cacheVal = { val: value, exp: cacheExpireDate }
        localStorage.setItem(key, JSON.stringify(cacheVal))// 存入缓存值
    } catch (e) { }
}

/**
 * 删除本地存储
 * @param {String} key
 */
export const removeStorage = key => {
    if (!localStorage) { return false }
    localStorage.removeItem(key)
}

/**
 * 清空本地存储
 */
export const clearStorage = () => {
    if (!localStorage) { return false }
    localStorage.clear()
}
