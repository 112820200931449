import React, { useState } from 'react'
import EchartReact from 'comps/Sign/EchartReact'
import { COLORS_20, COLORS } from 'utils/type'

export default function Develop(props) {
    return (<EchartReact
        id={props.id}
        options={getOption()}
        style={{ width: '480px', height: '320px' }}
    />)
}


function getOption() {
    return {
        tooltip: {
            trigger: 'item'
        },
        legend: {
            orient: 'vertical',
            right: 160,
            bottom: 62,
            icon: 'circle',
            itemHeight: 10,
            itemWidth: 10,
            data: ['>20%', '15%-20%', '10%-15%', '5%-10%','<5%']
        },
        series: [
            {
                type: 'pie',
                radius: '70%',
                center: ['24%','50%'],
                label: {show: false},
                hoverAnimation: false,
                data: [
                    {value: 22,name:'>20%'},
                    {value: 37,name:'15%-20%'},
                    {value: 29,name:'10%-15%'},
                    {value: 14,name:'5%-10%'},
                    {value: 10,name:'<5%'}
                ]
            }
        ],
        color:COLORS
    }
}