import React from 'react'
import { Switch, Route, NavLink } from 'react-router-dom'
import SideBar from 'comps/sidebar'
import { Layout } from 'antd'
import General from './General'
import Graph from './Graph'
import Group from './Group'
import 'styles/board/board.scss'

// 科创版布局组件
const BOARDSIDE = [
    { href:'/board/general',title:'概况'},
    { href:'/board/graph',title:'产业图谱'},
    { href:'/board/group',title:'申报企业'}
]
const Board = () => {
    document.title = '科创板'
    return (
        <Layout className="board">
            <SideBar data={BOARDSIDE} type='LINK'/>
            <div className="content">
                <Switch>
                    <Route exact path="/board/general" component={General} />
                    <Route exact path="/board/graph" component={Graph} />
                    <Route exact path="/board/group" component={Group} />
                </Switch>
            </div>
        </Layout>
    )
}

export default Board