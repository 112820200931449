import React,{ PureComponent }  from 'react'
import classNames from 'classnames'
import { Modal,Button } from 'antd'
import { Msg } from 'utils'
import { getStorage,setStorage } from 'utils/storage'
import SelectLabel from 'comps/label-select/SelectLabel'
import 'styles/comps/labelmodal.scss'

const userType = [
    {title:'投资机构/<br />企业战投部',icon_hover:'icon-institution-hover',icon:'icon-institution-default',type:5},
    {title:'大型企业',icon_hover:'icon-company-hover',icon:'icon-company-default',type:9},
    {title:'高校/研究院',icon_hover:'icon-college-hover',icon:'icon-college-default',type:7},
    {title:'医院',icon_hover:'icon-hospital-hover',icon:'icon-hospital-default',type:8},
    {title:'园区',icon_hover:'icon-park-hover',icon:'icon-park-default',type:6},
    {title:'政府',icon_hover:'icon-gove-hover',icon:'icon-gove-default',type:10},
    {title:'学者/科学者',icon_hover:'icon-person-hover',icon:'icon-person-default',type:11},
]
class LabelModal extends PureComponent{
    state = {
        visible: !(getStorage('level') && getStorage('level') !== 4),
        selectType:4,hoverType:4
    }
    closeModal = ()=>{
        this.setState({ visible:false })
    }
    // 选中
    selectType = type => {
        this.setState({
            selectType:type
        })
    }
    // 鼠标移入
    handleMouseOver = type =>{
        this.setState({
            hoverType:type
        })
    }
    handleMouseOut = type => {
        this.setState({
            hoverType:4
        })
    }
    // 保存身份
    saveLabel = ()=>{
        this.axios.post('/user/edit',{level:this.state.selectType}).then(res=>{
            if (res.code === 10000){
                Msg('success','保存成功！')
                setStorage('level',this.state.selectType,30)
                this.props.getUserType()
                this.closeModal()
            }
        })
    }
    render(){
        const { selectType,hoverType } = this.state
        return (
            <Modal
                title='请选择身份'
                visible={this.state.visible}
                footer={null}
                className='usertype-modal'
                width={1000}
            >
                <div className='usertype_div'>
                    <ul>
                        {
                            userType.map( l =>{
                                return <li key={l.title} className={classNames('type-list',{'hover': hoverType === l.type},{'active':selectType === l.type})}
                                    onClick={e=>this.selectType(l.type)}
                                    onMouseOver={e=>this.handleMouseOver(l.type)}
                                    onMouseOut={e=>this.handleMouseOut(l.type)}
                                >
                                    <svg className="symbol_icon" aria-hidden="true">
                                        <use xlinkHref={`#${ hoverType === l.type || selectType === l.type ? l.icon_hover : l.icon }`}></use>
                                    </svg><br/>
                                    <span dangerouslySetInnerHTML={{__html:l.title}}></span>
                                </li>
                            })
                        }
                    </ul>
                    <Button className="save-label" disabled={selectType === 4}
                        onClick={this.saveLabel} >
                        保存
                    </Button>
                </div>
            </Modal>
        )
    }
}

export default LabelModal
