import React from 'react'
import { Result,Button } from 'antd'

export const Error500 = ()=>{
    return  <Result
        status="500"
        title="500"
        subTitle="服务器出错了！"
        extra={<Button type="primary" href='/home'>回到首页</Button>}
    />
}
export const Error404 = ()=>{
    return  <Result
        status="404"
        title="404"
        subTitle="没有找到当前页面!"
        extra={<Button type="primary" href='/home'>回到首页</Button>}
    />
}
export const Error403 = ()=>{
    return  <Result
        status="403"
        title="403"
        subTitle="你没有权限访问当前页面！"
        extra={<Button type="primary" href='/home'>回到首页</Button>}
    />
}

