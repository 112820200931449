import React from 'react'
import { Skeleton } from 'antd'
import classNames from 'classnames'
import { AvatarLoad } from 'comps/common/AvatarLoad'
import { COMP_STATUS } from 'utils/type'

const BasicCompare = props => {
    const { DataArray,CompareLoading } = props
    return (
        <section className='basic'>
            <Skeleton active paragraph={{ rows: 5 }} loading={CompareLoading}>
                <ul className='basic-ul'>
                    <li></li>
                    <div className={classNames('flex_box', DataArray.length === 2 ? 'two' : 'three')}>
                        { DataArray.map(d => <li className={classNames('logo')} key={d.id}> 
                            <AvatarLoad type={1} src={d.logo} />
                        </li> )}
                    </div>
                </ul>
                <ul className='basic-ul'>
                    <li></li>
                    <div className={classNames('flex_box', DataArray.length === 2 ? 'two' : 'three')}>
                        { DataArray.map(d => <li className='other_name' key={d.id}>{d.other_name}</li> )}
                    </div>
                </ul>
                <ul className='basic-ul'>
                    <li>公司全称</li>
                    <div className={classNames('flex_box',DataArray.length === 2 ? 'two' : 'three')}>
                        { DataArray.map(d => <li key={d.id}> {d.name} </li> ) }
                    </div>
                </ul>
                <ul className='basic-ul'>
                    <li>成立时间</li>
                    <div className={classNames('flex_box',DataArray.length === 2 ? 'two' : 'three')}>
                        { DataArray.map(d => <li key={d.id}> {d.found_date} </li> ) }
                    </div>
                </ul>
                <ul className='basic-ul'>
                    <li>运营状态</li>
                    <div className={classNames('flex_box',DataArray.length === 2 ? 'two' : 'three')}>
                        { DataArray.map(d => <li key={d.id}> {COMP_STATUS[`${d.status}`]} </li> ) }
                    </div>
                </ul>
                <ul className='basic-ul'>
                    <li>所在行业</li>
                    <div className={classNames('flex_box',DataArray.length === 2 ? 'two' : 'three')}>
                        { DataArray.map(d => <li key={d.id} className='domain'>
                            {
                                d.industryPaths.length > 0 ?
                                    d.industryPaths.map( industry => <span key={industry.id}>{industry.name}</span>)
                                    :
                                    <i>-</i>
                            }
                        </li> ) }
                    </div>
                </ul>
                <ul className='basic-ul'>
                    <li>标签</li>
                    <div className={classNames('flex_box',DataArray.length === 2 ? 'two' : 'three')}>
                        { DataArray.map(d => <li key={d.id} className='label'> 
                            {
                                d.labelInfos.length > 0 ?
                                    d.labelInfos.map( label=> <span key={label.id}>{label.name}</span>)
                                    :
                                    <i>-</i>
                            }
                        </li> ) }
                    </div>
                </ul>
                <ul className='basic-ul'>
                    <li>所在地区</li>
                    <div className={classNames('flex_box',DataArray.length === 2 ? 'two' : 'three')}>
                        { DataArray.map(d => <li key={d.id}> 
                            {
                                d.locations.length > 2 ? `${d.locations[0].Name} · ${d.locations[1].Name}` : <i>-</i>
                            }
                        </li> )}
                    </div>
                </ul>
                <ul className='basic-ul'>
                    <li>公司规模</li>
                    <div className={classNames('flex_box',DataArray.length === 2 ? 'two' : 'three')}>
                        { DataArray.map(d => <li key={d.id}>{d.size}</li> )}
                    </div>
                </ul>
                {/* <ul className='basic-ul'>
                    <li>市值/估值</li>
                    <div className={classNames('flex_box',DataArray.length === 2 ? 'two' : 'three')}>
                        { DataArray.map(d => <li key={d.id}>
                            {
                                d.invsetMoney !== 0 ?
                                    `${d.invsetMoney}万 人民币` : <i>-</i>
                            }
                        </li> )}
                    </div>
                </ul> */}
            </Skeleton>
        </section>
    )
}
export default BasicCompare