import ExportJsonExcel from 'js-export-excel'
import * as echarts from 'echarts'
import { message } from 'antd'

const TEST_API = 'https://dti-test-api.dtinsights.cn/' // 测试环境接口
const PROD_API = 'https://api.loyiti.com/'  // 正式环境接口
// export const APIBaseURL = process.env.API_ENV === 'test' ? TEST_API : PROD_API
// export const APIBaseURL = process.env.NODE_ENV === 'production' ? PROD_API : TEST_API
console.log('window.location.host--------------', window.location.host)
export let APIBaseURL = window.location.host === 'www.dtinsights.cn' ? PROD_API : TEST_API
/**
 * debounce 防抖函数
 * @param {function} fn
 * @param {number} delay 时间ms
 * @return {function}
 */
export function debounce(func, delay) {
    let timer = null
    return function() {
        if (timer) {clearTimeout(timer)}
        timer = setTimeout(function() {
            func.apply(this, arguments)
            timer = null
        }, delay)
    }
}
/**
 * throttle 节流函数
 * @param {function} fn
 * @param {number} delay 时间ms
 * @return {function}
 */
export function throttle(func, delay) {
    let timer = null
    return function() {
        if (timer) {return}
        timer = setTimeout(function() {
            func.apply(this, arguments)
            timer = null
        }, delay)
    }
}
/**
 * deepClone 深拷贝
 * @param {object} object
 * @param {array} cache
 * @return {object} obj
 * 方式1：JSON.parse(JSON.stringify(displayTags))
 * 缺点：如果对象或者数组里有函数什么的, 就会出问题
 */
export function deepClone(obj, cache = []){
    // typeof [] => 'object' typeof {} => 'object'
    if (obj === null || typeof obj !== 'object') {
        return obj
    }
    // 如果传入的对象与缓存的相等, 则递归结束, 这样防止循环
    /**
	 * 类似下面这种
	 * var a = {b:1}
	 * a.c = a
	 * 资料: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Errors/Cyclic_object_value
    */
    const hit = cache.filter(c => c.original === obj)[0]
    if (hit) {
        return hit.copy
    }

    const copy = Array.isArray(obj) ?  [] :   {}
    // 将copy首先放入cache, 因为我们需要在递归deepClone的时候引用它
    cache.push({
        original: obj,
        copy
    })
    Object.keys(obj).forEach(key => {
        copy[key] = deepClone(obj[key], cache)
    })

    return copy
}

// 全局提示
export function Msg(type,text){
    message.destroy()
    if (type === 'warning'){
        message.warning(text)
    } else if (type === 'error') {
        message.error(text)
    } else if (type === 'success'){
        message.success(text)
    } else if ( type === 'info'){
        message.info(text)
    }
}

/**
 * 生成随机 数字
 * @param {Number} len - 长度
 */
export function RandomNum(len) {
    return Math.random()
        .toString()
        .substr(3, len)
}


//将数字转换成金额显示
export function toMoney(num){
    num = Number(num).toFixed(2)
    num = parseFloat(num)
    num = num.toLocaleString()
    return num //返回的是字符串23,245.12保留2位小数
}
// 转化B/M/K
export function intlFormat(num){
    return new Intl.NumberFormat().format(Math.round( num * 100) / 100)
}
export function makeFriendly(num){
    if ( num >= 1000000000){
        return intlFormat( num / 1000000000) + 'B'
    } else if ( num >= 1000000){
        return intlFormat( num / 1000000) + 'M'
    } else if ( num >= 1000){
        return intlFormat( num / 1000) + 'k'
    } else {
        return intlFormat(num)
    }
}
// 上市公司的市值 转化成亿，万
export function makeFriendlyCH(num){
    if ( num >= 100000000){ // 超过1亿
        return intlFormat( num / 100000000) + '亿'
    } else if ( num >= 10000){ // 超过1万
        return intlFormat( num / 10000) + '万'
    } else {
        return intlFormat(num)
    }
}

// 应对产业详情页面的数字金额转化
export function industryMoney(num,base){
    let n = base ? num * base : num * 10000
    if ( n >= 100000000){ // 超过10亿
        return intlFormat( n / 100000000)
    } else if ( n >= 100000){ // 超过10万
        return intlFormat( n / 10000)
    } else {
        return intlFormat(n)
    }
}
export function isBillion(num,base){
    let n = base ? num * base : num * 10000
    return n >= 100000000
}
// 保存csv
export const saveCsv = (head,data) =>{
    //s1.用一个数组来存一行数据, 所以第一行用一个数组来保存字段名
    let newHead = []
    head.forEach(head=> newHead.push(head.title))
    let headArr = [ newHead ]
    //s2. 将数据push到大数组中 
    for (let i = 0; i < data.length; i++) {
        let arr = []
        for (let k of head){
            arr.push(data[i][k.key])
        }
        headArr.push(arr)
    }
    //s3. 按照csv文件内容格式，把每个数组用 , 连接，形成一行，并存入新数组 
    let csvRows = []
    for (let j = 0; j < headArr.length; j++) {
        csvRows.push(headArr[j].join(','))
    }
    // //s4. 把新数组用 \n 回车连接，形成csvString 
    let csvString = csvRows.join('\n')
    // //BOM的方式解决EXCEL乱码问题 
    let BOM = '\uFEFF'
    csvString = BOM + csvString
    // s5: 利用a标签实现下载功能
    saveAs(csvString,'demo')
}
/**
 * 下载Excel文件
 * @param {*} head  表头内容
 * @param {*} data  表格body内容
 * @param {*} filename 文件名
 */
export const saveExcel = (head,data,filename)=>{
    let option = {},newHead = []
    head.forEach(head=> newHead.push(head.title))
    option.fileName = (filename || 'down_excel') // 下载的文件名字
    option.datas = [
        {
            sheetData: data,
            sheetName: "sheet",
            // sheetFilter: ["two", "one"],
            sheetHeader: newHead,
            columnWidths: new Array(newHead.length).fill(10),
        },
    ]
    var toExcel = new ExportJsonExcel(option) 
    toExcel.saveExcel() //保存
}
// 用a标签模拟下载过程
export const saveAs = (obj, fileName)=>{ 
    const tmpa = document.createElement("a")
    tmpa.download = fileName + '.csv'  || "下载.csv"
    tmpa.href = 'data:attachment/csv,' + encodeURI(obj) //绑定a标签
    tmpa.click() //模拟点击实现下载
    setTimeout(function () { //延时释放
        URL.revokeObjectURL(obj) //用URL.revokeObjectURL()来释放这个object URL
    }, 100)
}


// 导出功能xlsx文件 模拟a下载
export const saveWithALink = url => {
    const tmpa = document.createElement("a")
    tmpa.href =  APIBaseURL + url //绑定a标签
    tmpa.click() //模拟点击实现下载
}
/**
 * reactDom 结构输出字符串，处理数组和a链接的情况
 * @param {*} data 
 * @return {String} str
 */
export const doVirtualDom = data =>{
    let str = ''
    if (data.props.to){
        str = data.props.children
        return str
    } else {
        data.props.children.forEach(child =>{
            const childprops = child.props.children
            if ( typeof childprops === 'string'){
                str += childprops
                str += '、'
            } else {
                str += childprops.join('')
                str += '、'
            }
        })
        return str.slice(0,str.length - 1 )
    }
    
}

/**
 * 根据antd table的head 和 所需数据
 * @param {*} head 
 * @param {*} csvData 
 * @return {Array} newData
 */
export const turnTableData = (head,csvData)=>{
    let newData = []
    csvData.forEach((csvLine,i)=>{
        let newLine = {}
        for (let k of head){
            // 遇到 新增的inde序号的情况，后台的数据中没有序号这个字段 所以需要前端单独处理
            const resRender = k.key === 'index' ? i + 1 : k.render(csvLine[`${k.key}`],csvLine)
            // 如果render内容不是字符串的情况 处理虚拟dom结构
            if (typeof resRender === 'object'){
                newLine[`${k.key}`] = doVirtualDom(resRender)
            } else {
                newLine[`${k.key}`] = resRender
            }
        }
        newData.push(newLine)
    })
    return newData
}

/**
 * 利用echarts 下载图片
 * @param {String} filename 
 * @param {Number} id 
 * @return null 下载png格式的图片
 */
export const echartSavePic = (filename,id)=>{
    let myChart = echarts.getInstanceByDom(document.getElementById(`${id}`))
    let url = myChart.getConnectedDataURL({
        pixelRatio: 5,　　//导出的图片分辨率比率,默认是1
        backgroundColor: '#fff',　　//图表背景色
        excludeComponents: [　　//保存图表时忽略的工具组件,默认忽略工具栏
            'toolbox'
        ],
        type: 'png'　　//图片类型支持png和jpeg
    })
    let $a = document.createElement('a')
    $a.download = filename + '.png' 
    $a.target = '_blank'
    $a.href = url
    // Chrome and Firefox
    if (typeof MouseEvent === 'function') {
        let evt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: false
        })
        $a.dispatchEvent(evt)
    } else { // IE
        let html = '' + 
        '<body style="margin:0">' + 
        '![](' + url + ')' + 
        '</body>'
        let tab = window.open()
        tab.document.write(html)
    }
}