import React from 'react'
import { Breadcrumb } from 'antd'
import 'styles/utils/columns.scss'

function BreadComp(props){
    const { data } = props
    return (
        <Breadcrumb separator=">">
            {
                data.map((bread,i)=>{
                    return  <Breadcrumb.Item key={i}>
                        { bread.url ? <a className='item' href={bread.url} >{bread.title}</a> : <span className='item'>{bread.title}</span> }
                    </Breadcrumb.Item>
                })
            }
        </Breadcrumb>
    )
}
export default BreadComp