import React from 'react'
import { Link } from 'react-router-dom'
import { Table } from 'antd'
import BtmFcBtn from './BtmFcBtn'
// 可视化组件
import CompGraph from 'pages/echarts/CompGraph'
import { toMoney,saveExcel,turnTableData,echartSavePic } from 'utils'

// 关系图 https://gallery.echartsjs.com/editor.html?c=xkiiaUN8q

class CompanyCompeteTabs extends React.Component{
    state = {
        showMode:'line'
    }
    // 切换展示形式：line table
    showChange = e =>{
        this.setState({ showMode:e.target.value })
    }
    // 下载功能 根据showMode下载的格式不同
    savaTableCsv = ()=>{
        const { showMode } = this.state
        const { fullID,data,hostId,compName } = this.props
        if (showMode === 'line'){
            const picId = !fullID ? hostId : `${fullID}-${hostId}`
            echartSavePic(`${compName}-竞业关系`,`${picId}-compete`) // 下载png格式图片 文件名和唯一ID
        } else {
            // 当前表头 当前表格内容
            let head = columnsConfig,csvData = data.filter(data => data.id !== String(hostId))
            // 使用export-excel插件
            saveExcel(head,turnTableData(head,csvData),`${compName}-竞业关系`)
        }
    }
    render(){
        const { showMode } = this.state
        const { fullID,data,hostId } = this.props
        return (
            <div className='tabcharts invest-tabs'>
                <div className='charts-box'>
                    {
                        showMode === 'line' ? 
                            <CompGraph data={data} id={hostId} fullID={fullID}/>
                            :
                            <Table pagination={false} rowKey={ record => record.id || record.date }
                                dataSource={ data.filter(data => data.id !== String(hostId))}  scroll={{ y: 400 }} 
                                columns={ columnsConfig } 
                                className='custom-table'
                            />
                    }
                </div>
                <BtmFcBtn isDispalyTitle={false} isDispalyRadio={true} isDispalyDown={true}
                    showMode={showMode} fullID={fullID} 
                    savaTableCsv={this.savaTableCsv} showChange={this.showChange}
                    fullScreen={this.props.fullScreen}
                />
            </div>
        )
    }
}
export default CompanyCompeteTabs

// 融资的表头
const columnsConfig = [
    {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        width: 100,
        render: (text, record, index) =>  String(index + 1)
    },
    {
        title: '公司简称',
        dataIndex: 'other_name',
        key: 'other_name',
        render:(text,record) => <Link to={`/company/${record.id}`}>{text}</Link>
    },
    {
        title: '当前融资轮次',
        dataIndex: 'last_round',
        key: 'last_round',
        render:text => text
    },
    {
        title: '成立日期',
        dataIndex: 'found_date',
        key: 'found_date',
        render:text => text
    },
    {
        title: '相关产业',
        dataIndex: 'domainInfos',
        key: 'domainInfos',
        render:text => text
    },
    // {
    //     title: '注册资金',
    //     dataIndex: 'real_amount',
    //     key: 'real_amount',
    //     render:(text,record)=>{
    //         return toMoney(text) + '万人民币'
    //     },
    // }
]