import React, { PureComponent } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Button} from 'antd'
import CollectionCreateForm from 'comps/collect/CollectionCreateForm'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import '@ant-design/compatible/assets/index.css'
import 'styles/comps/collect.scss'

@connect(state => state)
@withRouter
class CreateBtn extends PureComponent{
    formRef = React.createRef()
    state = {
        createVisible:false
    }
    // 编辑
    createCollect = e =>{
        e.preventDefault()
        this.setState({ createVisible: true })
    }
    // 取消新建
    handleCancel = () => {
        this.setState({ createVisible: false })
        const current = this.formRef.current.formRef.current
        current.resetFields()
    }
    // 保存新建
    handleCreate = () => {
        const current = this.formRef.current.formRef.current
        const name = current.getFieldValue('name')
        const type = current.getFieldValue('type')
        const describe = current.getFieldValue('describe')
        name && type && this.axios.post('/collection/create',{name:name,data_type:type,describe:describe})
            .then(res=>{
                if (res.code === 10000){
                    current.resetFields()
                    this.props.history.push(`/mycollection/${res.data.id}`)
                    this.setState({ createVisible: false }) // 关闭弹框
                }
            })
    }
    saveFormRef = formRef => {
        this.formRef = formRef
    };
    render(){
        return (
            <p className='actions'>
                <Button className='new-collect' onClick={this.createCollect}> <PlusOutlined /> 创建专辑</Button>
                {/* 编辑弹框 */}
                <CollectionCreateForm
                    wrappedComponentRef={this.saveFormRef}
                    visible={this.state.createVisible}
                    onCancel={this.handleCancel}
                    onCreate={this.handleCreate}
                    ref={this.formRef}    
                />
            </p>
        )
    }
}
export default CreateBtn

