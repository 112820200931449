import React from 'react'
import EchartReact from 'comps/Sign/EchartReact'

export default function Pyear(props) {
    return (<EchartReact
        id={props.id}
        options={getOption(props.data)}
        style={{ width: '799px', height: '180px' }}
    />)
}

function getOption(data) {
    let nums = []
    let year = []
    data.map(item => {
        nums.push(item.num)
        year.push(item.year)
        return nums
    })
    return {
        title: {
            text: '专利趋势',
            padding: [10, 0, 0, 120],
            textStyle: {
                fontSize: 14,
                fontFamily: 'PingFangSC- Regular, PingFang SC',
                fontWeight: 400,
                color: '#333333'
            }
        },
        tooltip: {
            show: true
        },
        xAxis: {
            axisLine: {
                show: false
            },
            axisTick: {
                show: false
            },
            type: 'category',
            data: year
        },
        yAxis: {
            axisLine: {
                show: false
            },
            axisTick: {
                show: false
            },
            splitLine: {
                show: false
            },
            show: true,
            type: 'value',
            name: '专利数量(数)',
            nameTextStyle: {
                fontSize: 10,
            }
        },
        grid: {
            bottom: '15%',
            left: '20%'
        },
        series: [{
            data: nums,
            type: 'bar',
            showBackground: true,
            barWidth: 20,
            barGap: '10%',
            padding: [300, 0, 0, 0],
            barCategoryGap: '10%',
            backgroundStyle: {
                color: 'rgba(180, 180, 180, 0.2)'
            },

            emphasis: {
                itemStyle: {
                    color: '#A6D0E4'
                }
            }
        }],
        color: ['#02375A']
    }
}