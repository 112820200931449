import React from 'react'
import classNames from 'classnames'
import 'styles/board/caption.scss'
const Caption = props => {
    return (
        <div className="caption">
            <div className="head">
                {
                    props.title.map( item => <div key={item.id} className="caption-title">
                        {item.text}
                    </div>)
                }
            </div>
            <div  className={classNames('body', { 'area-scroll': props.cate === 'area' })}>
                { 
                    props.list.map( item => <div key={item.id} className="item">
                        <div className="unit">{item.unit}</div>
                        <div className="nums">{item.nums}</div>
                    </div>) 
                }
            </div>
        </div>
    )
}

export default Caption