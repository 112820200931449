import React from 'react'
import classNames from 'classnames'
import { CompareIndexBar } from 'pages/echarts/bar'

const IndexCompare = props => {
    const { DataArray,DtLoading,DtIndex } = props
    return (
        !DtLoading && <section className='index'>
            <h1><span>TI指数对比</span></h1>
            <ul className='index-ul'>
                <li>
                    <span></span>
                    <span>TI指数</span>
                    <span>科技指标</span>
                    <span>市场指标</span>
                    <span>财务指标</span>
                </li>
                <div className={classNames('flex_box', DataArray.length === 2 ? 'two' : 'three')}>
                    {
                        DataArray.length > 0 && DataArray.map((d,i) => {
                            return <CompareIndexBar id={`${d.id}index`} key={d.id} other_name={DataArray[i].other_name} 
                                data={DtIndex.filter(dt => dt.id === d.id)[0]}
                            />
                        })
                    }
                </div>
            </ul>
        </section>
    )
}

export default IndexCompare