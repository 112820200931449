import React, { useState } from 'react'
import { getStorage } from 'utils/storage'
import { TEST_USER } from 'utils/type'
import Subcell from './SubCell'
import { Tootip1 } from 'comps/tootip/Tootip'

const Sub = props => {
    const [visible, setVisible] = useState(false)
    const cells = [], isTestUser = TEST_USER.includes(getStorage('level')) // 是否是测试用户
    const icons = [
        { id: 1, label: "icon-menu-wave" },
        { id: 425, label: "icon-menu-env" },
        { id: 489, label: "icon-menu-life" },
        { id: 107, label: "icon-menu-device" },
        { id: 319, label: "icon-menu-energy" },
        { id: 192, label: "icon-menu-material" }
    ]
    const changeModal = value =>{
        setVisible(value)
    }
    props.list.forEach(item => {
        icons.forEach(icon => {
            if (icon.id === item.id) {
                item.icon = icon.label
                cells.push(item)
            }
        })
    })

    return (
        <>
            {/* 权限弹窗 */}
            <Tootip1 value={visible} changeModal={changeModal} />
            <div className="sub" style={{ 'display': (props.show ? 'flex' : 'none') }}>
                {
                    isTestUser ? cells.map(item => {
                        if (![1, 489].includes(item.id)) { return <Subcell changeModal={changeModal} key={item.id} hide={true} {...item} /> }
                        else {
                            return <Subcell changeModal={changeModal} key={item.id} hide={false} {...item} />
                        }
                    })
                        :
                        cells.map(item => { return <Subcell changeModal={changeModal} key={item.id} hide={false} {...item} /> })
                }
            </div>
        </>
    )
}

export default Sub