import React from 'react'
import className from 'classnames'
const Project = props => {
    return <div className={className("list",{
        'list-no-border-btom': props.botmBorder
    })}>
        <div className="item">
            <div className="title">{props.name}</div>
            <div className="body">
                {props.manager && <div className="info">【项目经理】{props.manager}</div>}
                {props.code && <div className="info">【项目编号】{props.code}</div>}
                {props.budget && <div className="info">【项目预算】{props.budget}</div>}
                {props.s_date !== 0 && <div className="info">【立项时间】{props.s_date}年</div>}
            </div>
        </div>
    </div>
}

export default Project