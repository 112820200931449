import React, { Component } from 'react'
import { Table, Skeleton, Layout } from 'antd'
import AddCollectBtn from 'comps/collect/AddCollectBtn'
import { EmptyComp } from 'comps/common/EmptyComp'
import BreadComp from 'comps/bread'
import Bend from './Bend'
import Person from './Person'
import moment from 'moment'
import PageCustom from 'comps/PageCustom'
import  SideBar  from 'comps/sidebar'
import PackUp from 'comps/pack-up/PackUp'
import { getStorage } from 'utils/storage'
import { TEST_USER } from 'utils/type'
import { Tootip3 } from 'comps/tootip/Tootip'

import 'styles/tech/detail.scss'
import 'styles/comps/collect.scss'

const BreadData = [{ url: '/home', title: '首页' }, { url: '/tech', title: '技术列表' }, { url: '', title: '技术详情' }]
const side = [
    { href: "#explain", title: "释义" },
    { href: "#hot", title: "技术热度" },
    { href: "#network", title: "技术脉络" },
    { href: "#person", title: "人物" },
    { href: "#paper", title: "论文" },
    { href: "#company", title: "相关公司" }
]
class TechDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            total: 0,
            currentPage: 1,
            columns: [
                // { title: '', width: '10%', dataIndex: 'order', key: 'order', render: (t, r, i) => false },
                {
                    title: <div className='tech-tit'>序号</div>,
                    width: '15%',
                    dataIndex: 'order',
                    key: 'order',
                    align: 'center',
                    render: text => <span className='tech-con'>{text}</span>
                },
                {
                    title: <div className='tech-tit'>企业名称</div>,
                    width: '15%',
                    dataIndex: 'other_name',
                    key: 'other_name',
                    align: 'left',
                    render: (text, record) => <a className='tech-con' href={`/company/${record.id}`}>{text}</a>
                },
                {
                    title: <div className='tech-tit'>股票代码/融资信息</div>,
                    width: '20%',
                    dataIndex: 'roundInfo',
                    key: 'roundInfo',
                    render: text => (typeof text === 'string' ? text :
                        text.map(item => <span className='tech-con'>{item.ticker}</span>))
                },
                {
                    title: <div className='tech-tit'>成立时间</div>,
                    width: '20%',
                    dataIndex: 'found_date',
                    key: 'found_date',
                    render: text => <span className='tech-con'>{text}</span>
                },
                {
                    title: <div className='tech-tit'>所在行业</div>,
                    width: '30%',
                    dataIndex: 'industryInfo',
                    key: 'industryInfo',
                    render: text => <span className='tech-con'>{text.name}</span>,
                },
            ],
            info: {
                historyList: []
            },
            paper: [],
            company: [],
            expert: [],
            more: true,
            infoLoading: true,
        }
        this.saveBioRef = React.createRef()
    }
    componentDidMount() {
        const match = this.props.match
        // 技术详情
        this.axios.post('/tech/info', { id: match.params.id })
            .then(res => {
                if (res.code === 10000) {
                    if (res.data.historyList.length === 0) {
                        side.splice(side.indexOf(side.find(item => item.href === '#network')), 1)
                    }
                    this.setState({
                        info: res.data,
                        infoLoading: false
                    }, () => {
                        document.title = this.state.info.name
                    })
                }
            })
        // 技术论文
        this.axios.post('/tech/paper', { id: match.params.id, page: 1, size: 10 })
            .then(res => {
                if (res.data.items.length === 0) {
                    side.splice(side.indexOf(side.find(item => item.href === '#paper')), 1)
                }
                this.setState({ paper: res.data.items })
            })
        // 技术相关企业
        this.axios.post('/tech/company', { id: match.params.id, page: 1, size: 50 })
            .then(res => {
                if (res.data.items.length === 0) {
                    side.splice(side.indexOf(side.find(item => item.href === '#company')), 1)
                }
                this.setState({ company: res.data.items, total: res.data.count })
            })
        // 技术人物
        this.axios.post('/tech/expert', { id: match.params.id })
            .then(res => {
                if (res.data.length === 0) {
                    side.splice(side.indexOf(side.find(item => item.href === '#person')), 1)
                }
                this.setState({ expert: res.data })
            })
    }

    // 更改页码
    changeStatePage = page => {
        this.axios.post('/tech/company', { id: this.props.match.params.id, page: page, size: 50 }).then(res => {
            this.setState({ company: res.data.items, total: res.data.count, currentPage: page })
        })
    }

    render() {
        return (
            <Layout className="flex-box tech-detail">
                <SideBar data={side} />
                <div id="explain" className="content">
                    <BreadComp data={BreadData} />
                    <Skeleton active loading={this.state.infoLoading}>
                        <div className="top-box">
                            <div className="main">
                                <div className="title titlename">{this.state.info.name || '技术名称'}</div>
                                <div className="body">
                                    <div className="item"><span>中文名称：</span>{this.state.info.name_cn}</div>
                                    <div className="item"><span>英文名称：</span>{this.state.info.name_en}</div>
                                    <div className="item"><span>首次出现时间：</span>{this.state.info.date}</div>
                                    <div className="item"><span>技术发明者：</span>{this.state.info.inventor ? this.state.info.inventor : '未知'}</div>
                                    <div className="item"><span>最新论文时间：</span>{moment(this.state.info.new_paper_date * 1000).format('YYYY年MM月DD日')}</div>
                                    <div className="item"><span>主要技术领域：</span>{this.state.info.application}</div>
                                </div>
                            </div>
                            <AddCollectBtn off={true} id={this.props.match.params.id} type={4} />
                        </div>
                    </Skeleton>
                    <div className='bottom-box'>
                        <Skeleton active loading={this.state.infoLoading}>
                            <PackUp introduce={`释义：${this.state.info.definition || '内容更新中'}`} maxHeight={96}/>
                            <div className="right-box">
                                <div className="theme-box relate">
                                    <div className="item">
                                        <span>相关论文</span>
                                        <div className="num">
                                            {this.state.info.paper}
                                            <span>篇</span>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <span>相关企业</span>
                                        <div className="num">
                                            {this.state.info.company}
                                            <span>家</span>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <span>相关学者</span>
                                        <div className="num">
                                            {this.state.info.expert}
                                            <span>人</span>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <span>相关专利</span>
                                        <div className="num">
                                            {this.state.info.patent}
                                            <span>件</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Skeleton>

                    </div>
                    <div id="hot" className="title">技术热度</div>
                    <Skeleton active loading={this.state.infoLoading}>
                        <div className="theme-box trend-box">
                            <div className="figure">
                                <div className="head">主要指标</div>
                                <div className="body">
                                    <div className="item">
                                        <div className="text">论文高发年</div>
                                        <div className="data">{this.state.info.maxPaper}年</div>
                                    </div>
                                    <div className="item">
                                        <div className="text">企业创立高发年</div>
                                        <div className="data">{this.state.info.maxCompnay}年</div>
                                    </div>
                                </div>
                            </div>
                            <Bend
                                id={this.props.match.params.id}
                                company={this.state.info.map_company}
                                paper={this.state.info.map_paper}
                            />
                        </div>
                    </Skeleton>

                    {
                        this.state.info.historyList.length !== 0 && <>
                            <div id="network" className="title">技术脉络</div>
                            <div className="network">
                                {
                                    this.state.info.historyList.map((item, index) => {
                                        if (this.state.info.historyList.length < 4) {
                                            return <div key={index} className="history">{item}</div>
                                        } else {
                                            if (this.state.more) {
                                                return (index < 4 && <div key={index} className="history">{item}</div>)
                                            } else {
                                                return <div key={index} className="history">{item}</div>
                                            }
                                        }
                                    })
                                }
                                {this.state.info.historyList.length > 4 &&
                                    <div
                                        className="more"
                                        onClick={() => { this.setState({ more: !this.state.more }) }}
                                    >
                                        {this.state.more ? <span className='tip-btn'>展开</span> : <span className='tip-btn'>收起</span>}
                                        {this.state.more ?
                                            <img src={require('assets/images/tech/xiala.svg')} alt="down" className="arrow" /> :
                                            <img src={require('assets/images/tech/shangla.svg')} alt="Compare" className="arrow" />
                                        }
                                    </div>
                                }
                            </div>
                        </>
                    }

                    {this.state.info.name && this.state.expert.length > 0 &&
                        <>
                            <div id="person" className="title">人物</div>
                            <div className="theme-box person">
                                <Person
                                    id="tech-person"
                                    name={this.state.info.name}
                                    list={this.state.expert}
                                />
                            </div>
                        </>
                    }

                    {
                        this.state.paper.length > 0 && <>
                            <div id="paper" className="title">论文</div>
                            <div className="network">
                                {this.state.paper && this.state.paper.map((item, index) => (
                                    index < 10 &&
                                    <div key={index} className="paper-item">
                                        <div className="paper-title">{item.title || item.title_en}</div>
                                        <div className="right">
                                            <div className="author">{item.first_author}</div>
                                            <div className="time">{item.pub_date}</div>
                                        </div>
                                    </div>
                                ))}
                                {this.state.paper.length === 0 && <EmptyComp />}
                            </div>
                        </>
                    }

                    {
                        this.state.company.length > 0 && <>
                            <div id="company" className="title">相关公司</div>
                            <div className='box_company'>
                                {this.state.company.length === 0 ? <EmptyComp /> :
                                    <Table
                                        className="custom-table"
                                        columns={this.state.columns}
                                        rowKey='id'
                                        dataSource={this.state.company}
                                        pagination={false}
                                        bordered={false}
                                    />
                                }
                                {
                                    TEST_USER.includes(getStorage('level')) && <Tootip3 page={this.state.currentPage} aim={1} text='升级完整版，查看全部数据' />
                                }
                                <PageCustom
                                    total={this.state.total}
                                    currentPage={this.state.currentPage}
                                    pagesize={50}
                                    changeStatePage={this.changeStatePage}
                                    notLimit={true}
                                />
                            </div>
                        </>
                    }


                </div>
            </Layout>
        )
    }
}

export default TechDetail