import React, { Component } from 'react'
import { Row, Col, Input, Button, message, Form } from 'antd'
import { debounce, Msg } from 'utils'
import { clearStorage } from 'utils/storage'
import { Regcode } from 'libs/regcode'
import NologinLogo from './Logo'

import '@ant-design/compatible/assets/index.css'
import 'styles/pages/nologin.scss'

let timer
class Forgot extends Component {
    formRef = React.createRef()
    state = {
        captcha: '', dlgTipTxt: '获取验证码', seconds: 60, isDisabled: false
    }
    componentDidMount() {
        const _this = this
        clearStorage()
        document.title = '忘记密码'
        // 生成图形验证码
        timer = setTimeout(function () {
            const regcode = new Regcode()
            regcode.draw(document.querySelector('#regcode'), r => {
                _this.setState({ captcha: r })
            })
        }, 100)
    }
    // 获取短信验证码
    getSms = () => {
        this.formRef.current.validateFields(['mobile', 'captcha']).then(values => {
            this.axios.post('/code', { mobile: values.mobile, type: 2 }).then(res => {
                if (res.code === 10000) {
                    Msg('success', '短信验证码已发出！')
                    this.setState({ isDisabled: true })
                    // 验证码倒计时
                    let siv = setInterval(() => {
                        this.setState(preState => ({
                            seconds: preState.seconds - 1,
                            dlgTipTxt: `${preState.seconds - 1}s`
                        }), () => {
                            if (this.state.seconds === 0) {
                                clearInterval(siv)
                                this.setState({
                                    isDisabled: false, dlgTipTxt: "获取验证码", seconds: 60
                                })
                            }
                        })
                    }, 1000)
                }
            })
        }).catch(() => { return false })
    }
    // 忘记密码按钮
    goForgot = () => {
        this.formRef.current.validateFields(['mobile', 'pwd', 'sms', 'confpwd', 'captcha']).then(v => {
            this.axios.post('/find', { mobile: v.mobile, code: v.sms, pwd: v.pwd, pwd2: v.confpwd })
                .then(res => {
                    if (res.code === 10000) {
                        message.success('密码修改成功！')
                        this.props.history.push('/login')
                    }
                })
        }).catch(() => { return false })
    }
    // 忘记密码确认密码
    passwordConfirm = (rule, value) => {
        if (value && value !== this.formRef.current.getFieldValue('pwd')) {
            return Promise.reject('两次密码输入不一致!')
        } else { return Promise.resolve() }
    }
    // 验证码 校对
    captchaConfirm = (rule, v) => {
        const { captcha } = this.state
        if (v && v.toLowerCase() !== captcha.toLowerCase()) {
            return Promise.reject('验证码不正确')
        } else {
            return Promise.resolve()
        }

    }
    regPwd = (rule, value) => {
        // var patrn = /[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]/im; 
        const reg = /[`~!@#$%^&*()\-+=<>?:"{}|,/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]/
        if (reg.test(value)) {
            return Promise.reject('不能含有特殊字符！')
        }
        return Promise.resolve()
    }
    componentWillUnmount() {
        clearTimeout(timer)
    }
    render() {
        const { isDisabled, dlgTipTxt } = this.state
        return (
            <div className='login-con'>
                <section className='nologin-container'>
                    <NologinLogo />
                    <Row className='login-box register-box' type='flex'>
                        <Col span={14} offset={5} className='login-form forgot'>
                            <h1>忘记密码</h1>
                            <Form
                                ref={this.formRef}>
                                <Form.Item
                                    name='mobile'
                                    initialValue=''
                                    rules={[{ required: true, message: '手机号不能为空' }]}
                                    validateTrigger='onBlur'>
                                    <Input placeholder='请输入手机号' />
                                </Form.Item>
                                <Form.Item
                                    name='captcha'
                                    initialValue=''
                                    rules={[{ required: true, message: '图形验证码不能为空' }, { validator: this.captchaConfirm }]}
                                    validateTrigger='onBlur'>
                                    <Row>
                                        <Col span={16}>
                                            <Input className='code' type="text" placeholder="请输入图形验证码" />
                                        </Col>
                                        {/* 动态验证码 */}
                                        <Col span={8} className='code-input'> <canvas id="regcode"></canvas> </Col>
                                    </Row>
                                </Form.Item>
                                {/* 短信验证码 */}
                                <Form.Item
                                    name='sms'
                                    initialValue=''
                                    rules={[{ required: true, message: '短信验证码不能为空' }]}
                                    validateTrigger='onBlur'>
                                    <Row>
                                        <Col span={16}>
                                            <Input className='code' type="text" placeholder="请输入短信验证码" />
                                        </Col>
                                        {/* 动态验证码 */}
                                        <Col span={8} className='code-input'>
                                            <Button type="primary" onClick={this.getSms} className='sms-btn' disabled={isDisabled}> {dlgTipTxt} </Button>
                                        </Col>
                                    </Row>
                                </Form.Item>
                                <Form.Item
                                    name='pwd'
                                    initialValue=''
                                    rules={[{ required: true, min: 6, max: 16, message: '密码不能为空,长度为6-16位' }, { validator: this.regPwd }]}
                                    validateTrigger='onBlur'>
                                    <Input placeholder='请输入新密码' type="password" />
                                </Form.Item>
                                <Form.Item
                                    name='confpwd'
                                    initialValue=''
                                    rules={[{ required: true, message: '密码不能为空' }, { validator: this.passwordConfirm }]}
                                    validateTrigger='onBlur'>
                                    <Input placeholder='再次输入新密码' type="password" />
                                </Form.Item>
                                {/* 提交注册事件  防抖优化 */}
                                <Button type="primary" onClick={debounce(this.goForgot, 400)} className='login-btn'> 保存 </Button>
                                <span className='gologin'>已有账户？<span onClick={() => this.props.history.push('/login')}>去登录</span></span>
                            </Form>
                        </Col>
                    </Row>
                </section>
            </div>
        )
    }
}
export default Forgot