const addComItem = item => ({
    type: 'ADD_COM_ITEM',
    id: item.id,
    name: item.name
})

const clearComItem = () => ({
    type: 'CLEAR_COM_ITEM',
})

const delComItem = id => ({
    type: 'DELETE_COM_ITEM',
    id
})


// 公司筛选项 增加 删除一个 删除一类 删除所有
const addComLabel = label => ({
    type: 'ADD_COM_LABEL',
    id: label.id,
    name: label.name,
    cate: label.cate
})

const delComLabel = (id,cate) => ({
    type: 'DEL_COM_LABEL',
    id,cate
})

const clearComCate = cate => ({
    type: 'CLEAR_COM_CATE',
    cate
})

const clearComAll = () => ({
    type: 'CLEAR_COM_ALL'
})
export { addComItem, clearComItem, delComItem, addComLabel, delComLabel, clearComCate, clearComAll }