import React from 'react'
import { Col } from 'antd'
import { industryMoney } from 'utils'

const TopRightNums = props => {
    /**
     * 接口对应的字段
     * "money": 200,               //本年度投资金额 
     * "last_money": 69.25,        //达到去年投资金额百分比
     * "number": 1,                //本年度投资数
     * "new_number": 0,            //今日新增投资数
     * "new_invest_number": 0,     //今日新增相关投资方数量
     * "month_money": 0,           //本月投资总额
     * "last_month_money": -100,   //较去年本月同比增长百分比
     */
    const nums = props.nums,currentMonth = new Date().getMonth() + 1
    return <Col span={8} className='indi-industry-des flex_box'>
        <li className='flex_box'>
            <p>
                <span>本年投资总额</span>
                <span>
                    <strong>{industryMoney(nums.money)}</strong>亿
                </span>
            </p>
            <p>
                <span>已达到去年全年投资额</span>
                <span className='blue-num'>{nums.last_money}%</span>
            </p>
        </li>
        <li className='flex_box'>
            <p>
                <span>本年投资数量</span>
                <span><strong>{nums.number}</strong>起</span>
            </p>
            <p>
                <span>今日新增 <span className='blue-num'>{nums.new_number}</span> 起</span>
                <span>相关投资方 <span className='blue-num'>{nums.new_invest_number}</span> 家</span>
            </p>
        </li>
        <li className='flex_box'>
            <p>
                <span>本月投资总额</span>
                <span><strong>{industryMoney(nums.month_money)}</strong>亿</span>
            </p>
            <p>
                <span>较去年{currentMonth}月份</span>
                <span>同比增长
                    <span className={nums.last_month_money > 0 ? 'red' : 'green'}>
                        
                        {
                            nums.last_month_money > 0 ? <i className='iconfont icon-shangsheng'></i> :  <i className='iconfont icon-xiajiang'></i> 
                        }
                        {nums.last_month_money}%
                    </span>
                </span>
            </p>
        </li>
    </Col>
}

const BottomLeftNums = props => {
    /**
     * 接口对应的字段
     * "max": "200",               //本年度投资金额最高
     * "maxName": "A轮",           //本年度投资金额最高的轮次
     * "roundMax": 2,              //本年度投资数量最高
     * "roundMaxName": "A轮",      //本年度投资数量最高的轮次
     * "maxGrow": 88,              //近五年最高增长率百分比 
     * "maxGrowName": "Pre-B轮",   //近五年增长率最高的轮次 近5年投资方更青睐的投资阶段
     * "minGrow": -45,             //近五年最低增长率百分比
     * "minGrowName": "E轮"        //近五年增长率最低的轮次  近5年创业企业难度最大的阶段
     */
    const nums = props.nums,currentYear = new Date().getFullYear()
    return <Col span={10} className='indi-industry-des flex_box'>
        <li>
            <span>本年度投资金额最高</span>
            <span><strong>{nums.maxName} {industryMoney(nums.max)}</strong>亿</span>
        </li>
        <li>
            <span>本年度投资数量最高</span>
            <span><strong>{nums.roundMaxName} {nums.roundMax}</strong>起</span>
        </li>
        <li className='flex_box'>
            <p>
                <span>近5年创业企业难度最大的阶段</span>
                <span><strong>{nums.minGrowName}</strong></span>
            </p>
            <p>
                <span>{currentYear - 4}年至{currentYear}年{nums.minGrowName}融资的平均增长率为 <span className="blue-num">{nums.minGrow}%</span>
                </span>
            </p>
        </li>
        <li className='flex_box'>
            <p>
                <span>近5年投资方更青睐的投资阶段</span>
                <span><strong>{nums.maxGrowName}</strong></span>
            </p>
            <p>
                <span>{currentYear - 4}年至{currentYear}年{nums.maxGrowName}融资的平均增长率为 <span className="blue-num">{nums.maxGrow}%</span>
                </span>
            </p>
        </li>
    </Col>
}

export { TopRightNums, BottomLeftNums }