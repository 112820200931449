import React from 'react'
import 'styles/official/newfooter.scss'

function Footer(){
    const timeStamp = new Date().getTime()
    return (
        <footer className='newfooter-section'>
            <div className='foot-com'>
                <img src={'https://image.sciphi.cn/dt_insights/logo.svg?v=' +  timeStamp} alt='logo'/>
                <div className='border'></div>
                <div>透视未来的科技本质</div>
            </div>
            <div className='footer-beian'>
                <div className="border"></div>
                <div className='beian'>
                    <a href=' https://beian.miit.gov.cn/' rel="noopener noreferrer" target='_blank'>
                    京ICP备16049925号-14
                    </a>
                    <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502043658" rel="noopener noreferrer" target='_blank'>
                        <img src={require('@/assets/images/jinghui.jpg')} alt='erweima'/>
                    京公网安备 11010502043658号 
                    </a>
                </div>
                <div className="border"></div>
            </div>
        </footer>
    )
}

export default Footer