import React from 'react'
import { Popover } from 'antd'
const Industry = props => {
    let content = 'TI 产业指数是在 TI 产业分类（依据于新一代信息技术、高端装备、新材料、新能源、节能环保以及生物医药等科创板定义高新技术产业、战略性新兴科技产业及国际通用行业标准相关科技创新产业分类相结合建立的 TI 产业分类标准）与 TI 企业指数的基础上，在各个关键细分科技产业内，通过一级/二级市场科技创新企业的估值/市值、技术含量及技术商业化前景、研发投入与人才分布等总量指标计算得出的指数，以反映当下细分科创领域行业现状与产业发展前景等'
    return (
        <div className="industry">
            <div className="section-title">产业指数
                <Popover content={content} title={false} trigger="hover" placement="rightTop">
                    <span className="iconfont icon-explain-tip"></span>
                </Popover>
            </div>
            <div className="box">
                {props.list.map((item,index) => index === 0 ? <div className="item" key={item.id}>
                    <div className="nums">{item.nums}</div>
                    <div className="text">{item.text}</div>
                </div> :
                    <div className="itemList">
                        <div className="borders"></div>
                        <div className="item" key={item.id}>
                            <div className="nums">{item.nums}</div>
                            <div className="text">{item.text}</div>
                        </div>
                    </div>)}
            </div>
        </div>
    )
}

export default Industry