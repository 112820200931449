import React, { Component, useEffect } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { Button } from 'antd'
import { NavList } from 'utils/columns'
import 'styles/official/nav.scss'
import className from 'classname'

@withRouter
class Nav extends Component {
    constructor(props) {
        super(props)
        this.state = {
            timeStamp: new Date().getTime(),
            navBgColor: props.navBgColor || '',
            defaultPath: props.location.pathname || '/'
        }
    }
    // 鼠标划过nav变色
    changeColor = () => {
        let titleHeight = document.getElementById('nav-box').offsetHeight
        const nav = document.getElementById('nav-box')
        nav.style.backgroundColor = this.state.navBgColor || 'transparent'
        let that = this
        document.addEventListener('scroll', function (e) {
            document.documentElement.scrollTop > titleHeight ? nav.style.backgroundColor = '#3D3D3D' : nav.style.backgroundColor = that.state.navBgColor || 'transparent'
        }, false)
    }

    componentDidMount() {
        this.changeColor() 
    }

    componentDidUpdate(preveProps,preveState) {
        if (preveProps.location.pathname !== this.props.location.pathname) {
            this.setState({
                defaultPath: this.props.location.pathname
            }) 
        }
    }
    render() {
        const {timeStamp, defaultPath} = this.state
        return (
            <div className='nav-box-com' id='nav-box'>
                <div className='nav-inner'>
                    <Link to='/' id='logo'>
                        <img src={'https://image.sciphi.cn/dt_insights/logo.svg?v=' +  timeStamp} alt='logo' />    
                    </Link>
                    <div className="links">
                        {
                            NavList.map(item=>{
                                return <a className={className(['link', {'link-active': defaultPath === item.link ? true : false }])} key={item.id} href={item.link}>{item.title}</a>
                            })
                        }
                    </div>
                    <a className='btn' href='/login'>登录</a>
                </div>
            </div>
        )
    }
}

export default Nav