import React, { Component, useEffect, useState } from 'react'
import Year from './Year'
import axios from 'libs/axios'
import { CompareEmptyEmage } from 'comps/common/EmptyComp'

function PaperContent(props) {
    const { id, name } = props
    const [paperList, setPaperList] = useState([])

    useEffect(() => {
        // 论文图
        axios.post('/expert/paperslist', { id }).then(res => {
            if (res.code === 10000) {
                setPaperList(res.data.yearInfo)
            }
        })
    }, [id])

    return (
        <div className='paper-item'>
            <div className="paper-bottom">
                {
                    Object.keys(paperList).length > 0 ? <Year id={`yers${id}`} data={paperList} /> : <CompareEmptyEmage />
                }
            </div>
        </div>
    )
}

export default PaperContent