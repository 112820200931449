import React from 'react'
import { Popover } from 'antd'
import Radar from './Radar'
import { RadarMap } from 'comps/radar-map/RadarMap.jsx'

function Expert(props) {
    let content = 'TI人才指数由人物的论文、专利、教育背景、个人荣誉和技术落地能力等维度构成。囊括了主流的人才评价指标，同时对个人论文和专利情况进行分析，结合其参与的重大项目、获得的重要荣誉及奖项，以及其在商业世界的关系网络、技术的商业化水平和所研究细分领域的特殊情况，综合得出该指数。'
    const indicatorData = [
        { name: '论文', max: 45 },
        { name: '专利', max: 30 },
        { name: '成果转化', max: 5 },
        { name: '荣誉/任职', max: 10 },
        { name: '教育', max: 10 },
    ]
    return (
        <div className="patent">
            <div className="section-title">专家指数</div>
            <div className="patent-wrap">
                <div className="left-side">
                    <div className="piece">
                        <div>{props.h_index}</div>
                        <span>H-index指数</span>
                        <div className="separator"></div>
                    </div>
                    <div className="piece">
                        <div>{props.effective_patent}</div>
                        <span>有效专利数</span>
                        <div className="separator"></div>
                    </div>
                    <div className="piece">
                        <div>{props.total_patent}</div>
                        <span>专利数</span>
                    </div>
                    <div className="piece">
                        <div>{props.total_paper}</div>
                        <span>总发文数</span>
                        <div className="separator"></div>
                    </div>
                    <div className="piece">
                        <div>{props.total_cite}</div>
                        <span>总被引数</span>
                        <div className="separator"></div>
                    </div>
                    <div className="piece border-left">
                        <div>{props.avg_cite}</div>
                        <span>篇均被引数</span>
                    </div>
                    {/*<div className="piece">
                        <div className="expert">{props.dt_index} <img className="indicator" src={require('assets/images/person/decrease.png')} alt="decrease" /> </div>
                        <span>
                            TI专家指数 
                            <Popover content={content} title={false} trigger="hover" placement="rightTop">
                                <img src={require('assets/images/person/help.png')} alt="index" />
                            </Popover> 
                        </span>
                        <div className="separator"></div>  
                    </div>*/}
                    
                </div>
            </div>
            <div className="graph">
                {/* <Radar id="relate"
                    score={[props.paper_score, props.patent_score, props.commerce_score, props.honor_score, props.education_score]}
                /> */}
                <RadarMap RadarMapId={'relate'} 
                    radarData={[props.paper_score, props.patent_score, props.commerce_score, props.honor_score, props.education_score]} 
                    indicatorData={indicatorData} 
                    radarStyle={{width: '240px', height: '220px',tooltipIsShow: true, radarShape:'', seriesName:'专家指数'}}
                />
                
            </div>
        </div>
    )
}

export default Expert