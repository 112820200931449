import React, { Component, useEffect, useState } from 'react'
import { Checkbox, Table, Modal, Tag, Space, Layout, Button, Input, Form, Row, Col } from 'antd'
import { DownOutlined, UpOutlined } from '@ant-design/icons'
import Nav from './common/Nav'
import 'styles/official/regist.scss'
import { Msg } from 'utils'
import Protocol from './Protocol'

const layout = {
    wrapperCol: { span: 24 },
}

const list = [
    { type: '公司查看数', all: '不限', cc: '有限' },
    { type: '人才查看数', all: '不限', cc: '有限' },
    { type: '技术查看数', all: '不限', cc: '有限' },
    { type: '产业维度', all: '不限', cc: '有限' },
    { type: '园区查看数', all: '不限', cc: '有限' },
    { type: '专辑维度', all: '不限', cc: '有限' },
    { type: '公司详情', all: '不限', cc: '有限' },
    { type: '人才详情', all: '不限', cc: '有限' },
    { type: '技术详情', all: '不限', cc: '有限' },
    { type: '产业详情', all: '不限', cc: '有限' },
    { type: '园区详情', all: '不限', cc: '有限' },
    { type: '筛选结果', all: '不限', cc: '有限' },
    { type: '搜索结果', all: '不限', cc: '有限' },
    { type: '企业对比', all: '不限', cc: '无' },
    { type: '人才对比', all: '不限', cc: '无' },
    { type: '数据导出', all: '不限', cc: '无' },
    { type: '收藏', all: '不限', cc: '有限' },
    { type: '报告下载', all: '不限', cc: '无' },
]

class Regist extends Component {
    formRef = React.createRef()

    constructor(props) {
        super(props)
        this.state = {
            captcha: '',
            dlgTipTxt: '获取验证码',
            seconds: 60,
            isDisabled: false,
            heightOff: true,
            visible: false
        }
    }

    componentDidMount() {
        document.title = '注册'
        document.addEventListener("keyup", this.enterBtn, false)
    }
    // enter按键之后事件监控
    enterBtn = e => {
        if (e.keyCode === 13) {
            this.goRegister()
        }
    }
    componentWillUnmount() {
        // 卸载enter 事件
        document.removeEventListener('keyup', this.enterBtn, false)
    }


    // 不能为空限制
    notEmpty = text => {
        return { required: true, message: `${text}不能为空` }
    }

    // 最大字符限制
    maxStr = num => {
        return { max: num, message: `内容不能超过${num}个字` }
    }

    // 验证手机号
    regPhone = (rule, value) => {
        const reg = /^1[345678]\d{9}$/
        if (reg.test(value) === false) {
            return Promise.reject('手机号格式不正确！')
        }
        return Promise.resolve()
    }

    // 密码验证
    regPwd = (rule, value) => {
        const reg = /[`~!@#$%^&*()\-+=<>?:"{}|,/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]/
        if (reg.test(value)) {
            return Promise.reject('不能含有特殊字符！')
        }
        return Promise.resolve()
    }

    // 获取短信验证码
    getSms = () => {
        this.formRef.current.validateFields(['mobile', 'captcha']).then(values => {
            this.axios.post('/code', { mobile: values.mobile, type: 1 }).then(res => {
                if (res.code === 10000) {
                    Msg('success', '短信验证码已发出！')
                    this.setState({ isDisabled: true })
                    // 验证码倒计时
                    let siv = setInterval(() => {
                        this.setState(preState => ({
                            seconds: preState.seconds - 1,
                            dlgTipTxt: `${preState.seconds - 1}s`
                        }), () => {
                            if (this.state.seconds === 0) {
                                clearInterval(siv)
                                this.setState({
                                    isDisabled: false, dlgTipTxt: "获取验证码", seconds: 60
                                })
                            }
                        })
                    }, 1000)
                }
            })
        }).catch(() => { return false })
    }

    onFinish = values => {
        this.formRef.current.validateFields(['name', 'company', 'position', 'email', 'mobile', 'pwd', 'remember', 'sms']).then(v => {
            if (v.remember === true) {
                const options = { mobile: v.mobile, password: v.pwd, code: v.sms, email: v.email, job: v.position, name: v.name, company: v.company }
                this.axios.post('/register', options)
                    .then(res => {
                        if (res.code === 10000) {
                            Msg('success', '注册成功！')
                            this.props.history.push('/login')
                        }
                    })
            }
        }).catch(() => { return false })
    }

    check = values => {
        this.formRef.current.validateFields(['remember']) === true ? this.onFinish() : Msg('error', '点击同意条款完成注册')
    }

    render() {
        const { heightOff, dlgTipTxt, isDisabled, visible } = this.state
        return (
            <Layout className='all-box'>
                <Nav />
                <section className='regist-body'>
                    <div className='left'>
                        <p className='one'>DT Insights</p>
                        <p className='two'>适用于投资机构、科技企业、政府部门、高等院校、园区、医院、科学家等</p>
                        <p className='three'>试用版与完整版</p>
                        <p className='four'>为了满足不同客户的需求，DT Insights数据库为您提供科技产业内多维度数据及个性化功能，包含产业透视、企业分析、人才挖掘、自定义榜单及科技要素评价等。</p>
                        <p className='five'>您可以免费使用30天，具体数据内容：</p>
                        <table className={!heightOff ? 'table_t' : 'table'}>
                            <tr>
                                <th>功能介绍</th>
                                <th>完整版</th>
                                <th>试用版</th>
                            </tr>
                            {
                                list.map(item => {
                                    return <tr>
                                        <td>{item.type}</td>
                                        <td>{item.all}</td>
                                        <td>{item.cc}</td>
                                    </tr>
                                })
                            }
                        </table>
                        {
                            !heightOff ?
                                <div className='bottom' onClick={() => this.setState({ heightOff: true })}>
                                    收起<UpOutlined />
                                </div> :
                                <div className='bottom' onClick={() => this.setState({ heightOff: false })}>
                                    展开<DownOutlined />
                                </div>
                        }
                    </div>
                    <div className='right'>
                        <Form name='regist' className='regist' ref={this.formRef}
                            onFinish={this.onFinish}>
                            <Form.Item
                                {...layout}
                                name='name'
                                rules={[this.notEmpty('姓名'), this.maxStr(30)]}
                                validateTrigger='onBlur'
                            >
                                <Input placeholder='姓名' className='input' />
                            </Form.Item>
                            <Form.Item
                                name='company'
                                rules={[this.notEmpty('公司'), this.maxStr(30)]}
                                validateTrigger='onBlur'
                            >
                                <Input placeholder='公司' className='input' />
                            </Form.Item>
                            <Form.Item
                                name='position'
                                rules={[this.notEmpty('职位'), this.maxStr(30)]}
                                validateTrigger='onBlur'
                            >
                                <Input placeholder='职位' className='input' />
                            </Form.Item>
                            <Form.Item
                                name='email'
                                rules={[this.notEmpty('邮箱'), { type: 'email', message: '邮箱格式不正确' }]}
                                validateTrigger='onBlur'
                            >
                                <Input placeholder='邮箱' className='input' />
                            </Form.Item>
                            <Form.Item
                                name='mobile'
                                rules={[this.notEmpty('手机号'),this.regPhone ]}
                                validateTrigger='onBlur'
                            >
                                <Input placeholder='手机号码' className='input' />
                            </Form.Item>
                            <Form.Item
                                name="pwd"
                                rules={[{ required: true, min: 6, max: 16, message: '密码不能为空,长度为6-16位' }, { validator: this.regPwd }]}
                                validateTrigger='onBlur'
                            >
                                <Input.Password placeholder='密码' className='input' />
                            </Form.Item>
                            <Form.Item
                                name='sms'
                                rules={[{ required: true, message: '短信验证码不能为空' }]}
                                validateTrigger='onBlur'
                            >
                                <Row>
                                    <Col span={12}>
                                        <Input type="text" placeholder="短信验证码" className='input' />
                                    </Col>
                                    <Col span={8} offset={4}>
                                        <Button type="primary" className='btn' onClick={this.getSms} disabled={isDisabled}> {dlgTipTxt} </Button>
                                    </Col>
                                </Row>
                            </Form.Item>
                            <Form.Item name="remember" valuePropName="checked" className='input-o'>
                                <Checkbox className='txt'>已阅读 <a
                                    href='/protocol'
                                // onClick={()=>this.setState({ visible: true })}
                                >隐私条款</a></Checkbox>
                            </Form.Item>
                            <Form.Item className='input'>
                                <Button type="primary" htmlType="submit" className='_btn' onClick={this.check}>
                                    注册试用版
                                </Button>
                            </Form.Item>
                        </Form>
                    已有账户？<a href='/login'>去登录</a>
                    </div>
                </section>
                {/* <Modal
                    title="Modal 1000px width"
                    centered
                    visible={visible}
                    onOk={() => this.setState({ visible: false })}
                    onCancel={() => this.setState({ visible: false })}
                    width={1000}
                >
                    <Protocol />
                </Modal> */}
            </Layout>
        )
    }
}

export default Regist
