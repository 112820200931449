import React, { Component, useEffect, useState } from 'react'
import axios from 'libs/axios'
import moment from 'moment'
import { useHistory } from 'react-router-dom'

function BasicContent(props) {
    let history = useHistory()
    const { id, name } = props
    const [TiData, setTiData] = useState({}) // Ti指数
    const [invest, setInvest] = useState([]) // 融资

    useEffect(() => {
        // Ti指数
        axios.post('/company/score', { id }).then(res => {
            if (res.code === 10000) {
                setTiData(res.data)
            }
        })
        // 融资
        axios.post('/company/money', { id }).then(res => {
            if (res.code === 10000) {
                setInvest(res.data.invest)
            }
        })
    }, [id])
    return (
        <div className='basic-item'>
            <div className="basic-bottom">
                <div className="info-le">

                    <div className="info-item">
                        <span>TI指数</span>
                        <span>{TiData.financial + TiData.market + TiData.scientific || 0}</span>
                    </div>
                    <div className="info-item">
                        <span>科技指标</span>
                        <span>{TiData.scientific || 0}</span>
                    </div>
                    <div className="info-item">
                        <span>财务指数</span>
                        <span>{TiData.financial || 0}</span>
                    </div>
                    <div className="info-item">
                        <span>市场指数</span>
                        <span>{TiData.market || 0}</span>
                    </div>
                </div>
                <div className="info-cen">
                    <div className="info-cen-item">成立时间：<span>{props.found_date}</span></div>
                    <div className="info-cen-item">最近融资：<span>{invest.length === 0 ? '--' : moment((invest[0].date) * 1000).format('YYYY-MM-DD')}</span></div>
                    <div className="info-cen-item">所在行业：
                        <span title={ props.industryPaths && props.industryPaths.map( item => item.name + '' )}>
                            {
                                props.industryPaths && props.industryPaths.map((item, index) => {
                                    return index === props.industryPaths.length - 1 ? item.name : item.name + '、'
                                })
                            }
                        </span>
                    </div>
                    <div className="info-cen-item">公司规模：<span>{props.size}</span></div>
                </div>
                <div className="info-ri">
                    <div className="info-ri-top">
                        <span>相关产业：</span>
                        {
                            props.industry && props.industry.length > 0 ? <div className='info-ri-top-body'>
                                {
                                    props.industry.map(item => {
                                        return <p key={item.id}>{item.name || item.Name}</p>
                                    })
                                }
                            </div> : <div className="info-ri-top-body">&nbsp;暂无</div>
                        }
                    </div>
                    <div className="info-ri-bottom">
                        <span>相关专辑：</span>
                        {
                            props.labelInfos.length > 0 ? <div className='info-ri-bottom-body'>
                                {
                                    props.labelInfos.map(item => {
                                        // return <p onClick={() => history.push(`/collection/detail/${item.id}`)} key={item.id}>{item.name}</p>
                                        return <p key={item.id}>{item.name}</p>
                                    })
                                }
                            </div> : <p className="info-ri-bottom-body">&nbsp;暂无</p>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
export default BasicContent