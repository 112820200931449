
function effectSelectedID(state = '', action) {
    switch (action.type) {
    case 'SELECT_EFFECT_ITEM':
        return action.id
    case 'CLEAR_EFFECT_ITEM':
        return ''
    default:
        return state
    }
}

export { effectSelectedID }