//投资类型，1机构天使、2个人天使、3VC、4PE、5FOF、6投行、7券商、8非上市公司、9上市公司、10孵化器、11股权众筹平台、12资产管理、13其他
export const INVEST_TYPE = {
    '1':'机构天使','2':"个人天使",
    '3':'VC','4':'PE','5':'FOF','6':'投行','7':"券商",
    '8':'非上市公司','9':'上市公司','10':'孵化器',"11":'股权众筹平台','12':'资产管理',"13":'其他'
}
//公司运营的状态
export const COMP_STATUS = {
    1:'运营中',2:'已关闭',3:'以转型',4:'未上线',5:''
}

// 榜单的类型
export const COL_TYPE = {
    1:{name:'公司',unit:'家',other_name:'企业',href:'/company',en:'Company',more:'/company'},
    2:{name:'人才',unit:'位',other_name:'人才',href:'/person/detail',en:'Expert',more:'/person'},
    3:{name:'园区',unit:'个',other_name:'园区',href:'/park',en:'Park',more:'/park'},
    4:{name:'技术',unit:'项',other_name:'技术',href:'/tech',en:'Tech',more:'/tech'}
}
// 融资成功率对象
export const FINANCEOBJ = { all:'全部',angel:'天使',a:'A轮',b:'B轮',c:'C轮',d:'D轮',e:'E轮',up:'上市'}

export const COLORS_20 = [
    '#826AF9','#9E86FF','#BE99F7','#DDB6FF','#FCD5FF','#FFABD5','#EF8BB4','#D27099',
    '#B55680','#993B67','#7D214F','#610038','#700000','#8C1004','#AA311B','#C94D33',
    '#E8684B','#FE8363','#FF9F7C','#FFBB98'
]

// export const COLORS = [
//     '#02375A','#044E79','#0F6A94','#2583AF','#3FA3D2','#80BCD9','#80BCD9','#A2D6E0','#B9DEE5','#FFCAE3E8']
export const COLORS = [
    '#02375A','#044E79','#0F6A94','#2583AF','#3FA3D2','#80BCD9','#80BCD9','#B9DEE5','#CAE3E8',
    '#DAECED','#E6EFF0','#E6E4FF','#D4D1FF','#BCB8FF','#A39EE9','#8E89D7','#7872C8','#605AAD','#554FA0','#3A3485','#26206F'
]
export const COLORS_COMPETE = [
    '#0F6A94','#2583AF','#3FA3D2','#80BCD9','#80BCD9','#B9DEE5','#CAE3E8',
    '#DAECED','#E6EFF0','#E6E4FF','#D4D1FF','#BCB8FF','#A39EE9','#8E89D7','#7872C8','#605AAD','#554FA0','#3A3485','#26206F'
]

export const CHART_DOWN_COLORS = [
    '826AF9','9E86FF','BE99F7','DDB6FF','FCD5FF','FFABD5','EF8BB4','D27099',
    'B55680','993B67','7D214F','610038','700000','8C1004','AA311B','C94D33',
    'E8684B','FE8363','FF9F7C','FFBB98'
]
// 上市信息 代码简写
export const BOARD_TYPE = {
    'SH':'SH', 
    'SZ':'SZ',
    'O':'NASDAQ',
    'N':'NYSE',
    'HK':'HK',
    'A':'AMEX'
}

// 测试用户类型
// [4] => 测试学者/科学家用户
// [5] => 测试企业用户
// [6] => 测试园区用户
// [7] => 测试高校用户
// [8] => 测试医院用户
// [9] => 测试大型企业用户
// [10] => 测试政府用户
export const TEST_USER = [4,5,6,7,8,9,10]
// 正式用户类型
// [1] => 企业用户
// [2] => 园区用户
// [3] => 科学家用户
// [11] => 高校用户
// [12] => 医院用户
// [13] => 大型企业用户
// [14] => 政府用户
export const FORMAL_USER = [1,2,3,11,12,13,14]

// 错误码
export const ERROR_CODE = {
    "10000": "成功",
    "10001": "失败",
    "20000": "缺少参数",
    "30000": "已存在",
    "20001": "登录信息已过期，请重新登录！", // 后台返回的是 没有TOKEN
    "20002": "验证码错误",
    "20003": "密码错误",
    "20004": "用户已禁止访问",
    "20005": "不是手机号",
    "20006": "发送短信失败",
    "20007": "没有找到用户",
    "20008": "上传失败",
    "20009": "没有找到相关数据",
    "20010": "没有权限",
    "20011": "没有设备编码",
    "20012": "用户没有登录",
    "20013": "参数错误",
    "20014": "没有关注过",
    "20016": "邮件发送错误",
    "99999": "超出频率",
    "20018": "上传失败,空文件或不是Excel文件",
    "20019": "当前用户使用期限已过，请联系网站管理人员",
    "20020": "用户未生效",
    "20021": "两次密码不对",
    "20022": "用户原密码不对",
    "20023": "用户原密码与新密码一样",
    "20024": "园区订单有未完成订单",
    "20025": "搜索次数已达上限",
    "20026": "当前专辑的内容数量已达上限！",
    "20027": "用户注册手机号已存在！",
    "20032": "已存在专辑中，请勿重复收藏！"
}
// 北京、天津、河北、海南、香港、湖北、山东、西藏、四川、宁夏、甘肃、山西、重庆、陕西、青海、内蒙古、辽宁、湖南、贵州
// 钓鱼岛、澳门、江西、吉林、广东、云南、福建、黑龙江、安徽、河南、台湾、上海、广西、江苏、新疆、浙江
export const GeoCoordMapCity = {
    '上海': [121.4648, 31.2891],
    '新疆': [86.9236, 41.5883],
    '甘肃': [103.9901, 36.3043],
    '北京': [116.4551, 40.4539],
    '江苏': [120.2062, 32.9208],
    '广西': [108.479, 24.1152],
    '江西': [116.0046, 28.6633],
    '安徽': [117.29, 32.0581],
    '内蒙古': [111.4124, 41.4901],
    '黑龙江': [127.9688, 46.868],
    '天津': [117.4219, 39.4189],
    '山西': [112.3352, 37.9413],
    '广东': [114.5107, 23.8196],
    '四川': [103.9526, 30.7617],
    '西藏': [91.1865, 30.1465],
    '云南': [101.9199, 25.0663],
    '浙江': [119.5313, 29.8773],
    '湖北': [113.0896, 31.3628],
    '辽宁': [123.1238, 42.1216],
    '山东': [117.1582, 36.8701],
    '河北': [115.4995, 38.6006],
    '福建': [118.0543, 26.5222],
    '青海': [97.4038, 35.8207],
    '陕西': [109.1162, 34.2004],
    '贵州': [106.6992, 26.7682],
    '河南': [113.4668, 34.6234],
    '重庆': [107.7539, 30.1904],
    '宁夏': [106.3586, 38.1775],
    '吉林': [125.8154, 44.2584],
    '湖南': [111.8823, 28.2568],
    '海南': [109.8500, 19.7028],
    '台湾': [120.7964, 24.1528],
    '澳门': [113.557519,22.204118],
    '香港': [114.186124,22.293586],
    '钓鱼岛':[],
    '南海诸岛': []
}

// 人才筛选列表 拼音字母
export const personAlphabetList = [
    { id: 0,name:'全部'},
    { id: 'A', name: 'A' },
    { id: 'B', name: 'B' },
    { id: 'C', name: 'C' },
    { id: 'D', name: 'D' },
    { id: 'E', name: 'E' },
    { id: 'F', name: 'F' },
    { id: 'G', name: 'G' },
    { id: 'H', name: 'H' },
    { id: 'I', name: 'I' },
    { id: 'J', name: 'J' },
    { id: 'K', name: 'K' },
    { id: 'L', name: 'L' },
    { id: 'M', name: 'M' },
    { id: 'N', name: 'N' },
    { id: 'O', name: 'O' },
    { id: 'P', name: 'P' },
    { id: 'Q', name: 'Q' },
    { id: 'R', name: 'R' },
    { id: 'S', name: 'S' },
    { id: 'T', name: 'T' },
    { id: 'U', name: 'U' },
    { id: 'V', name: 'V' },
    { id: 'W', name: 'W' },
    { id: 'X', name: 'X' },
    { id: 'Y', name: 'Y' },
    { id: 'Z', name: 'Z' }                
]

