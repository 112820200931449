import React, { Component } from 'react'
import EchartReact from 'comps/Sign/EchartReact'
import { COLORS } from 'utils/type'

export default function Legal(props) {
    return (<EchartReact
        id={props.id}
        options={getOption(props.data)}
        style={{ width: '400px', height: '170px' }}
    />)
}

function getOption(data) {
    let des = []
    if (data.effective_patent !== 0) {
        des.push({ value: data.effective_patent, name: '有效专利数 ' + data.effective_patent})
    }
    if (data.invalid_patent !== 0) {
        des.push({ value: data.invalid_patent, name: '无效专利数 ' + data.invalid_patent})
    }
    if (data.examine_patent !== 0) {
        des.push({ value: data.examine_patent, name: '在审专利数 ' + data.examine_patent})
    }
    
    if (data.other_patent !== 0) {
        des.push({ value: data.other_patent, name: '未确定专利数 ' + data.other_patent})
    }
    return {
        title:{
            text:'专利分布',
            left: '25%',
            textStyle: {
                fontSize: 14,
                fontFamily: 'PingFangSC- Regular, PingFang SC',
                fontWeight: 400,
                color: '#333333'
            }
        },
        tooltip:{
            trigger: 'item',
            formatter: '{b}'
        },
        legend: {
            show: true,
            orient: 'vertical',
            right: 20,
            bottom: 0,
            icon: 'circle',
            itemHeight: 10,
            itemWidth: 10,
        },
        series: [
            {
                type: 'pie',
                radius: ['60%', '80%'],
                center: ['45%', '60%'],
                label: { show: false },
                hoverAnimation: false,
                data: des,
            }
        ],
        color: COLORS
    }
}


