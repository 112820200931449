import React from 'react'
import * as echarts from 'echarts'
import { GeoCoordMapCity } from 'utils/type'
// 参考 https://gallery.echartsjs.com/editor.html?c=x0dK5jItt

// 地图取值颜色
let piecesRange = [{
    min: 201,
    color: '#02375A'
},{
    min: 101, max: 200,
    color: '#044E79'
},{
    min: 31,max: 100,
    color: '#0F6A94'
},{
    min: 11,max: 30,
    color: '#3FA3D2'
},{
    min: 1,max: 10,
    color: '#80BCD9'
},{
    value: 0,
    color: '#A2CCE0'
}]

let GeoBarOptions = {
    baseOption: {
        timeline: { show:false },
        tooltip: {
            show: true,
            formatter: function(params) {
                return params.name + '：' + params.value
            },
            // 柱状图的hover
            axisPointer: { // 坐标轴指示器，坐标轴触发有效
                type: 'shadow', // 默认为直线，可选为：'line' | 'shadow'
                shadowStyle: {
                    color: 'rgba(150,150,150,0.1)' //hover颜色
                }
            }
        },
        // 线性变化图例
        visualMap: {
            show:false,
            type: 'piecewise',
            pieces: piecesRange,
            orient: 'vertical',
            itemWidth: 25,
            itemHeight: 15,
            showLabel: true,
            seriesIndex: [0],
        },
        grid: {
            right: '2%',
            top: 30,
            bottom: 30,
            width: '25%'
        },
        xAxis: {
            axisLine:{
                show:false,
                lineStyle:{ color:'#9AA1A9' }
            },
            splitLine:{
                lineStyle:{ type:'dotted', opacity:.5 }
            },
            axisTick:{ show:false }
        },
        yAxis: [{
            inverse: true,
            offset: '2',
            'type': 'category',
            data: '',
            nameTextStyle: { color: '#fff' },
            axisTick: { show: false },
            axisLabel: {
                textStyle: { fontSize: 14, color: '#000000' },
                interval: 0
            },
            axisLine: {
                show: false,
                lineStyle: { color: '#333' },
            },
            splitLine: {
                show: false,
                lineStyle: { color: '#333' }
            },
        }],
        geo: {
            map: 'china',
            top:10,
            right: '35%',
            left: '3%',
            bottom:10,
            label: {
                emphasis: { show: false }
            },
            itemStyle: {
                // 地图高亮颜色
                emphasis: { areaColor: '#21CFD4' }
            }
        },
        series: [{
            name: 'mapSer',
            type: 'map',
            map: 'china',
            roam: false,
            geoIndex: 0,
            label: { show: false },
        },
        {
            type: 'bar',
            zlevel: 2,
            label: {
                normal: { show: true, fontSize: 14, position: 'right', formatter: '{c}' }
            }
        }]
    },
    animationDurationUpdate: 3000,
    animationEasingUpdate: 'quinticInOut',
    options: []
}

class GeoBar extends React.Component{
    componentDidMount(){
        const propsData = this.props.data
        const { indName,title } = this.props
        // 数据处理部分
        let mapData = [],barYdata = [],barXdata = []
        for ( let k of Object.keys(GeoCoordMapCity)){
            // 如果props有key能匹配上 则传值，否则为0
            if (Object.keys(propsData).includes(k)){
                mapData.push({
                    name:k,value:propsData[k]
                })
            } else {
                mapData.push({
                    name:k,value:0
                })
            }
        }
        mapData.sort((a, b)=>{
            return b.value - a.value
        })

        for (let t = 0; t < 10; t++) {
            // bar 型图的横纵坐标数据
            barYdata[t] = mapData[t].name
            barXdata[t] = mapData[t].value
        }
        // map亮点数据 转化
        let convertData = function(data) {
            var resdata = []
            for (var i = 0; i < data.length; i++) {
                var geoCoord = GeoCoordMapCity[data[i].name]
                if (geoCoord) {
                    resdata.push({
                        name: data[i].name,
                        value: geoCoord.concat(data[i].value)
                    })
                }
            }
            return resdata
        }
        // tabs 切换会出现问题 所以options是直接
        GeoBarOptions.options = [{
            title:[
                {
                    text:`${indName}产业${title}数量前十省份`,
                    left:'69%',
                    top:0,
                    textStyle:{
                        color:'#1E2E42',
                        fontSize:14
                    }
                }
            ],        
            yAxis: {
                data: barYdata,
            },
            series: [{
                type: 'map',
                data: mapData
            },
            //地图中闪烁的点
            {
                type: 'effectScatter',
                coordinateSystem: 'geo',
                data: convertData(mapData.sort((a, b)=>{
                    return b.value - a.value
                })),
                tooltip:{
                    formatter:function(v){
                        return `${v.name}: ${v.value[2]}`
                    }
                },
                // 点的大小
                symbolSize: function(val) {
                    let size = 0
                    if (barXdata[0] > 300){
                        return val[2] / 50
                    } else if (barXdata[0]  > 100 ){
                        return val[2] / 20
                    } else if (barXdata[0] > 50){
                        return val[2] / 5
                    } else {
                        return val[2] / 1.5
                    }
                },
                showEffectOn: 'render',
                rippleEffect: {
                    brushType: 'stroke'
                },
                hoverAnimation: true,
                label: {
                    normal: {
                        formatter: '{b}',
                        position: 'right',
                        show: true
                    }
                },
                itemStyle: {
                    normal: {
                        color: '#FFFF00',
                        shadowBlur: 10,
                        shadowColor: '#FFFF00'
                    }
                },
                zlevel: 1
            },
            // 柱状图
            {
                type: 'bar',
                barWidth: 20,
                itemStyle: {
                    barBorderRadius:[0,10,10,0],
                    color: '#02375A'
                },
                data: barXdata,
            },
            // 四个pie
            // {
            //     type: 'pie',
            //     clockWise: false,
            //     radius: [50, 60],
            //     itemStyle: pieItemStyle,
            //     hoverAnimation: false,
            //     center: ['10%', '90%'],
            //     data: [{
            //         value: 25,
            //         itemStyle: pieDataItem
            //     },{
            //         value: 75,
            //         label: pieDataLabel,
            //         itemStyle: {
            //             normal: {
            //                 color: '#2D99FF',
            //                 shadowColor: '#2D99FF',
            //                 shadowBlur: 0
            //             }
            //         }
            //     }]
            // }
            ]
        }]
        const chartFatherWidth = document.getElementsByClassName('industry-tabs')[0].offsetWidth
        document.getElementById(`${this.props.id}`).style.width = chartFatherWidth - 40 + 'px'
        let myChart = echarts.init(document.getElementById(`${this.props.id}`))
        myChart.setOption(GeoBarOptions)
    }
    render(){
        return <div id={`${this.props.id}`} className='geo-bar'></div> 
    }
}
export default GeoBar