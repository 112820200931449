import React from 'react'

const Suggest = props => {
    return (
        <div className="suggest-box" style={{ 'display': (props.show ? 'block' : 'none')}}>
            {props.list.map(item => {
                let cate = '',label = '企业'
                switch (item.type){
                case '2':
                    cate = 'person/detail'
                    label = '人物'
                    break
                case '3':
                    cate = 'tech'
                    label = '技术'
                    break
                case '4':
                    cate = 'park'
                    label = '园区'
                    break
                default:
                    cate = 'company'
                    label = '企业'
                }
                return <a key={item.id} href={`/${cate}/${item.id}`}>
                    <span>{item.name_cn || item.other_name || item.name}</span>
                    <span className={`label ${cate}`}>{label}</span>
                </a>
            })}
        </div>
    )
}
 
export default Suggest