import React from 'react'

const Park = props => {
    const location = props.local_info.filter(l => l.level === '2')
    return (
        <div className='search-list'>
            
            <div className='sear-detail flex_box'>
                <div className="park-logo">
                    <img src={require('assets/images/search/park.svg')} alt="park.logo" />
                </div>
                {/* 内容 */}
                <dl>
                    <dd>
                        <a href={`/park/${props.id}`} rel="noopener noreferrer" target="_blank">
                            <span className='type'>地理</span>
                            <span className='name'>{props.name}</span>
                        </a>
                    </dd>
                    <dd className='intro'>{props.description}</dd>
                    <dd>
                        {
                            location[0] ? <span className='sign orange'>{location[0].name}</span> : null
                        }
                        <span className='sign orange'>{props.category_name}</span>
                    </dd>
                </dl>
            </div>
        </div>
    )
}

export default Park