import React from 'react'
import { Layout, Skeleton, Button, Select, Input } from 'antd'
import className from 'classnames'
import BreadComp from 'comps/bread' // 面包屑导航
import SideMenu from './SideMenu' //左侧导航
import { COL_TYPE } from 'utils/type'
import SwiperComponent from './SwiperComponent'
import RecommendItem from './RecommendItem'
import { withRouter } from 'react-router-dom'
import { Object } from 'core-js'
import { Tootip3 } from 'comps/tootip/Tootip'
import { getStorage } from 'utils/storage'
import { TEST_USER } from 'utils/type'

import 'styles/pages/collection.scss'

const { Content } = Layout,{ Option } = Select
const FixedBread = [{ url: '/home', title: '首页' }, { url: '', title: '专辑' }]

@withRouter
class OtherCollect extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            info: {},
            infoLoading: true,
            BreadData: [{ url: '/home', title: '首页' },{ url: '/collection/0', title: '推荐专辑' }],
            isCollectionDetail: false,
            isInputVisible: false,
            inputVal: '',
            recommendData: [],
            recommendReq: {
                page: 1,
                size: 10,
                data_type: this.props.match.params.id,
                order: null,
                name: '',
            },
            recommendDetailId: null,
            total: 0,
            empty: false,
            isFixed: false,
            leftWidth: 0,
            recommendDataload: true
        }

    }
    componentDidMount() {
        this.getRecommendData()

    }
    // id发生变化后重新发起数据请求
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            let dataType = this.props.match.params.id
            let recommendReq = Object.assign({}, this.state.recommendReq, { data_type: dataType }, { page: 1 })
            this.setState({
                recommendReq: recommendReq,
                recommendDataload: true
            }, () => {
                this.getRecommendData()
            })
        }
    }
    // 排序选择操作
    selectChange = order => {
        let recommendReq
        if (order * 1 === 2 || order * 1 === 3) {
            recommendReq = Object.assign({}, this.state.recommendReq, { order: order }, { page: 1 })
        } else {
            recommendReq = Object.assign({}, this.state.recommendReq, { order: null }, { page: 1 })
        }
        this.setState({
            recommendReq: recommendReq,
        }, () => {
            this.getRecommendData()
        })
        document.documentElement.scrollTop = 0
    }

    //查看详情
    checkDetail = id => {
        this.props.history.push({pathname:`/collection/detail/${id}`,state:{ id: id, recommendReq: this.state.recommendReq }})
    }


    //点击搜索icon
    clickSearchIcon = () => {
        if (this.state.inputVal) {
            let recommendReq = Object.assign({}, this.state.recommendReq, { name: this.state.inputVal }, { page: 1 })
            this.setState({ recommendReq }, () => {
                this.getRecommendData()
            })
        } else {
            this.setState({
                isInputVisible: !this.state.isInputVisible
            })
        }
    }

    //输入框回车
    onPressEnter = e => {
        let recommendReq = Object.assign({}, this.state.recommendReq, { name: this.state.inputVal }, { page: 1 })
        this.setState({
            recommendReq: recommendReq
        }, () => {
            this.getRecommendData()
        })
    }

    // 输入框改变内容
    inputChange = e => {
        this.setState({
            inputVal: e.target.value
        })
    }

    // 获取专辑列表
    getRecommendData = () => {
        let recommendReq = this.state.recommendReq
        this.axios.post('/collection/list', recommendReq).then(res => {
            if (res.code === 10000) {
                this.setState({ recommendData: res.data.items ? res.data.items : [], total: res.data.total, empty: true, recommendDataload: false }, () => {
                    this.windowOnScroll()
                })
            }
        })
    }

    // 获取更多专辑列表
    getMoreRecommendData = () => {
        let recommendReq = this.state.recommendReq
        this.axios.post('/collection/list', recommendReq).then(res => {
            if (res.code === 10000) {
                let recommendData = this.state.recommendData
                res.data.items && recommendData.push(...res.data.items)
                this.setState({ recommendData, total: res.data.total })

            }
        })
    }

    //加载更多
    loadMore = () => {
        let page = this.state.recommendReq.page + 1
        let recommendReq = Object.assign({}, this.state.recommendReq, { page: page })
        this.setState({
            recommendReq: recommendReq,
        }, () => {
            this.getMoreRecommendData()
        })
    }

    windowOnScroll = () => {
        let that = this
        // let dom = document.getElementsByClassName('recommend-items')[0]
        const clientWidth = document.body.clientWidth
        this.setState({
            leftWidth: (clientWidth - 1200) / 2 + 240
            // leftWidth: dom && dom.offsetLeft
        })
        window.onscroll = function () {
            let h = document.documentElement.scrollTop
            if (h > 480) {
                that.setState({
                    isFixed: true,
                })
            } else {
                that.setState({
                    isFixed: false
                })
            }
        }

    }
    render() {
        const { total, recommendData, recommendReq, empty } = this.state
        return (
            <Layout className="collect-box collect-con ">
                <BreadComp data={this.state.BreadData} />
                <SwiperComponent></SwiperComponent>
                {/* 推荐专辑列表 */}
                <Layout className='collection-con recommend-con'>
                    {/* 侧边导航 */}
                    <div className={className("sidemenu-box", {
                        'sidemenu-box-fixed': this.state.isFixed
                    })}>
                        <SideMenu current={recommendReq.data_type ? recommendReq.data_type : 0} propsKeys={'recommend'} />
                    </div>
                    <Skeleton active loading={this.state.recommendDataload}>
                        <Layout className='collect-rt' id="collectRt">
                            <Content className={className('collect-main',{
                                'recommendlist-pos': this.state.isFixed
                            })}>
                                <div className={className({
                                    'recommend-con-fixed': this.state.isFixed
                                })} style={{ left: this.state.leftWidth }}>
                                    <div className="search-select">
                                        <div className='search-select-box'>
                                            <div className="form-box">
                                                <div className="input-box">
                                                    <Input
                                                        placeholder="请输入搜索关键词"
                                                        onChange={this.inputChange}
                                                        onPressEnter={this.onPressEnter}
                                                        value={this.state.inputVal}
                                                        prefix={<i className="iconfont icon-search"></i>}
                                                    />
                                                </div>
                                                <div className="button-box">
                                                    <Button type="primary" onClick={this.clickSearchIcon} >搜索</Button>
                                                </div>
                                            </div>
                                            <div className="paixu-box">
                                                <Select style={{ width: 150, marginLeft: '30px' }} onChange={this.selectChange} bordered={true} placeholder="排序方式" dropdownClassName={className("dropdown")}>
                                                    <Option value="1">时间倒序</Option>
                                                    <Option value="2">时间正序</Option>
                                                    <Option value="3">专辑热度</Option>
                                                </Select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* 推荐专辑列表 */}
                                <section className="collect-basic">
                                    {
                                        <div className={className({
                                            'recommendlist': recommendData && recommendData.length === 0
                                        })}>
                                            {
                                                recommendData && recommendData.length === 0 && empty ? <img src={require('assets/images/collect/empty.png')} alt="" id="emptyimg" /> :
                                                    <>
                                                        {
                                                            this.state.recommendData && this.state.recommendData.map(item => {
                                                                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                                                return <a onClick={() => this.checkDetail(item.id)} href='' key={item.id}>
                                                                    <div className="recommend-items">
                                                                        <RecommendItem isDetail={false} recommendData={item}></RecommendItem>
                                                                    </div>
                                                                </a>
                                                            })
                                                        }
                                                        {
                                                            TEST_USER.includes(getStorage('level')) ? <Tootip3 page={1} aim={1} text='升级完整版，可查看全部1000+专辑'/> : <>
                                                                {
                                                                    total > recommendData.length ? <div className="load-more" onClick={this.loadMore}>加载更多</div> : null
                                                                }
                                                            </>
                                                        }
                                                    </>
                                            }
                                        </div>
                                    }

                                </section>

                            </Content>
                        </Layout>
                    </Skeleton>
                        
                </Layout>

            </Layout>

        )
    }
}
export default OtherCollect
