import React, { PureComponent } from 'react'
import { Pagination,Modal } from 'antd'
import { getStorage } from 'utils/storage'
import { TEST_USER } from 'utils/type'
import 'styles/comps/custom-page.scss'

class PageCustom extends PureComponent{
    changePage = (page,size)=>{
        const isTestUSer =  TEST_USER.includes(getStorage('level')) // 是否是测试用户
        if (!this.props.notLimit){
            window.scrollTo(0, 0) //  回到顶端
        }
        this.props.changeStatePage(page)
    }
    render(){
        const { total,currentPage,pagesize,size } = this.props
        return (
            <Pagination 
                className='custom-page'
                current={currentPage} 
                total={total} 
                // showQuickJumper
                hideOnSinglePage={true}
                onChange={this.changePage}
                pageSize={pagesize}
                showSizeChanger={false}
                size={size ? size : 'default'}
            />
        )
    }
}

export default PageCustom