import { Layout } from 'antd'
import React, { Component } from 'react'
import Nav from './common/Nav'
import 'styles/official/vippage.scss'

const list = [
    { type: '公司查看数', all: '不限', cc: '有限' },
    { type: '人才查看数', all: '不限', cc: '有限' },
    { type: '技术查看数', all: '不限', cc: '有限' },
    { type: '产业维度', all: '不限', cc: '有限' },
    { type: '园区查看数', all: '不限', cc: '有限' },
    { type: '专辑维度', all: '不限', cc: '有限' },
    { type: '公司详情', all: '不限', cc: '有限' },
    { type: '人才详情', all: '不限', cc: '有限' },
    { type: '技术详情', all: '不限', cc: '有限' },
    { type: '产业详情', all: '不限', cc: '有限' },
    { type: '园区详情', all: '不限', cc: '有限' },
    { type: '筛选结果', all: '不限', cc: '有限' },
    { type: '搜索结果', all: '不限', cc: '有限' },
    { type: '企业对比', all: '不限', cc: '无' },
    { type: '人才对比', all: '不限', cc: '无' },
    { type: '数据导出', all: '不限', cc: '无' },
    { type: '收藏', all: '不限', cc: '有限' },
    { type: '报告下载', all: '不限', cc: '无' },
]

function Vippage(props) {
    const { path } = props.match
    return (
        <div className='all-box-vip' >
            <div className='back-imgs'>
                <div className='vip-body'>
                    <h1>DT Insights·透视未来的科技本质</h1>
                    <span>适用于投资机构、科技企业、政府部门、高等院校、园区、医院、科学家等</span>
                    <span>试用版与完整版</span>
                    <span>为了满足不同客户的需求，DT Insights数据库为您提供科技产业内多维度数据及个性化功能，包含产业透视、企业分析、人才挖掘、自定义榜单及科技要素评价等。</span>
                    <span>咨询电话（李经理）：15044161849</span>
                </div>
            </div>
            <div className='vip-list'>
                <table className='vip-table'>
                    <tr>
                        <th></th>
                        <th>完整版</th>
                        <th>试用版</th>
                    </tr>
                    {
                        list.map(item => {
                            return <tr>
                                <td>{item.type}</td>
                                <td>{item.all}</td>
                                <td>{item.cc}</td>
                            </tr>
                        })
                    }
                </table>
            </div>
        </div>
    )
}

export default Vippage