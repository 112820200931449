import React from 'react'
import { Breadcrumb } from 'antd'
import moment from 'moment'
import 'styles/board/policy.scss'
class PolicyDetail extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            policy: {

            },
            loaded: false,
            appendix: []
        }
    }
    componentDidMount() {
        this.axios.post('/starmarket/policyinfo', { id: this.props.match.params.id })
            .then(res => this.setState({ policy: res.data, loaded: true }))
    }

    render() {
        return (
            <div className="policy-page">
                <Breadcrumb separator=">">
                    <Breadcrumb.Item><a href="/home">首页</a></Breadcrumb.Item>
                    <Breadcrumb.Item><a href="/board/general">科创板</a></Breadcrumb.Item>
                    <Breadcrumb.Item>政策法规</Breadcrumb.Item>
                </Breadcrumb>
                {this.state.loaded &&
                    <div className="content">

                        <div className="head">
                            <div className="title">{this.state.policy.title}</div>
                            <div className="date">{moment(this.state.policy.notice_date * 1000).format('YYYY-MM-DD')}</div>
                        </div>
                        <div className="main-body" dangerouslySetInnerHTML={{ __html: this.state.policy.content }}></div>
                        {this.state.policy.file_name.length !== 0 &&
                            <div className="append">
                                附件：{this.state.policy.file_name.map((item, index) => <a className="down" href={this.state.policy.qiniu_url[index]}>{item}</a>)}
                            </div>
                        }
                    </div>
                }
            </div>
        )
    }
}


export default PolicyDetail
