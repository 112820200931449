import React, { useState } from 'react'
import EchartReact from 'comps/Sign/EchartReact'

export default function Trend (props) {
    return (<EchartReact
        id={props.id + 'tech'}
        options={getOption(props.company,props.paper)}
        style={{ width: '140px', height: '84px' }}
    />)
}

function getOption(company,paper) {
    let year  = Object.keys(company)
    return {
        xAxis: {
            show: false,
            type: 'category',
            data: year
        },
        yAxis: {
            show: false,
            type: 'value'
        },
        series: [
            {
                data: Object.values(paper),
                type: 'line',
                name: '论文数',
                smooth: true,
                symbol: 'none',
                hoverAnimation: false
            },
            {
                data: Object.values(company),
                type: 'line',
                name: '企业成立数',
                smooth: true,
                symbol: 'none',
                hoverAnimation: false
            }
        ],
        color: ['#826af9', '#ffe700'],
        backgroundColor: '#F7F9FE'
    }
}