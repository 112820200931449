import React, { useEffect, useState } from 'react'
import { Layout } from 'antd'
import Nav from './common/Nav'
// import Footer from 'comps/navbar/Footer'
import NewFooter from './common/NewFooter'
import 'styles/official/about.scss'
import { aboutUsList } from 'utils/columns'

function About() {
    const [activeIndex, setAvtiveIndex] = useState(0)
    const [isFlag, setIsFlag] = useState(false)
    const handleScroll = ()=>{
        const moduleOne = document.getElementById('moduleOne')
        const moduleTwo = document.getElementById('moduleTwo')
        const moduleThree = document.getElementById('moduleThree')
        if (moduleThree) {
            // console.log('document.documentElement.scrollTop - moduleThree.offsetTop---------',document.documentElement.scrollTop, moduleThree.offsetTop, moduleOne.offsetHeight, moduleTwo.offsetHeight)
            if (document.documentElement.scrollTop >= moduleOne.offsetHeight) {
                let num = (document.documentElement.scrollTop - moduleOne.offsetHeight) / 220 < 1 ? 1 :  Math.floor((document.documentElement.scrollTop - moduleOne.offsetHeight) / 220)
                if (num < 4) {
                    setAvtiveIndex(num > activeIndex ? num : activeIndex)
                } else {
                    clearInterval(timer)
                    window.onscroll = null
                }
                // console.log('activeIndex------------------', num, activeIndex)
            }
            
        }
             
    }
    
    const timer = setTimeout(() => {
        window.onscroll = handleScroll
    })

    const imgLoad = e => {
        console.log('e-------------', e)
        const imgDom = new Image()
        imgDom.src = 'https://image.sciphi.cn/dt_insights/about-us/about-banner.jpg'
        // 图片加载完成使用正常的图片
        imgDom.onload = function () {
            setTimeout(() => {
                setIsFlag(true)
            }, 500)
            // setSrc(props.src)
            console.log('------------------')
        }
    }
    useEffect(() => {
        // changeColor()
    }, [])
    const timeStamp = new Date().getTime()
    return (
        <Layout className='all-box-about'>
            <Layout className='all-box-t'>
                <Nav />
                <img src={'https://image.sciphi.cn/dt_insights/about-us/about-banner.jpg?v=' +  timeStamp} alt='banner' className="main-banner" onLoad={imgLoad} style={{display: isFlag ? 'block' : 'block'}}/>
                <section className='module-one' id='moduleOne'>
                    {
                        isFlag ? <div className='one-body'>
                            <span><img src={'https://image.sciphi.cn/dt_insights/about-us/ball.png?v=' +  timeStamp} alt='' className="ball"/></span>
                            <span><img src={'https://image.sciphi.cn/dt_insights/about-us/ball.png?v=' +  timeStamp} alt='' className="ball"/></span>
                            <span><img src={'https://image.sciphi.cn/dt_insights/about-us/ball.png?v=' +  timeStamp} alt='' className="ball"/></span>
                            <span><img src={'https://image.sciphi.cn/dt_insights/about-us/ball.png?v=' +  timeStamp} alt='' className="ball"/></span>
                            {/* <img src={'https://image.sciphi.cn/dt_insights/about-us/ball.png?v=' +  timeStamp} alt='' className="ball"/>
                            <img src={'https://image.sciphi.cn/dt_insights/about-us/ball.png?v=' +  timeStamp} alt='' className="ball"/>
                            <img src={'https://image.sciphi.cn/dt_insights/about-us/ball.png?v=' +  timeStamp} alt='' className="ball"/> */}
                            <div className='animate__animated animate__fadeInRight animate__delay-1s'>
                                <div className="title">
                            DT Insights <br/> 透视未来的科技本
                                </div>
                                <div className="intro">创新的科技数据智能分析和研究平台</div>
                            </div>
                        </div> : null
                    }
                    
                </section>
            </Layout>
            <section className='module-two' id='moduleTwo'>
                {
                    isFlag ? <div className='two-body'>DT Insights诞生于 2020 年，以结构化的科技要素信息为基础，以多维度关系型数据平台为桥梁，涵盖了科技产业的全要素数据，贯穿产业、人才、企业、知识、园区、政策等多维度数据，协同络绎学者名片、络绎论文数据、络绎学术系列产品形成一体化的学术和科技数据矩阵，帮助企业、机构、政府、高校实现人才资源挖掘与科技企业数据集成，提供动态实时科技要素评价体系和聚焦全球视野的学术交流体系，构建科技产业的全景服务。</div> : null
                }
            </section>
            <section className='module-three' id='moduleThree'>
                <div className='three-body'>
                    {
                        aboutUsList.map((item, index) => (
                            item.imgPosition === 'right' &&  activeIndex >= index + 1  ? <div className='item animate__animated animate__fadeInUp' key={item.title}>
                                <div>
                                    <div className="title">{item.title}</div>
                                    <div className="profile">{item.profile}</div>
                                </div>
                                <img src={item.img + '?v=' +  timeStamp} alt='' className="right-img"/>
                            </div> : item.imgPosition === 'left' && activeIndex >= index + 1 ? <div className='item left-item animate__animated animate__fadeInUp' key={item.title}>
                                <img src={item.img + '?v=' +  timeStamp} alt='' className="left-img"/>
                                <div>
                                    <div className="title">{item.title}</div>
                                    <div className="profile">{item.profile}</div>
                                </div>
                            </div> : null
                        ))
                    }
                </div>
            </section>
            <section className='module-four'>
                <div className='four-body'>
                    <div className="title">联系我们</div>
                    <div className='info'>
                        <div className='left'>
                            <div>
                                <img src={'https://image.sciphi.cn/dt_insights/about-us/email.png?v=' +  timeStamp} alt='' className=""/> 
                            limengqiang@deeptechchina.com
                            </div>
                            <div>
                                <img src={'https://image.sciphi.cn/dt_insights/about-us/base.png?v=' +  timeStamp} alt='' className=""/> 
                            北京市朝阳区亮马河大厦2座10层1002室
                            </div>
                        </div>
                        <div className='right'>
                            <img src={'https://image.sciphi.cn/dt_insights/wechat.jpg?v=' +  timeStamp} alt='' className=""/> 
                        关注DT Insights
                        </div>
                    </div>
                </div>
            </section>
            <NewFooter />
        </Layout>
    )
}

export default About