import React, { Component } from 'react'
import EchartReact from 'comps/Sign/EchartReact'
import { COLORS } from 'utils/type'

export default function PieChart(props) {

    return (<EchartReact
        id={props.id}
        options={getOption(props.items, props.title)}
        style={{ width: props.width + 'px', height: props.height + 'px' }}
    />)
}

function getOption(data, title) {
    if (typeof data === 'undefined') {
        data = []
    }
    let des = data.map(item => {
        let desi = {}
        desi.value = item.num
        desi.name = item.name
        return desi
    })

    return {
        title: {
            text: title,
            left: '20%',
            textStyle: {
                fontSize: 14,
                fontFamily: 'PingFangSC- Regular, PingFang SC',
                fontWeight: 400,
                color: '#333333'
            }
        },
        tooltip: {
            trigger: 'item',
            formatter: '{b}  {c} 篇'
        },
        legend: {
            show: true,
            orient: 'vertical',
            right: 30,
            bottom: 0,
            icon: 'circle',
            itemHeight: 10,
            itemWidth: 10,
            data: des
        },
        series: [
            {
                type: 'pie',
                radius: '70%',
                center: ['40%', '60%'],
                label: { show: false },
                hoverAnimation: false,
                data: des
            }
        ],
        color: COLORS
    }
}