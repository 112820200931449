import React,{ Component } from 'react'
import { Col, Skeleton } from 'antd'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { selectEffectItem,clearEffectItem } from '@redux/effect/action'
import SelectField from './SelectField'
import axios from 'libs/axios'

@connect(state => state, { selectEffectItem,clearEffectItem })
@withRouter
class SortLists extends Component{
    state = {
        localID : this.props.match.params.city_id,
        fieldID : this.props.match.params.direction_id,
        title : '',
        lists : [],
        page : 1, sum : 0
    }
    componentDidMount(){
        this.getSortLists() // 获取排序列表数据
        this.props.clearEffectItem() // 清除选中的人物
    }
    // 获取排行榜数据列表
    getSortLists = () => {
        const { fieldID, localID,lists, page } = this.state
        axios.post('/expert/sort',{ field:fieldID, local:localID, page }).then(res => {
            const { code,data } = res
            if (code === 10000) {
                document.title = `${data.local_title}${data.field_title}`
                this.setState({
                    lists : lists.concat(data.items),
                    sum : data.count,
                    page : page + 1,
                    title : `${data.local_title}${data.field_title}`
                })
            }
        })
    }
    // 选中某个人物
    selectedItem = id =>{
        this.props.setFatherTitleState(false)
        this.props.selectEffectItem(id)
    }
    render(){
        const { effectSelectedID } = this.props
        const { title, lists, sum } = this.state
        return <Col span={8} className='effect-lf-list'>
            <SelectField title={title}/>
            {/* 人物排行列表 */}
            <Skeleton active paragraph={{ rows: 20 }} loading={lists.length === 0}>
                <ul className='effect-person-ul'>
                    {
                        lists.map((list,i) => {
                            return <li className={effectSelectedID !== list.id ? 'flex_box' : 'flex_box active'}
                                key={list.id}
                                onClick={()=>this.selectedItem(list.id)}>
                                {/* 前三名使用不同的icon 后面的使用数字 */}
                                <span className={list.sort > 3 ? 'effect-rank' : 'effect-rank rank-top'}>{list.sort}</span>
                                <dl>
                                    <dd className='flex_box'>
                                        <span className='name'>{list.name_cn || list.name_en}</span>
                                        <span className='pos' title={list.comany_name}>{list.comany_name}</span>
                                        <span className='pos' title={list.position}>{list.position}</span>
                                    </dd>
                                    <dd className='labels'>
                                        {
                                            list.labels.map( l => <span key={l.id}>{l.name}</span>)
                                        }
                                    </dd>
                                </dl>
                            </li>
                        })
                    }
                    <p onClick={sum > lists.length ? this.getSortLists : null} className='more-btn'>{
                        sum > lists.length ? '加载更多' : '已全部加载完毕'
                    }</p>
                </ul>
            </Skeleton>
        </Col>
    }
}

export default SortLists