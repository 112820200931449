import React from 'react'
import Paper from './Paper'
import Quote from './Quote'
import Pantent from './Patent'
import Legal from './Legal'

export default function Scholar(props) {
    return (
        <div className="statistics">
            {isok(props.paperPercent) && props.paperPercent.total_num !== 0 &&
                <div className="item">
                    <div className="head">期刊发文数占比</div>
                    <Paper id="paper" {...props.paperPercent} />
                </div>
            }
            {isok(props.Hindex) &&
                <div className="item">
                    <div className="head">H-index指数排名</div>
                    <Quote id="quote" data={props.Hindex} />
                </div>
            }
            {isok(props.patentYear) &&
                <div className="item">
                    <div className="head">历年专利情况</div>
                    <Pantent id="count" data={props.patentYear} />
                </div>
            }
            {isok(props.patentLegal) &&
                <div className="item">
                    <div className="head">专利在审情况</div>
                    <Legal id="article" data={props.patentLegal} />
                </div>
            }
        </div>
    )
}

function isok(param) {
    let flag = true
    if (param instanceof Array && param.length === 0) {
        flag = false
    }
    if (param instanceof Object) {
        flag = false
        for (let [k,v] of Object.entries(param)){
            if ( v  && k !== 'id') {
                flag = true
            }
        }
    }
    return flag
}