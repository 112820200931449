import React, { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom"
import BreadComp from 'comps/bread'
import VirtalListHOC from 'comps/common/VirtualList'
import { EmptyComp } from 'comps/common/EmptyComp'
import axios from 'libs/axios'
import moment from 'moment'

import 'styles/reserch/list.scss'

const BreadData = [{ url: '/home', title: '首页' }, { url: '', title: '研究' }]

const ResearchDetail = item => {
    let history = useHistory()
    const selectItem = id => {
        history.push(`/research/${id}`)
    }

    return <div onClick={() => selectItem(item.id)} key={item.id} className="arti">
        <div className="left">
            <div className="head">{item.title}</div>
            <div className="aut_time">
                <span className='aut'>{moment.unix(item.start_time).format("YYYY-MM-DD HH:mm")}</span>
                <span>{item.authorInfo.length === 0 ? '' : item.authorInfo.map((i, v) => { return '' + i.name })}</span>
            </div>
            <div className="body">{item.summary}</div>
        </div>
        <div className="right">
            {item.img && <img src={item.img} alt="header" />}
        </div>
    </div>
}

const VirtalResearchList = VirtalListHOC(ResearchDetail)

function List(props) {
    const [list, setList] = useState([])

    useEffect(() => {
        document.title = '研究'
        axios.post('/study').then(res => {
            const { code, data: { count, items } } = res
            if (code === 10000) {
                setList(items)
            }
        })
    }, [])

    return (
        <div className="reserch">
            <BreadComp className='bread' data={BreadData} />
            {
                list.length !== 0 ?  <VirtalResearchList 
                    data={list} scorllBoxHeight={1000} itemHeight={200} bufferCount={6}
                    className='research-lists' 
                /> 
                    : <div className='box_shadow'><EmptyComp /></div>
            }
        </div>
    )
}

export default List