import React , { useState } from 'react'

// 人物头像加载失败 显示默认头像
function LoadImg(props) {
    const [reveal, setreveal] = useState(false)
    if (!props.src || reveal) {
        return (<div className="df">
            <img
                src={require('assets/images/company/no-avatar.svg')}
                alt="avatar" className="default"
            />
        </div>)

    } else {
        return (<div className="df">
            <img
                src={props.src}
                className="avatar"
                onError={() => { setreveal(true) }}
                alt="avatar"
            />
        </div>)
    }
}

export default LoadImg