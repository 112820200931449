import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { toMoney, makeFriendlyCH } from 'utils'
import { INVEST_TYPE } from 'utils/type'
import 'styles/utils/columns.scss'

// 行业地理分布表头olumns
export const IndustryGeoColumns = [
    {
        title: '城市',
        dataIndex: 'city',
        key: 'city',
        render: text => text
    }, {
        title: '企业数量',
        dataIndex: 'all',
        key: 'all',
        render: text => text
    }, {
        title: '上市企业数量',
        dataIndex: 'up',
        key: 'up',
        render: text => text
    }, {
        title: '融资事件数量',
        dataIndex: 'invest',
        key: 'invest',
        render: text => text
    }, {
        title: '并购事件数量',
        dataIndex: 'merger',
        key: 'merger',
        render: text => text
    }
]

// 行业的投资趋势
// 投资趋势
export const InvestTrend = [
    {
        title: '年份',
        dataIndex: 'year',
        key: 'year',
        render: text => text
    }, {
        title: '类型',
        dataIndex: 'type',
        key: 'type',
        render: text => text
    }, {
        title: '金额（万人民币）',
        dataIndex: 'money',
        key: 'money',
        render: text => text
    }, {
        title: '数量',
        dataIndex: 'number',
        key: 'number',
        render: text => text
    }
]

// 轮次分布
export const RoundSpread = [
    {
        title: '年份',
        dataIndex: 'year',
        key: 'year',
        render: text => text
    }, {
        title: '轮次',
        dataIndex: 'type',
        key: 'type',
        render: text => text
    }, {
        title: '金额（万人民币）',
        dataIndex: 'money',
        key: 'money',
        render: text => text
    }, {
        title: '数量',
        dataIndex: 'number',
        key: 'number',
        render: text => text
    }
]

// 融资分布
export const FinanceSpread = [
    {
        title: '被投轮次',
        dataIndex: 'type',
        key: 'type',
        render: text => text
    }, {
        title: '数量',
        dataIndex: 'number',
        key: 'number',
        render: text => text
    }, {
        title: '百分比',
        dataIndex: 'percent',
        key: 'percent',
        render: text => text
    }
]

// 退出趋势
export const OutTrend = [
    {
        title: '年份',
        dataIndex: 'year',
        key: 'year',
        render: text => text
    }, {
        title: '类型',
        dataIndex: 'type',
        key: 'type',
        render: text => text
    }, {
        title: '退出金额（元）',
        dataIndex: 'money',
        key: 'money',
        render: text => text
    }, {
        title: '数量',
        dataIndex: 'number',
        key: 'number',
        render: text => text
    }
]

// 投退对比
export const InvestOut = [
    {
        title: '年份',
        dataIndex: 'year',
        key: 'year',
        render: text => text
    }, {
        title: '类型',
        dataIndex: 'type',
        key: 'type',
        render: text => text
    }, {
        title: '投资金额（万元）',
        dataIndex: 'invest_money',
        key: 'invest_money',
        render: text => text
    }, {
        title: '退出金额（元）',
        dataIndex: 'out_money',
        key: 'out_money',
        render: text => text
    }, {
        title: '投资数量',
        dataIndex: 'invest_num',
        key: 'invest_num',
        render: text => text
    }, {
        title: '退出数量',
        dataIndex: 'out_num',
        key: 'out_num',
        render: text => text
    }
]
// 上市公司
export const marketListColumns = page => {
    return ([
        {
            title: <div className='market_tit'>序号</div>,
            width: '20%',
            render: (text, record, index) => {
                return <div className='market_con'>{10 * (page - 1) + (index + 1)}</div>
            }
        },
        {
            title: <div className='market_tit'>企业名称</div>,
            width: '20%',
            dataIndex: 'other_name',
            key: 'other_name',
            render: (text, record) => <Link className='market_con' to={`/company/${record.id}`}>{text}</Link>
        }, {
            title: <div className='market_tit'>股票代码</div>,
            width: '20%',
            dataIndex: 'tickers',
            key: 'tickers',
            render: text => {
                return <div className='market_con'>{text.A || text.U || text.G}</div>
            }
        }, {
            title: <div className='market_tit'>成立时间</div>,
            width: '20%',
            dataIndex: 'found_date',
            key: 'found_date',
            render: text => {
                return <div className='market_con'>{text}</div>
            }
        }, {
            title: <div className='market_tit'>所在行业</div>,
            width: '20%',
            dataIndex: 'industry_name',
            key: 'industry_name',
            render: text => {
                return <div className='market_con'>{text}</div>
            }
        }, {
            title: <div className='market_tit'>当前市值</div>,
            width: '20%',
            dataIndex: 'market_caps',
            key: 'market_caps',
            render: text => {
                if (text.A) { return <div className='market_con'>{makeFriendlyCH(text.A) + '元'}</div> }
                if (text.U) { return <div className='market_con'>{makeFriendlyCH(text.U) + '元'}</div> }
                if (text.G) { return <div className='market_con'>{makeFriendlyCH(text.G) + '元'}</div> }
            }
        }
    ])
}


// 公司筛选列表
export const CompanyListColumns = [
    {
        title: <div className='company-tit'>企业名称</div>,
        width: '20%',
        dataIndex: 'other_name',
        key: 'other_name',
        // render:(text,record) => <Link to={`/company/${record.id}`}>{text}</Link>
        render: (text, record) => {
            return <Link className='company-name' to='' onClick={e => {
                e.preventDefault()
                window.open(`/company/${record.id}`)
            }} >{text}</Link>
        }
    },
    // {
    //     title: '产业',
    //     width:'22%',
    //     dataIndex: 'domain',
    //     key: 'domain',
    //     render:(text,record) => {
    //         return record.domainInfo.map(d => <span style={{ display:'block',lineHeight:1.5 }}>
    //             {d.name}
    //         </span>)
    //     }
    // },
    {
        title: <div className='company-tit'>TI指数</div>,
        dataIndex: 'dt',
        key: 'dt',
        render: text => {
            return <div className='company-name'>{text}</div>
        }
    }, {
        title: <div className='company-tit'>轮次</div>,
        dataIndex: 'last_round_name',
        key: 'last_round_name',
        render: text => {
            return <div className='company-name'>{text}</div>
        }
    }, {
        title: <div className='company-tit'>地区</div>,
        dataIndex: 'area',
        key: 'area',
        render: (text, record) => {
            return <div className='company-name'>{record.local_info.length > 0 && record.local_info[0].shortname}</div>
        }
    }, {
        title: <div className='company-tit'>成立时间</div>,
        dataIndex: 'found_date',
        key: 'found_date',
        render: text => {
            return <div className='company-name'>{text}</div>
        }
    }, {
        title: <div className='company-tit'>行业</div>,
        dataIndex: 'industry_name',
        key: 'industry_name',
        render: text => {
            return <div className='company-name'>{text}</div>
        }
    }
]


// 公司详情里融资table
// 融资的表头
export const InvestColumns = [
    {
        title: '时间',
        dataIndex: 'date',
        key: 'date',
        render: (text, record, index) => {
            return moment(text * 1000).format('YYYY-MM-DD')
        },
    }, {
        title: '轮次',
        dataIndex: 'round',
        key: 'round',
        render: text => text
    }, {
        title: '金额',
        dataIndex: 'money',
        key: 'money',
        render: (text, record) => {
            // t_amount 的值存在两种情况：number === 0 ? 另外字段 : number
            // 另外字段 === '未透露'  ? '未透露' ： 另外字段 + 币种
            let money_num
            if (record.t_amount === 0) {
                money_num = record.f_amount === '未透露' ? '未透露' : record.f_amount + record.currency
            } else {
                money_num = toMoney(record.t_amount) + '万' + record.currency
            }
            return money_num
        },
    }, {
        title: '股权',
        dataIndex: 'share',
        key: 'share',
        render: (text, record, index) => {
            return text === 0 ? '未透露' : `${text}%`
        },
    }, {
        title: '投资方',
        width: '30%',
        dataIndex: 'investor',
        key: 'investor',
        render: (text, record, index) => {
            let investor = []
            for (let i of record.investeorInfos) {
                investor.push(i.name)
                // if ( investor.length > 2 ){
                //     investor.push('...')
                //     break
                // }
            }
            return investor.join('、')
        },
    }
]
// 并购的表头
export const MergerColumns = [
    {
        title: '时间',
        dataIndex: 'date',
        key: 'date',
        render: text => {
            return moment(text * 1000).format('YYYY-MM-DD')
        },
    }, {
        title: '并购类型',
        dataIndex: 'type',
        key: 'type',
        render: text => {
            return text === 1 ? '并购' : '合并'
        }
    }, {
        title: '金额',
        dataIndex: 'money',
        key: 'money',
        render: (text, record) => {
            let money_num
            if (record.t_amount === 0) {
                money_num = record.f_amount === '未透露' ? '未透露' : record.f_amount + record.currency
            } else {
                money_num = toMoney(record.t_amount) + '万' + record.currency
            }
            return money_num
        },
    }, {
        title: '股权',
        dataIndex: 'share',
        key: 'share',
        render: (text, record, index) => {
            return text === 0 ? '未透露' : `${text}%`
        },
    }
]
// 投资人的表头
export const InvestorColumns = [
    {
        title: '投资人',
        dataIndex: 'name',
        key: 'name',
        render: text => text
    }, {
        title: '机构类型',
        dataIndex: 'type',
        key: 'type',
        render: text => {
            return INVEST_TYPE[`${text}`]
        }
    }, {
        title: '投资时间',
        dataIndex: 'items',
        key: 'items',
        className: 'investor-col',
        render: (text, record) => {
            return <span>
                {text.map(t => {
                    return <span key={t.date}>{moment(t.date * 1000).format('YYYY-MM-DD')}</span>
                })}
            </span>

        },
    }, {
        title: '投资轮次·金额',
        dataIndex: 'round_money',
        key: 'round_money',
        className: 'investor-col',
        render: (text, record) => {
            return <span>
                {record.items.map(t => {
                    let money_num
                    if (t.t_amount === 0) {
                        money_num = t.f_amount === '未透露' ? '未透露' : t.f_amount + t.currency
                    } else {
                        money_num = toMoney(t.t_amount) + '万' + t.currency
                    }
                    return <span key={t.date}>{t.round} {money_num}</span>
                })}
            </span>
        },
    }
]


// 我的收藏公司列表
export const MyCollectCompany = [
    {
        title: '序号',
        width: '12%',
        dataIndex: 'key',
        key: 'key',
        render: (text, record, index) => record.num
    },
    {
        title: '企业名称',
        width: '20%',
        dataIndex: 'object_name',
        key: 'object_name',
        render: (text, record) => {
            return <Link to={record.is_normal === 1 ? `/company/${record.object_id}` : undefined}>{text}</Link>
        }
        // render:(text,record) => {
        //     return <Link to='' onClick={e =>{
        //         e.preventDefault()
        //         window.open(`/company/${record.object_id}`)
        //     }} >{text}</Link>
        // }
    }, {
        title: '简介',
        width: '30%',
        dataIndex: 'one_line',
        key: 'one_line',
    }, {
        title: 'TI指数',
        width: '15%',
        dataIndex: 'dt',
        key: 'dt',
    }
    // table折行，产品建议暂不显示轮次及投资方字段
    // , {
    //     title: '轮次',
    //     width: '10%',
    //     dataIndex: 'round',
    //     key: 'round',
    //     render: (text, record) => record.invest.round
    // }, {
    //     title: '投资方',
    //     width: '20%',
    //     dataIndex: 'investors',
    //     key: 'investors',
    //     render: (text, record) => {
    //         const invest = record.invest
    //         return Object.keys(invest).length > 0 ? Object.values(invest.investor).join('、') : ''
    //     }
    // }
]

// 我的收藏人物列表
export const MyCollectExpert = [
    {
        title: '序号',
        width: '12%',
        dataIndex: 'key',
        key: 'key',
        render: (text, record, index) => record.num
    },
    {
        title: '人物名称',
        width: '15%',
        dataIndex: 'object_name',
        key: 'object_name',
        render: (text, record) => {
            return <Link to={record.is_normal === 1 ? `/person/detail/${record.object_id}` : undefined}>{text}</Link>
        }
    }, {
        title: '地区',
        width: '15%',
        dataIndex: 'area',
        key: 'area',
        render: (text, record) => record.location_name || record.abroad_location || '-'
    }, {
        title: '任职机构',
        width: '20%',
        dataIndex: 'organization',
        key: 'organization',
        render: (text, record) => record.employment ? record.employment.object_name : '-'
    }, {
        title: '职位',
        width: '15%',
        dataIndex: 'position',
        key: 'position',
        render: (text, record) => record.employment ? record.employment.position : '-'
    }
]

// 我的收藏技术列表
export const MyCollectTech = [
    {
        title: '序号',
        width: '8%',
        dataIndex: 'key',
        key: 'key',
        render: (text, record, index) => record.num
    }, {
        title: '技术名称',
        width: '20%',
        dataIndex: 'object_name',
        key: 'object_name',
        render: (text, record) => <Link to={`/tech/${record.object_id}`}>{text}</Link>
    }, {
        title: '首次出现时间',
        width: '20%',
        dataIndex: 'date',
        key: 'date',
    }, {
        title: '技术发明者',
        width: '20%',
        dataIndex: 'inventor',
        key: 'inventor',
    }, {
        title: '应用领域',
        width: '20%',
        dataIndex: 'application',
        key: 'application',
    }
]

// 我的收藏园区列表
export const MyCollectPark = [
    {
        title: '序号',
        width: '12%',
        dataIndex: 'key',
        key: 'key',
        render: (text, record, index) => record.num
    }, {
        title: '园区名称',
        width: '25%',
        dataIndex: 'object_name',
        key: 'object_name',
        // render:(text,record) => <Link to={`/park/${record.object_id}`}>{text}</Link>
        render: (text, record) => {
            return <Link to='' onClick={e => {
                e.preventDefault()
                window.open(`/park/${record.object_id}`)
            }} >{text}</Link>
        }
    }, {
        title: '级别',
        width: '15%',
        dataIndex: 'level',
        key: 'level',
    }, {
        title: '类型',
        width: '25%',
        dataIndex: 'category',
        key: 'category',
    }, {
        title: '成立时间',
        width: '20%',
        dataIndex: 'est_date',
        key: 'est_date',
    }
]


// 园区列表页面
export const ListColumns = [
    {
        title: <div className='tit'>园区名称</div>,
        width: '20%',
        dataIndex: 'name',
        key: 'name',
        // render:(text,record) => <Link to={`/park/${record.id}`}>{text}</Link>
        render: (text, record) => {
            return <Link className='tab-name' to='' onClick={e => {
                e.preventDefault()
                window.open(`/park/${record.id}`)
            }} >{text}</Link>
        }
    }, {
        title: <div className='tit'>成立时间</div>,
        dataIndex: 'est_date',
        key: 'est_date',
        render: text => <div className='tab-other'>{text === 0 ? '--' : moment(text * 1000).format('YYYY年MM月DD')}</div>
    }, {
        title: <div className='tit'>所在城市</div>,
        dataIndex: 'location_name',
        key: 'location_name',
        render: text => <div className='tab-other'>{text}</div>
    }, {
        title: <div className='tit'>园区级别</div>,
        dataIndex: 'level_name',
        key: 'level_name',
        render: text => <div className='tab-other'>{text === '' ? '--' : text}</div>
    }, {
        title: <div className='tit'>园区类型</div>,
        dataIndex: 'category_name',
        key: 'category_name',
        render: text => <div className='tab-other'>{text}</div>
    }
]

// 招商工作台
export const PlatformColumns = [
    {
        title: <div className='zs-tit'>企业名称</div>,
        width: '20%',
        dataIndex: 'other_name',
        key: 'other_name',
        // render:(text,record) => <Link to={`/company/${record.id}`}>{text}</Link>
        render: (text, record) => {
            return <Link className='zs-name' to='' onClick={e => {
                e.preventDefault()
                window.open(`/company/${record.id}`)
            }} >{text}</Link>
        }
    },
    // {
    //     title: '产业',
    //     width:'20%',
    //     dataIndex: 'industry',
    //     key: 'industry',
    //     render:(text,record) => {
    //         let domainArr = []
    //         record.domainInfo.forEach(item => domainArr.push(item.name))
    //         return domainArr.join(',')
    //     }
    // },
    {
        title: <div className='zs-tit'>TI指数</div>,
        dataIndex: 'dt',
        key: 'dt',
        render: text => <span className='zs-con'>{text}</span>
    }, {
        title: <div className='zs-tit'>轮次</div>,
        dataIndex: 'last_round_name',
        key: 'last_round_name',
        render: text => <span className='zs-con'>{text}</span>
    }, {
        title: <div className='zs-tit'>地区</div>,
        dataIndex: 'area',
        key: 'area',
        render: (text, record) => {
            return <span className='zs-con'>{record.local_info.length > 0 && record.local_info[0].shortname}</span>
        }
    }, {
        title: <div className='zs-tit'>成立时间</div>,
        dataIndex: 'found_date',
        key: 'found_date',
        render: text => <span className='zs-con'>{text}</span>
    }, {
        title: <div className='zs-tit'>行业</div>,
        dataIndex: 'industry_name',
        key: 'industry_name',
        render: text => <span className='zs-con'>{text}</span>
    }
]

// 园区历史订单
export const historyColums = [
    {
        title: '订单序号',
        dataIndex: 'order_id',
        key: 'order_id'
    }, {
        title: '下单时间',
        dataIndex: 'create_time',
        key: 'create_time',
        render: text => moment(text * 1000).format('YYYY-MM-DD')
    }, {
        title: '公司',
        width: '20%',
        dataIndex: 'company_title',
        key: 'company_title'
    }, {
        title: '招商状态',
        dataIndex: 'status',
        key: 'status'
    }
]

// 临时首页
export const NavList = [
    // { id: 99, title: '络绎学术', link: 'https://loyioa.com/' },
    { id: 107, title: '首页', link: '/' },
    { id: 100, title: '产品与服务', link: '/demand' },
    { id: 103, title: '数据化与认知', link: '/digitization' },
    // { id: 101, title: '研究与报告', link: '/report' },
    // { id: 105, title: '试用与购买', link: '/regist' },
    { id: 106, title: '关于我们', link: '/about' },
]

// 科创板产业图谱中的表格列表
export const GraphList = [
    { width: '6%', title: '序号', dataIndex: 'order', key: 'order', render: (t, r, i) => i + 1 },
    { title: '企业名称', dataIndex: 'name', key: 'name', render: (text, record) => <a href={`/company/${record.id}`}>{text}</a> },
    { width: '10%', title: '产业链数量', dataIndex: 'num', key: 'num' },
    { width: '10%', title: '上市状态', dataIndex: 'status', key: 'status' },
    { title: '所在版块', dataIndex: 'board', key: 'board' },
    { width: '10%', title: 'DT指数', dataIndex: 'dt', key: 'dt' },
    { width: '12%', title: '市值', dataIndex: 'market_cap', key: 'market_cap', render: (text, record) => makeFriendlyCH(text) + '元' },
    { width: '10%', title: '最近财年收入', dataIndex: 'total', key: 'total', render: (text, record) => makeFriendlyCH(text) + '元' },
    { title: 'PE', dataIndex: 'pe', key: 'pe' },
    { title: 'PB', dataIndex: 'pb', key: 'pb' },
]

// 科创板申报企业的表格列表
export const GroupList = [
    { title: '序号', dataIndex: 'order', key: 'order', render: (t, r, i) => i + 1 },
    {
        title: '公司',
        dataIndex: 'name',
        key: 'name',
        render: (text, record) => {
            return record.is_normal === 1 ? <a href={`/company/${record.company_id}`}>{text}</a> : <span>{text}</span>
        }
    },
    {
        title: '审核状态',
        dataIndex: 'star_progress',
        key: 'star_progress'
    },
    {
        title: '更新时间',
        dataIndex: 'update_time',
        key: 'update_time',
        render: (t, r, i) => moment(t * 1000).format('YYYY年MM月DD日')
    },
    // {
    //     title: '财务数据',
    //     dataIndex: 'financial',
    //     key: 'financial',
    //     render: (text, record) => {
    //         return record.is_stock > 0 ? <a href={`/company/stock/${record.company_id}`}>查看</a> : <span>-</span>
    //     }
    // }
]

// 产品与服务
export const demandList = [
    {
        type: '科创人才',
        type_icon: 'https://image.sciphi.cn/dt_insights/demand/type1.svg',
        intro: ['看到、看懂、看远', '资源链接、快速落地'],
        info: [
            '科研成果传播，科研地位确立',
            '转化落地资源',
            '人才成长',
            '… …'
        ]
    },
    {
        type: '创新企业',
        type_icon: 'https://image.sciphi.cn/dt_insights/demand/type2.svg',
        intro: ['巩固科技布局', '持续科技投入'],
        info: [
            '寻找下一阶段增长曲线、营收新增长点寻找领军技术人才并引入',
            '寻找创新型技术，积极合作',
            '提升科技品牌科技影响力，通过专业圈层二次传播',
            '… …'
        ]
    },
    {
        type: '政府',
        type_icon: 'https://image.sciphi.cn/dt_insights/demand/type3.svg',
        intro: ['触达产学研资源', '预先布局、自然落子'],
        info: [
            '产业布局合理化',
            '扩大链接高新高潜力项目',
            '提升科技人才、创新项目城市吸引力',
            '… …'
        ]
    },
    {
        type: '科技投资主体',
        type_icon: 'https://image.sciphi.cn/dt_insights/demand/type4.svg',
        intro: ['挖掘与甄别', '监控投资效果'],
        info: [
            '进一步扩大优质项目池',
            '缺乏对科研技术的洞察，难以把握技术（科研项目）发展与应用前景',
            '… …'
        ]
    }
]

export const demandProfile = [
    {
        title: '开放的论文/专利分析',
        img: 'https://image.sciphi.cn/dt_insights/demand/part1.png',
        profile: '基于自研的论文检索引擎技术，识别、定义、发展海量论文信息，通过精细化颗粒度解析和标签化关系处理构建高延展性的科技信息搜索库，研究体系覆盖计算机科学、生物学、医学、物理学、数学、统计学等多个核心学术领域，为用户提供开放的论文/专利分析服务。',
        imgPosition: 'right'
    },
    {
        title: '专业的学者名片、学术会议',
        img: 'https://image.sciphi.cn/dt_insights/demand/part2.png',
        profile: '积极探索聚焦全球创新视野下的学术交流体系，为用户提供学术社区、学者名片、学术会议等功能性内容，鼓励用户发布交流最新的学术科研成果，并通过媒体传播研究结论与原创新闻有效放大学术科技的影响力。',
        imgPosition: 'left'
    },
    {
        title: '多领域人才深度挖掘',
        img: 'https://image.sciphi.cn/dt_insights/demand/part3.png',
        profile: '积累海量科技人才信息，从研究领域、所属地区、所在产业标签化展示人物数据，涵盖专业指标 H-index 指数、总引用数、平均引用数，从申请专利、发表论文、技术商业化、教育背景、个人荣誉等多项数据分析综合得出人才专项 TI 指数。',
        imgPosition: 'right'
    },
    {
        title: '全方位科技企业数据整合',
        img: 'https://image.sciphi.cn/dt_insights/demand/part4.png',
        profile: '帮助用户建立清晰的商业网络认知，根据不同需求高效快速掌握有效信息。科技公司板块涵盖团队成员、融资上市等基础信息，以图表形式展示公司所获得的科技专利概况，并在此基础之上能迅速掌握专利数据的分布情况以及在行业中基于公司属性、研究领域、金融状况等多维度的竞业关系。',
        imgPosition: 'left'
    },
    {
        title: '动态实时科技要素评价体系',
        img: 'https://image.sciphi.cn/dt_insights/demand/part5.png',
        profile: '根据企业、人才、技术多个维度，结构性整合各维数据信息，动态实时体现细分产业或领域最具价值科技要素。',
        imgPosition: 'right'
    },
    {
        title: '系统性的产业深度透视',
        img: 'https://image.sciphi.cn/dt_insights/demand/part6.png',
        profile: '从全局角度洞察产业形式，通过直观图表展示产业分布及企业形态等投融资信息，基于此得出该产业在全国的地理分布情况，将产业属性中的科技、财务、市场等多项指标可视化。',
        imgPosition: 'left'
    }
]

// 关于我们
export const aboutUsList = [
    {
        title: '我们的团队',
        img: 'https://image.sciphi.cn/dt_insights/about-us/part1.png',
        profile: '以北京为核心，并在深圳、杭州、西安等地设立创新研发、服务中心并辐射全国，持续引入高科技人才和技术，专注于全球新兴科技产业服务。',
        imgPosition: 'right'
    },
    {
        title: '研究实力',
        img: 'https://image.sciphi.cn/dt_insights/about-us/part2.png',
        profile: '坚持高投入研发，核心成员来自清华大学、中科院、伦敦政经学院、纽约大学等知名院校，体量庞大的数据与前沿技术相结合带来的创新成果，已成为牢不可摧的技术护城河。',
        imgPosition: 'left'
    },
    {
        title: '客户认可',
        img: 'https://image.sciphi.cn/dt_insights/about-us/part3.png',
        profile: '累计帮助百余家客户获得科技业务提升，在政府、世界500强企业或独角兽企业、金融机构、投资机构多个领域，我们已经成为广泛认可的合作伙伴。',
        imgPosition: 'right'
    }
]