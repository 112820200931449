import React from 'react'
import {withRouter} from "react-router-dom"
import { Skeleton } from 'antd'
import Swiper from "swiper/swiper-bundle.js"
import "swiper/swiper-bundle.css"
// import './swiper.scss'
import className from 'classname'
import formatType from 'utils/formatType'
class SwiperComponent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            bannerData: [],
            bannerLoading: true,
            recommendReq: {
                page: 1,
                size: 10,
                data_type: null,
                order: null,
                name: '',
            },
            bannerDataFirst: [],
            bannerDataFirstLen: 0,
            textVisible: false
        }

    }
    componentDidMount () {
        this.getBannerList()
        // this.swiperImg()
    }

    //专辑banner
    getBannerList = () => {
        
        this.axios.post('/collection/banner').then(res => {
            if (res.code === 10000){
                var img = new Image()
                img.src = require('assets/images/collect/banner2.png')
                let that = this
                img.onload = function(){
                    that.setState({
                        bannerData: res.data,
                        bannerLoading: false,
                        bannerDataFirst:  res.data[0],
                        bannerDataFirstLen: res.data[0].items.length,
                        textVisible: true
                    })
                }
                // this.setState({
                //     bannerData: res.data,
                //     bannerLoading: false,
                //     bannerDataFirst:  res.data[0],
                //     bannerDataFirstLen: res.data[0].items.length
                // }, () => {
                //     this.bannerText()
                // })    
            }
        })
    }

    //背景图片加载完毕显示文字
    bannerText = () => {
        // var url = window.getComputedStyle(document.getElementById('swiperSlide')).backgroundImage
        // var url1 = url.substring(40)
        // var src = url1.substring(0, url1.length - 15)
        var img = new Image()
        img.src = require('assets/images/collect/banner2.png')
        let that = this
        img.onload = function(){
            that.setState({
                textVisible: true
            })
        }
    }

    //点击详情
    clickToDetail = (id,type) => {
        let recommendReq = Object.assign({}, this.state.recommendReq, {data_type: type})
        this.setState({
            recommendReq
        }, () => {
            this.props.history.push({pathname:`/collection/detail/${id}`,state:{ id: id, recommendReq: this.state.recommendReq }})
        })
    }

    // 轮播换背景
    swiperImg = () => {
        var arrUrl = [require('../../assets/images/collect/banner1.png'),require('../../assets/images/collect/banner2.png')]
        this.state.bannerData.forEach(item => {
            arrUrl.push(item.photo)
        })
        var num = 0
        var imgDom = document.getElementById('swiper-slide')
        setInterval(function(){
            imgDom.style.background = 'url(' + arrUrl[num] + ')'//url后面的内容是根据数组来定的所以拼接
            num++
            num %= arrUrl.length//num 除以数组的长度，这样每次就只有数组中的图片来回切换 
        },10000)//一分钟之后执行该函数
    }

    render() {
        const {bannerData, bannerDataFirst} = this.state
        const swiperList = [1,2]
        return (
        // <div className="swiper-box">
        //     <div className="swiper-container">
        //         <div className="swiper-wrapper">
        //             {/* {
        //                 swiperList.map(item => {
        //                     return <div className={`swiper-slide swiper-slide${item}`} id="swiper-slide">
        //                         <div className="swiper-left">
        //                             <div className="swiper-left-title">
        //                                 {bannerData[0] && bannerData[0].name}
        //                             </div>
        //                             <div className="swiper-left-content">
        //                                 {bannerData[0] && bannerData[0].introduce}
        //                             </div>
        //                             <div className="swiper-left-click-btn" onClick={() => this.clickToDetail(bannerData[0].id,bannerData[0].data_type)}>
        //                                 点击进入
        //                             </div>
        //                         </div>
        //                         <div className="swiper-right">
        //                             {
        //                                 bannerData.slice(1).map(item => {
        //                                     return <div className="swiper-right-item"  onClick={() => this.clickToDetail(item.id,item.data_type)}>
        //                                         <div className="swiper-right-title">
        //                                             {item.name}
        //                                         </div>
        //                                         <div className="swiper-right-content">
        //                                             {item.introduce}
        //                                         </div>
        //                                     </div>
        //                                 })
        //                             }

        //                         </div>
        //                     </div>
        //                 })
        //             } */}
                    
        //         </div>

        //         {/* 左右箭头按钮 */}
        //         {/* <div className="swiper-button-next"></div>
        //         <div className="swiper-button-prev"></div> */}
        //     </div>

        // </div>
            <Skeleton active loading={this.state.bannerLoading}>
                <div className="swiper-slide swiper-slide2" id="swiperSlide">
                    <div className={className("swiper-left",{
                        // 'textblock': this.state.textVisible
                    })}>
                        <div className="swiper-left-title" title={bannerDataFirst && bannerDataFirst.name}>
                            {bannerDataFirst && bannerDataFirst.name}
                        </div>
                        <div className="swiper-left-content">
                            {bannerDataFirst && bannerDataFirst.introduce ? bannerDataFirst.introduce : formatType(bannerDataFirst.obj_num, bannerDataFirst.data_type,bannerDataFirst.items)}
                        </div>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a href='' onClick={() => this.clickToDetail(bannerDataFirst.id,bannerDataFirst.data_type)}>
                            <div className="swiper-left-click-btn">
                                点击进入
                            </div>
                        </a>
                    </div>
                    <div className={className("swiper-right",{
                        // 'textblock': this.state.textVisible
                    })}>
                        {
                            bannerData.slice(1).map(item => {
                                return <div className="swiper-right-item"  key={item.id} onClick={() => this.clickToDetail(item.id,item.data_type)}>
                                    <div className="swiper-right-title" title={item.name}>
                                        {item.name}
                                    </div>
                                    <div className="swiper-right-content">
                                        {item && item.introduce ? item.introduce : formatType(item.obj_num, item.data_type,item.items)}
                                    </div>
                                </div>
                            })
                        }

                    </div>
                </div>

            </Skeleton>
                
        )
    }
}

export default withRouter(SwiperComponent)
