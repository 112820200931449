import React,{Component} from 'react'
import { DownOutlined, UpOutlined } from '@ant-design/icons'
// import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Collapse, Input, Button, Form } from 'antd'
import { debounce } from 'utils'

const { Panel } = Collapse

class Center extends Component{
    state = {
        info:{},
        saveVal: {}
    }
    // 修改信息
    saveInfo = type =>{
        let saveVal = this.state.saveVal
        this.axios.post('/user/edit',
            { 
                parkorcompany: type === 'company' ? saveVal.company : '' ,
                job:type === 'job' ? saveVal.job : '' ,
                email:type === 'email' ? saveVal.email : '' 
            })
            .then(res=>{
                if (res.code === 10000){
                    this.getInfo()
                    // this.closePanel(type === 'company' ? 1 : 2)
                    let key 
                    if (type === 'company') {
                        key = 1
                    } else if (type === 'job') {
                        key = 2
                    } else if (type === 'email') {
                        key = 4
                    }
                    this.closePanel(key)
                }
            })
    }
    componentDidMount(){
        this.getInfo()
    }
    // 获取个人信息
    getInfo = () =>{
        this.axios.post('/user/info').then(res=>{
            if (res.code === 10000){
                this.setState({
                    info:res.data
                })
            }
        })
    }
    // 编辑
    panelChange = key =>{
        const allHeaderDoms = document.querySelectorAll('.ant-collapse-item')
        allHeaderDoms.forEach((list,i) => {
            const HeaderDom = list.querySelector('.ant-collapse-header')
            const CurrentContent = list.querySelector('.ant-collapse-content')
            if ( key - 1 === i){
                HeaderDom.style.display = 'none'
                if (CurrentContent){
                    CurrentContent.style.display = 'block'
                }
            } else {
                HeaderDom.style.display = 'block'
                if (CurrentContent){
                    CurrentContent.style.display = 'none'
                }
            }
        })
    }
    // 收起
    closePanel = key =>{
        const allHeaderDoms = document.querySelectorAll('.ant-collapse-item')
        Array.from(allHeaderDoms).forEach((list,i) => {
            const HeaderDom = list.querySelector('.ant-collapse-header')
            if ( key - 1 === i){
                HeaderDom.style.display = 'block'
                const CurrentContent = list.querySelector('.ant-collapse-content')
                if (CurrentContent){
                    CurrentContent.style.display = 'none'
                }
            }
        })
    }

    // 提交成功
    onFinish = values => {
        // console.log('Success:', values)
        this.setState({
            saveVal: values
        })
    }

    // 提交失败
    onFinishFailed = errorInfo => {
        // console.log('Failed:', errorInfo)
    }

    // 改变输入内容
    onValuesChange = values => {
        // console.log(values)
    }

    // 邮箱校验
    regEmail = (rule,value) => {
        const reg = /\w+@[a-z0-9]+\.[a-z]{2,4}/
        // console.log(reg.test(value))
        if (!reg.test(value)) {  
            return Promise.reject('邮箱格式错误')
        }
        return Promise.resolve()
    }

    render(){

        const { info } = this.state
        const formItemLayout = {
            // labelCol: { sm: { span: 4 }},
            wrapperCol: { sm: { span: 8 }},
        }
        const genExtra = key => (
            <span className='edit-btn'>
                编辑 <DownOutlined />
            </span> 
        )
        return this.state.info ? 
            <Collapse
                accordion
                onChange={this.panelChange}
                expandIconPosition='right'
                className='center-box'
            >
                {/* 公司园区 */}
                <Panel 
                    header={<span className='title'><span>公司/园区 </span>{info.parkorcompany}</span>} 
                    key="1" extra={genExtra()}
                >
                    <h2>公司/园区</h2>
                    <Form {...formItemLayout} 
                        initialValues={{
                            company: info.parkorcompany
                        }}
                        onFinish={this.onFinish}
                        onFinishFailed={this.onFinishFailed}
                        onValuesChange={this.onValuesChange}
                        validateTrigger="onBlur" 
                    >
                        <Form.Item label='修改公司/园区' rules={[{ required: true, message: '公司/园区不能为空' },{max:30,message:'内容不超过30个字'}]} name="company">
                            <Input />
                        </Form.Item>
                        <Button type="primary" onClick={debounce(()=>this.saveInfo('company'),400)} className='save-btn'  htmlType="submit"> 保存 </Button>
                    </Form>
                    <span className='edit-btn' onClick={e =>this.closePanel(1)}>收起 <UpOutlined /></span>
                </Panel>
                {/* 职位 */}
                <Panel 
                    header={<span className='title'><span>职位 </span>{info.job}</span>} key="2" 
                    extra={genExtra()}
                >
                    <h2>职位</h2>
                    <Form {...formItemLayout} 
                        initialValues={{
                            job: info.job
                        }}
                        onFinish={this.onFinish}
                        onFinishFailed={this.onFinishFailed}
                        onValuesChange={this.onValuesChange}
                        validateTrigger="onBlur" 
                    >
                        <Form.Item label='修改职位' rules={[ { required: true, message: '职位不能为空' },{max:30,message:'内容不超过30个字'}]} name="job">
                            <Input />
                        </Form.Item>
                        <Button type="primary" onClick={debounce(()=>this.saveInfo('job'),400)} className='save-btn' htmlType="submit"> 保存 </Button>
                    </Form>
                    <span className='edit-btn' onClick={e =>this.closePanel(2)}>收起 <UpOutlined /></span>
                </Panel>
                <Panel header={<span className='title'><span>绑定手机 </span>{info.mobile}</span>} key="3" disabled></Panel>
                {/* 邮箱 */}
                <Panel header={<span className='title'><span>绑定邮箱 </span>{info.email}</span>} key="4" extra={genExtra()}>
                    <h2>邮箱</h2>
                    <Form {...formItemLayout} 
                        initialValues={{
                            email: info.email
                        }}
                        onFinish={this.onFinish}
                        onFinishFailed={this.onFinishFailed}
                        onValuesChange={this.onValuesChange}
                        // validateTrigger="onBlur" 
                    >
                        <Form.Item label='修改邮箱' rules={[ { required: true, message: '邮箱不能为空' }, {validator: this.regEmail}]} name="email">
                            <Input />
                        </Form.Item>
                        <Button type="primary" onClick={debounce(()=>this.saveInfo('email'),400)} className='save-btn' htmlType="submit"> 保存 </Button>
                    </Form>
                </Panel>
            </Collapse> : null
    } 
}

export default Center