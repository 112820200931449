import React, { Component } from 'react'
import Develop from './Develop'
import Caption from './Caption'
class Intension extends Component {
    constructor(props) {
        super(props)
        this.state = {
            captions1 : {
                title: [{ id: 31, text: '研发强度' }, { id: 32, text: '企业数量' }],
                list: [
                    { id: 10, nums: 10, unit: "<5%" },
                    { id: 14, nums: 14, unit: "5%~10%" },
                    { id: 29, nums: 29, unit: "10%~15%" },
                    { id: 37, nums: 37, unit: "15%~20%" },
                    { id: 22, nums: 22, unit: ">20%" }
                ]
            }
        }
    }
    render() { 
        return (
            <div className="flex-box">
                <Develop id="fund" data={this.props.data} />
                <Caption {...this.state.captions1} />
            </div>
        )
    }
}
 
export default Intension
