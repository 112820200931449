import React,{Component} from 'react'
import { withRouter } from 'react-router-dom'
// import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Collapse, Input, Button, message, Form, Divider } from 'antd'
import { debounce } from 'utils'
import { removeStorage } from 'utils/storage'
import MyAxios from 'libs/axios'
import 'styles/pages/user.scss'
function PwdSetting(props){

    // 忘记密码确认密码
    function passwordConfirm(rule, value){
        let pwdVal = document.getElementById('pwd').value
        if (value && value !== pwdVal){
            return Promise.reject('两次密码输入不一致!')
        } else {
            return Promise.resolve()
        }
    }
    const formItemLayout = {
        // labelCol: { sm: { span: 4 }},
        wrapperCol: { sm: { span: 8 }},
    }
    // 验证密码，不包含特殊符号
    function regPwd (rule, value){
        // var patrn = /[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]/im; 
        const reg = /[`~!@#$%^&*()\-+=<>?:"{}|,/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]/
        if (reg.test(value)) {            
            return Promise.reject('不能含有特殊字符')
        }
        return Promise.resolve()
    }
    function logout(){
        MyAxios.post('/logout')
            .then(res=>{
                if (res.code === 10000){
                    removeStorage('token')
                    //  修改密码成功之后退出 重新登录
                    props.history.push('/login')
                }
            })
    }
    // 修改信息
    function saveInfo(values){
    
        values && MyAxios.post('/user/edit',
            {  oldpwd:values.oldpwd,pwd:values.pwd,pwd2:values.pwd2 })
            .then(res=>{
                if (res.code === 10000){
                    //  修改密码成功之后退出 重新登录
                    message.success('密码修改成功！请重新登录')
                    logout()
                }
            })
    }

    // 提交成功
    function onFinish(values) {
        // console.log('Success:', values)
        saveInfo(values)
    }

    // 提交失败
    function onFinishFailed(errorInfo) {
        // console.log('Failed:', errorInfo)
    }

    // 改变输入内容
    function onValuesChange(values) {
        // console.log(values)
    }

    return (
        <Form
            className='pwd-con' 
            {...formItemLayout}
            name="basic" 
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            onValuesChange={onValuesChange}
            validateTrigger="onBlur" 
        >
            <Form.Item label='原密码' rules={[ { required: true, message: '原密码不能为空' }]} name="oldpwd">
                <Input placeholder='请输入原密码' type="password" /> 
            </Form.Item>
            <Form.Item label='新密码' rules={ [{required: true, min:6,max:16, message:'密码不能为空,长度为6-16位'},{validator:regPwd}]} name="pwd">
                <Input placeholder='请输入新密码' type="password" id="pwd"/>
            </Form.Item>
            <Form.Item label='确认新密码' rules={[ { required: true, message: '密码不能为空' },{validator: passwordConfirm}]} name="pwd2">
                <Input placeholder='再次输入新密码' type="password"/>
            </Form.Item>
            <Button type="primary" onClick={debounce(saveInfo,400)} htmlType="submit" className='save-btn'> 保存 </Button>
        </Form>
    )
}
// export default Form.create()(withRouter(PwdSetting))
export default withRouter(PwdSetting)