import React, { Component } from 'react'
import { Layout,Skeleton,Empty } from 'antd'
// 组件
import  SideBar  from 'comps/sidebar'
import  BreadComp  from 'comps/bread'  // 面包屑导航
import BasicInfo from './detail/BasicInfo'
import ParkService from './detail/ParkService'
import { EmptyComp } from 'comps/common/EmptyComp'
// mock数据
import { PARKSIDE } from 'utils/constant'

import 'styles/pages/park.scss'

const { Content } = Layout
const BreadData = [{url:'/home',title:'首页'},{url:'/park',title:'地理'},{url:'',title:'园区详情'}]

class ParkDetail extends Component {
    state = {
        InfoData:{},InfoLoading:true,
    }
    componentDidMount(){
        this.getDetail()
    }
    getDetail = ()=>{
        this.axios.post('/park/info',{ id:this.props.match.params.id})
            .then(res=>{
                document.title = res.data.name
                this.setState({
                    InfoData:res.data,
                    InfoLoading:false
                })
            })
    }
    render() {
        const { InfoData,InfoLoading } = this.state
        return (
            <Layout className='park-container' id='basic'>
                {/* sider */}
                <SideBar data={Object.keys(InfoData).length > 0 && InfoData.awards.length > 0 ? PARKSIDE : PARKSIDE.slice(0,2)} />
                <Layout className='layout-rt'>
                    <BreadComp data={BreadData} />
                    {/* main */}
                    <Content className='park-main'>
                        {/* 概述 —— 园区名 功能按钮 简介*/}
                        <BasicInfo InfoData={InfoData} InfoLoading={InfoLoading} id={this.props.match.params.id}/>
                        {/* 园区服务 */}
                        <ParkService InfoData={InfoData} InfoLoading={InfoLoading}/>
                        {/* 园区影响力 */}
                        {
                            !InfoLoading && <section id='effect' className='effect'>
                                {
                                    Object.keys(InfoData).length > 0 && InfoData.awards.length > 0 && <>
                                        <h1 className='parktitle'> 园区影响力 </h1>
                                        <ul className='effect-ul'>
                                            {
                                                InfoData.awards.map(l => <li key={l.id}>{l.name}</li>)
                                            }
                                        </ul> 
                                    </>
                                }
                            </section>
                        }
                        
                    </Content>
                </Layout>
            </Layout>
        )
    }
}

export default ParkDetail
