import React, { Component } from 'react'
import { Tag,Button,Skeleton } from 'antd'
import { setStorage } from 'utils/storage'
import { Msg } from 'utils'
import 'styles/comps/labelmodal.scss'
import { withRouter } from 'react-router-dom'

const { CheckableTag } = Tag

@withRouter
class SelectLabel extends Component{
    state = {
        domainCheckedBox:this.props.domainChecked,
        expertCheckedBox:this.props.expertChecked,
        expertLabel:[],expertLoading:true,
        domainLabel:[],domainLoading:true
    }
    componentDidMount(){
        this.getLabel()
    }
    // 获取标签数据
    getLabel = ()=>{
        this.axios.post('/domain',{ level:2 }).then(res=>{
            if (res.code === 10000){
                this.setState({
                    domainLoading:false,
                    domainLabel:res.data
                })
            }
        })
        this.axios.post('/expert/research').then(res=>{
            if (res.code === 10000){
                this.setState({
                    expertLoading:false,
                    expertLabel:res.data
                })
            }
        })
    }
    // 选中状态
    tagHandleChange = (type,checked,tag) =>{
        const { domainCheckedBox,expertCheckedBox } = this.state
        if (checked){
            if (type === 'domain' && domainCheckedBox.length === 10){
                Msg('warning','产业偏好兴趣标签最多选择10个！')
            } else if (type === 'expert' && expertCheckedBox.length === 10){
                Msg('warning','人物偏好兴趣标签最多选择10个！')
            } else {
                this.setState({
                    domainCheckedBox:type === 'domain' ? domainCheckedBox.concat(tag) : domainCheckedBox,
                    expertCheckedBox:type === 'expert' ? expertCheckedBox.concat(tag) : expertCheckedBox
                })
            }
        }
    }
    // 删除单个事件
    tagClose = (tag,type)=>{
        const { domainCheckedBox,expertCheckedBox } = this.state
        this.setState({
            domainCheckedBox: type === 'domain' ? domainCheckedBox.filter(l => l.id !== tag.id) : domainCheckedBox,
            expertCheckedBox: type === 'expert' ? expertCheckedBox.filter(l => l.id !== tag.id) : expertCheckedBox,
        })
    }
    // 清除所有项
    clearFilter = type =>{
        const { domainCheckedBox,expertCheckedBox } = this.state
        this.setState({
            domainCheckedBox: type === 'domain' ? [] : domainCheckedBox,
            expertCheckedBox: type === 'expert' ? [] : expertCheckedBox
        })
    }
    // 保存标签数据
    saveLabel = ()=>{
        const { domainCheckedBox,expertCheckedBox } = this.state
        let domainIds = '',expertIds = ''
        domainCheckedBox.forEach(l => {
            domainIds += l.id
            domainIds += ','
        })
        expertCheckedBox.forEach(l => {
            expertIds += l.id
            expertIds += ','
        })
        this.axios.post('/user/set',{ type:1,ids:domainIds }).then(res=>{
            return res.code
        }).then(code=>{
            this.axios.post('/user/set',{ type:2,ids:expertIds }).then(data=>{
                if (data.code === 10000 && code === 10000){
                    Msg('success','标签保存成功！')
                    let re = [{type:1,ids:domainCheckedBox},{type:2,ids:expertCheckedBox}]
                    setStorage('re',re,365)
                    // 如果是首页
                    this.props.type === 'index' && this.props.closeModal()
                    // 如果是个人中心页面
                    this.props.type === 'user' && this.props.history.push('/home')
                }
            })
        })
    }
    render(){
        const { domainCheckedBox,domainLabel,expertLabel,expertCheckedBox } = this.state
        let isDisable = domainCheckedBox.length === 0 && expertCheckedBox.length === 0 
        return (
            <div className='label-select'>
                {/* 产业偏好 */}
                <LabelList title='产业偏好' TagsData={domainLabel} checkedBox={domainCheckedBox} type='domain' 
                    tagHandleChange={this.tagHandleChange}
                    tagClose={this.tagClose}
                    clearFilter={this.clearFilter}
                    loading={this.state.domainLoading}
                />
                {/* 人物偏好 */}
                <LabelList title='人物偏好' TagsData={expertLabel} checkedBox={expertCheckedBox} type='expert' 
                    tagHandleChange={this.tagHandleChange}
                    tagClose={this.tagClose}
                    clearFilter={this.clearFilter}
                    loading={this.state.expertLoading}
                />
                <Button className="save-label" disabled={isDisable}
                    onClick={this.saveLabel} >
                    选好了，保存 
                    <i className='iconfont icon-next'></i>
                </Button>
            </div>
        )
    }
}

export default SelectLabel

const LabelList = props =>{
    const { title,TagsData,checkedBox,type,loading } = props
    return (
        <Skeleton active paragraph={{ rows: 4 }} loading={loading}>
            <section className='tags-box'>
                <h1>{title}</h1>
                <div className='box_shadow'>
                    {TagsData.map(tag => (
                        <CheckableTag key={tag.id} checked={checkedBox.some(selectTag => Number(selectTag.id) === tag.id)}
                            onChange={checked => props.tagHandleChange(type,checked,tag,)}>
                            {tag.name}
                        </CheckableTag>
                    ))}
                    {/* 显示选中的结果 */}
                    {
                        checkedBox.length > 0 && 
                        <div className='checked-box flex_box'>
                            <p>
                                {checkedBox.map(tag=>{
                                    return <Tag closable key={`${tag.id}${tag.name}`} 
                                        className={tag.type === 'sub' ? 'sub-tag' : ''}
                                        onClose={e => {
                                            e.preventDefault()
                                            props.tagClose(tag,type)
                                        }}>
                                        {tag.name || tag.ShortName}
                                    </Tag>
                                })}
                            </p>
                            {/* 清除筛选项 */}
                            <Button onClick={props.clearFilter.bind(this,type)}>清除标签</Button>
                        </div>
                    }
                </div>
            </section>
        </Skeleton>
    )
}