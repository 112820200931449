import React, { Component } from 'react'
import { Layout } from 'antd'
import BreadComp from 'comps/bread'  // 面包屑导航
import SideMenu from './SideMenu' //左侧导航
import { COL_TYPE } from 'utils/type'
import SwiperComponent from './SwiperComponent'
import MyCollectionItem from './MyCollectionItem'
import MyCollectionDetail from './MyCollectionDetail'
import { withRouter } from 'react-router-dom'
import PageCustom from 'comps/PageCustom'
import classNames from 'classname'

import 'styles/pages/collection.scss'

const { Content } = Layout
const  FixedBread = [{url:'/home',title:'首页'},{url:'',title:'专辑'}]

@withRouter
class MyCollect extends Component{
    state = {
        BreadData : [{ url: '/home', title: '首页' },{ url: '/mycollection/0', title: '我的专辑' }],
        info:{},editData:{},
        isMyCollectDetail: false,
        myCollectReq: {
            page: 1,
            size: 10,
            data_type: this.props.match.params.id
        },
        myCollectionData: [],
        MyCollectDetailId: null,
        bannerData: [],
        bannerLoading: true,
        total:0,
        page:1,
        empty: false,
        isFixed: false,
        leftWidth: 0,
    }
    componentDidMount(){
        this.getMyList()
    }
    // id发生变化后重新发起数据请求
    componentDidUpdate(prevProps,prevState){
        if (prevProps.match.params.id !== this.props.match.params.id){
            let dataType = this.props.match.params.id
            let myCollectReq = Object.assign({}, this.state.myCollectReq, {data_type: dataType},{page:1})
            this.setState({
                myCollectReq: myCollectReq
            }, () => {
                this.getMyList()
            })
        }
    }
    //查看详情
    checkDetail = id => {
        this.setState({
            isMyCollectDetail: true,
            MyCollectDetailId: id
        })
        this.props.history.push({pathname:`/mycollection/detail/${id}`,state:{ id: id, myCollectReq: this.state.myCollectReq }})
    }

    // 取消查看详情
    checkDetailCancel = ()=> {
        this.setState({
            isMyCollectDetail: false
        })
    }

    // 获取我的专辑列表
    getMyList = () =>{
        let myCollectReq = this.state.myCollectReq
        this.axios.post('/collection/mylist', myCollectReq ).then(res=>{
            if (res.code === 10000){
                this.setState({
                    myCollectionData: res.data.items,
                    total: res.data.total,
                    empty: true
                }, () => {
                    this.windowOnScroll()
                })
            }
        })
    }

    // 分页
    change = page => {
        let myCollectReq = Object.assign({}, this.state.myCollectReq, {page: page})
        this.setState({
            myCollectReq: myCollectReq,
            page
        }, () => {
            this.getMyList()
        })
    }

    windowOnScroll = () => {
        let that = this
        let dom = document.getElementById('myBasicBox')
        this.setState({
            leftWidth: dom && dom.offsetLeft + 200
        })
        window.onscroll = function () {
            let h = document.documentElement.scrollTop
            if (h > 480) {
                that.setState({
                    isFixed: true
                })
            } else {
                that.setState({
                    isFixed: false
                })
            }
        }

    }
    render(){
        const { myCollectionData,total,empty } = this.state
        return (
            <Layout className='collect-box collect-con mycollect-con'>
                <BreadComp data={this.state.BreadData} />
                <SwiperComponent ></SwiperComponent>
                {
                    this.state.isMyCollectDetail ? (<MyCollectionDetail MyCollectDetailId={this.state.MyCollectDetailId} myCollectReq={this.state.myCollectReq} checkDetailCancel={this.checkDetailCancel}></MyCollectionDetail>) : (
                        <Layout className='collection-con'>
                            <div className={classNames("sidemenu-box", {
                                'sidemenu-box-fixed': this.state.isFixed
                            })}>
                                <SideMenu current={this.props.match.params.id  + '_4'} propsKeys={'my'}/>
                            </div>
                            <Layout className='collect-rt mycollect'>
                                <Content className='collect-main' id="myBasicBox">
                                    {/* 我的专辑列表 */}
                                    <section className={classNames("my-basic", {
                                        'recommendlist': myCollectionData && myCollectionData.length === 0,
                                        'myrecommendlist-pos': this.state.isFixed
                                    })}>
                                        {
                                            total === 0 && empty ? <div className="emptyimg-boxs"><img src={require('assets/images/collect/empty.png')} alt="" id="emptyimg"/></div> :
                                                <div>
                                                    {
                                                        myCollectionData && myCollectionData.map(item => {
                                                            // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                                            return <a onClick={() => this.checkDetail(item.id)} href='' key={item.id}>
                                                                <div className="mycollection-items">
                                                                    <MyCollectionItem isMyDetail = {false} MyCollectionItemData = {item}></MyCollectionItem>
                                                                </div>
                                                            </a>
                                                        })
                                                    }
                                                    <PageCustom
                                                        currentPage={this.state.page}
                                                        pagesize={10}
                                                        total={this.state.total}
                                                        changeStatePage={this.change}
                                                        size="small"
                                                    />
                                                </div>
                                        }
                                    </section>
                                </Content>
                            </Layout>
                        </Layout>
                    )
                }
            </Layout>
        )
    }
}

export default MyCollect
