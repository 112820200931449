import React, { Component } from 'react'
import BreadComp from 'comps/bread'
import Year from './Year'
import { EmptyComp } from 'comps/common/EmptyComp'
import Thesis from './Thesis'
import classNames from 'classnames'
import PageCustom from 'comps/PageCustom'
import { Tootip3 } from 'comps/tootip/Tootip'
import { getStorage } from 'utils/storage'
import { TEST_USER } from 'utils/type'

class ThesisList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tabs: [
                { id: 'year', active: true, title: '按年份排序' },
                { id: 'quote', active: false, title: '按引用量排序' }
            ],
            quote: [
                { id: 1, active: true, title: '全部' },
                { id: 2, active: false, title: '0-10' },
                { id: 3, active: false, title: '11-50' },
                { id: 4, active: false, title: '> 50' },
            ],
            list: [],
            chart: [],
            tatal: 0,
            name: '',
            // 高亮tab
            activeTab: 'year',
            // 高亮引用数
            activeQuote: 1,
            // 默认页数
            page: 1,
            BreadData: [{ url: '/home', title: '首页' }, { url: '/person', title: '人物列表' }, { url: '', title: '' }, { url: '', title: '论文' }]
        }
    }
    componentDidMount() {
        this.requestPaper({ id: this.props.match.params.id })
        this.axios.post('/expert/index', { id: this.props.match.params.id })
            .then(res => {
                if (res.code === 10000) {
                    this.setState({
                        name: res.data.name_cn ? res.data.name_cn : res.data.name_en
                    }, () => {
                        document.title = this.state.name
                        let Bs = [{ url: '/home', title: '首页' }, { url: '/person', title: '人物列表' }, { url: '/person/detail/' + this.props.match.params.id, title: this.state.name }, { url: '', title: '论文' }]
                        this.setState({ BreadData: Bs })
                    })
                }
            })
    }
    // 发起 请求论文列表
    requestPaper(ops) {
        this.axios.post('/expert/paperslist', ops)
            .then(res => {
                if (res.code === 10000) {
                    this.setState({
                        list: res.data.data,
                        chart: res.data.yearInfo,
                        total: res.data.total[0]
                    })
                }
            })
    }
    // 改变页面
    change = page => {
        this.setState({
            page
        }, () => {
            if (this.state.activeTab === 'quote') {
                this.requestPaper({ id: this.props.match.params.id, page: this.state.page, citations: this.state.activeQuote, order: 2 })
            } else {
                this.requestPaper({ id: this.props.match.params.id, page: this.state.page })
            }

        })
    }
    // 切换 年份 引用量 tab
    clickTab(id) {
        this.setState({
            tabs: this.state.tabs.map(item => item.id === id ? { ...item, active: true } : { ...item, active: false }),
            activeTab: id,
            page:1
        }, () => {
            if (this.state.activeTab === 'quote') {
                this.requestPaper({ id: this.props.match.params.id, page: this.state.page, order: 2 })
            } else {
                this.requestPaper({ id: this.props.match.params.id, page: this.state.page })
            }
        })
    }
    // 切换按照引用量排序 下面的tab
    clickQuote(id) {
        this.setState({
            activeQuote: id,
            quote: this.state.quote.map(item => item.id === id ? { ...item, active: true } : { ...item, active: false }),
            page: 1
        }, () => {
            this.requestPaper({ id: this.props.match.params.id, page: 1, citations: this.state.activeQuote, order: 2 })
        })
    }
    render() {
        return (
            <div className="content-list">
                <BreadComp data={this.state.BreadData} />
                <div className="content-title">论文列表<span>共{this.state.total}篇</span></div>
                <section className="content-box">
                    {/* 按照年份 按照引用量的tabs */}
                    <div className="action-bar">
                        {this.state.tabs.map(item => <div
                            key={item.id}
                            onClick={this.clickTab.bind(this, item.id)}
                            className={classNames({ active: item.active })}
                        >{item.title}</div>)}
                    </div>
                    {/* 按照年份排序的柱状图 */}
                    {this.state.chart.length !== 0 && this.state.activeTab === 'year' &&
                        <Year id="yers" data={this.state.chart} />}
                    {/* 按照引用量排序的tab */}
                    {this.state.chart.length !== 0 && this.state.activeTab === 'quote' &&
                        (<div className="quote">
                            {this.state.quote.map(item => <div
                                key={item.id}
                                className={classNames({ active: item.active })}
                                onClick={this.clickQuote.bind(this, item.id)}
                            >{item.title}</div>)}
                        </div>)
                    }
                    {/* 筛选结果列表 */}
                    {this.state.list.length === 0 ? <EmptyComp /> :
                        this.state.list.map((item, index) => <Thesis key={item.paper_id} {...item} botmBorder={index + 1 === this.state.list.length ? true : false} />)}
                    {/* 权限弹框 */}
                    {
                        TEST_USER.includes(getStorage('level')) && <Tootip3 page={this.state.page} aim={1} text='升级完整版，查看全部数据' />
                    }
                    {/* 页码控制 */}
                    {this.state.list.length !== 0 &&
                        <PageCustom
                            currentPage={this.state.page}
                            pagesize={10}
                            total={this.state.total}
                            changeStatePage={this.change}
                        />}
                </section>
            </div>
        )
    }
}


export default ThesisList