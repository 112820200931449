import React, { Component } from 'react'
import { Tag, Button, Skeleton } from 'antd'
import { SelectTags,SubSelectTags } from './SelectTags'
import { createFoundList } from './Fn'
import { personAlphabetList } from 'utils/type'
// redux
import { connect } from 'react-redux'

import 'styles/comps/select-tags.scss'

// 成立时间数据
const foundedList = createFoundList()
// 筛选项列表参数 数组格式 便于循环输出
const FilterArr = [
        {type:'tech',text:'应用技术'},
        {type:'domain',text:'所在产业'},
        {type:'industry',text:'所在行业'},
        {type:'subindustry',text:'子行业'},
        {type:'round',text:'融资轮次'},
        {type:'area',text:'总部地区'},
        {type:'date',text:'成立时间'}
    ],
    PersonFilterArr = [
        { type: "tech", text: "应用技术" },
        { type: "field", text: "重点领域" },
        { type: "area", text: "所在地区" },
        { type: "domain", text: "热点产业" },
        { type: "college", text: "所在院校"},
        { type: "alphabet", text: "拼音字母" },
    ]
const allObj = [{ id: 0, name: '全部' }]

@connect(state => state)
class FilterList extends Component{
    state = {
        techList:[], domainList:allObj, scopesList:allObj,
        roundList:allObj, areaList:allObj,
        fieldList: allObj, // 人才领域
        collegeList: allObj, // 人才所在院校
        FilterLoading:true,
        subScopesLists:[]  // 公司二级行业
    }
    componentDidMount(){
        this.getFilterList() // get all filter list
    }
    
    // 行业 产业 融资轮次 总部地区
    getFilterList = () =>{
        const { filterPage } = this.props
        // 接受一个promise 返回一个promise  根据code码不同，返回resolve或者reject
        const promisePost = promise => {
            return new Promise(resolve => {
                promise.then(res => {
                    if (res.code === 10000) {resolve(res.data)}
                })
            })
        }

        const postDomain = promisePost(this.axios.post('/domain',{ level:2 })), // 产业
            postIndustry = promisePost(this.axios.post('/industry')), // 行业
            postRound = promisePost(this.axios.post('/invest/round')), // 融资伦次
            postArea = promisePost(this.axios.post('/area',{ pid:100000,type:1 })), // 总部地区
            postCollege = promisePost(this.axios.post('/expert/baseschool')), // 人才所在院校
            postExpertField = promisePost(this.axios.post('/expert/area')), // 人才重点领域
            postExpertDomain = promisePost(this.axios.post('/domain',{ level:1 })) // 人才热点产业

        // Promise.allSettled 接受所有的数据请求，等所有api有返回值时再setState
        filterPage === 'company' && Promise.allSettled([postDomain,postIndustry,postRound,postArea])
            .then(([domain,industry,round,area]) => {
                const { domainList,scopesList,roundList,areaList } = this.state
                const { subScopesId } = this.props
                let subScopesLists = industry.status === 'fulfilled' && subScopesId ? industry.value.filter(tag => tag.id === subScopesId)[0].items : []
                this.setState({ 
                    FilterLoading :  false,
                    domainList    :  domain.status === 'fulfilled' ? domainList.concat(domain.value) : domainList,
                    scopesList    :  industry.status === 'fulfilled' ? scopesList.concat(industry.value) : [],
                    subScopesLists:  [{ id: 0, name: "全部" }, ...subScopesLists],
                    roundList     :  round.status === 'fulfilled' ? roundList.concat(round.value) : roundList,
                    areaList      :  area.status === 'fulfilled' ? areaList.concat(area.value).filter(l => l.id !== 900000)  : areaList,
                })
            })
        // 人才筛选项 数据请求
        filterPage === 'person' && Promise.allSettled([postExpertField,postArea,postExpertDomain,postCollege])
            .then(([field,area,domain,college]) => {
                const { fieldList, areaList, domainList, collegeList } = this.state
                this.setState({
                    FilterLoading :  false,
                    fieldList     :  field.status === 'fulfilled' ? fieldList.concat(field.value) : fieldList,
                    collegeList   :  college.status === 'fulfilled' ? collegeList.concat(college.value) : collegeList,
                    domainList    :  domain.status === 'fulfilled' ? domainList.concat(domain.value) : domainList,
                    areaList      :  area.status === 'fulfilled' ? areaList.concat(area.value).filter(l => l.id !== 900000)  : areaList,
                })
            })
    }

    shouldComponentUpdate(preProps,preState){
        // console.log('FilterList SCU',preProps,preState)
        // 只有 redux 中comLabels 和 state 、perLabels 变化时 更新当前组件
        if (preProps.comLabels !== this.props.comLabels 
            || preState !== this.state
            || preProps.perLabels !== this.props.perLabels
        ) {return true}
        return false
    }
    
    componentDidUpdate(prevProps){
        // 公司一级行业的ID不同时 更新 二级行业数据
        if (prevProps.subScopesId !== this.props.subScopesId){
            const { scopesList } = this.state
            const { subScopesId } = this.props
            let subScopesLists = subScopesId ? scopesList.filter(tag => tag.id === subScopesId)[0].items : []
            this.setState({
                subScopesLists: [{ id: 0, name: "全部" }, ...subScopesLists]
            })
        }
    }
    
    render(){
        const { techList,domainList,scopesList,roundList,areaList,subScopesLists,collegeList,fieldList } = this.state
        const { subScopesId,title,comLabels, filterPage, perLabels } = this.props
        // 不同的type 输出不同的state List
        let tagsName = type =>{
            switch (type) {
            case 'tech': return techList
            case 'domain': return domainList
            case 'industry': return scopesList
            case 'round': return roundList
            case 'area': return areaList
            case 'college' : return collegeList
            case 'field' : return fieldList
            case 'alphabet' : return personAlphabetList
            default: return foundedList
            }
        }
        // 显示选中的tag
        const selectLabelTags = filterPage === 'company' ? comLabels : perLabels
        return (
            <section className='company-filters'>
                <h1>{title}</h1>
                {/* 筛选条件 */}
                <Skeleton active paragraph={{ rows: 5 }} loading={this.state.FilterLoading}>
                    {/* 公司筛选条件列表 产业二级 行业二级三级 轮次 地区 时间 */}
                    {
                        filterPage === 'company' && FilterArr.map( filter =>{
                            if (filter.type === 'subindustry'){
                                return subScopesId !== 0 && 
                                    <SubSelectTags 
                                        type={filter.type}
                                        comLabels={this.props.comLabels}
                                        subData={ subScopesLists } 
                                        subChange = {(id,checked,tag) => this.props.tagsChange(id, checked,tag,filter.type)}
                                    />
                            } else {
                                return <SelectTags key={filter.type}
                                    TagsData={tagsName(filter.type)}  // tags 数据
                                    handleChange={(id,checked,tag) => this.props.tagsChange(id, checked,tag,filter.type)}  // tag 点击事件
                                    type={filter.type} text={filter.text}
                                    filterPage='company'
                                    isFold = { filter.type !== 'date' && filter.type !== 'tech'}
                                />
                            }
                        })
                    }
                    {/* 人才筛选列表 */}
                    {
                        filterPage === 'person' && PersonFilterArr.map( filter => {
                            return <SelectTags key={filter.type}
                                TagsData={tagsName(filter.type)}  // tags 数据
                                handleChange={(id,checked,tag) => this.props.tagsChange(id, checked,tag,filter.type)}  // tag 点击事件
                                type={filter.type} text={filter.text}
                                filterPage='person'
                                isFold = { filter.type !== 'domain' && filter.type !== 'tech' && filter.type !== 'college' && filter.type !== 'alphabet'}
                            />
                        })
                    }
                </Skeleton>
                {/* 显示选中的tag */}
                {
                    selectLabelTags.length > 0 && 
                    <div className='checked-box flex_box'>
                        <p>
                            {selectLabelTags.map(tag =>{
                                return <Tag closable key={`${tag.id}${tag.name}`} 
                                    className={tag.cate.indexOf('sub') > -1 ? 'sub-tag' : ''}
                                    onClose={e => {
                                        e.preventDefault()
                                        this.props.tagClose(tag.id,tag.cate)
                                    }}> {tag.name}
                                </Tag>
                            })}
                        </p>
                        {/* 清除筛选项 */}
                        <Button onClick={this.props.clearFilter}>清除筛选</Button>
                    </div>
                }
            </section>
        )
    }
}
export default FilterList


