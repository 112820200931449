import React, { useState } from 'react'
import { message } from 'antd'
import cs from 'classnames'
import LoadImg from './LoadImg'
import Sign from 'comps/Sign/Sign'
import { getStorage } from 'utils/storage'
import { TEST_USER } from 'utils/type'

function Profile(props) {
    return (
        <div className="thumb">
            <div className="thumbbox">
                <a
                    className="body"
                    href={'/person/detail/' + props.id}
                    target={"_blank"}
                    rel="noopener noreferrer"
                >
                    <div className="left-side">
                        <LoadImg src={props.photo} />
                    </div>
                    <div className="profiles">
                        <p className="fullname">{props.name_cn || props.name_en}</p>
                        {/*<p className="dt-index">DT人才指数：<span>{props.dt}</span></p>*/}
                        <p className="company">{props.comany_name}</p>
                        <p className="position"> {props.position}</p>
                    </div>
                </a>
                <div className="bottom">

                    <div className="tags">
                        {props.honor.map(item => <Sign key={item.id} color="orange" name={item.name} />)}
                        {props.field_info.map(item => <Sign key={item.id} color="purple" name={'研究领域：' + item.name} />)}
                        {props.labels.map(item => <Sign key={item.id} color="blue" name={item.name} />)}
                    </div>
                </div>
                <div className="info">
                    {props.email &&
                        <div className="item email" onClick={TEST_USER.includes(getStorage('level')) ? () => { props.changeModal(true) } : () => { copy(props.email) }} title={props.email}>
                            <i className='iconfont icon-ic_xinfeng'></i>
                            {/* 联系 */}
                            <input id={props.email} style={{ display: 'none' }} defaultValue={props.email} />
                        </div>}
                    {/* 对比 */}
                    <i onClick={TEST_USER.includes(getStorage('level')) ? () => { props.changeModal(true) } : e => {
                        e.preventDefault()
                        props.click(props.id, props.name_cn)
                    }}
                    className={cs('iconfont icon-compare', { 'active': props.active })}></i>
                </div>
            </div>
        </div>
    )
}


export default Profile
function copy(text) {
    let reselectPrevious,
        range,
        selection,
        mark,
        success = false
    try {
        reselectPrevious = toggleSelection()
        range = document.createRange()
        selection = document.getSelection()

        mark = document.createElement('span')
        mark.textContent = text
        mark.style.all = 'unset'
        mark.style.position = 'fixed'
        mark.style.top = 0
        mark.style.clip = 'rect(0,0,0,0)'
        mark.style.whiteSpace = 'pre'
        mark.style.webkitUserSelect = 'text'
        mark.style.MozUserSelect = 'text'
        mark.style.msUserSelect = 'text'
        mark.style.userSelect = 'text'
        document.body.appendChild(mark)
        range.selectNodeContents(mark)
        selection.addRange(range)
        const successful = document.execCommand('copy')
        if (!successful) {
            throw new Error('copy command was unsuccessful')
        }
        success = true
    } catch (err) {
        try {
            (window.clipboardData.setData('text', text))
            success = true
        } catch (e) {
            window.console.log(e)
        }
    } finally {
        if (selection) {
            if (typeof selection.removeRange === 'function') {
                selection.removeRange(range)
            } else {
                selection.removeAllRanges()
            }
        }
        if (mark) {
            document.body.removeChild(mark)
        }
        reselectPrevious()
    }
    message.destroy()
    message.info('邮箱复制成功')
}
/* eslint-disable */
function toggleSelection() {
    const selection = document.getSelection()

    if (!selection.rangeCount) {
        return function () { }
    }

    let active = document.activeElement;
    const ranges = []

    for (let i = 0; i < selection.rangeCount; i++) {
        ranges.push(selection.getRangeAt(i))
    }

    switch (active.tagName.toUpperCase()) {
        case 'INPUT':
        case 'TEXTAREA':
            active.blur()
            berak
        default:
            active = null
            break
    }

    selection.removeAllRanges()

    return function restoreSelection() {
        selection.type === 'Caret' && selection.removeAllRanges()
        if (!selection.rangeCount) {
            ranges.forEach(range => {
                selection.addRange(range)
            })
        }

        active && active.focus()
    }
}