import React, { Component, useEffect, useState } from 'react'
import 'styles/companyparison/index.scss'
import { Skeleton, Tabs } from 'antd'
import { connect } from 'react-redux'
import { addComItem, clearComItem, delComItem } from '@redux/company/action'
import axios from 'libs/axios'
import { AvatarLoad } from 'comps/common/AvatarLoad'
import BasicContent from './basic/Content'
import FinancingContent from './financing/Content'
import PatentContent from './patent/Content'
import IndustryContent from './industry/Content'

const { TabPane } = Tabs
@connect(state => state, { addComItem, clearComItem, delComItem })

class ComComparison extends Component {
    constructor(props) {
        super(props)
        this.state = {
            activeKey: '0',
            tabMenu: [
                { id: 0, title: '基本信息' },
                { id: 1, title: '融资数据' },
                { id: 2, title: '科技专利' },
                { id: 3, title: '产业图谱' },
                // { id: 4, title: '研报' },
                // { id: 5, title: '最新消息' }
            ],
            compareLoading: true,
            datas: []
        }
    }

    tabChange = key => {
        this.setState({ activeKey: key })
    }

    componentDidMount() {
        const { compareCompanyItems } = this.props
        const ids = compareCompanyItems.map(item => {
            return new Promise(resolve => {
                axios.post('/company', { id: item.id }).then(res => {
                    if (res.code === 10000) { resolve(res.data) }
                })
            })
        })
        Promise.allSettled(ids).then(res => {
            this.setState({ 
                compareLoading: false,
                datas: res.map(r => r.value )
            })
        })
    }

    render() {
        const { activeKey, tabMenu, datas, compareLoading } = this.state
        return (
            <div className="comperpare">
                <Tabs defaultActiveKey={activeKey} onChange={this.tabChange} tabBarExtraContent={<span className='tab-right' onClick={() => this.props.history.push('/company')}>去列表添加+</span>} tabBarGutter={40} >
                    {
                        tabMenu.map(item => {
                            return <TabPane tab={<div className='tab-title'>{item.title}</div>} key={item.id}>
                                <Skeleton loading={compareLoading} active paragraph={{ rows: 10 }}>
                                    {
                                        datas.map((i, dex) => {
                                            return <React.Fragment key={i.id}>
                                                <div className="com-com-basic-top">
                                                    <div className="image-box">
                                                        <AvatarLoad src={i.logo} type={1} />
                                                    </div>
                                                    <div className="basic-info" onClick={() => this.props.history.push(`/company/${i.id}`)}>
                                                        <span>{i.name}
                                                            <p>
                                                                {
                                                                    i.ipo_status === 1 && i.roundInfo !== null ? (typeof i.roundInfo === 'string' ? i.roundInfo : i.roundInfo.length > 0 && i.roundInfo[0].ticker) : i.roundInfo
                                                                }
                                                            </p></span>
                                                        <span>
                                                            {i.one_line}
                                                            <>
                                                                {
                                                                    i.industryPaths && `${i.industryPaths.filter(item => item.level === 2).name === undefined ? '' : ` · ${i.industryPaths.filter(item => item.level === 2).name}`}`
                                                                }
                                                            </>
                                                            <>
                                                                {
                                                                    i.industryPaths && `${i.industryPaths.filter(item => item.level === 3).name === undefined ? '' : ` · ${i.industryPaths.filter(item => item.level === 3).name}`}`
                                                                }
                                                            </>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="com-com-body">
                                                    {
                                                        item.id === 0 && <BasicContent {...i} />
                                                    }
                                                    {
                                                        item.id === 1 && <FinancingContent {...i} />
                                                    }
                                                    {
                                                        item.id === 2 && <PatentContent {...i} />
                                                    }
                                                    {
                                                        item.id === 3 && <IndustryContent {...i} />
                                                    }
                                                </div>
                                            </React.Fragment>
                                        })
                                    }
                                </Skeleton>
                            </TabPane>
                        })
                    }
                </Tabs>
            </div>
        )
    }
}

export default ComComparison

