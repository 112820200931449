import React, { Component } from 'react'
import { connect } from 'react-redux'
import { switchCate, changeKeywords, changeCate } from '@redux/search/action'
import { Input, message,Empty,Tabs,Skeleton } from 'antd'
import PageCustom from 'comps/PageCustom'
import EmptyCom from './Empty'
import Result from './Result'
import Collection from './Collection'
import { getStorage } from 'utils/storage'
import { TEST_USER } from 'utils/type'
import SearchInput from './SearchInput'

import 'styles/search/result.scss'
import 'styles/pages/search.scss'

const { TabPane } = Tabs

const TABS = [
    {name:'全部',key:'0'},
    {name:'企业',key:'1'},
    {name:'人物',key:'2'},
    {name:'技术',key:'3'},
    // {name:'园区',key:'4'},
]
class Search extends Component {
    constructor(props) {
        super(props)
        this.state = {
            list: [],   // 搜索结果列表
            suggestShow: false,     // 联想框是否关闭
            count: 0,
            keywords: '',
            empty: false,
            page: 1,
            searchLimit:false,    // 是否被搜索限制
            activeKey:'0',  // 选中tab 当前搜索类别
            loading:false,  // 是否搜索完成
        }
        this.hideSuggest = this.hideSuggest.bind(this)
        this.showSuggest = this.showSuggest.bind(this)
        this.onChangePage = this.onChangePage.bind(this)
        this.tabChangeSearch = this.tabChangeSearch.bind(this)
        this.request = this.request.bind(this)
    }
    // 显示联想内容
    showSuggest() {
        this.setState({ suggestShow: true })
    }
    hideSuggest() {
        this.setState({ suggestShow: false })
    }
    // 搜索结果
    request(cate, page = 1 ) {
        // 请求前关闭搜索建议 不显示empty
        this.setState({ list: [],suggestShow: false,empty: false,loading:true,page })
        let keywords = this.props.keywords
        this.axios.post('/search', { key: keywords, size: 20, page,t:cate })
            .then(({code,data:{count,items}}) => {
                if (code === 20025){ // 搜索达到上限
                    this.setState({ searchLimit: true,loading:false })
                } else if (code === 10000 ){
                    if (count) {
                        this.setState({
                            searchLimit:false,
                            count: count,
                            list: items,
                            keywords,loading:false
                        })
                    } else { // 搜索结果为空
                        this.setState({
                            searchLimit:false,
                            empty: true,
                            count: 0,
                            list: [],
                            keywords,loading:false
                        })
                    }
                }
            })
    }
    // 切换搜索类型
    tabChangeSearch(key){
        this.setState({
            activeKey:key,page:1
        })
        this.request(key)
    }
    componentDidMount() {
        this.setState({
            count: 0,
            list: [],
            activeKey:'0'
        },()=>this.request(this.state.activeKey))
        document.title = '搜索'
    }
    // 翻页
    onChangePage(page) {
        this.setState({ page })
        this.request(this.state.activeKey, page)
    }
    render() {
        const isTestUSer =  TEST_USER.includes(getStorage('level')) // 是否是测试用户
        return (
            <div className="search-page" onClick={() => this.setState({ suggestShow: false })}>
                <div className="searchimg-box">
                    <img src={require('../../assets/images/logo.svg')} alt='logo' className="searchLogoImg"/>
                </div>
                {/* 搜索框 */}
                <SearchInput 
                    request={this.request}
                    activeKey={this.state.activeKey}
                    hideSuggest={this.hideSuggest} 
                    showSuggest={this.showSuggest} suggestShow={this.state.suggestShow} 
                    home={true}/>
                <div className="result">
                    <div className="content">
                        {/* tab切换 */}
                        <Tabs activeKey={this.state.activeKey} onChange={this.tabChangeSearch}>
                            { TABS.map(tab => <TabPane tab={tab.name} key={tab.key} ></TabPane>)}
                        </Tabs>
                        <Skeleton active paragraph={{ rows: 20 }} loading={this.state.loading}>
                            {/* 是否为空 */}
                            { this.state.list.length === 0 ?
                                <EmptyCom keywords={this.state.keywords} show={this.state.empty} />
                                :
                                <Result list={this.state.list} 
                                    isTestUSer={isTestUSer}
                                    activeKey={this.state.activeKey}
                                />
                            }
                            {/* 测试用户限制 */}
                            {
                                this.state.searchLimit && isTestUSer && <Empty description='您好，当前搜索次数已达上限，查看完整结果需付费购买，欢迎咨询：18693418964' image={Empty.PRESENTED_IMAGE_SIMPLE}/>
                            }
                        </Skeleton>
                    </div>
                    {/* 右侧推荐专辑 */}
                    {/* <Collection /> */}
                </div>
                {/* 页码 */}
                <PageCustom 
                    total={this.state.count} 
                    currentPage={this.state.page} 
                    pagesize={20}
                    changeStatePage={this.onChangePage}
                />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        keywords: state.keywords,
        cates: state.cates,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        clickCate: cate => {
            dispatch(switchCate(cate))
        },
        // 高亮类别
        setActiveCate: cate => {
            dispatch(changeCate(cate))
        },
        input: keywords => {
            dispatch(changeKeywords(keywords))
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Search)