import React, { Component, useEffect, useMemo, useState } from 'react'
import 'styles/percomparison/index.scss'
import { connect } from 'react-redux'
import { addItem, clearItem, deleteItem } from '@redux/person/action'
import { Skeleton, Tabs } from 'antd'
import { AvatarLoad } from 'comps/common/AvatarLoad'
import axios from 'libs/axios'
import BasicContent from './basic/Content'
import PaperContent from './paper/Content'
import ArticleContent from './article/Content'
import PatentContent from './patent/Content'

const { TabPane } = Tabs
@connect(state => state, { addItem, clearItem, deleteItem })

class PerComparison extends Component {
    constructor(props) {
        super(props)
        this.state = {
            activeKey: '0',
            tabMenu: [
                { id: 0, title: '基本信息' },
                { id: 1, title: '发文趋势' },
                { id: 2, title: '论文分布' },
                { id: 3, title: '专利趋势' }
            ],
            data: [],
            compareLoading: true
        }
    }

    tabChange = key => {
        this.setState({ activeKey: key })
    }

    componentDidMount() {
        const { compareItem } = this.props
        const ids = compareItem.map(item => {
            return new Promise(resolve => {
                axios.post('/expert/index', { id: item.id }).then(res => {
                    if (res.code === 10000) { resolve(res.data) }
                })
            })
        })
        Promise.allSettled(ids).then(res => {
            this.setState({ 
                compareLoading: false,
                data: res.map(r => r.value )
            })
        })
    }

    render() {
        const { activeKey, tabMenu, data, compareLoading } = this.state
        return (
            <div className='perpare'>
                <Tabs defaultActiveKey={activeKey} onChange={this.tabChange} tabBarExtraContent={<span className='tab-right' onClick={() => this.props.history.push('/person')}>去列表添加+</span>} tabBarGutter={40} >
                    {
                        tabMenu.map(item => {
                            return <TabPane tab={<div className='tab-title'>{item.title}</div>} key={item.id}>
                                <Skeleton loading={compareLoading} active paragraph={{ rows: 10 }}>
                                    {
                                        data.map(i => {
                                            return <React.Fragment key={i.id}>
                                                <div className="com-basic-top">
                                                    <div className="image-box">
                                                        <AvatarLoad src={i.photo} type={2} />
                                                    </div>
                                                    <div className='basic-info' onClick={() => this.props.history.push(`/person/detail/${i.id}`)}>
                                                        <span>{i.name_cn || i.name_en}</span>
                                                        <span>{i.employment && i.employment.object_name} · {i.employment && i.employment.position}</span>
                                                    </div>
                                                </div>
                                                <div className="com-body">
                                                    {
                                                        item.id === 0 && <BasicContent {...i} />
                                                    }
                                                    {
                                                        item.id === 1 && <PaperContent {...i} />
                                                    }
                                                    {
                                                        item.id === 2 && <ArticleContent {...i} />
                                                    }
                                                    {
                                                        item.id === 3 && <PatentContent {...i} />
                                                    }
                                                </div>
                                            </React.Fragment>
                                        })
                                    }
                                </Skeleton>
                            </TabPane>
                        })
                    }
                </Tabs>
            </div>
        )
    }
}

export default PerComparison