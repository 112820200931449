import React from 'react'
import Cont from 'pages/tech/Cont'

const Tech = props => {
    return (
        <div className='search-list'>
            
            <div className='sear-detail flex_box'>
                <Cont id={props.id} company={props.map_company} paper={props.map_paper} width={'126px'} height={'84px'}/>
                {/* 内容 */}
                <dl>
                    <dd>
                        <a href={`/tech/${props.id}`} rel="noopener noreferrer" target="_blank">
                            <span className='type'>技术</span>
                            <span className='name'>{props.name}</span>
                        </a>
                    </dd>
                    <dd className='intro'>{props.definition}</dd>
                    <dd>
                        {props.application !== '' && <span className='sign orange'>应用领域：{props.application}</span>}
                    </dd>
                </dl>
            </div>
        </div>
    )
}

export default Tech