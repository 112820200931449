import React from 'react'
import { List, Avatar,Skeleton } from 'antd'
import { AvatarLoad } from 'comps/common/AvatarLoad'
import { EmptyComp } from 'comps/common/EmptyComp'

const Members = props =>{
    const { MemberLoading,MembersData } = props
    return (
        <section id='member' className='member'>
            <h1>团队成员</h1>
            {
                MembersData.length > 0 ? 
                    <List
                        itemLayout="horizontal"
                        dataSource={MembersData}
                        renderItem={item => (
                            <List.Item key={item.id}>
                                <Skeleton loading={MemberLoading} active avatar>
                                    <List.Item.Meta
                                        avatar={<AvatarLoad src={item.photo} type={2}/>}
                                        title={<span className='name'>{item.name_cn || item.name_en}<span>{item.position}</span></span>}
                                        description={item.bio}
                                    />
                                </Skeleton>
                            </List.Item>
                        )}
                    />
                    :
                    <div className=''><EmptyComp /></div>
            }
            
        </section>
    )
}
export default Members