import React, { Component } from 'react'
import { ExclamationCircleFilled } from '@ant-design/icons'
import { Modal, Button, message, Skeleton, Tooltip } from 'antd'
import CollectionCreateForm from './CollectionCreateForm'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Msg } from 'utils'

import 'styles/comps/collect.scss'
import '@/index.scss'

@withRouter
@connect(state => state )
class AddCollectBtn extends Component {
    formRef = React.createRef()
    state = {
        collectVisible: false, // 加入专辑弹框
        lists: [], listLoading: true, // 专辑列表
        createVisible: false, // 新建的专辑弹框
    }
    onCancel = e => {
        e.stopPropagation()
        this.setState({ collectVisible: false })
    }
    // 打开加入专辑弹框 并请求专辑列表
    openCollect = () => {
        const { type, selectKeys } = this.props
        if (selectKeys && selectKeys.length === 0) {
            Msg('warning', '请选择需要收藏的条目！')
            return
        }
        this.setState({ collectVisible: true })
        this.axios.post('/collection/getcollections', { data_type: type }).then(res => {
            if (res.code === 10000) {
                this.setState({
                    lists: res.data,
                    listLoading: false
                })
            }
        })
    }
    // 将目标元素或者榜单 加到选定的专辑
    addCollect = (e, collection_id) => {
        e.stopPropagation()
        const { id, type, listId, selectKeys } = this.props
        if (selectKeys) {
            this.axios.post('/collection/collect', { object_ids: selectKeys.join(','), collection_id, data_type: type })
                .then(res => {
                    if (res.code === 10000) {
                        message.info('添加成功！')
                        this.setState({ collectVisible: false })
                    }
                })
        } else {
            // 目标(公司，人才，技术，园区)object_ids,多个的情况用‘，’分隔 目标专辑collection_id 专辑类型data_type
            !listId ?  // 单个目标的情况
                this.axios.post('/collection/collect', { object_ids: id, collection_id, data_type: type })
                    .then(res => {
                        if (res.code === 10000) {
                            message.success('添加成功！')
                            this.setState({ collectVisible: false })
                        }
                    })
                // 目标是整个榜单列表全部 参数是目标榜单的id,和我的专辑id
                : this.axios.post('/collection/collectall', { collection_id: listId, my_collection_id: collection_id })
                    .then(res => {
                        if (res.code === 10000) {
                            message.success('添加成功！')
                            this.setState({ collectVisible: false })
                        }
                    })
        }
    }
    // 新建专辑
    // 编辑
    createCollect = e => {
        this.setState({
            createVisible: true
        })
    }
    // 取消新建
    handleCancel = () => {
        this.setState({ createVisible: false })
        const current = this.formRef.current.formRef.current
        current.resetFields()
    }
    // 保存新建
    handleCreate = e => {
        const current = this.formRef.current.formRef.current
        const name = current.getFieldValue('name')
        const type = current.getFieldValue('type')
        const describe = current.getFieldValue('describe')
        name ? this.axios.post('/collection/create', { name: name, data_type: type, describe: describe })
            .then(res => {
                if (res.code === 10000) {
                    // 创建新的专辑后把目标元素全部添加到新的专辑内
                    this.addCollect(e, res.data.id)
                    this.setState({ createVisible: false, collectVisible: false }, () => { current.resetFields() }) // 关闭弹框
                }
            }) : Msg('warning', '请填写专辑名称')
    }
    saveFormRef = formRef => {
        this.formRef = formRef
    }
    render() {
        const { type, listId, selectKeys, textDisable, off, authority } = this.props
        const { lists } = this.state
        return (
            <div className="collect_btn" onClick={authority !== false ? this.openCollect : () => this.props.history.push('/regist')}>
                {
                    off ? <Tooltip placement="top" title={!textDisable && (listId ? '全部收藏' : (selectKeys && selectKeys.length > 0 ? `收藏 ${selectKeys.length} 条` : '收藏'))}>
                        <i className='iconfont icon-collect'></i>
                    </Tooltip> :
                        <>
                            {
                                !textDisable && (listId ? '全部收藏' : (selectKeys && selectKeys.length > 0 ? `收藏 ${selectKeys.length} 条` : '收藏'))
                            }
                        </>
                }
                {/* 多种情况：榜单的全部收藏，榜单里面的单个收藏，公司列表多个收藏，公司、园区、任务、技术详情页面的单个收藏 */}
                {/* 原来显示收藏了多少条 */}
                {/* {
                    !textDisable && (listId ? '全部收藏' : (selectKeys && selectKeys.length > 0 ? `收藏 ${selectKeys.length} 条` : '收藏'))
                } */}
                <Modal
                    visible={this.state.collectVisible}
                    title={<div style={{textAlign:'center'}}>选择专辑</div>}
                    className='edit-collect-modal add-collect'
                    footer={null}
                    onCancel={this.onCancel}
                    closeIcon={<i className="iconfont icon-modal-close"></i>}
                >
                    {lists.length > 0 && <div className='tip'>请选择要添加的专辑</div>}
                    {/* 专辑列表 */}
                    {
                        lists.length > 0 ?
                            <Skeleton active paragraph={{ rows: 4 }} loading={this.state.listLoading}>
                                <ul className="scrollBar">
                                    {lists.map(l => <li key={l.id}>
                                        <div>{l.name}({l.num})</div>
                                        <span className='add-btn' onClick={e => this.addCollect(e, l.id)}>添加</span>
                                    </li>)}
                                </ul>
                            </Skeleton>
                            :
                            <span className='no-tip'><ExclamationCircleFilled />暂无相关专辑，请先创建新的专辑</span>
                    }
                    {/* 创建新的专辑 */}
                    <p className='actions'>
                        <Button className='new-collect' onClick={this.createCollect}>创建专辑</Button>
                    </p>
                </Modal>
                {/* 新建专辑弹框 */}
                <CollectionCreateForm
                    wrappedComponentRef={this.saveFormRef}
                    visible={this.state.createVisible}
                    onCancel={this.handleCancel}
                    onCreate={this.handleCreate}
                    type={type}
                    ref={this.formRef}
                />
            </div>
        )
    }
}

export default AddCollectBtn

