import React from 'react'
import { AvatarLoad } from 'comps/common/AvatarLoad'

const Company = props => {
    console.log(props.domainInfo)
    return (
        <div className='search-list'>
            
            <div className='sear-detail flex_box company-list'>
                <AvatarLoad src={props.logo} type={props.type} />
                {/* 内容 */}
                <dl>
                    <dd>
                        <a href={`/company/${props.id}`} rel="noopener noreferrer" target="_blank" >
                            <span className='type'>企业</span>
                            <span className='name'>{props.other_name || props.name}</span>
                            <span className='des'>{props.one_line}</span>
                        </a>
                    </dd>
                    <dd className='intro'>{props.intro}</dd>
                    <dd className='date'>成立时间：{props.found_date}</dd>
                    <dd>
                        {/* TI指数大于50的 显示 */}
                        { props.dt > 50 && <span className='sign orange'>TI指数：{props.dt}</span>}
                        {/* 轮次为 尚未获投和不明确 的不显示 */}
                        {
                            (props.last_round !== '尚未获投' && props.last_round !== '不明确') && <span className='sign orange'>{props.last_round}</span>
                        }
                        {/* 产业 前两个 */}
                        { props.domainInfo.slice(0,2).map(item=><span className='sign blue' key={item.id}>{item.name}</span>)}
                        {/* 榜单 */}
                        { props.labels_info.map(l => <span className='sign blue' key={l.key}>{l.name}</span>)}
                    </dd>
                </dl>
            </div>
        </div>
    )
}

export default Company