import React,{ useState } from 'react'
import 'styles/comps/custom-avatar.scss'
const AvatarLoad = props => {
    const { src,type } = props
    // type 1 公司 2 人物 
    const [reveal, setreveal] = useState(false)
    if (!props.src || reveal) {
        return (<span className="custom-no-avatar">
            <img
                src={require(`assets/images/company/no-${type === 1 ? 'logo' : 'avatar'}.svg`)}
                alt="avatar" className="no-avatar"
            />
        </span>)
    } else {
        return (<span className="custom-avatar">
            <img
                src={props.src}
                className="avatar"
                onError={() => { setreveal(true) }}
                alt="avatar"
            />
        </span>)
    }
}
export { AvatarLoad }