import React from 'react'
import { Anchor,Layout } from 'antd'
import { withRouter } from 'react-router-dom'
import 'styles/comps/sidebar.scss'

// https://ant.design/components/anchor-cn/
const { Sider } = Layout,{ Link } = Anchor

/**
 * 页面的侧边导航 props
 * @param {* Array} data - 必填 [{href:'#basic',title:'概况'}]  导航列表
 * @param {String} type - 'LINK' 跳转类型的锚点 / 不传
 */

const SideBar = props => {
    // 监听锚点上的点击事件
    const handleClick = (e, link) => {
        e.preventDefault()
        if (link.href.indexOf('/') > -1){ // 如果是链接锚点，则跳转
            props.history.push(link.href)
        }
    }
    // 自定义高亮的锚点  针对跳转到路由的锚点
    const getCurrentAnchor = () => {
        return props.location.pathname // 返回当前路由地址
    }
    return (
        <Sider className='sider-lt'>
            <Anchor onClick={handleClick} offsetTop={86} 
                // 只有跳转链接的锚点才有这个自定义高亮锚点
                getCurrentAnchor={ props.type === 'LINK' ? getCurrentAnchor : null}
            >
                {
                    typeof props.data !== 'undefined' && props.data.map(side=>{
                        return <Link href={side.href} title={side.title} key={side.href} />
                    })
                }
            </Anchor>
        </Sider>
    )
}

export  default withRouter(SideBar)