import React, { Component } from 'react'
import * as echarts from 'echarts'
import { COLORS } from 'utils/type'

// 参考：https://www.makeapie.com/editor.html?c=xByhZucYHW
// 参考：https://blog.csdn.net/tt18473481961/article/details/108045893
// 官方：https://github.com/ecomfe/echarts-wordcloud

class PaperCloud extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    componentDidMount() {
        this.initChart()
    }
    initChart = () => {
        const option = {
            backgroundColor: "#fff",
            title: {
                text: '论文主题',
                left: '5%',
                top: '0%',
                textStyle: {
                    fontSize: 14,
                    fontFamily: 'PingFangSC- Regular, PingFang SC',
                    fontWeight: 400,
                    color: '#333333'
                }
            },
            // tooltip: {
            //   pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>"
            // },
            series: [
                {
                    type: "wordCloud",
                    //用来调整词之间的距离
                    gridSize: 0,
                    //用来调整字的大小范围
                    // Text size range which the value in data will be mapped to.
                    // Default to have minimum 12px and maximum 60px size.
                    sizeRange: [8, 16],
                    // Text rotation range and step in degree. Text will be rotated randomly in range [-90,                                                                             90] by rotationStep 45
                    //用来调整词的旋转方向，，[0,0]--代表着没有角度，也就是词为水平方向，需要设置角度参考注释内容
                    // rotationRange: [-45, 0, 45, 90],
                    // rotationRange: [ 0,90],
                    rotationRange: [0, 0],
                    //随机生成字体颜色
                    // maskImage: maskImage,
                    textStyle: {
                        normal: {
                            color: function () {
                                return COLORS[Math.floor((Math.random() * COLORS.length))]
                            }
                        }
                    },
                    //位置相关设置
                    // Folllowing left/top/width/height/right/bottom are used for positioning the word cloud
                    // Default to be put in the center and has 75% x 80% size.
                    // left: "center",
                    // top: "center",
                    // right: null,
                    top: 40,
                    width: "80%",
                    height: "75%",
                    data: this.props.data
                }
            ]
        }
        let myChart = echarts.init(document.getElementById(this.props.id))
        myChart.setOption(option)
        // 点击某个字 跳转到技术的详情页面
        // myChart.on('click',function(params){
        //     window.location.href = `/tech/${params.data.id}`
        // })
    }
    render() {
        return <div id={this.props.id} style={{ width: '700px', height: '170px' }}></div>
    }
}

export default PaperCloud