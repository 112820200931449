import React, { Component } from 'react'
import { Skeleton } from 'antd'
import { AvatarLoad } from 'comps/common/AvatarLoad'
import AddCollectBtn from 'comps/collect/AddCollectBtn'
// import DownloadBtn from 'comps/ppt/DownloadBtn'
import { COMP_STATUS } from 'utils/type'
// redux
import { connect } from 'react-redux'
import { addComItem, delComItem } from '@redux/company/action'
import CompareBtn from 'comps/compare/CompareBtn'
import PackUp from 'comps/pack-up/PackUp'
// 公司概念
class BasicInfo extends Component {

    render() {
        let { BasicData, RoundData, labels, contacts, BasicLoading, compareItems } = this.props
        // 除去华为的尚未获投和未确定的轮次
        const Round = RoundData.filter(r => r.id !== 15 && r.id !== 13)
        return (
            <section id='basic' className='basic'>
                <Skeleton active avatar paragraph={{ row: 4 }} loading={BasicLoading}>
                    <div className='header-title flex_box' >
                        {
                            Object.keys(BasicData).length > 0 &&
                            <h1 className='flex_box'>
                                <AvatarLoad src={BasicData.logo} type={1} />
                                <dl>
                                    <dt>
                                        {BasicData.other_name}
                                        {/* 已上市 ? 股票代码 : 连接融资接口 */}
                                        <span className='stock-code'>
                                            {
                                                BasicData.ipo_status === 1 && BasicData.roundInfo !== null ?
                                                    (typeof BasicData.roundInfo === 'string' ? BasicData.roundInfo : BasicData.roundInfo.length > 0 && BasicData.roundInfo[0].ticker) :
                                                    // 尚未获投不显示 针对华为 id=15
                                                    RoundData.length > 0 && Round.filter(round => round.id === BasicData.last_round).length > 0 && Round.filter(round => round.id === BasicData.last_round)[0].name
                                            }
                                        </span>
                                    </dt>
                                    {/* 二级行业 - 三级行业 - 简介 */}
                                    {
                                        BasicData.industryPaths && <dd>
                                            {
                                                BasicData.industryPaths.filter(list => list.level === 2).length > 0 && <strong>{
                                                    BasicData.industryPaths.filter(list => list.level === 2)[0].name
                                                } ·  </strong>
                                            }
                                            {
                                                BasicData.industryPaths.filter(list => list.level === 3).length > 0 && <strong>{
                                                    BasicData.industryPaths.filter(list => list.level === 3)[0].name
                                                } ·  </strong>
                                            }
                                            <strong>{BasicData.one_line}</strong>
                                        </dd>
                                    }
                                    {/* 行业-地区-标签 */}
                                    <dd>
                                        {
                                            labels.map(label => {
                                                return <i key={label.id}>{label.name || label.Name}</i>
                                            })
                                        }
                                    </dd>
                                </dl>
                            </h1>
                        }
                        <ul className='fn-ul'>
                            {/* 对比功能 */}
                            <li className='flex_box compare'>
                                <CompareBtn compareOff={1} id={BasicData.id} other_name={BasicData.other_name}/>
                            </li>
                            {/* 收藏功能 */}
                            <li className='flex_box collect'>
                                <AddCollectBtn off={true} type={1} id={BasicData.id} />
                            </li>
                            {/* PPT下载功能 */}
                            {/* <li className='flex_box down'>
                                <DownloadBtn type='company' id={BasicData.id} pptData={props.pptData}/>
                            </li> */}
                        </ul>
                    </div>
                    {
                        Object.keys(BasicData).length > 0 &&
                        <div className='summary com-info'>
                            <dl>
                                <div className='summary-auto'>
                                    <dt className='flex_box'>
                                        <span>{BasicData.name}</span>
                                        <span><i>成立于</i>{BasicData.found_date}</span>
                                        <span><i>公司规模</i>{BasicData.size}</span>
                                        {/* 运营状态 */}
                                        <span className='operate'>{COMP_STATUS[`${BasicData.status}`]}</span>
                                    </dt>
                                    {/* <dd ref={this.saveRef}>{BasicData.intro}</dd> 公司简介 */}
                                    <PackUp introduce={BasicData.intro} maxHeight={70}></PackUp>
                                </div>
                              
                            </dl>
                            {
                                BasicData.contacts !== null && <ul className='flex_box link_ul'>
                                    {contacts.phone !== '' && <li><span>电话：</span><i title={contacts.phone}>{contacts.phone !== 'null' ? contacts.phone : '暂无'}</i></li>}
                                    {contacts.email !== '' && <li><span>邮箱：</span><i title={contacts.email}>{contacts.email !== 'null' ? contacts.email : '暂无'}</i></li>}
                                    {contacts.address !== '' && <li><span>地址：</span><i title={contacts.address}>{contacts.address !== 'null' ? contacts.address : '暂无'}</i></li>}
                                    {contacts.website !== '' && <li><span>网址：</span><i title={contacts.website}>{contacts.website !== 'null' ? contacts.website : '暂无'}</i></li>}
                                </ul>
                            }
                        </div>
                    }
                </Skeleton>
            </section>
        )
    }
}

function mapStateToProps(state) {
    return {
        compareItems: state.compareCompanyItems
    }
}

function mapDispatchToProps(dispatch) {
    return {
        addComItem: item => {
            dispatch(addComItem(item))
        },
        delComItem: item => {
            dispatch(delComItem(item))
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(BasicInfo)