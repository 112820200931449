import React from 'react'
import { Link } from 'react-router-dom'
import { Skeleton } from 'antd'
import 'styles/person/academic.scss'
import className from 'classnames'

class Academic extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            isLoading: true
        }
    }
    componentDidMount() {
        this.axios.post('/expert/researchrank', { id: this.props.id })
            .then(res => this.setState({ data: res.data, isLoading: false}))

    }
    render() {
        return (
            <Skeleton  active loading={this.state.isLoading}>
                <div className="section-title academic-title">影响力排行</div>
                <div className="academic-box">
                    <div className="academic">

                        {
                            this.state.data && this.state.data.length === 0 ?
                                <div className="emptyimg">
                                    <img src={require("assets/images/person/empty.png")} alt="" />
                                </div> :
                                this.state.data && this.state.data.map(item => <div key={item.field_id} className={className({
                                    "ranking-list": true,
                                    "ranking-no-bottom": this.state.data.length === 1
                                })} >
                                    <div className="left">
                                        {/* <img src={require('assets/images/person/award.png')} alt="aword" /> */}
                                        <Link to={{
                                            pathname:`/person/effect/${item.location_id}/${item.field_id}`,
                                            state:{title:`${item.location_name}${item.field_name}`}
                                        }}>
                                            {item.location_name}
                                            <span>#{item.field_name}</span>
                                        </Link>
                                    </div>
                                    <div className="order">第<span className="nums">{item.rank}</span>位</div>
                                </div>)}
                    </div>
                </div>
            </Skeleton>
        )
    }
}


export default Academic
