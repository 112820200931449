import React from 'react'
import { Empty } from 'antd'
import 'styles/comps/empty.scss'

function EmptyComp(){
    return (
        <div className='update-data no-data'>
            <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE}/>
            <span>数据正在更新中</span>
        </div>
    )
}

// 根据可视区域 居中显示
function CustomEmptyImage(){
    return (
        <div className='custom-empty flex_box'>
            <img src={require("assets/images/person/empty.png")} alt="数据正在更新中..." />
        </div>
    )
}

function CompareEmptyEmage(){
    return (
        <div className='compare-empty'>
            <img src={require("assets/images/no_pic.png")} alt="数据正在更新中..." />
        </div>
    )
}
export { EmptyComp, CustomEmptyImage, CompareEmptyEmage }