/* eslint-disable no-mixed-spaces-and-tabs */
import React               from 'react'
import PropTypes from 'prop-types'
import Nav from 'comps/navbar/Nav'
import Footer from 'comps/navbar/Footer'
import { Layout } from 'antd'

class WebLayout extends React.Component {
    static propTypes = {
    	children: PropTypes.node
    }
	state = {
	    // hasAside:false
	}
	// 跳转之后重新回到顶端 router4.x的坑
	componentDidUpdate(prevProps) {
    	if (this.props.location.pathname !== prevProps.location.pathname) {
    		window.scrollTo(0, 0)
    		// window.location.reload()
    	}
	}
	componentDidMount(){
	    // 底部显示条件
	    // let isAside = document.querySelectorAll('.aside')
	    // let hasAside = document.querySelectorAll('aside')
	    // let hasAffix = document.querySelectorAll('.collection-con')
	    // this.setState({
	    //     hasAside:isAside.length > 0 || hasAside.length > 0 || hasAffix.length > 0
	    // })
	}
	render() {
	    const { pathname } = this.props.location
    	return (
    		<Layout className='app-container'>
    			<Nav />
	            {
	                pathname === '/vippage' ? this.props.children
	                : <section className='container'>
	                        {this.props.children}
	                    </section> 
	            }
	            <Footer />
    			{/* <BackTop /> */}
    		</Layout>
    	)
	}
}

export default WebLayout
