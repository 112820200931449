import React from 'react'
import moment from 'moment'
import RandomImg from 'comps/random-img/RandomImg.jsx'
import formatType from 'utils/formatType'
class RecommendItem extends React.Component {
    render() { 
        const {recommendData} = this.props
        return ( 
            <div>
                {
                    this.props.isDetail ? <div className="recommend-item recommend-detail-item">
                        <RandomImg randomName={recommendData.name} photoUrl={recommendData.photo} randomWidth={'120px'} randomHeight={'67px'} dataType={recommendData.data_type} />
                        <div className="introduce">{recommendData.name}</div>
                    </div> : <div className="recommend-item">
                        <RandomImg randomName={recommendData.name}  photoUrl={recommendData.photo} randomWidth={'120px'} randomHeight={'67px'}  dataType={recommendData.data_type} />
                        <div className="introduce">{recommendData.name}</div>
                        <div className="all">{formatType(recommendData.obj_num, recommendData.data_type)}</div>
                        <div className="date">发布日期：{moment(recommendData.release_time * 1000).format('YYYY年MM月DD日')}</div>
                    </div>
                }
            </div>
        )
    }
}
 
export default RecommendItem