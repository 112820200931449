import React, { Component } from 'react'
import BreadComp  from 'comps/bread'
import { EmptyComp } from 'comps/common/EmptyComp'
import Project from './Project'
import PageCustom from 'comps/PageCustom'
class ProjectList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            list: [],
            count: 0,
            name: '',
            // 默认页数
            page: 1,
            BreadData: [{url:'/home',title:'首页'},{url:'/person',title:'人物列表'},{url:'',title:''},{url:'',title:'项目'}]
        }
        this.onChange = this.onChange.bind(this)
    }

    componentDidMount() {
        this.requestProject({ id: this.props.match.params.id})
        this.axios.post('/expert/index', { id: this.props.match.params.id })
            .then(res => {
                if (res.code === 10000) {
                    this.setState({
                        name: res.data.name_cn ? res.data.name_cn : res.data.name_en
                    },()=>{
                        document.title = this.state.name
                        let Bs = [{url:'/home',title:'首页'},{url:'/person',title:'人物列表'},{url:'/person/detail/' + this.props.match.params.id,title: this.state.name},{url:'',title:'项目'}]
                        this.setState({BreadData: Bs})
                    })
                }
            })
    }
    requestProject(ops) {
        this.axios.post('/expert/project',ops )
            .then(res => {
                this.setState({
                    list: res.data.data,
                    count: res.data.total[0]
                })
            })
    }
    onChange(page) {
        this.setState({page}, () => {
            this.requestProject({id: this.props.match.params.id,page})
        })
        
    }
    render() {
        return (
            <div className="content-list">
                <BreadComp data={this.state.BreadData} />
                <div className="content-title">参与项目<span>共{this.state.count}项</span></div>
                <section className="content-box">
                    {this.state.list.length === 0 ? <EmptyComp /> :
                        this.state.list.map((item, index) => <Project key={item.id} {...item} botmBorder={index + 1 === this.state.list.length ? true : false}/>)}
                    
                    {this.state.count !== 0 &&
                        <PageCustom
                            currentPage={this.state.page}
                            pagesize={10}
                            total={this.state.count}
                            changeStatePage={this.onChange}
                        />}
                </section>
            </div>
        )
    }
}


export default ProjectList