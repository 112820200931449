import React from 'react'
import { Tabs, Modal, Checkbox, Table } from 'antd'
import * as echarts from 'echarts'
// 可视化组件
import GeoBar from 'pages/echarts/geo'
import BtmFcBtn from './BtmFcBtn'
import { GeoCoordMapCity } from 'utils/type'
// 配置
import { IndustryGeoColumns } from 'utils/columns'
// 导出方法
import { toMoney,saveExcel,turnTableData,echartSavePic } from 'utils'

const { TabPane } = Tabs

// 产品要求tabs展示的顺序
const TabsTitle = [
    {name:'融资',key:'invest'},
    {name:'并购',key:'merger'},
    {name:'企业分布',key:'all'},
    {name:'上市',key:'up'},
]

class IndustryGeoTabs extends React.Component{
    state = {
        showMode:'line',visible:false,currentNum:0,checkValue:0,
        tableData:[],
        echartId : !this.props.fullID ?  'geo' : `${this.props.fullID}-geo`, //echart 图表唯一ID
    }
    componentDidMount(){
        let tableData = []
        const propsData = this.props.data
        // 处理table列表的数据
        for ( let k of Object.keys(GeoCoordMapCity)){
            tableData.push({
                city  : k,
                all   : propsData['all'][`${k}`] || 0,
                up    : propsData['up'][`${k}`] || 0,
                invest: propsData['invest'][`${k}`] || 0,
                merger: propsData['merger'][`${k}`] || 0
            })
        }
        // 按照企业数量排序
        tableData.sort((a, b)=>{
            return b.all - a.all
        })
        this.setState({ tableData })
    }
    // 切换展示形式：line table
    showChange = e =>{
        this.setState({ showMode:e.target.value })
    }
    // tab切换：行业分布 企业形态 key是字符串
    tabChange = key =>{
        this.setState({ currentNum:Number(key) })
    }
    // 底部左右箭头 切换tab type:reduce or add
    toggleChartType = type =>{
        const { currentNum } = this.state
        const maxNum = TabsTitle.length - 1
        let num = currentNum
        if (type === 'add'){
            num = currentNum === maxNum ? 0 : ++ num
        } else {
            num = currentNum === 0 ? maxNum : -- num
        }
        this.setState({ currentNum:num })
    }
    // 切换全屏
    fullScreen = () => {
        this.setState({ visible: true })
    };
    hideModal = () => {
        this.setState({ visible: false })
    };
    // 保存为excel格式的文件
    savaTableCsv = () =>{
        const { showMode,tableData,echartId,currentNum } = this.state
        const { indName } = this.props
        const filename = `${indName}-${TabsTitle[currentNum].name}` // 文件名
        const type = TabsTitle[currentNum].key // 选择数据类型
        if (showMode === 'line'){
            echartSavePic(filename,`${type}${echartId}`) // 下载png格式图片 文件名和唯一ID
        } else {
            // 使用export-excel插件
            saveExcel(IndustryGeoColumns,turnTableData(IndustryGeoColumns,tableData),filename)
        }
    }
    // checkChange = (e,v) =>{
    //     this.setState({ checkValue:v })
    // }
    render(){
        const { showMode,currentNum,tableData,echartId } = this.state
        const { fullID,data,indName } = this.props
        // 单选项列表
        // const checkBoxOptions = [{ label: '医药', value: 0 },{ label: '创新药', value:1 },{ label: '仿制药', value:2 },{ label: 'CRO', value:3 },
        //     { label: 'CMO', value: 4 },{ label: '生物疫苗', value:5 },{ label: '医药商业零售', value:6 },{ label: '消费级医疗需求', value:7 },
        // ]
        return (
            <div className='tabcharts industry-tabs'>
                <Tabs activeKey={String(currentNum)} onChange={this.tabChange}>
                    {
                        TabsTitle.map((tab,index) =>{
                            const chartsTop = <ul className='charts-top flex_box'>
                                <li className='flex_box'> <span>{data.all_num}<i>家</i></span><span>企业总数</span> </li>
                                <li className='flex_box'> <span>{data.up_num}<i>家</i></span><span>上市企业数量</span> </li>
                                <li className='flex_box'> <span>{data.invest_num}<i>起</i></span><span>融资事件</span> </li>
                                <li className='flex_box'> <span>{data.merger_num}<i>起</i></span><span>并购事件</span> </li>
                            </ul>
                            return (
                                <TabPane tab={tab.name} key={index} >
                                    {/* 单选项 */}
                                    {/* <p className='check-group'>
                                        { checkBoxOptions.map(checkList=>{
                                            return <Checkbox checked={checkList.value === this.state.checkValue}
                                                onChange={(e,v)=>this.checkChange(e,checkList.value)}>
                                                {checkList.label}
                                            </Checkbox>
                                        })}
                                    </p> */}
                                    {chartsTop}
                                    {
                                        showMode === 'line' ? 
                                            <GeoBar data={data[`${tab.key}`]} id={`${tab.key}${echartId}`} indName={indName} title={tab.name}/>
                                            :
                                            <Table pagination={false} rowKey={ record=> record.city }
                                                dataSource={ tableData }  scroll={{ y: 400 }} 
                                                columns={ IndustryGeoColumns } className='custom-table' />      
                                    }
                                </TabPane>
                            )
                        })
                    }
                </Tabs>
                {/* 底部功能图标 */}
                <BtmFcBtn isDispalyTitle={true} isDispalyRadio={true} isDispalyDown={true}
                    TabsTitle={TabsTitle} currentNum={currentNum} showMode={showMode} fullID={fullID}
                    toggleChartType={this.toggleChartType} savaTableCsv={this.savaTableCsv} showChange={this.showChange} fullScreen={this.fullScreen}
                />
                {/* 全屏 */}
                <Modal
                    title="地理情况"
                    visible={this.state.visible}
                    onOk={this.hideModal}
                    onCancel={this.hideModal}
                    className='echart-full'
                    width={1000}
                    footer={null}
                >
                    <IndustryGeoTabs fullID='full' id={echartId} data={data} indName={indName}/>
                </Modal>
            </div>
        )
    }
}
export default IndustryGeoTabs

