import React from 'react'

const dataData = [
    {title:'数据可视化',des:'通过具有自然语言处理功能的数据分析引擎，来挖掘大量的非结构化文档，用以识别有关新兴技术和颠覆性技术与商业趋势的信号，通过可视化模型来呈现。'},
    {title:'工具智能化',des:'横向对比包括技术、专家、企业、政策在内的科技要素，纵向构建关注要点，实时计算您所关注的科技要素指数及前景预测。'},
    {title:'分析结构化',des:'从业经验丰富的分析师从技术、商业、市场、专利等维度出发，结构化呈现科技要素在时空区间的竞争力，技术趋势及商业机会一目了然。'},
    {title:'情报结论化',des:'人才多维评价、企业经营状况、产业发展前景、技术迭代路径、地域成长空间，科技要素的多维度智能分析让决策更加果断。'},
]
const sciData = [ '高校学者','研究机构科研工作者','研究机构科创菁英' ]
const enterData = [ '技术转型企业','生产制造企业','优质投资机构']

const FloorSection = props => {
    return (
        <div>
            <section className='data'>
                <div className='container'>
                    <h1 className='title'>数据驱动分析，智能获取情报 <span>一站式获取关于科技的所有趋势与情报</span></h1>
                    {
                        dataData.map((list,index) => <dl key={list.title}>
                            <svg className="symbol_icon" aria-hidden="true">
                                <use xlinkHref={`#icon-home-data${index}`}></use>
                            </svg>
                            <dt>{list.title}</dt>
                            <dd>{list.des}</dd>
                        </dl>)
                    }
                </div>
            </section>
            {/* 科创专家团队 */}
            <div className='container sci flex_box'>
                <dl>
                    <dt>科创专家团队</dt>
                    <dd className='des'>建立专业的科学家服务团队，为科研工作者提供丰富的产业信息、社交环境，并实时更新科研工作者的科学技术研发进展、成果发表、商业应用需求。科学家们也可以通过络绎TI沟通渠道上发布自己的科研方向及成果，来寻找合适的机构合作方。</dd>
                    {
                        sciData.map((list,index) => <dd className='list' key={list}>
                            <i className={`iconfont icon-home-sci${index}`}></i>{list}
                        </dd>)
                    }
                </dl>
                <img src={require('assets/images/home/icon2_right.svg')} alt='icon'/>
            </div>
            {/* 企业会员 */}
            <div className='container sci flex_box'>
                <img src={require('assets/images/home/icon3_left.svg')} alt='icon'/>
                <dl>
                    <dt>企业会员</dt>
                    <dd className='des'>为了促进科研工作者之间以及科研工作者与企业之间的高效沟通，我们将与全球各大科研学术机构、高校机构建立合作关系，通过丰富的线下活动和合作模式促进科研学术界与产业互通，打造一个科研与产业发展趋势、人才与企业、项目与资本密切互动的社交平台。</dd>
                    {
                        enterData.map((list,index) => <dd className='list' key={list}>
                            <i className={`iconfont icon-home-com${index}`}></i>{list}
                        </dd>)
                    }
                </dl>
            </div>
        </div>
    )
}
export default FloorSection