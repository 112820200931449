import React, { useState } from 'react'
import { Button } from 'antd'
import { Tootip1 } from 'comps/tootip/Tootip'
import { TEST_USER } from 'utils/type'
import { getStorage } from 'utils/storage'

import 'styles/comps/exportbtn.scss'

const ExportBtn = props => {
    const [ toopvisible,setToopvisible ] = useState(false)
    const onExportEvent = () => {
        // 权限限制 测试用户打开提示
        if (TEST_USER.includes(getStorage('level')) ){
            setToopvisible(true)
        // 正式用户 使用当前页面的 props 导出方法
        } else { 
            props.exportList()
        }
    }
    // 更改权限弹框 是否打开状态
    const changeModal = value => {
        setToopvisible(value)
    }
    return (
        <>
            <Button onClick={onExportEvent} className='export-btn'>导出</Button>
            {/* 权限弹框 */}
            <Tootip1 value={toopvisible} changeModal={changeModal} />
        </>
    )
}

export default ExportBtn