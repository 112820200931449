import React from 'react'
import { Tag } from 'antd'
import cs from 'classnames'
import FilterInput from './FilterInput'
// redux
import { connect } from 'react-redux'

const { CheckableTag } = Tag

@connect(state => state)
class SelectTags extends React.Component {
    state = {
        showAll: false,
    }
    /**
     * @description: 处理content文案的点击展开收起
     * @return: null
     */
    handleContent = e => {
        e.stopPropagation()
        let { showAll } = this.state
        this.setState({
            showAll: !showAll
        })
    };

    // 当前tag是否高亮  return true->高亮 false->不高亮
    isHighLighted = (id,type) => {
        const { perLabels, comLabels,filterPage } = this.props
        const selectLabels = filterPage === 'company' ? comLabels : perLabels
        if (id){ // id !== 0 的情况 是否包含在redux中
            return selectLabels.some(selectTag => selectTag.id === id && selectTag.cate === type )
        } else { // 全部选项  当redux的当前类没有数据时 高亮
            return selectLabels.filter(selectTag => selectTag.cate === type ).length === 0
        }
    }

    render() {
        let { showAll } = this.state
        const { isFold } = this.props // 是否显示展开收起按钮
        const { TagsData,handleChange,text,type,filterPage } = this.props
        return (
            <div className={cs('select-tag flex_box', 
                {'hidden-text': !showAll && isFold },
                {'filter-box': type === 'college' || type === 'tech'},
                {'alpha-box': (type === 'alphabet')})}>
                <span className='title'>{text}</span>
                <div className='tags_box'>
                    {TagsData.map(tag => (
                        <CheckableTag key={tag.id} checked={this.isHighLighted(tag.id,type)}
                            onChange={checked => handleChange(tag.id, checked,tag)}
                        >
                            {tag.name || tag.ShortName}
                        </CheckableTag>
                    ))}
                </div>
                {/* 技术筛选项 增加 搜索框 */}
                { type === 'tech' && <FilterInput postUrl='/nav/searchtech' keyword='请输入关键词，选择更多技术' cate={type} type={filterPage} /> }
                { type === 'college' && <FilterInput postUrl='/expert/searchschool' keyword='请输入关键词，选择更多院校' cate={type} type={filterPage}/> }
                {
                    isFold && <span className="toggle-btn-sel"
                        onClick={e => { this.handleContent(e) }}>
                        {!showAll ? 
                            <>展开 <i className='iconfont icon-down-arrow'></i></> 
                            : 
                            <>收起 <i className='iconfont icon-top-arrow'></i></>}
                    </span>
                }
            </div>
        )
    }
}

// 行业的二级
function SubSelectTags(props){
    const { subData,subChange,comLabels,type } = props
    // 当前tag是否高亮  return true->高亮 false->不高亮
    const isHighLighted = (id,type) => {
        if (id){ // id !== 0 的情况 是否包含在redux中
            return comLabels.some(selectTag => selectTag.id === id && selectTag.cate === type )
        } else { // 全部选项  当redux的当前类没有数据时 高亮
            return comLabels.filter(selectTag => selectTag.cate === type ).length === 0
        }
    }
    return (
        <div className='sub-select-tag'>
            <span className='title'>细分行业</span>
            <div>
                {subData.map(tag => (
                    <CheckableTag key={tag.id} checked={isHighLighted(tag.id,type)}
                        onChange={checked => subChange(tag.id, checked,tag)}
                    >
                        {tag.name}
                    </CheckableTag>
                ))}
            </div>
        </div>
    )
}
export { SelectTags, SubSelectTags }