import React, { useEffect } from 'react'
import { toMoney, makeFriendlyCH } from 'utils'
import { A_KEYS, US_KEYS, HK_KEYS } from 'utils/stock'

export default function Round(props) {
    const { gpType, cell } = props
    let cellItem = []
    cell.forEach(item => {
        cellItem.push(item.per_share)
    })

    useEffect(() => {
        if (gpType === 'G') {
            cellItem.forEach(item => {
                item.mgzb = ''
                item.ccnlzb = ''
                item.ylnlzb = ''
                item.ylzlzb = ''
                item.cwfxzb = ''
            })
            cell.forEach((item, index) => {
                item.per_share = cellItem[index]
            })
        }
        if (gpType === 'U') {
            cellItem.forEach(item => {
                item.REVENUE = changeUnit(item.REVENUE, 1, '亿')
                item.GROSSMARGIN = changeUnit(item.GROSSMARGIN, 1, '亿')
                item.PARENTPROFIT = changeUnit(item.PARENTPROFIT, 2, '亿')
                item.REVENUEYOY = changeUnit(item.REVENUEYOY, 2, '%')
                item.GROSSMARGINYOY = changeUnit(item.GROSSMARGINYOY, 2, '%')
                item.PARENTPROFITYOY = changeUnit(item.PARENTPROFITYOY, 2, '%')
                item.GROSSPROFITMARGIN = changeUnit(item.GROSSPROFITMARGIN, 2, '%')
                item.NETPROFIT = changeUnit(item.NETPROFIT, 2, '%')
                item.ACCOUNTRATE = changeUnit(item.ACCOUNTRATE, 2, '')
                item.TURNOVERRATE = changeUnit(item.TURNOVERRATE, 2, '')
                item.TOTALRATE = changeUnit(item.TOTALRATE, 2, '')
                item.ACCOUNTDAYS = changeUnit(item.ACCOUNTDAYS, 2, '')
                item.TURNOVERDAYS = changeUnit(item.TURNOVERDAYS, 2, '')
                item.EQUALITYRATE = changeUnit(item.EQUALITYRATE, 2, '%')
                item.NETASSETRATE = changeUnit(item.NETASSETRATE, 2, '%')
                item.LIQUALITYRATE = changeUnit(item.LIQUALITYRATE, 2, '')
                item.QUICKRATIO = changeUnit(item.QUICKRATIO, 2, '')
                item.CASHRATIO = changeUnit(item.CASHRATIO, 2, '')
                item.TOTALDAYS = changeUnit(item.TOTALDAYS, 2, '')
                item.ASSETRATIO = changeUnit(item.ASSETRATIO, 2, '%')
                item.PROPERTYRATIO = changeUnit(item.PROPERTYRATIO, 2, '%')
            })
            cell.forEach((item, index) => {
                item.per_share = cellItem[index]
            })
        }
    }, [cell, cellItem, gpType])



    return (
        <div className="round">
            <div className="left">
                <div className="cell emp">报告日期</div>
                {Object.values(Find(gpType)).map((item, index) => {
                    return <div key={index} className={item === '每股指标' || item === '成长能力指标' || item === '盈利能力指标' || item === '盈利质量指标' || item === '财务风险指标' || item === '财报类型' ? 'cell emp' : 'cell'}>{item}</div>
                })}
            </div>
            <div className="right">
                {cell.map(report => <Rep key={report.date} date={report.date} data={report.per_share} gpType={props.gpType} />)}
            </div>
        </div>
    )
}

function changeUnit(item, num, unit) {
    if (isNaN(parseFloat(item))){
        return '--'
    } else if (unit === '亿'){
        return (parseFloat(item) / 100000000).toFixed(num) + unit
    } else {
        return parseFloat(item).toFixed(num) + unit
    }
}


function Find(type) {
    switch (type) {
    case "A":
        return A_KEYS
    case "U":
        return US_KEYS
    default:
        return HK_KEYS
    }
}


function Rep(props) {

    return (
        <div className="report">
            <div className="cell emp">{props.date}</div>
            {
                Object.keys(Find(props.gpType)).map((k, index) => {
                    return <div key={index} className={k === 'TIMETYPE' ? 'cell emp' : 'cell '}>{`${props.data[`${k}`]}`}</div>
                })
            }
        </div>
    )
}
