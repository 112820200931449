import React from 'react'
import 'styles/official/signup.scss'
import { Layout } from 'antd'

function Signup(){
    return (
        <Layout className='all-box-signup'>
            <img className='picture' src={require('../../assets/images/official/sec1.jpg')} alt='sec1'/> 
            <img className='picture' src={require('../../assets/images/official/sec2.jpg')} alt='sec2' />
            <img className='picture' src={require('../../assets/images/official/sec3.jpg')} alt='sec3' />
            <img className='picture' src={require('../../assets/images/official/sec4.jpg')} alt='sec4' />
            <img className='picture' src={require('../../assets/images/official/sec5.jpg')} alt='sec5' />
        </Layout>
    )
}

export default Signup