import React, { Component } from 'react'
import BreadComp from 'comps/bread'
import { EmptyComp } from 'comps/common/EmptyComp'
import CompareBtn from 'comps/compare/CompareBtn'
import ExportBtn from 'comps/common/ExportBtn'  // 导出按钮
import { saveWithALink } from 'utils'
import { Skeleton } from 'antd'
import { ChainComp } from 'comps/chain'
import Industry from './graph/Industry'
import { Tootip2 } from 'comps/tootip/Tootip'
import { TEST_USER } from 'utils/type'
import { getStorage } from 'utils/storage'
import '../../index.scss'

const BreadData = [{ url: '/home', title: '首页' }, { url: '/board/general', title: '科创板' }, { url: '', title: '产业图谱' }]

class Graph extends Component {
    constructor(props) {
        super(props)
        this.state = {
            currentPage: 1,
            data: [],
            chain: '人工智能',
            domainline: [],
            lineH: false
        }
        this.getChain = this.getChain.bind(this)
    }

    // 设置产业链名称 并且获取对应公司
    getChain(industry) {
        this.setState({
            chain: industry.name,
            industryID: industry.id,
            currentPage: 1
        })
        this.axios.post('/domain/line',{ id: industry.id }).then(res=>{
            if (res.code === 10000){
                this.setState({
                    domainline:res.data,
                    lineH: true,
                })
            }
        })
        // 依据产业id 发送请求
        // this.axios.post('/starmarket/domainline', { id: industry.id })
        //     .then(res => this.setState({ domainline: res.data, lineH: true }))
        this.request({ id: industry.id, size:20, page:1 })
    }

    request(options) {
        this.axios.post('/starmarket/domaincompanys', options)
            .then(res => {
                this.setState({
                    data: res.data.data,
                    total: res.data.total
                })
            })
    }

    componentDidMount() {
        this.getChain({ id: 44, name: '人工智能' }) // 默认请求的产业
    }

    // 更改页码
    changeStatePage = page => {
        this.request({page, size:20, id: this.state.industryID})
        this.setState({
            currentPage: page
        })
    }

    // 导出公司列表
    exportList = () => {
        const { industryID,chain } = this.state
        this.axios.post('/starmarket/export',{ id: industryID, domain: chain }).then(res => {
            if (res.code === 10000) {saveWithALink(res.data)}
        })
    }

    render() {
        const { data, currentPage,total,domainline } = this.state
        return (
            <div className="graph">
                <BreadComp data={BreadData} />
                <div className="title title-border">科创版产业</div>
                {/* 产业列表 */}
                <Industry click={this.getChain} />
                {/* 产业链 */}
                <div className="title title-border">{this.state.chain}</div>
                {
                    TEST_USER.includes(getStorage('level')) ? <Tootip2 /> :
                        <Skeleton active paragraph={{ rows: 10 }} loading={domainline === null}>
                            <div className='tabcharts'>
                                {
                                    domainline.length !== 0 ? <ChainComp data={domainline} /> : <EmptyComp />
                                }
                            </div>
                        </Skeleton>
                }
                {/* 企业列表 */}
                {this.state.data.length === 0 ? <EmptyComp /> :
                    <>
                        <div className="title  title-border">
                            企业列表
                            <ExportBtn exportList={this.exportList}/>
                        </div>
                        <CompareBtn 
                            className={'custom-table'}
                            compareOff={3}
                            tableData={data}
                            total={total}
                            currentPage={currentPage}
                            changeStatePage={this.changeStatePage}
                        />
                    </>
                }
            </div>
        )
    }
}

export default Graph