import React, { Component } from 'react'
import { Breadcrumb, Anchor, Tabs,Layout } from 'antd'
import { EmptyComp } from 'comps/common/EmptyComp'
import Balance from './Banlance'
import Round from './Round'
import Income from './Income'
import Cash from './Cash'
import  SideBar  from 'comps/sidebar'
import 'styles/board/stock.scss'

const { TabPane } = Tabs

const { Link } = Anchor
class Finance extends Component {
    constructor(props) {
        super(props)
        this.state = {
            menu: [
                { href: '#main', title: '主要指标' },
                /*
                { href: '#balance', title: '资产负债表' },
                { href: '#income', title: '利润表' },
                { href: '#cash', title: '现金流表' }
                */
            ],
            // 公司股票信息
            stock: [],
            main: [],
            balance: [],
            income: [],
            gpType: ''
        }
    }

    request(id, type) {
        this.axios.post('/starmarket/mainnew', { id })
            .then(res => {
                this.setState({ main: res.data }, () => {
                    this.setState({ gpType: this.state.stock.find(item => item.id === id).type })
                })
            })
        /*
        this.axios.post('/starmarket/balance', { id })
            .then(res => this.setState({ balance: res.data }))
        this.axios.post('/starmarket/income', { id })
            .then(res => this.setState({ income: res.data }))
        */
    }
    componentDidMount() {
        // 2是美股 8是A股 1是港股
        // this.request(1)
        this.axios.post('/starmarket/stock', { id: this.props.match.params.id })
            .then(res => {
                this.setState({ stock: res.data, gpType: res.data[0].type }, () => {
                    if (this.state.stock.length > 0) {
                        this.request(this.state.stock[0].id)
                    }
                })
            })
    }
    render() {
        const { stock, menu } = this.state
        return (
            <Layout className="all-box">
                <SideBar data={menu} />
                <div className="right-box">
                    <Breadcrumb separator=">">
                        <Breadcrumb.Item><a href="/home">首页</a></Breadcrumb.Item>
                        <Breadcrumb.Item><a href={`/company/${this.props.match.params.id}`}>
                            { stock.length > 0 && stock[0].company_name }</a>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>财务数据</Breadcrumb.Item>
                    </Breadcrumb>
                    <div className="title-box">
                        {stock.map((item, index) => index === 0 ? <span className="title">{item.company_name}</span> : '')}
                    </div>
                    <div>
                        <Tabs defaultActiveKey="1"
                            onChange={i => { this.request(stock[i].id) }}
                        >
                            {
                                stock.map((item, index) => {
                                    return <TabPane tab={item.ticker} key={index}>
                                        {this.state.main.length !== 0 && <div id="main" className="section-title">主要指标</div>}
                                        {this.state.main.length === 0 ? <EmptyComp /> : <Round cell={this.state.main} gpType={this.state.gpType} />}
                                        {/* <div id="balance" className="section-title">资产负债表</div>
                                        <div id="income" className="section-title">利润表</div>
                                        <div id="cash" className="section-title">现金流表</div> */}
                                    </TabPane>
                                })
                            }
                        </Tabs>
                    </div>
                </div>
            </Layout>
        )
    }
}

export default Finance