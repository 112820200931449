import React from 'react'
import LabelBox from './LabelBox'
import Indicant from './Indicant'
import { RadarMap } from 'comps/radar-map/RadarMap.jsx'
const maxs1 = [
    { name: '科技指标', max: 40 },
    { name: '市场指标', max: 30 },
    { name: '财务指标', max: 30 },
]
const maxs = [
    { name: '科技指标', max: 35 },
    { name: '市场指标', max: 25 },
    { name: '财务指标', max: 40 },
]
const Company = props => {
    return (
        <div className="rank">
            {props.list.map(item => (
                <React.Fragment key={item.id}>
                    <div className="label-wrap"><LabelBox label="blue" text="企业" color="#1975D0" /></div>
                    <a
                        href={'/company/' + item.id}
                        className="rank-title"
                        style={{ borderColor: '#1975D0' }}
                    >
                        {item.other_name}
                        <span>{item.one_line}</span>
                    </a>
                    <div className="company-item">
                        <div className="graph">
                            {/* <Indicant id={item.id} inds={[item.scientific_index, item.market_index, item.financial_index]} ipo={item.ipo_status} /> */}
                            <RadarMap  
                                RadarMapId={item.id} 
                                radarData={[item.scientific_index, item.market_index, item.financial_index]} 
                                indicatorData={item.ipo_status === 1 ? maxs1 : maxs} 
                                radarStyle={{width: '250px', height: '198px',tooltipIsShow: false, radarShape:'circle', seriesName:'radarMap'}}
                            />
                        </div>
                        <div className="right">
                            <div className="head">相关度第<span>{item.sort}</span>位</div>
                            <div>TI指数：<span className="index">{item.dt}</span></div>
                            <div>科技指标：<div className="bar" style={{ width: item.scientific_index * 3 }}>{item.scientific_index}</div></div>
                            <div>财务指标：<div className="bar" style={{ width: item.financial_index * 3 }}>{item.financial_index}</div></div>
                            <div>市场指标：<div className="bar" style={{ width: item.market_index * 3 }}>{item.market_index}</div></div>
                        </div>
                    </div>
                </React.Fragment>
            ))}
        </div>
    )
}

export default Company
