import React, { Component } from 'react'
import { connect } from 'react-redux'
import { addItem, clearItem, deleteItem } from '@redux/person/action'
import Content from './Content'
import cs from 'classnames'
import { Modal, Button, Tooltip, message } from 'antd'
import { withRouter } from 'react-router-dom'
import { Tootip1 } from 'comps/tootip/Tootip'
import { getStorage } from 'utils/storage'
import { TEST_USER } from 'utils/type'

import 'styles/comps/compare.scss'
import 'styles/person/person_modal.scss'

@connect(state => state, { addItem, clearItem, deleteItem })
@withRouter
class ComparePersonBtn extends Component {
    state = {
        openCompare: this.props.compareItem.length > 0, modalVisible: false, visible: false
    }
    // 切换显示对比列表框
    toggleActive = () => {
        this.setState({ openCompare: !this.state.openCompare })
    }
    // 删除选中的对比列表中的某个id
    deleteOneId = id => {
        this.props.deleteItem(id)
    }
    // 开始对比
    openCompareModal = () => {
        this.setState({
            modalVisible: true,
        })
    }
    closeCompareModal = () => {
        this.setState({
            modalVisible: false
        })
    }
    // 添加人物到对比工具里面
    addItem = (id, name) => {
        if (this.isCompared(id)) {
            this.props.deleteItem(id)
        } else {
            if (this.props.compareItem.length < 3) {
                this.props.addItem({ id, name })
            } else {
                message.info('最多支持三位专家对比哟')
            }
        }
    }
    isCompared = id => {
        let flag = false
        this.props.compareItem.forEach(item => {
            if (item.id === id) {
                flag = true
            }
        })
        return flag
    }
    // 继续添加 去试试的按钮
    goAddCompare = () => {
        const pathname = this.props.location.pathname, matchId = this.props.match.params.id
        // 只有在公司详情页 继续添加按钮才会跳转到公司列表页面
        if (pathname.includes('person') && matchId) {
            this.props.history.push('/person')
        } else { // 其他对比列表页面 公司对比 我的专辑公司 科创板
            this.setState({ openCompare: false })
        }
    }
    componentDidUpdate(prevProps, prevState,) {
        if (prevProps.compareItem.length !== this.props.compareItem.length) {
            this.setState({ openCompare: true })
        }
    }
    // // 权限弹窗
    changeModal = value => {
        this.setState({ visible: value })
    }
    render() {
        const { openCompare, visible } = this.state
        const compareLists = this.props.compareItem, compareL = compareLists.length
        const {
            compareOff,
            id,
            name
        } = this.props
        // compareOff 1:右上角  
        return (
            <>
                {
                    compareOff === 1 && <Tooltip placement='top' title='对比' onClick={ TEST_USER.includes(getStorage('level')) ? () => this.changeModal(true) : () => this.addItem(id, name)}>
                        <i className={cs('iconfont icon-compare', { 'active': this.isCompared(id) })}></i>
                    </Tooltip>
                }
                {/* 权限弹窗 */}
                <Tootip1 value={visible} changeModal={this.changeModal} />
                {/* 当选中数量大于1出现弹窗 */}
                {
                    compareL > 0 && <>
                        <div className={cs('compare-box', { 'active': openCompare })}>
                            <h1 className='compare-title' onClick={this.toggleActive}>
                                专家对比（{`${compareL}`}/3）
                            </h1>
                            <ul className='body'>
                                {
                                    compareLists.map(list => {
                                        return <li key={list.id}>
                                            <span className='iconfont icon-modal-close' onClick={() => this.deleteOneId(list.id)}></span>
                                            {list.name === '' ? '-' : list.name}
                                        </li>
                                    })
                                }
                            </ul>
                            {
                                <div className='footer'>
                                    <Button onClick={() => this.props.clearItem()}>清空</Button>
                                    {
                                        compareL === 1 ? <Button onClick={this.goAddCompare} type='primary'>继续添加</Button>
                                            : <Button type='primary'
                                                // onClick={this.openCompareModal}
                                                onClick={() => this.props.history.push('/personcomparison')}
                                            >开始对比</Button>
                                    }
                                </div>
                            }
                        </div>

                        <Modal
                            title="专家对比"
                            visible={this.state.modalVisible}
                            onCancel={this.closeCompareModal}
                            footer={null}
                            closeIcon={<i className="iconfont icon icon-modal-close"></i>}
                            className='compare-items person-items'
                            width={'90%'}
                            style={{ top: 120 }}
                        >
                            <Content list={this.props.compareItem} />
                        </Modal>
                    </>
                }
            </>
        )
    }
}

export default ComparePersonBtn
