import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Button, Input, Skeleton, Tooltip } from 'antd'
import { RightOutlined } from '@ant-design/icons'
import axios from 'libs/axios'

const SelectField = props => {
    const [ selSwitch, setSelSwitch ] = useState(false)     // 切换到选中城市和领域的下拉框
    const [ stepNum, setStep ] = useState(0)    // 当前的步骤 -1-关闭选择框 0-省份，1-城市，2-领域
    const [ province, setProvince ] = useState([])  // 初始化的省份数组
    const [ cities, setCities ] = useState([])  // 筛选出的城市数组
    const [ proObj, setProObj] = useState({name:''})    // 选中的省份
    const [ cityObj, setCityObj ] = useState({name:''})     // 选中的城市
    const [ fields, setFields ] = useState([])  // 获取选中城市下的领域列表
    const [ fieldObj, setFiledObj ] = useState({name:''})   // 选中的领域
    const [ inputValue, setInputValue ] = useState('')      // input 值
    useEffect(()=>{
        // 获取省份和城市
        axios.post('expert/local').then(res=>{
            if (res.code === 10000){
                setProvince(res.data)
            }
        })
    },[])
    // 选择省份
    const selectProvince = (id,name) =>{
        setProObj({id,name})
        setInputValue(`${name}`)
        // 筛选省份下的城市
        setCities([{id:0,name:'全部'}].concat(province.filter(p => p.id === id)[0].items))
        next()
    }
    // 选择城市
    const selectCity = (id,name) => {
        let localID = proObj.id
        // 区分是否选择的全部
        // id===0 选择全部的情况 - 默认是选择当前的省份
        if (id === 0){
            setInputValue(`${proObj.name}`)
        } else {
        // 选择子城市的情况
            localID = id
            setInputValue(`${name}`)
        }
        setCityObj({id,name})
        next()
        axios.post('/expert/field',{local:localID}).then(res=>{
            if (res.code === 10000){
                setFields(res.data)
            }
        })
    }
    // 选中领域
    const selectField = (id,name) => {
        setFiledObj({id,name})
        setInputValue(`${cityObj.id !== 0 ? cityObj.name : proObj.name}#${name}`)  // input value设置
        setStep(-1) // 选择完领域 弹框消失
    }
    // 点击完成按钮 跳转其他的领域
    const changeEffectID = () => {
        const localID = cityObj.id !== 0 ? cityObj.id : proObj.id
        if (fieldObj.id){
            props.history.push(`/person/effect/${localID}/${fieldObj.id}`)
            window.location.reload()
        }
    }
    // input 事件
    const inputChange = () => {
        if (stepNum === -1){
            setStep(0)
        } else {
            setStep(-1)
        }
    }
    // 完成一步，进行下一步
    const next = () =>  setStep(stepNum + 1)
    // 回到上一步
    const prev = () => setStep(stepNum - 1)
    const toolTip = 'TI人才影响力指数由人物的论文、专利、教育背景、个人荣誉和技术成果转化能力等维度构成。其囊括了主流的人才评价指标，同时对个人论文和专利情况进行分析，结合其参与的重大项目、获得的重要荣誉及奖项，以及其在商业世界的关系网络、技术的商业化水平和所研究细分领域的特殊情况，综合得出该指数。在此基础上，络绎TI按照不同城市、不同行业的指数排名情况，对主要学者进行实时排名，以呈现不同城市、不同领域的代表学术人才。'
    return <>
        {
            !selSwitch ? <h1 className='flex_box' id='effect-title'>
                {props.title}影响力排行
                {/* 提示 解释文案 */}
                <Tooltip placement="bottomLeft" title={toolTip}
                    arrowPointAtCenter={true}
                    getPopupContainer={() => document.getElementById('effect-title')}>
                    <i className='iconfont icon-explain'></i>
                </Tooltip>
                {/* 切换出现选择框 */}
                <i className='iconfont icon-ic_qiehuan' onClick={() => setSelSwitch(true)}></i>
            </h1>
                :
                <div className='select-box'>
                    <Input 
                        placeholder='请选择地区领域' 
                        value={inputValue} 
                        onClick={inputChange}
                    />
                    {/* 选中对勾 */}
                    <i onClick={changeEffectID} className={!fieldObj.id ? 'disable-duigou' : 'duigou'}></i>
                    {/* 取消按钮 */}
                    <i className='cha' onClick={() => setSelSwitch(false)}></i>
                    { !(stepNum < 0) && <div className="steps-content select-steps-custom">
                        {/* 省份 */}
                        {
                            stepNum === 0 && 
                            <div className='select-list'>
                                <h1 className='flex_box'> 
                                    <span>请选择地区</span>
                                </h1>
                                <ul>
                                    {
                                        province.map(pro => <li 
                                            className={proObj.id === pro.id ? 'active flex_box' : 'flex_box'}
                                            key={pro.id} onClick={()=>{selectProvince(pro.id,pro.name)}}>
                                            {pro.name}  <RightOutlined />
                                        </li>)
                                    }
                                </ul>
                            </div>
                        }
                        {/* 城市 */}
                        {
                            stepNum === 1 && 
                            <div className='select-list'>
                                <h1 className='flex_box'> 
                                    <span>{proObj.name}</span>
                                    <span onClick={()=>setStep(0)}>重选</span>
                                </h1>
                                <ul>
                                    {
                                        cities.map(pro => <li 
                                            className={cityObj.id === pro.id ? 'active flex_box' : 'flex_box'}
                                            key={pro.id} onClick={()=>{selectCity(pro.id,pro.name)}}>
                                            {pro.name}  <RightOutlined />
                                        </li>)
                                    }
                                </ul>
                            </div>
                        }
                        {/* 领域 */}
                        {
                            stepNum === 2 && 
                            <div className='select-list'>
                                <h1 className='flex_box'> 
                                    <span>{cityObj.name || proObj.name}</span>
                                    <span onClick={()=>setStep(0)}>重选</span>
                                </h1>
                                <ul>
                                    <Skeleton active paragraph={{ rows: 10 }} loading={fields.length === 0}>
                                        {
                                            fields.map(pro => <li 
                                                className={fieldObj.id === pro.id ? 'active flex_box' : 'flex_box'}
                                                key={pro.id} onClick={()=>{selectField(pro.id,pro.name)}}>
                                                {pro.name}
                                            </li>)
                                        }
                                    </Skeleton>
                                </ul>
                            </div>
                        }
                    </div>}
                </div>
        }
    </>
}

export default withRouter(SelectField)