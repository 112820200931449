import React, { useEffect, useState } from 'react'
import { Layout, Button } from 'antd'
import BreadComp from 'comps/bread'
import Nav from './common/Nav'
import classnames from 'classnames'
import 'styles/official/detail.scss'
import axios from 'libs/axios'
import moment from 'moment'
import AddCollectBtn from 'comps/collect/AddCollectBtn'
import PackUp from 'comps/pack-up/PackUp'
import RandomImg from 'comps/random-img/RandomImg.jsx'
import formatType from 'utils/formatType'
import { AvatarLoad } from 'comps/common/AvatarLoad'
function AlbumDetails(props) {
    const { id, name } = props.match.params
    const BreadData = [{ url: '/report', title: '研究报告' }, { url: '', title: name }]

    const [data, setData] = useState({})
    const [off, setOff] = useState(false)

    useEffect(() => {
        axios.post('/studynew/collectinfo', { id }).then(res => {
            if (res.code === 10000) {
                setData(res.data)
            }
        })
    }, [id])
    let yuansu
    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        yuansu = document.getElementById('lists')
    })


    return (
        <Layout className='all-box-detail'>
            <Nav />
            <section className='detail-body'>
                <BreadComp data={BreadData} />
                <div className='detail-body-title'>
                    {
                        Object.keys(data).length > 0 && <RandomImg 
                            dataType={data.data_type}
                            photoUrl={data.photo}
                            randomName={data.name} randomWidth={'200px'} randomHeight={'111px'} />
                    }
                    <div className='detail-body-title-right'>
                        <p>{data.name}</p>
                        <p><span>共 {data.count} 家公司</span><span>创建于: {moment(data.release_time * 1000).format("YYYY年MM月DD日")}</span></p>
                    </div>
                </div>
                <div className='detail-body-intro'>{data && data.introduce ? data.introduce : formatType(data.count, data.data_type, data.items)}</div>
                <div className='company-list'>
                    <div className='company-list-body'>
                        <span>企业名单</span>
                        <Button className='company-list-btn' onClick={() => { props.history.push('/login') }}>全部收藏</Button>
                    </div>
                    <div className={off ? 'company-list-con-show' : 'company-list-con'} id='lists'>
                        {
                            data.items && data.items.map(item => {
                                return <p>{item.object_name || item.show_name}</p>
                            })
                        }
                    </div>
                    {
                        data.items && data.items.length > 36 && <>
                            {
                                !off ? <div className='zhankai' onClick={() => setOff(true)}>展开 <i className="iconfont icon-collection-zhankai" /></div> 
                                    : <div className='zhankai' onClick={() => setOff(false)}>收起 <i className="iconfont icon-collection-shouqi" /></div>
                            }
                        </>
                    }

                </div>
                <div className='body-details'>
                    <div className='details-title'>内容详情</div>
                    {
                        data.items && data.items.map(item => {
                            return (
                                <div className='details-item'>
                                    <div className='item-top'>
                                        <div className='item-body-left'>
                                            <AvatarLoad src={item.logo} type={1} />
                                            <div className='item-right'>
                                                <p>{item.object_name || item.show_name}</p>
                                                <p>关键词：{item.keywords}</p>
                                            </div>
                                        </div>
                                        <div className='item-body-right'>
                                            <AddCollectBtn off={true} authority={false} />
                                        </div>
                                    </div>
                                    <PackUp introduce={item.reason} maxHeight={96}></PackUp>
                                </div>
                            )
                        })
                    }

                </div>
            </section>
        </Layout>
    )
}

export default AlbumDetails