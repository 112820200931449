import React from 'react'
import { Input } from 'antd'
import { connect } from 'react-redux'
import { addLabel } from '@redux/person/action'
import { addComLabel } from '@redux/company/action'

@connect(state => state, { addLabel, addComLabel })
class FilterInput extends React.Component {
    state = {
        value: undefined,list:[],isBlur:false
    };

    componentDidMount(){
        // 事件监听 点击其他区域 关闭搜索下拉框
        document.addEventListener("click", this.clickEvent, false)
    }

    clickEvent = () => {
        this.setState({ isBlur:false })
    }

    componentWillUnmount(){
        document.removeEventListener('click', this.clickEvent, false) // enter事件监听取消
    }

    getSearch = key =>{
        // 技术词条 高校词条
        const keyV = this.props.cate === 'tech' ? 'tech_name' : 'school_name'
        // const isZH = /^[\u4e00-\u9fa5]+$/i.test(key) // 全部是中文汉字
        const isCN = escape(key).indexOf( "%u" ) > -1 || /\d/i.test(key)  // 是否包含中文汉字 或者包含数字 全部以中文的形式展示
        this.axios.post(this.props.postUrl,{[`${keyV}`]:key === '' ? null : key}).then(res=>{
            if (res.code === 10000){
                this.setState({
                    list: res.data, isCN, isBlur:true
                })
            }
        })
    }
    // 选中之后的操作
    onSelect = (value,e) => {
        e.stopPropagation()
        const id = Number(value.split('-')[0]),name = value.split('-')[1]
        // 嵌入redux中，判断是否存在？存在则不加入，页面没有变化；不存在则加入redux
        if (this.props.type === 'company') { // 公司的技术筛选项
            const isExist = this.props.comLabels.filter(item => Number(item.id) === id && item.cate === this.props.cate )
            if (isExist.length === 0 ){
                this.props.addComLabel({ id,name,cate:this.props.cate })
            }
        } else {
            const isExist = this.props.perLabels.filter(item => Number(item.id) === id && item.cate === this.props.cate )
            if (isExist.length === 0 ){
                this.props.addLabel({ id,name,cate:this.props.cate })
            }
        }
        
        this.setState({ list:[],value:undefined,isBlur:false }) // 搜索框内为空值
    }

    handleChange = v => {
        this.setState({ value:v.target.value })
        this.getSearch(v.target.value)
    } 
    render() {
        const { list,isCN,value,isBlur } = this.state
        const { cate } = this.props
        return (
            <div className="fiter-search-input">
                <Input
                    className='custom-search'
                    placeholder={this.props.keyword}  // 请输入公司/人物/技术/园区名称
                    value={value}
                    onChange={this.handleChange}
                    autoComplete="off"
                />
                {/* 搜索结果显示 */}
                {
                    isBlur && <div className='results'>
                        <ul className="result-box">
                            {list.map(item => {
                                // 技术词条类型的 name === item.name 显示简称
                                let name = cate === 'tech' ? item.name : (isCN ? (item.name || item.name_cn) : item.name_en),
                                    v = `${item.id}-${name}`
                                return <li key={item.id} onClick={this.onSelect.bind(this,v)}>{name}</li>
                            })}
                        </ul>
                    </div>
                }
            </div>
        )
    }
}
export default FilterInput