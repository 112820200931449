import React, { useState } from 'react'
import EchartReact from 'comps/Sign/EchartReact'
import { COLORS } from 'utils/type'

export default function Person(props) {
    return (<EchartReact
        id={props.id}
        options={getOption(props.name, props.list)}
        style={{ width: '860px', height: '500px' }}
    />)
}

function getOption(name, list) {
    let data = []
    let link = []
    data.push({ name: name, symbolSize: 60, value: 10 })

    list.map(item => {
        if (!data.find(el => el.name === item.name_cn)) {
            data.push({ name: item.name_cn, symbolSize: 40, value: 10 })
            link.push({ source: name, target: item.name_cn })
        }
        return [link, data]
    })
    return {
        color: COLORS,
        series: [
            {
                type: 'graph',
                layout: 'force',
                draggable: true,
                force: {
                    repulsion: 200, 
                    gravity: 0.02,
                    edgeLength: 60,
                    friction: 0.1, // 移动速度
                },
                label: {
                    show: true
                },
                links: link,
                lineStyle: {
                    normal: { opacity: 1, width: 1, curveness: 0 }
                },
                data: data,
            }
        ]
    }
}