import React, { Component } from 'react'
import { Layout } from 'antd'
// 组件
import FilterList from 'comps/select-tags/FilterList'
import BreadComp  from 'comps/bread'  // 面包屑导航
import { filterId,arrayTurnState,subTurnState,filterRemoveTag } from 'comps/select-tags/Fn'
import OneCart from './OneCart'

const { Content } = Layout

const BreadData = [{url:'/home',title:'首页'},{url:'/park',title:'地理'},{url:'',title:'招商工作台'}]
const allObj = [{id:0,name:'全部'}]

class Platform extends Component{
    state = {
        displayTags:{
            'industryTags':allObj, 'scopesTags':allObj, 'subscopesTags':allObj,
            'roundTags':allObj, 'areaTags':allObj,'foundedTags':allObj,
            'techTags':allObj
        },
        scopesList:[],
        subScopesId: 0,subScopesLists:[],
        total:0,tableData:[],
        currentPage:1
    }
    componentDidMount(){
        this.getCompanyList() // get listdata
        this.getScopesList()
        document.title = '招商工作台'
    }
    // 选中tag
    tagsChange = (id, checked,tag,type)=>{
        const { displayTags,scopesList } = this.state
        let nextSelectedTags,nextDisplayTags = {...displayTags},
            state = displayTags[`${type}Tags`] // 取当前type对应的state
        if (id){ // 不是全部 id不是0
            // 二级菜单里面选择
            if (type.indexOf('sub') > -1){
                nextSelectedTags = subTurnState(checked,state,tag)
            } else {
                // 行业 选择是单选 其他的是多选
                nextSelectedTags = arrayTurnState(checked,state,tag,type)
            }
            nextSelectedTags = nextSelectedTags.length === 0 ? allObj : nextSelectedTags
        } else { // 选择全部 选择ID是0
            nextSelectedTags = allObj
        }
        if (type === 'scopes'){
            // 切换一级行业 时 子行业全部清空
            let subScopesId = checked ? id : 0 // 选中的目标二级行业
            // 选中二级行业的下面的三级行业
            let subScopesLists = id ? scopesList.filter(tag=>tag.id === subScopesId)[0].items : []
            this.setState({ 
                displayTags : {...nextDisplayTags,'scopesTags':nextSelectedTags,'subscopesTags':allObj},
                subScopesId,
                subScopesLists:[{id: 0, name: "全部"},...subScopesLists],
                currentPage:1
            },() => this.getCompanyList() )
        } else {
            let obj = {}
            obj[`${type}Tags`] = nextSelectedTags
            this.setState({
                displayTags:Object.assign(displayTags,obj),
                currentPage:1
            },()=> this.getCompanyList() )
        }
    }
    // 单个tag close
    tagClose = removeTag =>{
        const { displayTags,subScopesId } = this.state
        let nextDisplayTags  = JSON.parse(JSON.stringify(displayTags)) // 深拷贝
        for (let key of Object.keys(displayTags)){ // 匹配到removeTag 然后过滤掉
            if ( nextDisplayTags[key].some(tag => tag.id === removeTag.id) && nextDisplayTags[key].some(tag => tag.name === removeTag.name)){
                if (key === 'scopesTags'){ // 如何切换的是一级行业 则子行业全部清空
                    nextDisplayTags.subscopesTags = allObj
                }
                nextDisplayTags[key] = filterRemoveTag(nextDisplayTags[key],removeTag)
            }
        }        
        this.setState({
            displayTags:nextDisplayTags,
            subScopesId: nextDisplayTags.scopesTags[0].id === 0 ? 0 : subScopesId,
            currentPage:1
        },()=>{ this.getCompanyList() })
    }
    // 清空筛选项
    clearFilter = ()=>{
        this.setState({
            displayTags:{
                'industryTags':allObj, 'scopesTags':allObj,
                'roundTags':allObj, 'areaTags':allObj,
                'foundedTags':allObj, 'subscopesTags':allObj,
                'techTags':allObj
            },
            subScopesId:0,currentPage:1
        },()=>{
            this.getCompanyList()
        })
    }
    // 数据请求
    getCompanyList = ()=>{
        const { displayTags,currentPage } = this.state
        let industry,domain = filterId(displayTags.industryTags),
            round = filterId(displayTags.roundTags),
            area = filterId(displayTags.areaTags),
            date = filterId(displayTags.foundedTags),
            tech = filterId(displayTags.techTags)
        // 如果选择了二级行业则传递二级的参数ID，若是二级选择了全部则传递一级的ID
        if (displayTags.subscopesTags[0].id !== 0){  // 表示二级有被选择
            industry = filterId(displayTags.subscopesTags)
        } else {
            industry = filterId(displayTags.scopesTags)
        }
        this.axios.post('/company/list',{tech,industry,domain,round,area,date,page:currentPage}).then(res=>{
            this.setState({
                total:res.data.count,
                tableData:res.data.items,
                selectedRowKeys:[]
            })
        })
    }
    // 获取技术标签的id
    getSearchId = v =>{
        const { displayTags } = this.state
        this.setState({ 
            displayTags:{...displayTags,'techTags':[{id:v.split('-')[0],name:v.split('-')[1]}]},
            currentPage:1
        },()=>{
            this.getCompanyList()
        })
    }
    // 获取行业数据
    getScopesList = ()=>{
        this.axios.post('/industry').then(res=>{
            if (res.code === 10000){
                this.setState({
                    scopesList:this.state.scopesList.concat(res.data)
                })
            }
        })
    }
    // 改变页码
    changeStatePage = page=>{
        this.setState({ 
            currentPage:page 
        },()=>{
            this.getCompanyList()
        })
    }
    render(){
        const DT_INDEX_TIP = '指数介绍'
        const { displayTags,subScopesId,total,subScopesLists,tableData } = this.state
        return (
            <Layout className='company-con platform'>
                <BreadComp data={BreadData} />
                {/* main */}
                <Content className='park-main'>
                    {/* 公司筛选项列表 */}
                    <FilterList 
                        displayTags={displayTags} subScopesId={subScopesId} subScopesLists={subScopesLists}
                        allObj={allObj}
                        getSearchId={this.getSearchId}
                        tagsChange={this.tagsChange}
                        tagClose={this.tagClose}
                        clearFilter={this.clearFilter}
                        title='招商工作台'
                    />
                    <OneCart tableData={tableData} total={total} 
                        currentPage={this.state.currentPage}
                        changeStatePage={this.changeStatePage}
                    />
                </Content>
            </Layout>
        )
    }
}

export default Platform