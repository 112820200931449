import React from 'react'
import { RadarMap } from 'comps/radar-map/RadarMap.jsx'
const DtObj = {
    scientific:'科技指标',scientific_max:35,ipo_scientific_max:40,
    financial:'财务指标',financial_max:40,ipo_financial_max:30,
    market:'市场指数',market_max:25,ipo_market_max:30,
}
const IndexData = props =>{
    const { data,ipo } = props
    let radarData = [],indicatorData = []
    for (let [k,v] of Object.entries(data)){
        radarData.push( v )
        indicatorData.push({
            name:DtObj[`${k}`],max:ipo === 1 ? DtObj[`ipo_${k}_max`] : DtObj[`${k}_max`]
        })
    }
    return (
        <div className='flex_box indexdata-box'>
            <ul className='flex_box'>
                <li className='flex_box'>
                    <i className='iconfont icon-loupan1'></i>
                    <span>TI指数<br /><span className="strong">{data.scientific + data.financial + data.market}</span></span>
                </li>
                <li className='flex_box'>
                    <i className='iconfont icon-keji1'></i>
                    <span>科技指标<br /><span  className="strong">{data.scientific}</span></span>
                </li>
                <li className='flex_box'>
                    <i className='iconfont icon-caiwu'></i>
                    <span>财务指数<br /><span className="strong">{data.financial}</span></span>
                </li>
                <li className='flex_box'>
                    <i className='iconfont icon-shichang2'></i>
                    <span>市场指数<br /><span className="strong">{data.market}</span></span>
                </li>
            </ul>
            <div className='dt-radar'>
                <RadarMap 
                    RadarMapId={'dt-radar'} 
                    radarData={radarData} 
                    indicatorData={indicatorData} 
                    radarStyle={{width: '184px', height: '130px',tooltipIsShow: true, radarShape:'circle', seriesName:'TI指数'}}
                />
            </div>
        </div>
    )
}

export default IndexData