import React, { Component } from 'react'
import { Skeleton } from 'antd'
import AddCollectBtn from 'comps/collect/AddCollectBtn'
import { DownOutlined, UpOutlined } from '@ant-design/icons'

class BasicInfo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            zsOff: true, // 展开收起开关显示
            zsClickOff: true // 点击开关
        }
    }

    txtRef = React.createRef()

    componentDidUpdate(prveProps, nextProps) {
        let listHeight = document.getElementById('list')
        if (prveProps.InfoData !== this.props.InfoData) {
            this.txtRef.current.clientHeight > listHeight && this.setState({ zsOff: true })
        }
    }


    render() {
        const { InfoData, InfoLoading, id } = this.props
        const { zsOff, zsClickOff } = this.state
        return (
            <Skeleton active avatar paragraph={{ row: 4 }} loading={InfoLoading}>
                {
                    Object.keys(InfoData).length > 0 && <section id='basic'>
                        <div className='header-title flex_box'>
                            <h1>
                                {InfoData.name}<br />
                                {InfoData.location.length >= 2 && <i>{InfoData.location[1].name}</i>}
                                <i>{InfoData.category}</i>
                                {
                                    InfoData.labelInfos.map(item => {
                                        return <i>{item.name}</i>
                                    })
                                }
                            </h1>
                            {/* 收藏 分享 下载等功能 */}
                            <ul>
                                <li className='flex_box collect'>
                                    <AddCollectBtn off={true} type={3} id={id} />
                                </li>
                            </ul>
                        </div>
                        <div className='summary park-info'>
                            <div className='nr'>
                                <dl className={zsClickOff ? 'text-body' : 'text-auto'} ref={this.txtRef}>{InfoData.description}</dl>
                                {
                                    zsOff && <>
                                        {
                                            <div className='btn-zs' onClick={() => this.setState({ zsClickOff: !zsClickOff })}>{zsClickOff ? <>展开<DownOutlined /></> : <>收起<UpOutlined /></>}</div>
                                        }
                                    </>

                                }
                            </div>
                            <ul className='flex_box link_ul' id='list'>
                                <li><span>电话：</span><p>{InfoData.phone === '' ? '暂无' : InfoData.phone}</p></li>
                                <li><span>邮箱：</span><p>{InfoData.email === '' ? '暂无' : InfoData.email}</p></li>
                                <li><span>地址：</span><p>{InfoData.address === '' ? '暂无' : InfoData.address}</p></li>
                                <li><span>网址：</span><p>{InfoData.web === '' ? '暂无' : InfoData.web}</p></li>
                            </ul>
                        </div>
                    </section>
                }
            </Skeleton>
        )
    }
}

export default BasicInfo
