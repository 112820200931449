import React, { Component } from 'react'
import { Link ,withRouter} from 'react-router-dom'
import { DownOutlined, SearchOutlined, UpOutlined } from '@ant-design/icons'
import Dialog from 'rc-dialog'
import SerachForm from 'pages/search/SearchForm'
import Sub from './Sub'
import 'styles/comps/nav.scss'
import className from 'classname'
@withRouter
class Nav extends Component {
    constructor(props) {
        super(props)
        this.state = {
            layer: false,   // 搜索浮层展示 / 隐藏
            toggle: false,  // 二级产业菜单 展示 / 隐藏 
            nav: [
                { id: 541, title: '专辑', link: '/collection' },
                { id: 542, title: '人物', link: '/person' },
                { id: 543, title: '产业', link: '/industry' },
                { id: 544, title: '技术', link: '/tech' },
                { id: 545, title: '公司', link: '/company' },
                // { id: 547, title: '地理', link: '/park' },
                { id: 546, title: '科创板', link: '/board/general' },
                { id: 548, title: '研究', link: '/research'},
            ],
            sub: [],
            active: false,
            currentIndex: null
        }

        this.hideLayer = this.hideLayer.bind(this)
        // this.toggle = this.toggle.bind(this)
        this.closeSub = this.closeSub.bind(this)
    }
    
    hideLayer() {
        this.setState({
            layer: false
        })
    }

    componentDidMount() {
        this.axios.post('/nav/domain')
            .then(res => {
                if (res.code === 10000) {
                    this.setState({
                        sub: res.data
                    })
                }
            })
        this.checkPath()
    }

    componentDidUpdate(preveProps,preveState) {
        if (preveProps.location.pathname !== this.props.location.pathname) {
            this.checkPath()
            this.setState({
                toggle: false,
            })
        }
       
    }

    checkPath = () => {
        if (this.props.location.pathname.includes('/mycollection')) {
            this.setState({
                currentIndex: 541
            })
        }  else if (this.props.location.pathname.includes('/board')) {
            this.setState({
                currentIndex: 546
            })
        } else {
            const findResult = this.state.nav.find(val => this.props.location.pathname.includes(val.link))
            if (findResult) {
                this.setState({
                    currentIndex: findResult.id
                })
            } else {
                this.setState({
                    currentIndex: null
                })
            }
        }
    }
    toggle= id => {
        const isShow = this.state.toggle
        this.setState({
            toggle: !isShow,
            currentIndex: id
        })
    }
    closeSub() {
        this.setState({
            toggle: false
        })
    }
    layer() {
        this.setState({
            layer: !this.state.layer
        })
    }
   
    handleClick = id => {
        this.setState({
            // active: true
            currentIndex: id
        })
    }
    render() {
        return (
            <nav className="nav-box">
                <div className="nav-inner">
                    <Link to='/home' id="logo">
                        <img src={require('../../assets/images/logofff.svg')} alt='logo' />
                    </Link>
                    <div className="links">
                        {this.state.nav.map((item, index) => {
                            if (index === 2) {
                                return (
                                    <div 
                                        onClick={() => this.toggle(item.id)} 
                                        onMouseLeave={()=>this.setState({toggle: false})}
                                        className={className(["link",{'link-active':this.state.currentIndex === item.id ? true : false}])}
                                        key={item.id}
                                    >
                                        {item.title}
                                        {this.state.toggle ? <UpOutlined /> : <DownOutlined />}
                                        <Sub list={this.state.sub} show={this.state.toggle} />
                                    </div>
                                )
                            }
                            return <Link className={className(["link",{'link-active':this.state.currentIndex === item.id ? true : false}])} key={item.id} to={item.link} onClick={() => this.handleClick(item.id)}>{item.title}</Link>
                        })}

                    </div>
                    {/* 搜索按钮 */}
                    <div className="search" onClick={this.layer.bind(this)}>
                        <i className="iconfont icon-search searchOutlined"></i>
                    </div>
                   
                    <Link to="/user" className="user">
                        {/* <img src={require('assets/images/search/user.png')} alt="user" /> */}
                        <i className='iconfont icon-user-avatar'></i>
                    </Link>
                    <Dialog
                        prefixCls="search"
                        animation="fade"
                        maskClosable={false}
                        mask={false}
                        destroyOnClose= {true}
                        visible={this.state.layer}
                        closeIcon= {<img src={require('assets/images/search/close.svg')} style={{width:24,height:24}} alt="close" />}
                        onClose={this.hideLayer}
                    >
                        <img src={require('../../assets/images/logo.svg')} alt='logo' className='search-logo' />
                        <SerachForm hide={this.hideLayer}/>
                    </Dialog>
                </div>
            </nav>
        )
    }
}

export default Nav