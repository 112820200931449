import React, { Component } from 'react'
import { Form } from '@ant-design/compatible'
import { Row, Col, Input, Button, message } from 'antd'
import { debounce,Msg } from 'utils'
import { clearStorage } from 'utils/storage'
import NologinLogo from './Logo'

import '@ant-design/compatible/assets/index.css'

// 最大字符限制
const maxStr = num => {
    return { max:num,message:`内容不能超过${num}个字` }
}
// 不能为空限制
const notEmpty = text => {
    return { required: true, message: `${text}不能为空` }
}
class Register extends Component{
    state = {
        captcha:'',dlgTipTxt:'获取验证码',seconds:60,isDisabled:false
    }
    componentDidMount(){
        clearStorage()
        document.title = '注册'
        document.addEventListener("keyup",this.enterBtn,false)
    }
    // enter按键之后事件监控
    enterBtn = e=>{
        if (e.keyCode === 13){
            this.goRegister()
        }
    }
    componentWillUnmount(){
        // 卸载enter 事件
        document.removeEventListener('keyup',this.enterBtn,false)
    }
    // 获取短信验证码
    getSms = ()=>{
        const { form:{ validateFields }} = this.props
        validateFields(['mobile','captcha'],(err,v)=>{
            if (err) {return false}
            this.axios.post('/code',{ mobile:v.mobile,type:1 }).then(res=>{
                if (res.code === 10000){
                    Msg('success','短信验证码已发出！')
                    this.setState({ isDisabled:true })
                    // 验证码倒计时
                    let siv = setInterval(() => {
                        this.setState( preState => ({
                            seconds: preState.seconds - 1,
                            dlgTipTxt: `${preState.seconds - 1}s`
                        }), () => {
                            if (this.state.seconds === 0) {
                                clearInterval(siv)
                                this.setState({
                                    isDisabled: false, dlgTipTxt: "获取验证码", seconds: 60
                                })
                            }
                        })
                    }, 1000)
                }
            })
        })
    }
    // 注册
    goRegister = () =>{
        const { form:{ validateFields }} = this.props
        validateFields((err,v)=>{
            if (err) {return false}
            const options = {mobile:v.mobile,password:v.pwd,code:v.sms,email:v.email,job:v.position,name:v.name,company:v.company}
            // 请求修改密码
            this.axios.post('/register',options)
                .then(res=>{
                    if (res.code === 10000){
                        message.success('注册成功！')
                        this.props.history.push('/login')
                    }
                })
        })
    }
    // 验证手机号
    regPhone = (rule, value, callback) => {
        const reg = /^1[345678]\d{9}$/
        if (reg.test(value) === false) {
            callback('手机号格式不正确！')
        }
        callback()
    }
    // 密码验证
    regPwd = (rule, value, callback) => {
        const reg = /[`~!@#$%^&*()\-+=<>?:"{}|,/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]/
        if (reg.test(value)) {
            callback('不能含有特殊字符！')
        }
        callback()
    }
    render(){
        const { getFieldDecorator } = this.props.form
        const { isDisabled,dlgTipTxt } = this.state
        return (
            <div className='login-con'>
                <section className='nologin-container'>
                    <NologinLogo />
                    <Row className='login-box register-box' type='flex'>
                        <Col span={14} offset={5} className='login-form forgot'>
                            <h1>用户注册</h1>
                            <Form>
                                <Form.Item >
                                    {getFieldDecorator('name', {
                                        rules: [ notEmpty('姓名'),maxStr(30) ], validateTrigger: 'onBlur',
                                    })( <Input placeholder='请输入您的姓名' /> )}
                                </Form.Item>
                                <Form.Item >
                                    {getFieldDecorator('company', {
                                        rules: [ notEmpty('公司名'),maxStr(30) ], validateTrigger: 'onBlur',
                                    })( <Input placeholder='请输入公司名' /> )}
                                </Form.Item>
                                <Form.Item >
                                    {getFieldDecorator('position', {
                                        rules: [ notEmpty('职位'),maxStr(30)], validateTrigger: 'onBlur',
                                    })( <Input placeholder='请输入您的职位' /> )}
                                </Form.Item>
                                <Form.Item >
                                    {getFieldDecorator('email', {
                                        rules: [ notEmpty('邮箱'), { type: 'email', message: '邮箱格式不正确'}], validateTrigger: 'onBlur',
                                    })( <Input placeholder='请输入邮箱' /> )}
                                </Form.Item>
                                <Form.Item >
                                    {getFieldDecorator('mobile', {
                                        rules: [ notEmpty('手机号'),{ validator: this.regPhone }], validateTrigger: 'onBlur',
                                    })( <Input placeholder='绑定手机号' /> )}
                                </Form.Item>
                                <Form.Item >
                                    {getFieldDecorator('pwd', { initialValue: '',
                                        rules: [ { required: true, min:6,max:16,message: '密码不能为空,长度为6-16位' },{validator:this.regPwd}],validateTrigger: 'onBlur',
                                    })( <Input placeholder='请输入密码' type="password"/> )}
                                </Form.Item>
                                {/* 短信验证码 */}
                                <Form.Item >
                                    <Row>
                                        <Col span={16}>
                                            { getFieldDecorator('sms', {
                                                rules:[ {required:true, message:'短信验证码不能为空'}
                                                ],validateTrigger: 'onBlur',
                                            })( <Input className='code' type="text" placeholder="请输入短信验证码"/>) 
                                            }
                                        </Col>
                                        {/* 动态验证码 */}
                                        <Col span={8} className='code-input'>
                                            <Button type="primary" onClick={this.getSms} className='sms-btn' disabled={isDisabled}> {dlgTipTxt} </Button>
                                        </Col>
                                    </Row>
                                </Form.Item>
                                {/* 提交注册事件  防抖优化 */}
                                <Button type="primary" onClick={debounce(this.goRegister,400)} className='login-btn'> 保存 </Button>
                                <span className='gologin'>已有账户？<span onClick={()=>this.props.history.push('/login')}>去登录</span></span>
                            </Form>
                        </Col>
                    </Row>
                </section>
            </div>
        )
    }
}
export default Form.create()(Register)