import React, { PureComponent } from 'react'
import { setStorage,getStorage } from 'utils/storage'
import SelectLabel from 'comps/label-select/SelectLabel'

import 'styles/comps/labelmodal.scss'

class UserRecommend extends PureComponent{
    constructor(props){
        super(props)
        let re = getStorage('re')
        this.state = {
            domainCheck: re ? re[0].ids : [],
            expertCheck: re ? re[1].ids : [],
        }
    }
    render(){
        return (
            <SelectLabel domainChecked={this.state.domainCheck} expertChecked={this.state.expertCheck} type='user'/>
        )
    }
}
export default UserRecommend