import React from 'react'
import LabelBox from './LabelBox'
import Trend from './Trend'
import moment from 'moment'
const Tech = props => {
    return (
        <div className="rank teach-rank">
            {props.list.map((item, index) => (
                <React.Fragment key={item.id}>
                    <div className="label-wrap"><LabelBox label="technology" text="技术" color="#2222DA" /></div>
                    <a href={'/tech/' + item.id} className="rank-title" style={{ borderColor: '#2222DA' }}>{item.name}</a>
                    <div className="tech">
                        <div className="tech-pic">
                            <Trend id={item.id} company={item.map_compnay} paper={item.map_paper} />
                        </div>
                        <div className="content">
                            <div>技术发明者：{item.inventor ? item.inventor : '未知'}</div>
                            <div>首次出现时间：{item.date ? item.date : '未知'}</div>
                            <div>最新论文时间：{moment(item.new_paper_date * 1000).format('YYYY年MM月DD日')}</div>
                            <div className="ap-wrap">应用领域：<span className="application"> {item.application ? item.application : '未知'}</span></div>
                        </div>
                    </div>
                </React.Fragment>
            ))}
        </div>
    )
}

export default Tech