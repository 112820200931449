import React from 'react'
import { Switch, Route, NavLink } from 'react-router-dom'
import ThesisList from './content/ThesisList'
import PatentList from './content/PatentList'
import ProjectList from './content/ProjectList'
import 'styles/person/content.scss'

class ContentList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            expert: '',
            paperslist: true,
            patentlist: true,
            project: true
        }
    }
    componentDidMount() {
        this.axios.post('/expert/index', { id: this.props.match.params.id })
            .then(res => {
                this.setState({
                    expert: res.data.name_cn || res.data.name_en
                })
            })
        // 论文
        // this.axios.post('/expert/paperslist', { id: this.props.match.params.id})
        //     .then(res=>{
        //         const { data, code } = res
        //         if (code === 10000){
        //             if (data.data.length !== 0){
        //                 this.setState({ paperslist: false})
        //             }
        //         }                
        //     })
        // // 专利
        // this.axios.post('expert/patentlist', { id: this.props.match.params.id})
        //     .then(res=>{
        //         const { data, code } = res
        //         if (code === 10000){
        //             if (data.data.length !== 0){
        //                 this.setState({ patentlist: false})
        //             }  
        //         }
        //     })
        // // 项目
        // this.axios.post('expert/project', { id: this.props.match.params.id})
        //     .then(res =>{
        //         const { data, code } = res
        //         if (code === 10000){
        //             if (data.data.length !== 0){
        //                 this.setState({ project: false})
        //             }  
        //         }
        //     })
    }
    render() {
        const { paperslist, patentlist, project } = this.state
        return (
            <div className="flex-box">
                <div className="aside person-aside">
                    <Route>
                        <NavLink  className="link-item" activeClassName="active" to={"/person/detail/" + this.props.match.params.id}>基本信息</NavLink>
                        {
                            paperslist && <NavLink  className="link-item link-item-other" activeClassName="active" to={"/person/thesis/" + this.props.match.params.id}>论文</NavLink>
                        }
                        {
                            patentlist && <NavLink  className="link-item  link-item-other" activeClassName="active" to={"/person/patent/" + this.props.match.params.id}>专利</NavLink>
                        }
                        {
                            project && <NavLink  className="link-item  link-item-other" activeClassName="active" to={"/person/project/" + this.props.match.params.id}>项目</NavLink>
                        }
                        
                    </Route>
                </div>
                <div className="content">
                    <Switch>
                        <Route exact path="/person/thesis/:id" component={ThesisList} />
                        <Route exact path="/person/patent/:id" component={PatentList} />
                        <Route exact path="/person/project/:id" component={ProjectList} />
                    </Switch>
                </div>
            </div>
        )
    }
}


export default ContentList