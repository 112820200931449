import React from 'react'
import Sign from 'comps/Sign/Sign'
import LoadImg from './LoadImg'
import Radar from './Radar'

class CompareItem extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            infos: {
                labelHonors: [],
                labelAreas: [],
                labelInfos: [],
                employment: {}
            },
            indicant: {}
        }
    }
    componentDidMount() {
        this.axios.post('/expert/index', { id: this.props.id })
            .then(res => this.setState({
                infos: res.data
            }))
        this.axios.post('/expert/dtindex', { id: this.props.id })
            .then(res => this.setState({
                indicant: res.data
            }))
    }
    render() {
        const { infos, indicant } = this.state
        return (
            <div className="item">
                {/* 基本信息 */}
                <div className="head">
                    <div className="profile">
                        <LoadImg src={infos.photo} />
                    </div>
                    <div className="compare-title person-name">{infos.name_cn === null ? '-' : infos.name_cn}</div>
                </div>
                {/* 任职机构 */}
                <div className="info">
                    {Object.keys(infos.employment).length > 0 && infos.employment.object_name.search('、') !== -1 ?
                        <div style={{ fontSize: 12 }}>{infos.employment.object_name}</div>
                        : <div style={{ fontSize: 14 }}>{infos.employment.object_name}</div>
                    }
                </div>
                {/* 职位职称 */}
                <div className="info">{Object.keys(infos.employment).length > 0 && infos.employment.position === null ? '-' : infos.employment.position}</div>
                <div className="info">{infos.labelAreas.length > 0 && infos.labelAreas.map(item => <span key={item.id}>{item.name}</span>)}</div>
                <div className="tags">
                    {infos.labelHonors.length > 0 && infos.labelHonors.map((item, index) => (index === 0 ? <Sign key={item.id} color="orange" name={item.name} /> : false))}
                </div>
                {/* 雷达图 */}
                <section className="radar">
                    <Radar id={this.props.id} score={[indicant.paper_score, indicant.patent_score, indicant.commerce_score, indicant.honor_score, indicant.education_score]} />
                </section>
                {/* 横向柱状图 */}
                <div className="rows"
                    style={{ width: parseInt(indicant.dt_index * 2), maxWidth: 298 }}
                >
                    {indicant.dt_index}
                </div>
                <div className="rows"
                    style={{ width: handleValue(indicant.h_index), maxWidth: 298 }}
                >
                    {indicant.h_index}
                </div>
                <div className="rows"
                    style={{ width: handleValue(indicant.total_paper), maxWidth: 298 }}
                >
                    {indicant.total_paper}
                </div>
                <div className="rows"
                    style={{ width: handleValue(indicant.total_cite), maxWidth: 298 }}
                >
                    {indicant.total_cite}
                </div>
                <div className="rows"
                    style={{ width: handleValue(indicant.avg_cite), maxWidth: 298 }}
                >
                    {indicant.avg_cite}
                </div>
                <div className="rows pink"
                    style={{ width: handleValue(indicant.total_patent), maxWidth: 298 }}
                >
                    {indicant.total_patent}
                </div>
                <div className="rows pink"
                    style={{ width: handleValue(indicant.effective_patent), maxWidth: 298 }}
                >
                    {indicant.effective_patent}
                </div>
            </div>
        )
    }
}


export default CompareItem

function handleValue(value) {
    if (value < 10) {
        return value * 2
    } else if (value < 100) {
        return value
    } else if (value < 200) {
        return value / 2
    } else if (value < 300) {
        return value / 3
    } else if (value < 400) {
        return value / 4
    } else if (value < 500) {
        return value / 5
    } else if (value < 1000) {
        return value / 10
    } else {
        if (value / 10 > 340) {
            return 300
        } else {
            return value / 10
        }
    }
}