import React, { Component } from 'react'
import PageCustom from 'comps/PageCustom'
import BreadComp from 'comps/bread'
import className from 'classnames'
import TechItem from './TechItem'
import { EmptyComp } from 'comps/common/EmptyComp'
import 'styles/tech/techlist.scss'
import { Skeleton } from 'antd'
import { getStorage } from 'utils/storage'
import { TEST_USER } from 'utils/type'
import { Tootip3 } from 'comps/tootip/Tootip'
const BreadData = [{ url: '/home', title: '首页' }, { url: '', title: '技术列表' }]
class TechList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            domain: [],
            list: [],
            selected: [],
            count: 0,
            page: 1,
            domainLoading: true
        }
        this.isAdded = this.isAdded.bind(this)
        this.clearSelected = this.clearSelected.bind(this)
        this.onChange = this.onChange.bind(this)
        this.request = this.request.bind(this)
    }

    isAdded(id) {
        let flag = false
        this.state.selected.map(item => {
            if (item === id) {
                flag = true
            }
            return flag
        })
        return flag
    }

    clearSelected() {
        this.setState({
            selected: []
        }, () => this.request({ size: 18, page: this.state.page }))

    }
    // 处理点击领域
    clickDomain(id) {
        let that = this
        if (this.isAdded(id)) {

            let dos = this.state.selected
            let ins = dos.indexOf(id)
            if (ins > -1) {
                dos.splice(ins, 1)
            }
            this.setState({
                selected: dos,
                page: 1
            }, () => { 
                this.request({ domain: this.state.selected.join(), size: 18, page: 1 }) 
            })

        } else {
            this.setState({
                selected: [...this.state.selected, id],
                page: 1
            }, () => this.request({ domain: this.state.selected.join(), size: 18, page: 1 }), that.setState({ page: 1 }))
        }
    }


    // 翻页处理
    onChange(page) {
        this.setState({ page }, () => { this.request({ domain: this.state.selected.join(), size: 18, page }) })
    }

    componentDidMount() {
        this.axios.post('/domain', { level: 2 })
            .then(res => {
                if (res.code === 10000) {
                    this.setState({
                        domain: res.data,
                        domainLoading: false
                    })
                }
            })
        this.request({ size: 18 })
        document.title = '技术'
    }
    request(ops = {}) {
        this.axios.post('/tech/list', ops)
            .then(res => {
                if (res.code === 10000) {
                    this.setState({
                        list: res.data.items,
                        count: res.data.count
                    })
                }
            })
    }
    render() {
        return (
            <div className="tech">
                <BreadComp data={BreadData} />
                <div className="page-title">技术列表</div>
                <Skeleton active loading={this.state.domainLoading} >
                    <div className="column">
                        <div className="column-title">所在产业</div>
                        <div className="category">
                            <div
                                className={className('cate-label', { 'active': !this.state.selected.length })}
                                onClick={this.clearSelected.bind(this)}
                            >全部</div>
                            {this.state.domain.map((item, index) => <div
                                key={item.id}
                                onClick={this.clickDomain.bind(this, item.id)}
                                className={className('cate-label', { 'active': this.isAdded(item.id) })}
                            >{item.name}</div>)}
                        </div>
                    </div>
                </Skeleton>
                
                <div className="tech-list">
                    {
                        this.state.list.length === 0 ? <EmptyComp /> :
                            this.state.list.map(item =>  <TechItem key={item.id} {...item} />)
                    }
                    
                </div>
                {
                    TEST_USER.includes(getStorage('level')) && <Tootip3 page={this.state.page} aim={3} text='升级完整版，查看全部新兴技术' />
                }
                {this.state.list.length !== 0 &&
                    <PageCustom
                        total={this.state.count}
                        currentPage={this.state.page}
                        pagesize={18}
                        changeStatePage={this.onChange}
                    />}
            </div>
        )
    }
}


export default TechList