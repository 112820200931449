import React from 'react'
import className from 'classname'
import { withRouter } from 'react-router-dom'
import 'styles/comps/pack-up.scss'

@withRouter
class PackUp extends React.Component{
    constructor(props) {
        super(props)
        this.state = {
            btnIsVisible: false,
            btnStatus:false
        }
        this.saveRef = React.createRef()
    }
    componentDidMount () {
        if (this.props.introduce && this.saveRef.current && this.saveRef.current.offsetHeight > this.props.maxHeight) {
            this.setState({
                btnStatus: true,
                btnIsVisible: true
            })
        }
    }
    openCont = () =>{
        this.setState({
            btnStatus: !this.state.btnStatus
        })
    }
    /**
     * 必传参数introduce(详情内容), maxHeight(展示内容最大高度，例:120,注意不要带'px')
     */
    render(){
        const {introduce, maxHeight} = this.props
        return  <div>
            <div className={className('des-con')} style={{height: !this.state.btnStatus ? 'auto' : maxHeight + 'px'}} >
                <span ref={this.saveRef}>{introduce}</span>
            </div>
            {
                this.state.btnIsVisible && this.state.btnStatus 
                    ? <div className="open-cont" onClick={this.openCont}>展开<i className="iconfont icon-down-arrow"></i></div> : 
                    this.state.btnIsVisible && <div className="open-cont" onClick={this.openCont}>收起<i className="iconfont icon-top-arrow"></i></div>
            }
        </div> 
        
    }
}
export default PackUp