import React, { Component } from 'react'
import * as echarts from 'echarts'
class Cont extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    componentDidMount() {
        let year  = Object.keys(this.props.company)
        let company  = Object.values(this.props.company)
        let paper  = Object.values(this.props.paper)
        let line = echarts.init(document.getElementById(`line-${this.props.id}`))
        line.setOption({
            xAxis: {
                show: false,
                type: 'category',
                data: year
            },
            yAxis: {
                show: false,
                type: 'value'
            },
            series: [
                {
                    data: paper,
                    type: 'line',
                    name: '论文数',
                    smooth: true,
                    symbol: 'none',
                    hoverAnimation: false
                },
                {
                    data: company,
                    type: 'line',
                    name: '企业成立数',
                    smooth: true,
                    symbol: 'none',
                    hoverAnimation: false
                }
            ],
            color: ['#6A72B9', '#EAEAFF'],
            backgroundColor: '#F7F9FE'
        })
    }
    render() {
        return (
            <div id={`line-${this.props.id}`} style={{ width: this.props.width ? this.props.width : '120px', height: this.props.height ? this.props.height : '64px'}} ></div>
        )
    }
}


export default Cont

