import React, { Component } from 'react'
import { Skeleton } from 'antd'
import BreadComp from 'comps/bread'
import Filter from './Filter'
import { EmptyComp } from 'comps/common/EmptyComp'
import CompareBtn from 'comps/compare/CompareBtn'

import 'styles/board/group.scss'

const BreadData = [{ url: '/home', title: '首页' }, { url: '/board/general', title: '科创板' }, { url: '', title: '申报企业' }]
class Group extends Component {
    constructor(props) {
        super(props)
        this.state = {
            current: 1, // 当前页码
            data: []
        }
    }
    componentDidMount() {
        // 分类状态 键值为空
        this.request()
    }
    request(ops) {
        this.axios.post('/starmarket/list', { ...ops, size: 1000 })
            .then(res => {
                this.setState({
                    data: res.data.items,
                    current: 1
                })
            })
    }
    changePage = page => {
        this.setState({ current: page })
    }
    render() {
        const { data, current } = this.state
        return (
            <div className="group">
                <BreadComp data={BreadData} />
                <div className="title">科创板申报数据</div>
                <div className="wrap-box">
                    <Filter request={this.request.bind(this)} />
                    {
                        this.state.data.length === 0 ? <EmptyComp /> :
                            <Skeleton active paragraph={{ rows: 10 }} loading={this.state.data.length > 0 ? false : true}>
                                <CompareBtn 
                                    className={'custom-table'}
                                    compareOff={4}
                                    tableData={data}
                                    total={data.length}
                                    currentPage={current}
                                    cahngeStatePage={this.changePage}
                                />
                            </Skeleton>
                    }
                </div>
            </div>
        )
    }
}

export default Group