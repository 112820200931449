import React from 'react'
import { Link } from 'react-router-dom'
import { COL_TYPE } from 'utils/type'
import { AvatarLoad } from 'comps/common/AvatarLoad'
import AddCollectBtn from 'comps/collect/AddCollectBtn'
import PackUp from 'comps/pack-up/PackUp'

class DetailList extends React.Component{
    
    // type 1 公司 2 人物 4 技术
    render(){
        const { type, ...data } = this.props
        return (
            <div>
                {
                    <div className='list' key={data.object_id}>
                        <div className='title'>
                            <AvatarLoad src={data.logo} type={type} />
                            <h1>
                                <span><Link to={data.is_normal === 1 ? `${COL_TYPE[`${type}`].href}/${data.object_id}` : undefined}>{data.show_name || data.object_name}</Link>
                                </span>
                                {
                                    data.keywords !== '' && <span>关键词：{data.keywords}</span>
                                }
                            </h1>
                            {/* 收藏按钮 */}
                            <AddCollectBtn off={true} id={data.object_id} type={type}/>
                        </div>
                        <PackUp introduce={data.reason} maxHeight={100}></PackUp>
                    </div>
                }
            </div>
        )
    }
}
export default DetailList