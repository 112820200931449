import React, { Component } from 'react'
import { Layout,Skeleton,Table } from 'antd'
// 组件
import  SideBar  from 'comps/sidebar' // 左侧导航
import BreadComp  from 'comps/bread'  // 面包屑导航
import IndustryInvestTabs   from 'comps/charts-tab/IndustryInvestTabs' // 融资分析tab
import IndustryGeoTabs      from 'comps/charts-tab/IndustryGeoTabs' // 地理分布tab
import ExportBtn from 'comps/common/ExportBtn'  // 导出按钮
import { ChainComp } from 'comps/chain'  // 产业链
import PageCustom from 'comps/PageCustom'
import { EmptyComp } from 'comps/common/EmptyComp'
import { industryMoney,isBillion,saveWithALink } from 'utils'
import { marketListColumns } from 'utils/columns'
import { Tootip2 } from 'comps/tootip/Tootip'
// mock数据
import { INDUSTRYSIDE } from 'utils/constant'
import 'styles/industry/index.scss'
import { getStorage } from 'utils/storage'
import { TEST_USER } from 'utils/type'

const { Content } = Layout

const DT_TOP = [
        {key:'amount',name:'企业注册总金额',unit:'万',base:10000},
        {key:'company',name:'企业数',unit:'家',base:1},
        {key:'upCompany',name:'上市企业',unit:'家',base:1},
        {key:'investor',name:'本年度新晋投资方',unit:'家',base:1},
        {key:'money',name:'本年投资总额',unit:'万',base:10000},
        {key:'number',name:'本年投资数量',unit:'起',base:1}
    ],DT_INDEX = ['科技指数','财务指数','市场指数','智慧指数'],
    DT_INDEX_TIP = '指数介绍'
const BreadData = [{url:'/home',title:'首页'},{url:'',title:'产业'}]

class IndustryIndex extends Component {
    state = {
        BasicData:{},BasicLoading:true, // 基本信息
        ChainData:[],ChainLoading:true, // 产业链数据
        MapData:{},MapLoading:true,  // 地图数据
        InvestTabsData:{},InvestLoading:true, // 图表数据
        marketList:[],marketTotal:0,
        id:this.props.match.params.id,
        marketPage:1
    }
    componentDidMount(){
        this.getData()
    }
    // 切换产业时 id发生变化后重新发起数据请求
    componentDidUpdate(prevProps,prevState){
        if (prevProps.match.params.id !== this.props.match.params.id){
            window.location.reload()
            // this.setState({
            //     id:this.props.match.params.id
            // },()=>{ this.getData() })
        }
    }
    // 所有产业数据请求
    getData = ()=>{
        this.getBasic()
        this.getChain()
        this.getMap()
        this.getInvest()
        this.getList()
    }
    // 产业的基本信息
    getBasic = ()=>{
        this.axios.post('/domain/base',{ id:this.state.id }).then(res=>{
            if (res.code === 10000){
                document.title = res.data.name
                this.setState({
                    BasicData:res.data,
                    BasicLoading:false
                })
            }
        })
    }
    // 产业链
    getChain = ()=>{
        this.axios.post('/domain/line',{ id:this.state.id }).then(res=>{
            if (res.code === 10000){
                this.setState({
                    ChainData:res.data,
                    ChainLoading:false
                })
            }
        })
    }
    // 地理分布
    getMap = ()=>{
        this.axios.post('/domain/map',{ id:this.state.id }).then(res=>{
            if (res.code === 10000){
                this.setState({
                    MapData:res.data,
                    MapLoading:false
                })
            }
        })
    }
    // 投融资tab切换数据
    getInvest = ()=>{
        this.axios.post('/domain/table',{ id:this.state.id }).then(res=>{
            if (res.code === 10000){
                this.setState({
                    InvestTabsData : res.data,
                    InvestLoading : false 
                })
            }
        })
    }
    // 获取上市列表
    getList = page =>{
        this.axios.post('/domain/list',{ id:this.state.id,page: page || 1,size:10 }).then(res=>{
            if (res.code === 10000){
                this.setState({
                    marketList:res.data.items,
                    marketTotal:res.data.count,
                    marketPage:page || 1
                })
            }
        })
    }
    // 导出上市公司
    exportList = () => {
        const { id,BasicData } = this.state
        this.axios.post('/domain/export',{ id, domain: BasicData.name }).then(res => {
            if (res.code === 10000) {saveWithALink(res.data)}
        })
    }
    render() {
        const { BasicData,ChainData,MapData,InvestTabsData,marketList,marketTotal } = this.state
        return (
            <Layout className='park-container industry-con' id='basic'>
                {/* sider */}
                <SideBar data={INDUSTRYSIDE} />
                <Layout className='layout-rt'>
                    {/* 面包屑导航 */}
                    <BreadComp data={BreadData} />
                    {/* main */}
                    <Content className='park-main'>
                        {/* 概述 —— 园区名 功能按钮 简介*/}
                        <section>
                            <Skeleton active paragraph={{ rows: 3 }} loading={this.state.BasicLoading}>
                                <h1 className='noborder'>产业概况 - {BasicData.name}</h1>
                                <ul className='dt-top flex_box'>
                                    {
                                        DT_TOP.map(l=>{
                                            return (<li key={l.key} className='dt-cen'>
                                                {l.name}<br/>
                                                <span>
                                                    {industryMoney(BasicData[`${l.key}`],l.base)}
                                                    <i> {isBillion(BasicData[`${l.key}`],l.base) ? '亿' : l.unit} </i>
                                                </span>
                                            </li>)
                                        })
                                    }
                                </ul>
                            </Skeleton>
                        </section>
                        {/* 投融资 tab 切换*/}
                        <section id='finance' className='finance'>
                            <h1 className='parktitle'> 投融资 </h1>
                            <Skeleton active paragraph={{ rows: 8 }} loading={this.state.InvestLoading}>
                                { !this.state.InvestLoading && <IndustryInvestTabs data={InvestTabsData} indName={BasicData.name}/>}
                            </Skeleton>
                        </section>
                        {/* 产业链 tab 切换*/}
                        <section id='chain' className='chain'>
                            <h1 className='parktitle'> 产业链 </h1>
                            {
                                TEST_USER.includes(getStorage('level')) ? <Tootip2 /> :
                                    <div className='tabcharts'>
                                        <Skeleton active paragraph={{ rows: 6 }} loading={this.state.ChainLoading}>
                                            {
                                                ChainData.length > 0 ?
                                                    <ChainComp data={ChainData} />
                                                    : <EmptyComp />
                                            }
                                        </Skeleton>
                                    </div>
                            }
                        </section>
                        {/* 地理情况 tab 切换*/}
                        <section id='geography' className='geography'>
                            <h1 className='parktitle'> 地理情况 </h1>
                            <Skeleton active paragraph={{ rows: 8 }} loading={this.state.MapLoading}>
                                {
                                    !this.state.BasicLoading && <IndustryGeoTabs data={MapData} indName={BasicData.name} /> 
                                }
                            </Skeleton>
                        </section>
                        {/* 上市情况 列表*/}
                        <section id='market' className='market'>
                            <h1 className='parktitle'> 
                                上市企业 
                                <ExportBtn exportList={this.exportList}/>
                            </h1>
                            {
                                marketList.length > 0 ? 
                                    <div>
                                        <Table rowKey={ record=> record.id }
                                            dataSource={marketList} 
                                            columns={marketListColumns(this.state.marketPage)} 
                                            className='custom-table'
                                            pagination={false}
                                        />
                                        <PageCustom total={marketTotal} currentPage={this.state.marketPage} pagesize={10}
                                            changeStatePage={this.getList} notLimit={true}
                                        />
                                    </div>
                                    :
                                    <div>
                                        <EmptyComp />
                                    </div>
                            }
                        </section>
                    </Content>
                </Layout>
            </Layout>
        )
    }
}

export default IndustryIndex
