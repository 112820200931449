import React, { Component } from 'react'
import { connect } from 'react-redux'
import { addItem, deleteItem, clearItem } from '@redux/person/action'
import AddCollectBtn from 'comps/collect/AddCollectBtn'
import PersonCompare from 'comps/person-compare'
import Sign from 'comps/Sign/Sign'
import LoadImg from '../list/LoadImg'
import Profile from './Profile'
import className from 'classname'
import { Tootip1 } from 'comps/tootip/Tootip'
import PackUp from 'comps/pack-up/PackUp'
class Basic extends Component {
    constructor(props) {
        super(props)
        this.state = {
            active: false,
        }
    }

    render() {
        const { visible } = this.state
        const { id, name_cn, name_en } = this.props
        return (
            <div className="basic">
                <div className="left">
                    <div className="summary">
                        <div className="photo">
                            <LoadImg src={this.props.photo} />
                        </div>
                        <div className="info">
                            <div className="section-title">{this.props.name_cn || this.props.name_en}</div>
                            <Profile {...this.props.employment} address={this.props.location_name} />
                            <div className="tags">
                                {this.props.labelHonors.map(item => <Sign key={item.id} color="orange" name={item.name} />)}
                                {this.props.labelAreas.map(item => <Sign key={item.id} color="purple" name={'研究领域：' + item.name} />)}
                                {this.props.labelInfos.map(item => <Sign key={item.id} color="blue" name={item.name} />)}
                            </div>
                        </div>
                    </div>
                    <div className="action flex_box">
                        {/* 对比 */}
                        <PersonCompare compareOff={1} id={id} name={name_cn || name_en} />
                        {/* 收藏 */}
                        <AddCollectBtn off={true} id={this.props.id} type={2} />
                    </div>
                </div>
                {/* 权限弹窗 */}
                <Tootip1 value={visible} changeModal={this.changeModal} />
                <div className="right">
                    {this.props.bio !== '' &&
                        <div>
                            <div className={className(["introduce"])}>
                                <PackUp introduce={this.props.bio} maxHeight={120}></PackUp>  
                            </div>                           
                        </div>
                    }
                    <div className="contact">
                        <ul>
                            <li title={this.props.email}>邮箱: {this.props.email === null ? '暂无' : this.props.email}</li>
                            <li title={this.props.address}>地址: {this.props.address === null ? '暂无' : this.props.address}</li>
                            <li title={this.props.personal_url}>个人主页: {this.props.personal_url ? this.props.personal_url : '暂无' }</li>
                            <li title={this.props.lab_url}>实验室主页: {this.props.lab_url ? this.props.lab_url : '暂无'}</li>
                        </ul>
                    </div>
                </div>
            </div >
        )
    }
}

function mapStateToProps(state) {
    return {
        compareItem: state.compareItem,
        labels: state.labels
    }
}

function mapDispatchToProps(dispatch) {
    return {
        addItem: item => {
            dispatch(addItem(item))
        },
        delete: id => {
            dispatch(deleteItem(id))
        },
        clear: () => {
            dispatch(clearItem())
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Basic)
