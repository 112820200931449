import React,{ PureComponent }  from 'react'
import { Modal } from 'antd'
import { getStorage } from 'utils/storage'
import SelectLabel from 'comps/label-select/SelectLabel'
import 'styles/comps/labelmodal.scss'

class LabelModal extends PureComponent{
    state = {
        visible: !(getStorage('re') && getStorage('re').length > 0),
    }
    closeModal = ()=>{
        this.setState({ visible:false })
        this.props.getRecomList()
    }
    render(){
        return (
            <Modal
                title='请选择感兴趣的标签'
                visible={this.state.visible}
                footer={null}
                className='label-modal'
                width={1000}
            >
                <SelectLabel closeModal={this.closeModal} type='index' 
                    domainChecked={[]} expertChecked={[]} />
            </Modal>
        )
    }
}

export default LabelModal
