import React, { Component, useEffect, useState } from 'react'
import axios from 'libs/axios'
import { CompareEmptyEmage } from 'comps/common/EmptyComp'

function IndustryContent(props) {
    const { id, name } = props
    const [chain, setChain] = useState([])
    const [temp, setTemp] = useState([])

    useEffect(() => {
        let temp = []
        // 产业链
        axios.post('/company/cdomain', { id }).then(res => {
            if (res.code === 10000) {
                setChain(res.data)
                res.data.data.map(item => {
                    return temp.push(...item.items.filter(i => i.name === '' && i.other_name === ''))
                })
            }
            setTemp(temp)
        })

    }, [id])
    return (
        <div className='ind-item'>
            <div className="ind-bottom">
                <div className="chain">
                    <span>涉及产业链：{chain && chain.domainNum}个</span>
                    <span>相关竞争企业：{chain && chain.num - temp.length}家</span>
                </div>
                {
                    chain.data && chain.data.length > 0 ? chain.data.map(item => {
                        return <div className="ind-bottom-item" key={item.name + item.cname}>
                            <span>{item.name}/{item.cname}</span>
                            <dd>
                                {
                                    item.items.map(i => {
                                        return <i key={i.id}>{i.other_name}</i>
                                    })
                                }
                            </dd>
                        </div>
                    }) :
                        <div className="ind-bottom-item">
                            <CompareEmptyEmage />
                        </div>
                }
            </div>
        </div>
    )
}

export default IndustryContent