import React from 'react'
import { Tabs, Modal, Table } from 'antd'
import * as echarts from 'echarts'
// 可视化组件
import { IndustryInvestBarLine,IndustryMergerBarLine,IndustryInvestOut } from 'pages/echarts/bar'
import { FunnelFinance } from 'pages/echarts/FunnelFinance'
import ScatterRound from 'pages/echarts/ScatterRound'
// 表格头部
import { InvestTrend,RoundSpread,FinanceSpread,OutTrend,InvestOut } from 'utils/columns'
import { toMoney,industryMoney,isBillion,saveExcel,turnTableData,echartSavePic } from 'utils'
import BtmFcBtn from './BtmFcBtn'
import { FINANCEOBJ } from 'utils/type'
const { TabPane } = Tabs

// 投资趋势
// https://echarts.apache.org/examples/zh/editor.html?c=mix-line-bar

// 投资轮次分布
// https://echarts.apache.org/examples/zh/editor.html?c=scatter-single-axis

// 退出趋势
// https://gallery.echartsjs.com/editor.html?c=xSJJXiE1Wx

// 投退对比
// https://echarts.apache.org/examples/zh/editor.html?c=grid-multiple

// 投资人分布
// https://gallery.echartsjs.com/editor.html?c=xOahNh6fbl

// tabs 名称 顺序
const TabsTitle = [
    {name:'投资趋势',key:'invest'},
    {name:'轮次分布',key:'round'},
    {name:'融资分布',key:'finance_ok'},
    {name:'并购趋势',key:'merger'},
    {name:'退出趋势',key:'out'},
    {name:'投退对比',key:'invest_out'},
]
// 输出单位
function isUnit(num){
    return isBillion(num) ? '亿' : '万'
}
class IndustryInvestTabs extends React.Component{
    state = {
        showMode:'line',visible:false,currentNum:0,checkValue:0,
        InvestData:[],RoundData:[],MergerData:[],FinanceData:[]
    }
    componentDidMount(){
        this.turnTableData() // 投资趋势
    }
    // 切换展示形式：line table
    showChange = e =>{
        this.setState({ showMode:e.target.value })
    }
    // tab切换：行业分布 企业形态
    tabChange = key =>{
        this.setState({ currentNum:Number(key) },()=>{
            this.state.showMode === 'line' && this.chartResize(Number(key))
        })
    }
    // 只有在mode === 'line'的情况下 切换tabs之后 重绘echarts
    chartResize = num =>{
        const { fullID } = this.props
        const id = fullID ? `${fullID}${TabsTitle[num].key}` : TabsTitle[num].key
        let myChart = echarts.init(document.getElementById(`${id}`))
        myChart.resize()
    }
    // 切换可视化类型
    toggleChartType = type =>{
        const { currentNum } = this.state
        const maxNum = TabsTitle.length - 1
        let num = currentNum
        if (type === 'add'){
            num = currentNum === maxNum ? 0 : ++ num
        } else {
            num = currentNum === 0 ? maxNum : -- num
        }
        this.setState({ currentNum:num },()=>{
            this.state.showMode === 'line' && this.chartResize(num)
        })
    }
    // 切换全屏
    fullScreen = () => {
        this.setState({ visible: true })
    }
    hideModal = () => {
        this.setState({ visible: false })
    }
    checkChange = (e,v) =>{
        this.setState({ checkValue:v })
    }
    // table数据处理
    turnTableData = ()=>{
        const propsData = this.props.data,propsInvest = propsData.items.invest,
            propsRound = propsData.items.round,propsMerger = propsData.items.merger,
            propsFinance = propsData.items.ok,propsOut = propsData.items.out
        let InvestData = [],RoundData = [],FinanceData = [],MergerData = [],OutData = [],InvestOutData = []
        // 投资趋势
        for (let [k,v] of Object.entries(propsInvest)){
            // 处理投资table数据
            InvestData.push({ year:`${k}年`, type:'VC', money:v.vc_money, number:v.vc_number, id:`${k}vc`})
            InvestData.push({ year:`${k}年`, type:'PE',  money:v.pe_money, number:v.pe_number, id:`${k}pe`})
            InvestData.push({ year:`${k}年`, type:'天使', money:v.angeli_money, number:v.angeli_number, id:`${k}angel`})
            InvestData.push({ year:`${k}年`, type:'其他', money:v.other_money, number:v.other_number, id:`${k}other`})
        }
        // 轮次分布
        for (let [year,value] of Object.entries(propsRound)){
            for (let [k,v] of Object.entries(value)){
                RoundData.push({ year:`${year}年`, type:k, money:v.rmb, number:v.num, id:`${year}k`})
            }
        }
        // 融资分布
        for (let [round,v] of Object.entries(propsFinance)){
            const percent = propsFinance['all'] ? Number(v / propsFinance['all'] * 100 ).toFixed(2) + '%' : 0
            FinanceData.push({ type:FINANCEOBJ[`${round}`],number:v,percent,id: round})
        }
        // 并购趋势
        for (let [year,v] of Object.entries(propsMerger)){
            MergerData.push({ year:`${year}年`,type:'并购',money:v.merger_money,number:v.merger_number,id:`${year}merger`})
            MergerData.push({ year:`${year}年`,type:'合并',money:v.aco_money,number:v.aco_number,id:`${year}merge`})
        }
        // 退出趋势
        for (let [k,v] of Object.entries(propsOut)){
            OutData.push({ year:`${k}年`, type:'VC', money:v.vc_money, number:v.vc_number, id:`${k}vc`})
            OutData.push({ year:`${k}年`, type:'PE',  money:v.pe_money, number:v.pe_number, id:`${k}pe`})
            OutData.push({ year:`${k}年`, type:'天使', money:v.angeli_money, number:v.angeli_number, id:`${k}angel`})
            OutData.push({ year:`${k}年`, type:'其他', money:v.other_money, number:v.other_number, id:`${k}other`})
        }
        // 投退对比
        for (let [year,invest] of Object.entries(propsInvest)){
            InvestOutData.push({ 
                year:`${year}年`, type:'VC', id:`${year}vc`,
                invest_money:invest.vc_money, 
                invest_num:invest.vc_number,
                out_money:propsOut[`${year}`].vc_money, 
                out_num:propsOut[`${year}`].vc_number,
            })
            InvestOutData.push({ 
                year:`${year}年`, type:'PE', id:`${year}pe`,
                invest_money:invest.pe_money, 
                invest_num:invest.pe_number,
                out_money:propsOut[`${year}`].pe_money, 
                out_num:propsOut[`${year}`].pe_number,
            })
            InvestOutData.push({ 
                year:`${year}年`, type:'天使', id:`${year}angel`,
                invest_money:invest.angeli_money, 
                invest_num:invest.angeli_number,
                out_money:propsOut[`${year}`].angeli_money, 
                out_num:propsOut[`${year}`].angeli_number,
            })
            InvestOutData.push({ 
                year:`${year}年`, type:'其他', id:`${year}other`,
                invest_money:invest.other_money, 
                invest_num:invest.other_number,
                out_money:propsOut[`${year}`].other_money, 
                out_num:propsOut[`${year}`].other_number,
            })
        }
        this.setState({ InvestData,RoundData,MergerData,FinanceData,OutData,InvestOutData })
    }
    // 保存为excel格式的文件
    savaTableCsv = () =>{
        const { showMode,currentNum } = this.state
        const { fullID,indName } = this.props
        const filename = `${indName}-${TabsTitle[currentNum].name}` // 文件名
        const type = TabsTitle[currentNum].key // 选择数据类型
        if (showMode === 'line'){ // echarts 可视化 下载成图片
            const id = fullID ? `${fullID}${type}` : type
            echartSavePic(filename,id) // 下载png格式图片 文件名和唯一ID
        } else { // table 列表下载成excel
            const { InvestData,RoundData,MergerData,FinanceData,OutData,InvestOutData } = this.state
            // 使用export-excel插件
            let head,csvData
            switch (type) {
            case 'invest':
                head = InvestTrend
                csvData = InvestData
                break
            case 'round':
                head = RoundSpread
                csvData = RoundData
                break
            case 'merger':
                head = InvestTrend
                csvData = MergerData
                break
            case 'finance_ok':
                head = FinanceSpread
                csvData = FinanceData
                break
            case 'out':
                head = OutTrend
                csvData = OutData
                break
            default:
                head = InvestOut
                csvData = InvestOutData
                break
            }
            saveExcel(head,turnTableData(head,csvData),filename)
        }
    }
    render(){
        const { showMode,currentNum,InvestData,RoundData,MergerData,FinanceData,OutData,InvestOutData } = this.state
        const { fullID,data,indName } = this.props
        return (
            <div className='tabcharts invest-tabs'>
                <Tabs activeKey={String(currentNum)} onChange={this.tabChange}>
                    {
                        TabsTitle.map((tab,index) => {
                            return <TabPane tab={tab.name} key={index}>
                                {/* 近十年投资趋势 */}
                                {
                                    tab.key === 'invest' && <div className='charts-box'>
                                        <ul className='charts-top flex_box'>
                                            <li className='flex_box'> <span>
                                                {industryMoney(data.numbers.money)}
                                                <i>{isUnit(data.numbers.money)}</i></span><span>本年度融资总额
                                            </span> </li>
                                            <li className='flex_box'> <span>{data.numbers.number}<i></i></span><span>本年度融资企业数</span> </li>
                                            <li className='flex_box'> <span>
                                                {industryMoney(data.numbers.mean)}
                                                <i>{isUnit(data.numbers.mean)}</i></span><span>平均融资金额
                                            </span></li>
                                            <li className='flex_box'> <span>
                                                {industryMoney(data.numbers.middle)}
                                                <i>{isUnit(data.numbers.middle)}</i></span><span>融资金额中位数
                                            </span> </li>
                                        </ul>
                                        { showMode === 'line' ? 
                                            <IndustryInvestBarLine 
                                                id={ fullID ? `${fullID}${tab.key}` : tab.key } // 唯一echart id
                                                data={ data.items.invest } title='投资趋势' unit='万元' type='投资'
                                            /> 
                                            : <Table scroll={{ y: 450 }}  pagination={false} rowKey={ record=> record.id }
                                                dataSource={ InvestData }  columns={InvestTrend} className='custom-table'
                                            />
                                        }
                                    </div>
                                }
                                {/* 近五年投资轮次分布 */}
                                {
                                    tab.key === 'round' && <div className='charts-box'>
                                        <ul className='charts-top flex_box'>
                                            <li className='flex_box'> <span>
                                                {industryMoney(data.numbers.money)}
                                                <i>{isUnit(data.numbers.money)}</i></span><span>本年度融资总额
                                            </span></li>
                                            <li className='flex_box'> <span>{data.numbers.number}<i></i></span><span>本年度融资企业数</span> </li>
                                            <li className='flex_box'> <span>
                                                {data.numbers.maxName}·{industryMoney(data.numbers.max)}
                                                <i>{isUnit(data.numbers.max)}</i></span><span>本年度融资金额最高</span> </li>
                                            <li className='flex_box'> <span>{data.numbers.roundMaxName}·{data.numbers.roundMax}<i>起</i></span><span>本年度融资数量最高</span> </li>
                                        </ul>
                                        { showMode === 'line' ? 
                                            <ScatterRound 
                                                id={ fullID ? `${fullID}${tab.key}` : tab.key } // 唯一echart id
                                                data={ data.items.round }
                                            /> 
                                            : <Table scroll={{ y: 450 }}  pagination={false} rowKey={ record=> record.id }
                                                dataSource={ RoundData }  columns={RoundSpread} className='custom-table'
                                            />
                                        }
                                    </div>
                                }
                                {/* 近十年企业多轮融资成功率 */}
                                {
                                    tab.key === 'finance_ok' && <div className='charts-box'>
                                        <ul className='charts-top flex_box finance_ok'>
                                            {
                                                Object.keys(data.items.ok).map( (k,i) =>{
                                                    return <li className='flex_box' key={k}>
                                                        {FINANCEOBJ[`${k}`]} : <span className='number'>{FinanceData.length > 0 && FinanceData[i].percent}</span>
                                                    </li>
                                                })
                                            }
                                        </ul>
                                        { showMode === 'line' ? 
                                            <FunnelFinance 
                                                id={ fullID ? `${fullID}${tab.key}` : tab.key } // 唯一echart id
                                                data={ data.items.ok }
                                            /> 
                                            : <Table scroll={{ y: 450 }}  pagination={false} rowKey={ record=> record.id }
                                                dataSource={ FinanceData }  columns={FinanceSpread} className='custom-table'
                                            />
                                        }
                                    </div>
                                }
                                {/* 并购趋势 */}
                                {
                                    tab.key === 'merger' && <div className='charts-box'>
                                        <ul className='charts-top flex_box'>
                                            <li className='flex_box'> <span>{industryMoney(data.numbers.mergermoney)}
                                                <i>{isUnit(data.numbers.mergermoney)}</i></span><span>本年度并购金额</span> </li>
                                            <li className='flex_box'> <span>{data.numbers.mergernumber}<i></i></span><span>本年度并购数</span> </li>
                                            <li className='flex_box'> <span>
                                                {industryMoney(data.numbers.mergermean)}
                                                <i>{isUnit(data.numbers.mergermean)}</i></span><span>平均并购金额</span> </li>
                                            <li className='flex_box'> <span>
                                                {industryMoney(data.numbers.mergermiddle)}
                                                <i>{isUnit(data.numbers.mergermiddle)}</i></span><span>并购金额中位数</span> </li>
                                        </ul>
                                        { showMode === 'line' ? 
                                            <IndustryMergerBarLine 
                                                id={ fullID ? `${fullID}${tab.key}` : tab.key } // 唯一echart id
                                                data={ data.items.merger }
                                            /> 
                                            : <Table scroll={{ y: 450 }}  pagination={false} rowKey={ record=> record.id }
                                                dataSource={ MergerData }  columns={InvestTrend} className='custom-table'
                                            />
                                        }
                                    </div>
                                }
                                {/* 退出趋势 */}
                                {
                                    tab.key === 'out' && <div className='charts-box'>
                                        <ul className='charts-top flex_box'>
                                            <li className='flex_box'> <span>
                                                {industryMoney(data.numbers.outmoney,1)}
                                                <i>{isUnit(data.numbers.outmoney)}</i></span><span>本年度退出金额</span> </li>
                                            <li className='flex_box'> <span>{data.numbers.outnumber}<i></i></span><span>本年度退出数</span> </li>
                                            <li className='flex_box'> <span>
                                                {industryMoney(data.numbers.outmean,1)}
                                                <i>{isUnit(data.numbers.outmean)}</i></span><span>平均退出金额</span> </li>
                                        </ul>
                                        { showMode === 'line' ? 
                                            <IndustryInvestBarLine 
                                                id={ fullID ? `${fullID}${tab.key}` : tab.key } // 唯一echart id
                                                data={ data.items.out } title='退出趋势' unit='万元' type='退出'
                                            /> 
                                            : <Table scroll={{ y: 450 }}  pagination={false} rowKey={ record=> record.id }
                                                dataSource={ OutData }  columns={OutTrend} className='custom-table'
                                            />
                                        }
                                    </div>
                                }
                                {/* 投退对比 */}
                                {
                                    tab.key === 'invest_out' && <div className='charts-box'>
                                        { showMode === 'line' ? 
                                            <IndustryInvestOut 
                                                id={ fullID ? `${fullID}${tab.key}` : tab.key } // 唯一echart id
                                                out={ data.items.out } invest={ data.items.invest }
                                                title='投退对比'
                                            /> 
                                            : <Table scroll={{ y: 450 }}  pagination={false} rowKey={ record=> record.id }
                                                dataSource={ InvestOutData }  columns={InvestOut} className='custom-table'
                                            />
                                        }
                                    </div>
                                }
                            </TabPane>
                        })
                    }
                </Tabs>
                {/* 底部功能图标 */}
                <BtmFcBtn isDispalyTitle={true} isDispalyRadio={true} isDispalyDown={true}
                    TabsTitle={TabsTitle} currentNum={currentNum} showMode={showMode} fullID={fullID}
                    toggleChartType={this.toggleChartType} savaTableCsv={this.savaTableCsv} 
                    showChange={this.showChange} fullScreen={this.fullScreen}
                />
                {/* 全屏 */}
                <Modal
                    title="投融资"
                    visible={this.state.visible}
                    onOk={this.hideModal}
                    onCancel={this.hideModal}
                    className='echart-full'
                    width={'80%'}
                    footer={null}
                >
                    <IndustryInvestTabs fullID='full' data={data} indName={indName}/>
                </Modal>
            </div>
        )
    }
}
export default IndustryInvestTabs