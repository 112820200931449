import React from 'react'

function Honor(props) {
    return <div className="honor">
        <div className="section-title">个人荣誉</div>
        <div className="">
            {props.list.map(item => <li key={item.id}>{item.win_date ? item.win_date + ' 年' : ''} 荣获 {item.name}</li>)}
        </div>
    </div>
}

export default Honor