import React, { Component, useEffect, useState } from 'react'
import axios from 'libs/axios'
import { RadarMap } from 'comps/radar-map/RadarMap'
import { Tooltip } from 'antd'

function BasicContent(props) {
    const { id, bio } = props
    const [lineData, setLineData] = useState([]) // 数据图信息
    const [rankData, setRankData] = useState(0)
    const indicatorData = [
        { name: '论文', max: 45 },
        { name: '专利', max: 30 },
        { name: '商业化', max: 5 },
        { name: '荣誉/任职', max: 10 },
        { name: '教育', max: 10 },
    ]

    function handleValue(value) {
        if (value < 10) {
            return value * 2
        } else if (value < 100) {
            return value
        } else if (value < 200) {
            return value / 2
        } else if (value < 300) {
            return value / 3
        } else if (value < 400) {
            return value / 4
        } else if (value < 500) {
            return value / 5
        } else if (value < 1000) {
            return value / 10
        } else {
            if (value / 10 > 340) {
                return 300
            } else {
                return value / 10
            }
        }
    }

    useEffect(() => {
        // 数据图 雷达图信息
        axios.post('/expert/dtindex', { id }).then(res => {
            if (res.code === 10000) {
                setLineData(res.data)
            }
        })
        axios.post('/expert/researchrank', { id }).then(res => {
            if (res.code === 10000) {
                setRankData(res.data[0].rank)
            }
        })
    }, [id])

    return (
        <div className='basic-item'>
            <div className='basic-bottom'>
                <div className='pic-le'>
                    <div className='pic-le-top'>
                        <span>{lineData.dt_index || 0}</span>
                        <span>TI人才指数</span>
                    </div>
                    <div className='pic-le-bottom'>
                        <span>第{rankData}位</span>
                        <span>{props.location_name}#{props.labelAreas && props.labelAreas[0].name}</span>
                    </div>
                </div>
                <div className='pic-cen' id='pic-cen'>
                    {
                        Object.keys(lineData).length > 0 ? <RadarMap
                            RadarMapId={`ld${id}`}
                            radarData={[lineData.paper_score, lineData.patent_score, lineData.commerce_score, lineData.honor_score, lineData.education_score]}
                            indicatorData={indicatorData}
                            radarStyle={{ width: '190px', height: '170px', tooltipIsShow: true, seriesName: '专家指数' }}
                        /> : <img src={require('../../../assets/images/no_pic.png')} alt='no_pic' />
                    }
                </div>
                <div className='pic-ri'>
                    <div className='pic-ri-title'>
                        <div>H-index指数</div>
                        <div>总发文数</div>
                        <div>总被引数</div>
                        <div>篇均被引数</div>
                        <div>专利总数</div>
                        <div>有效专利数</div>
                    </div>
                    <div className='pic-ri-body'>
                        {/* 横向柱状图 */}
                        <Tooltip placement="top" title={lineData.h_index}>
                            <div style={{ width: parseInt(lineData.h_index * 2) || 0 }}>
                                {/* {lineData.dt_index} */}
                            </div>
                        </Tooltip>
                        <Tooltip placement="top" title={lineData.total_paper}>
                            <div style={{ width: handleValue(lineData.total_paper) || 0 }} >
                                {/* {lineData.h_index} */}
                            </div>
                        </Tooltip>
                        <Tooltip placement="top" title={lineData.total_cite}>
                            <div style={{ width: handleValue(lineData.total_cite) || 0 }}>
                                {/* {lineData.total_paper} */}
                            </div>
                        </Tooltip>
                        <Tooltip placement="top" title={lineData.avg_cite}>
                            <div style={{ width: handleValue(lineData.avg_cite) || 0}}>
                                {/* {lineData.total_cite} */}
                            </div>
                        </Tooltip>
                        <Tooltip placement="top" title={lineData.total_patent}>
                            <div style={{ width: handleValue(lineData.total_patent) || 0 }} >
                                {/* {lineData.avg_cite} */}
                            </div>
                        </Tooltip>
                        <Tooltip placement="top" title={lineData.effective_patent}>
                            <div className="rows pink" style={{ width: handleValue(lineData.effective_patent) || 0 }} >
                                {/* {lineData.total_patent} */}
                            </div>
                        </Tooltip>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default BasicContent