import React, { Component } from 'react'
import '@ant-design/compatible/assets/index.css'
import { Row, Col, Input, Checkbox, Button, Form } from 'antd'
import NologinLogo from './Logo'
import { debounce } from 'utils'
import { setStorage } from 'utils/storage'

import 'styles/pages/nologin.scss'

class Login extends Component{
    state = {
        timeStamp: new Date().getTime(),
        remember: true
    }
    componentDidMount(){
        document.addEventListener("keyup",this.enterBtn,false)
        document.title = 'DT Insights——透视未来的科技本质'
        
    }
    // 登录按钮
    goLogin = values=>{
        values && this.axios.post('/login',{ mobile:values.mobile,password:values.pwd,day: this.state.remember ? 7 : 1}).then(res=>{
            if (res.code === 10000){
                setStorage('token',res.data.token,30) //token每次调用接口都要携带
                setStorage('re',res.data.re,30) // 推荐标签数据 判断个性化首页是否弹框
                setStorage('level',res.data.level,30) // 用户身份，首次登录必填
                this.props.history.push('/home')
            }
        })
    }
    // enter按键之后事件监控
    enterBtn = e=>{
        if (e.keyCode === 13){
            this.onFinish()
        }
    }

    // 提交成功
    onFinish = values => {
        this.goLogin(values)
    }

    // 修改七天自动登录状态
    changeRember = () => {
        this.setState({
            remember: !this.state.remember
        })
    }
    componentWillUnmount(){
        // 卸载enter 事件
        document.removeEventListener('keyup',this.enterBtn,false)
    }
    render(){
        const { timeStamp, remember } = this.state
        return (
            <div className='login-con'>
                <section className='nologin-container'>
                   
                    <Row className='login-box' type='flex'>
                        <Col className='ad'>
                            <div> <NologinLogo /></div>
                            <div className="title1 title">领先的学术科技数据智能分析和服务引擎</div>
                            <div className='title'>学术、科技、智能三位一体</div>
                            <div className='title'>透视未来科技本质</div>
                            {/* <img src={require('../../assets/images/login.png')} alt='login' className="loginImg"/> */}
                        </Col>
                        <Col span={14} className='login-form'>
                            <a className='back' href='/'>返回</a>
                            <h1>账户登录</h1>

                            <Form name="basic" 
                                initialValues={{ remember: true }}
                                onFinish={this.onFinish}
                                onFinishFailed={this.onFinishFailed}
                                onValuesChange={this.onValuesChange}
                                validateTrigger="onBlur"  
                            >
                                <div className='inp-lable'>手机号</div>
                                <Form.Item rules={[ { required: true, message: '手机号不能为空' }]} name="mobile" className='mobile-item'>
                                    <Input placeholder='请输入手机号' />
                                </Form.Item>
                                <div className='inp-lable'>密码</div>
                                <Form.Item rules={[ { required: true, message: '密码不能为空' }]} name="pwd" className='pwd-item'>
                                    <Input placeholder='请输入密码' type="password"/>
                                </Form.Item>
                                <div className="forgot-box">
                                    <Form.Item className='btn-form'  name="remember" valuePropName="checked">
                                        {/* <Checkbox>7天内自动登录</Checkbox>   */}
                                        <div className='check-box' onClick={this.changeRember}>
                                            <img src={`https://image.sciphi.cn/dt_insights/icon/check${remember ? '-active' : ''}.svg?v=` +  timeStamp} alt='checkicon' className="checkicon"/>
                                            7天内自动登录
                                        </div>
                                    </Form.Item>
                                    {/* <span onClick={()=> this.props.history.push('/forgot')} className='goforgot'>忘记密码?</span> */}
                                </div>
                                {/* 提交注册事件  防抖优化 */}
                                <Button type="primary" onClick={debounce(this.goLogin,400)} className='login-btn' htmlType="submit"> 登录 </Button>
                                {/* <p className='info-tele'>
                                    <span>咨询电话：18693418964</span>
                                    <span>没有账号?<a href="/regist">去注册</a></span>
                                </p> */}
                            </Form> 

                        </Col>
                    </Row>
                </section>
            </div>
        )
    }
}
export default Login