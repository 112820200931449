import React, { Component } from 'react'
import { Skeleton } from 'antd'

class Collection extends Component {
    state = { list:[],loading:true }
    componentDidMount() {
        this.axios.post('/collection/toplist')
            .then(res => this.setState({ list: res.data,loading:false }))
    }
    render() {
        return (
            <div className="collection box_shadow">
                <div className="item">最新专辑</div>
                <Skeleton active paragraph={{ rows: 10 }} loading={this.state.loading}>
                    {this.state.list.map(item => <a
                        href={'/collection/' + item.id}
                        key={item.id}
                        className="item">{item.name}</a>)}
                </Skeleton>
            </div>
        )
    }
}


export default Collection