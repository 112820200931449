import React, { Component } from 'react'
import * as echarts from 'echarts'

export class EffectGenderPie extends Component{
    componentDidMount(){
        const option = {
            color:["#105B86","#1C8B8F"],
            tooltip: {
                trigger: 'item',
                formatter: '{a} <br/>{b} : {c} {d}%'
            },
            legend: {
                icon:'circle',
                right:'20',
                itemGap: 30,
                itemWidth: 10,
                data: ['女','男']
            },
            series: [
                {
                    name: '性别分布',
                    type: 'pie',
                    radius: '70%',
                    center: ['50%', '50%'],
                    label:{
                        show:true,
                        formatter:'{b} {d}%',
                        color:'#333'
                    },
                    data: this.props.data // [{name:'男',value:20},{name:'女',value:40,selected:true}]
                }
            ]
        }
        let myChart = echarts.init(document.getElementById(this.props.id))
        myChart.setOption(option)
    }
    render(){
        return  <div id={this.props.id} className='effect-pie'></div>
    }
}