import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { message, Skeleton,Tooltip } from 'antd'
import { AvatarLoad } from 'comps/common/AvatarLoad'
import { EffectRadar } from 'comps/radar-map/EffectRadar'
import AddCollectBtn from 'comps/collect/AddCollectBtn'
import PersonCompare from 'comps/person-compare'
import Paper from '../detail/Paper'
import Quote from '../detail/Quote'
import Pantent from '../detail/Patent'
import Legal from '../detail/Legal'
import PackUp from 'comps/pack-up/PackUp'
import { connect } from 'react-redux'
import { addItem, deleteItem } from '@redux/person/action'
import axios from 'libs/axios'

const indicatorData = [
    { name: '论文', max: 45 },
    { name: '专利', max: 30 },
    { name: '成果转化', max: 5 },
    { name: '荣誉/任职', max: 10 },
    { name: '教育', max: 10 },
]
const InfoCard = props => {
    const desRef = useRef(null)
    const [curChart,setCurChart] = useState(0) // 学术当前tab
    const [ basic, setBasic ] = useState({}) // 基本信息
    const [ dtindex, setDtindex ] = useState({}) // 指数
    const [ academic, setAcademic ] = useState({}) // 学术数据
    
    const id = props.effectSelectedID
    useEffect(()=>{
        // 人物指数数据
        axios.post('/expert/dtindex',{ id }).then(res=>{
            if (res.code === 10000){
                setDtindex(res.data)
            }
        })
        // 人物基本信息
        axios.post('/expert/index',{ id }).then(res=>{
            if (res.code === 10000){
                setBasic(res.data)
            }
        })
        // 人物学术概况
        axios.post('/expert/academic',{ id }).then(res=>{
            if (res.code === 10000){
                setAcademic(res.data)
            }
        })
    },[ id ])
    // tab切换
    const tabsChange = type => {
        if (type){
            if (curChart === 3){
                setCurChart(0)
            } else {
                setCurChart( curChart + 1 )
            }
        } else {
            if (curChart === 0){
                setCurChart(3)
            } else {
                setCurChart( curChart - 1 )
            }
        }
    }
    const addCompare = id =>{
        if (isCompared(id)) {
            props.delete(id)
        } else {
            if (props.compareItem.length < 3) {
                props.addItem({ id: id, name: basic.name_cn || basic.name_en })
            } else {
                message.info('最多支持三位专家对比哟')
            }
        }
    }
    const isCompared = id =>{
        let flag = false
        props.compareItem.forEach(item => {
            if (item.id === id) {
                flag = true
            }
        })
        return flag
    }
    return <section className='effect-info-card'>
        <Skeleton active paragraph={{ row: 16}} 
            loading={Object.keys(basic).length === 0 && Object.keys(dtindex).length === 0}>
            {/* 基本信息 */}
            <div className="flex_box basic-box">
                <div className="flex_box basic-lf">
                    <AvatarLoad src={basic.photo} type={2} />
                    <dl>
                        <dt>{basic.name_cn || basic.name_en}</dt>
                        {
                            basic.employment && <dd>
                                <span>机构：{basic.employment.object_name}</span>  
                                <span>职位：{basic.employment.position}</span></dd>
                        }
                        <dd>{
                            basic.labelAreas && basic.labelAreas.map(l => <span key={l.id}>{l.name}</span>)
                        }</dd>
                    </dl>
                </div>
                {/* 雷达图 */}
                {
                    Object.keys(dtindex).length > 0 && <EffectRadar RadarMapId={`effect-radar${basic.id}`} 
                        radarData={[dtindex.paper_score, dtindex.patent_score, dtindex.commerce_score, dtindex.honor_score, dtindex.education_score]} 
                        indicatorData={indicatorData} 
                        radarStyle={{width: '200px', height: '160px',tooltipIsShow: true, radarShape:'', seriesName:''}}
                    />
                }
            </div>
            {/* 简介 */}
            {
                basic.bio && <PackUp introduce={basic.bio} maxHeight={120} />
            }
            {/* 指数 */}
            <ul className='index-ul flex_box'>
                <li className='flex_box'>
                    <span>{dtindex.h_index}</span>H-index指数
                </li>
                <li className='flex_box'>
                    <span>{dtindex.total_paper}</span>总发文数量
                </li>
                <li className='flex_box'>
                    <span>{dtindex.total_cite}</span>总被引数
                </li>
                <li className='flex_box'>
                    <span>{dtindex.total_patent}</span>专利数
                </li><li className='flex_box'>
                    <span>{dtindex.effective_patent}</span>有效专利数
                </li>
            </ul>
        </Skeleton>
        {/* 可视化图表 */}
        <Skeleton active paragraph={{ row: 10}} 
            loading={Object.keys(basic).length === 0 && Object.keys(academic).length === 0}>
            {
                Object.keys(academic).length > 0 && <div className="charts-btns flex_box">
                    {/* 可视化图切换 */}
                    <div className="charts-tab flex_box">
                        <i className='iconfont icon-fanye' onClick={()=>tabsChange(false)}/>
        
                        { curChart === 0 && <Paper id="paper" {...academic.paperPercent}/> }
                        { curChart === 1 && <Quote id="quote" data={academic.Hindex}/> }
                        { curChart === 2 && <Pantent id="count" data={academic.patentYear} style={ {width: '90%', height: '240px',paddingLeft: 50} } /> }
                        { curChart === 3 && <Legal id="article" data={academic.patentLegal}/> }
        
                        <i className='iconfont icon-iconfont_hexinhu' onClick={()=>tabsChange(true)}/>
                    </div>
                    <p className='btns-box flex_box'>
                        {/* 对比按钮 */}
                        <PersonCompare compareOff={1} id={basic.id} name={basic.name_cn || basic.name_en} />
                        {/* 收藏按钮 */}
                        <AddCollectBtn off={true} id={basic.id} type={2} textDisable={false}/>
                        {/* 查看详情页 */}
                        <Tooltip placement="top" title={'查看'}>
                            <Link to={`/person/detail/${basic.id}`}>
                                <i className='iconfont icon-eye'></i>
                            </Link>
                        </Tooltip>
                    </p>
                </div>
            }
        </Skeleton>
        
    </section>
}

function mapStateToProps(state) {
    return {
        effectSelectedID: state.effectSelectedID,
        compareItem: state.compareItem,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        addItem: item => {
            dispatch(addItem(item))
        },
        delete: id => {
            dispatch(deleteItem(id))
        }
    }
}


export default connect(mapStateToProps,mapDispatchToProps)(InfoCard)