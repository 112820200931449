import React from 'react'
const Card = props => {
    return (
        <div className="card">
            <div className="top">
                <span className="head">{props.title}</span>
                {props.more ? (<a href="#more" className="more">更多 &gt;</a>) : ''}
            </div>
            <div className="body">
                {props.children}
            </div>
        </div>
    )
}

export default Card