import React from 'react'
import * as echarts from 'echarts'

export default class EffectScatter  extends React.Component{
    componentDidMount(){
        const option = {
            color:['#105B86'],
            title: {
                show: this.props.isTitle,
                text: '学者分布',
            },
            grid: {
                right: '120',
                left:'80'
            },
            xAxis: {
                name:'专利数量(数)',
                nameGap: 20,
                nameTextStyle: {
                    verticalAlign: 'top',
                    padding: [8,0,8,12]
                },
                axisTick: { show: false },
                splitLine: { 
                    show: true,
                    lineStyle: {
                        type: 'dashed',
                        color: '#DADADA'
                    }
                },
            },
            yAxis: {
                name:'论文数量(数)',
                nameGap: 8,
                axisTick: { show: false },
                nameTextStyle: {
                    align:'right',
                    padding: [10,8,10,0]
                },
                splitLine: { 
                    show: true,
                    lineStyle: {
                        type: 'dashed',
                        color: '#DADADA'
                    }
                },
            },
            series: [{
                type: 'scatter',
                symbolSize: 8,
                emphasis: {
                    label: {
                        show: true,
                        formatter: function (param) {
                            return `${param.data[2]} 专利: ${param.data[1]} 论文: ${param.data[0]} `
                        },
                        padding:[5,10],
                        backgroundColor:'rgba(0,0,0,.8)',
                        color: '#fff',
                        position: 'top'
                    }
                },
                data: this.props.data
            }]
        }
        let myChart = echarts.init(document.getElementById(this.props.id))
        myChart.setOption(option)
    }
    render(){
        return <div className='effect-scatter' id={this.props.id}></div>
    }
    
}