import React, { Component, useEffect, useState } from 'react'
import { Layout, Skeleton } from 'antd'
import Nav from './common/Nav'
import moment from 'moment'
import 'styles/official/researchdetail.scss'
import axios from 'libs/axios'
import BreadComp from 'comps/bread'

function ResearchDetail(props) {
    const { id, name } = props.match.params
    const BreadData = [{ url: '/report', title: '研究报告' }, { url: '', title: name }]
    const [data, setData] = useState([])

    useEffect(() => {
        axios.post('/studynew/sinfo', { id }).then(res => {
            if (res.code === 10000) {
                setData(res.data)
            }
        })
    }, [id])
    const { title, start_time, content, authorInfo } = data
    return (
        <Layout className='all-box-research'>
            <Nav />
            <section className='research-body'>
                <BreadComp data={BreadData} />
                <Skeleton active paragraph={{ rows: 10 }} loading={data !== {} ? false : true}>
                    <div className="detail all">
                        <div className="leftbox">
                            <div className="title">{title}</div>
                            <div className="aut_time">
                                {
                                    authorInfo && authorInfo.map(item => {
                                        return <span className='aut'>{item.name}</span>
                                    })
                                }
                                <span>{moment.unix(start_time).format("YYYY-MM-DD HH:mm:ss")}</span>
                            </div>
                            <hr />
                            <div className="content" dangerouslySetInnerHTML={{ __html: content }} />
                        </div>
                    </div>
                </Skeleton>
            </section>
        </Layout>
    )
}

export default ResearchDetail