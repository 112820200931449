import React, { useEffect, useState } from 'react'
import { Layout, Button, Skeleton } from 'antd'
import Nav from './common/Nav'
import 'styles/official/report.scss'
import Footer from 'comps/navbar/Footer'
import axios from 'libs/axios'
import moment from 'moment'
import RandomImg from 'comps/random-img/RandomImg.jsx'

function Report(props) {

    const [newsList, setNewsList] = useState([])    // 快讯
    // const [picList, setPicList] = useState([])      // 图
    const [colList, setColList] = useState([])      // 专辑列表
    const [hotList, setHotList] = useState([])      // 热门专辑
    const [reportList, setReportList ] = useState([]) // 顶部研究列表  主要是学术的直播链接文章

    useEffect(() => {
        // 图
        // axios.post('/studynew').then(res => {
        //     const { code, data: { items } } = res
        //     if (code === 10000) {
        //         setPicList(items)
        //     }
        // })
        // 快讯
        axios.post('/studynew/news').then(res => {
            const { code, data: { items } } = res
            if (code === 10000) {
                setNewsList(items)
            }
        })
        // 专辑列表
        axios.post('/studynew/collection').then(res => {
            const { code, data } = res
            if (code === 10000) {
                setColList(data)
            }
        })
        // 热门专辑
        axios.post('/studynew/hot').then(res => {
            const { code, data } = res
            if (code === 10000) {
                setHotList(data)
            }
        })
        // 热门专辑
        axios.post('/studynew/banner',{ size:3 }).then(res => {
            const { code, data } = res
            if (code === 10000) {
                setReportList(data)
            }
        })
    }, [])
    return (
        <Layout className='all-box-report'>
            <Nav />
            <section className='report-body'>
                <div className='body-one'>
                    <div className='title'>
                        <p>研究</p>
                        {/* <Button className='btn' href='/regist'>注册试用版</Button> */}
                    </div>
                    <hr className='hr' />
                    <div className='all-body'>
                        <Skeleton paragraph={{ rows: 8 }} active loading={reportList.length === 0}>
                            <div className='left'>
                                {
                                    reportList.map(item => {
                                        return <a href={item.url} alt={item.title} key={item.url}>
                                            <img src={item.img} alt="report"/>
                                            <h3>{item.title}</h3>
                                        </a>
                                    })
                                }
                            </div>
                        </Skeleton>
                        <div className='right'>
                            <Skeleton paragraph={{ rows: 10 }} active loading={newsList.length === 0}>
                                {
                                    newsList.map(item => {
                                        return <div className='item' key={item.id}>
                                            <div className='tit'>{item.title}</div>
                                            <div className='article' dangerouslySetInnerHTML={{ __html: item.content }}></div>
                                            <div className='time'>{moment.unix(item.start_time).format("YYYY-MM-DD")}</div>
                                        </div>
                                    })
                                }
                            </Skeleton>
                        </div>
                    </div>
                </div>
            </section>
            <div className='title-tip'>
                <p>立即开始30天免费试用</p>
                {/* <a href='/#'>体验DEMO<i className='iconfont icon-iconfont_hexinhu' /></a> */}
            </div>
            <section className='report-body'>
                <div className='body-two'>
                    <div className='left'>
                        <div className='tit'>最新专辑</div>
                        <div className='body-top'>
                            <div className='body-right'>
                                {
                                    colList.map(item => {
                                        return <div className='item' key={item.id}
                                            onClick={() => { props.history.push(`/report/detail/${item.id}/${item.name}`) }}>
                                            <RandomImg
                                                dataType={item.data_type}
                                                photoUrl={item.photo}
                                                randomName={item.name} randomWidth={'180px'} randomHeight={'100px'} />
                                            <div className='intro'>
                                                <div className='ti'>{item.name}</div>
                                                <div className="bod">
                                                    {item.introduce !== '' ? item.introduce : `该专辑包含${item.object_names.substr(0, item.object_names.length - 1)}等${item.num}家公司`}
                                                </div>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div className='right'>
                        <div className='tit'>热门专辑</div>
                        <div className='body'>
                            {
                                hotList.map(item => {
                                    return <div className='item' key={item.id}
                                        onClick={() => { props.history.push(`/report/detail/${item.id}/${item.name}`) }}>
                                        <RandomImg
                                            dataType={item.data_type}
                                            photoUrl={item.photo}
                                            randomName={item.name} randomWidth={'370px'} randomHeight={'205px'} />
                                        <div className='tit'>{item.name}</div>
                                        <div className='ti_all'>
                                            <span>共{item.num}家公司</span>
                                            <span>创建于：{moment.unix(item.release_time).format("YYYY-MM-DD")}</span>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </Layout>
    )
}

export default Report