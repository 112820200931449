import React from 'react'
import Person from './Person'
import Company from './Company'
import Tech from './Tech'
import Park from './Park'

const Result = props => {
    const { cate,list,isTestUSer,activeKey } = props
    return (
        <div className="list">
            {activeKey === '0' && <>
                {
                    list.map(item => {
                        if (item.type === 1){
                            return <Company key={item.id} {...item} />
                        } else if (item.type === 2){
                            return <Person key={item.id} {...item} />
                        } else if (item.type === 3){
                            return <Tech key={item.id} {...item} />
                        } else {
                            return <Park key={item.id} {...item} />
                        }
                    })
                }
            </>}
            { activeKey === '1' && list.map(item=><Company key={item.id} {...item} />) }
            { activeKey === '2' && list.map(item=><Person key={item.id} {...item} />) }
            { activeKey === '3' && list.map(item=><Tech key={item.id} {...item} />) }
            { activeKey === '4' && list.map(item => <Park key={item.id} {...item} />) }
            {
                isTestUSer && list.length > 28 && <span className='test-search-tip'>您好，当前只展示部分搜索结果，完整结果需付费购买，欢迎咨询：18693418964</span>
            }
        </div>
    )
}


export default Result