import React from 'react'
import { Table } from 'antd'
function Resume(props) {

    return (
        <div className="resume">
            <div className="section-title">{props.title}</div>
            <Table 
                className="custom-table"
                dataSource={props.dataSource}
                rowKey = 'id'
                columns={props.columns}
                pagination= {false} 
                loading={false} 
            />
        </div>
    )
    
}

export default Resume