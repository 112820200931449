import React from 'react'
import EchartReact from 'comps/Sign/EchartReact'

export default function Year(props) {
    return (<EchartReact
        id={props.id}
        options={getOption(props.data, props.show, props.title, props.dw)}
        style={{ width: props.data.length * 60 >= props.width ? props.width : props.data.length * 60 + 'px', height: props.height + 'px' }}
    />)
}

function getOption(data, show, title, dw) {
    let nums = []
    let year = []
    data.map(item => {
        nums.push(Object.values(item)[0])
        year.push(Object.values(item)[1])
        return nums
    })

    return {
        title: {
            text: title,
            padding: [10, 0, 0, 40],
            textStyle: {
                fontSize: 14,
                fontFamily: 'PingFangSC- Regular, PingFang SC',
                fontWeight: 400,
                color: '#333333'
            }
        },
        tooltip: {
            show: true
        },
        xAxis: {
            axisLine: {
                show: false
            },
            axisTick: {
                show: false
            },
            type: 'category',
            data: year
        },
        yAxis: {
            axisLine: {
                show: false
            },
            axisTick: {
                show: false
            },
            splitLine: {
                show: false
            },
            axisLabel: {
                show: show
            },
            show: true,
            type: 'value',
            name: dw,
            nameTextStyle: {
                fontSize: 10,
                padding: [0, 0, 0, -10]
            }
        },
        grid: {
            bottom: '15%',
        },
        series: [{
            data: nums,
            type: 'bar',
            showBackground: true,
            barWidth: '30%',
            barGap: '10%',
            barCategoryGap: '10%',
            backgroundStyle: {
                color: 'rgba(180, 180, 180, 0.2)'
            },
            emphasis: {
                itemStyle: {
                    color: '#A6D0E4'
                }
            }
        }],
        color: ['#02375A']
    }
}