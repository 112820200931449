import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { switchCate, changeKeywords, changeCate } from '@redux/search/action'
import { Input, Button } from 'antd'
import { throttle,Msg } from 'utils'
import Suggest from './Suggest'
/**
 * props params
 * @param {Boolean} home - 是否是搜索主页，false是搜索弹框
 * @param {Function} request - 搜索结果
 * @param {Function} hideModal - 隐藏搜索弹框
 * @param {String} activeKey - 搜索的类型 '0'全部 '1'公司 '2'人物 '3'技术 '4'园区
 * @param {Boolean} suggestShow - 是否显示联想框
 * @param {Function} hideSuggest - 将suggestShow 设置为false 隐藏联想框
 * @param {Function} showSuggest - 将suggestShow 设置为true 打开联想框
 */

class SearchInput extends Component{
    constructor(props) {
        super(props)
        this.state = {
            suggest: []     // 联想数据列表
        }
        this.getSuggestList = throttle(this.getSuggestList.bind(this), 300)
        this.enterBtn = this.enterBtn.bind(this)
        this.searchBtn = this.searchBtn.bind(this)
        this.inputRef = React.createRef()
    }
    // 输入框 onChange事件 触发联想
    handleChange(event) {
        const keywords = event.target.value.trim()
        this.props.input(keywords)
        this.getSuggestList()
    }
    componentDidMount() {
        document.addEventListener("keyup", this.enterBtn, false)    // 监听enter事件
        this.inputRef.current.focus() // 获取焦点
    }
    componentWillUnmount() {
        document.removeEventListener('keyup', this.enterBtn, false) // enter事件监听取消
    }
    // 监听回车键 执行搜索
    enterBtn(e) {
        if (e.keyCode === 13) {
            this.searchBtn()
        }
    }
    // 获取搜索建议
    getSuggestList() {
        let key = this.props.keywords
        if (key.length > 0) {
            this.axios.post('/search',{key,type:1}).then(({code,data})=>{
                if (code === 10000){
                    this.setState({
                        suggest:data.items
                    })
                    this.props.showSuggest()
                }
            })
        } else {
            return null
        }
    }
    // 搜索按钮
    searchBtn() {
        if (this.props.keywords){
            const isHome = this.props.home
            this.props.hideSuggest()
            !isHome && this.props.hideModal()
            !isHome && this.props.history.push('/search')
            isHome && this.props.request(this.props.activeKey)
        } else {
            Msg('warning','请输入关键字！')
        }
    }
    render(){
        return (
            <div className='search-form content'>
                {/* {this.props.home && <h5>输入公司/人物/技术/园区名称</h5>} */}
                <div className="form-box">
                    <div className="input-box">
                        <Input
                            id='search-input'
                            placeholder="请输入公司/人物/技术名称"  // 请输入公司/人物/技术/园区名称
                            value={this.props.home ? this.props.keywords : undefined}
                            onChange={e=>this.handleChange(e)}
                            ref={this.inputRef}
                            autoComplete="off"
                            prefix={<i className="iconfont icon-search searchOutlined"></i>}
                        />
                    </div>
                    <div className="button-box">
                        <Button type="primary" onClick={this.searchBtn}>搜索</Button>
                    </div>
                </div>
                {/* 联想结果列表 */}
                <Suggest list={this.state.suggest} show={this.props.suggestShow}  />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        cates: state.cates,
        keywords: state.keywords,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        clickCate: cate => {
            dispatch(switchCate(cate))
        },
        // 高亮类别
        setActiveCate: cate => {
            dispatch(changeCate(cate))
        },
        input: keywords => {
            dispatch(changeKeywords(keywords))
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchInput))