import React, { Component } from 'react'
import IndustryCharts from './charts/IndustryCharts'
import PersonEffect from './charts/PersonEffect'
import CollectionTech from './charts/CollectionTech'
import LabelModal from './LabelModal'
import { getStorage } from 'utils/storage'
import UserTypeModal from './UserTypeModal'
import 'styles/indiviation/chartsindex.scss'

class ChartsIndex extends Component{
    state = {
        isParkUser: getStorage('level') === 2 || getStorage('level') === 6,
    }
    componentDidMount(){
        document.title = 'DT Insights——透视未来的科技本质'
    }
    // 获取是否园区用户
    getUserType = ()=>{
        this.setState({
            isParkUser: getStorage('level') === 2 || getStorage('level') === 6
        })
    }
    getRecomList = () => {}
    render(){
        return <section className='individuation-charts'>
            {/* 产业 */}
            <IndustryCharts />
            {/* 人物影响力 */}
            <PersonEffect />
            {/* 最新专辑 技术词云 */}
            <CollectionTech />
            {/* 用户选择类型 */}
            <UserTypeModal getUserType={this.getUserType}/>
            {/* 用户选择关注的标签 */}
            <LabelModal getRecomList={this.getRecomList}/>
        </section>
    }
}

export default ChartsIndex