import React, { Component } from 'react'
import echarts from 'echarts'
class EchartReact extends Component {
    constructor(props) {
        super(props)
        this.echarts = echarts
        this.state = {
            id : this.props.id,
        }
    }
    componentDidMount() {
        this.getEchartsInstance()
    }
    // 数据更新时
    static getDerivedStateFromProps(nextProps,prevState){
        if (nextProps.id !== prevState.id) {
            return {
                id : nextProps.id
            }
        }
        return null
    }
    componentDidUpdate() {
        this.getEchartsInstance()
    }

    getEchartsInstance = () => {
        const echartObj = this.echarts.init(document.getElementById(this.state.id))
        echartObj.setOption(this.props.options)
    }


    render() { 
        const { style, className } = this.props
        return (<div id={this.state.id} style={style} className={className}></div>)
    }
}
 
export default EchartReact