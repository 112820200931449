const allObj = [{id:0,name:'全部'}]

// 筛选项的id
export function filterId(lists){
    let arr = []
    lists.filter(list => list.id !== 0 ).forEach(tag =>{ arr.push(tag.id) })
    return arr.join(',')
}

// 支持多选的一级
export function arrayTurnState(checked,state,tag,type){
    if (type !== 'scopes'){
        return checked ? [...state, tag].filter(t => t.id !== 0) : state.filter(t => t.id !== tag.id)
    } else {
        return checked ? [tag] : state.filter(t => t.id !== tag.id)
    }
}
// 支持多选的二级
export function subTurnState(checked,state,tag){
    tag['type'] = 'sub'
    return checked ? [...state,tag].filter(t => t.id !== 0) : state.filter(t => t.id !== tag.id)
}
// 删除项
export function filterRemoveTag(data,removeTag){
    const filterData = data.filter(tag => tag.id !== removeTag.id )
    return filterData.length === 0 ? allObj : filterData
}

export function createFoundList(){
    // 获取当前年份
    let years = [{id:0,name:'全部'}],MyYear = new Date().getFullYear()
    for (let i = 0;i < 8;i++){
        if (i === 7){
            years.push({ id:MyYear - i, name: `${MyYear - i}以前` })
        } else {
            years.push({ id:MyYear - i, name: `${MyYear - i}` })
        }
    }
    return years
}