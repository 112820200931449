import React from 'react'
import { Tabs, Modal, Table } from 'antd'
import { EmptyComp } from 'comps/common/EmptyComp'
import BtmFcBtn from './BtmFcBtn'
import { saveExcel, turnTableData } from 'utils'
import { InvestColumns, InvestorColumns, MergerColumns } from 'utils/columns'
import 'styles/comps/table.scss'

const { TabPane } = Tabs

// 产品要求tabs展示的顺序
const TabsTitle = [
    { name: '投资', key: 'invest' },
    { name: '并购', key: 'merger' },
    { name: '投资人', key: 'investor' }
]
// 选择table表头的配置项
let columnsConfig = key => key === 'invest' ? InvestColumns : (key === 'investor' ? InvestorColumns : MergerColumns)
class CompanyInvestTabs extends React.Component {
    state = {
        visible: false, currentNum: 0,
    }
    // tab切换：行业分布 企业形态 key是字符串
    tabChange = key => {
        this.setState({ currentNum: Number(key) })
    }
    // 底部左右箭头 切换tab type:reduce or add
    toggleChartType = type => {
        const { currentNum } = this.state
        const maxNum = TabsTitle.length - 1
        let num = currentNum
        if (type === 'add') {
            num = currentNum === maxNum ? 0 : ++num
        } else {
            num = currentNum === 0 ? maxNum : --num
        }
        this.setState({ currentNum: num })
    }
    // 打开全屏
    fullScreen = () => {
        this.setState({ visible: true })
    };
    // 关闭全屏
    hideModal = () => {
        this.setState({ visible: false })
    };
    // 保存为excel格式的文件
    savaTableCsv = () => {
        const { currentNum } = this.state
        const { data, compName } = this.props
        // 当前表头 当前表格内容
        const filename = `${compName}-${TabsTitle[currentNum].name}` // 文件名
        const type = TabsTitle[currentNum].key // 选择数据类型
        let head = columnsConfig(type), csvData = data[type]
        // 使用export-excel插件
        saveExcel(head, turnTableData(head, csvData), filename)
    }
    render() {
        const { showMode, currentNum } = this.state
        const { fullID, data, compName } = this.props
        return (
            <div className='tabcharts com-invest'>
                <Tabs activeKey={String(currentNum)} onChange={this.tabChange}>
                    {
                        TabsTitle.map((tab, index) => {
                            return (
                                <TabPane tab={tab.name} key={index} >
                                    {
                                        data[`${tab.key}`].length > 0 ?
                                            <Table pagination={false} rowKey={record => record.id || record.date}
                                                dataSource={data[`${tab.key}`]} scroll={{ y: 300 }}
                                                columns={columnsConfig(tab.key)}
                                                className='custom-table'
                                            />
                                            :
                                            <EmptyComp />
                                    }
                                </TabPane>
                            )
                        })
                    }
                </Tabs>
                <BtmFcBtn isDispalyTitle={true} isDispalyRadio={false} isDispalyDown={data[TabsTitle[currentNum].key] && data[TabsTitle[currentNum].key].length > 0}
                    TabsTitle={TabsTitle} currentNum={currentNum} showMode={showMode} fullID={fullID}
                    toggleChartType={this.toggleChartType} savaTableCsv={this.savaTableCsv}
                    fullScreen={this.fullScreen}
                />
                {/* 全屏 */}
                <Modal
                    title="融资"
                    visible={this.state.visible}
                    onOk={this.hideModal} onCancel={this.hideModal}
                    className='echart-full'
                    width={'80%'}
                    footer={null}
                >
                    <CompanyInvestTabs fullID='full' data={data} compName={compName} />
                </Modal>
            </div>
        )
    }
}
export default CompanyInvestTabs
