import React from 'react'
import classNames from 'classnames'

const TabBar = props => {
    const handelClick = id => {
        props.click(id)
    }
    return (
        <span className="tab-bar">
            {props.list.map(item => <span key={item.id}
                onClick={handelClick.bind(this, item.id)}
                className={classNames('item', { active: item.active })}
            >{item.title}</span>)}
        </span>
    )
}

export default TabBar