import React, { Component } from 'react'
import { Breadcrumb, Modal, Skeleton, Row, Col} from 'antd'
import BreadComp from 'comps/bread'
import Sum from './gneral/Sum'
import Card from './gneral/Card'
import Policy from './gneral/Policy'
import Rank from './gneral/Rank'
import Valuation from './gneral/Valuation'
import Scale from './gneral/Scale'
import Locate from './gneral/Locate'
import TabBar from './gneral/TabBar'
import Finance from './gneral/Finance'
import Intension from './gneral/Intension'
import {echartSavePic} from 'utils'
const BreadData = [{ url: '/home', title: '首页' }, { url: '', title: '科创板' }]
class General extends Component {
    constructor(props) {
        super(props)
        this.state = {
            sum: [
                { desc: '上市股票', nums: '106', unit: '只' },
                { desc: '总股本', nums: '341.28', unit: '亿股' },
                { desc: '流通股本', nums: '53.71', unit: '亿股' },
                { desc: '流通市值', nums: '2731.30', unit: '亿元' },
                { desc: '平均市盈率', nums: '82.04', unit: '倍' },
                { desc: '总市值', nums: '16996.47', unit: '亿元' },
            ],
            policy: [],
            vc_head: { order: '排名', name: '机构', nums: '投资数' },
            analysis: [
                { id: 'Intension', active: true, title: '研发强度' },
                { id: 'Locate', active: false, title: '地域分布' },
                { id: 'Finance', active: false, title: '融资事件' },
                { id: 'Scale', active: false, title: '募资规模' },
            ],
            activeTab: 'Intension',
            industry: [
                { id: '0', active: true, title: '全部' },
                { id: '1', active: false, title: '下一代信息技术' },
                { id: '489', active: false, title: '生物技术' },
                { id: '107', active: false, title: '高端制造装备' },
                { id: '192', active: false, title: '新材料' },
                { id: '319', active: false, title: '新能源' },
                { id: '425', active: false, title: '节能环保' }
            ],
            // 产业估值参考
            allInfos: {
                invest: {},
                scale: [],
                develop: [],
                domainValues: {},
                //区域分布
                area: {},
                starValues: {},
                investSort: []
            },
            //产业图表
            ValueID: '0',
            Values: {},
            InfosH: false,
            visible: false
        }
        this.changeTabs = this.changeTabs.bind(this)
        this.changeIndustry = this.changeIndustry.bind(this)
        this.save = this.save.bind(this)
    }
    save() {
        echartSavePic('科创板估值参考','val' + this.state.ValueID)
    }
    fullScreen = () => {
        this.setState({ visible: true })
    }
    hideModal = () => {
        this.setState({ visible: false })
    }
    changeTabs(cate) {
        this.setState({
            analysis: this.state.analysis.map(item => item.id === cate ? { ...item, active: true } : { ...item, active: false }),
            activeTab: cate
        })
    }
    // 产业切换
    changeIndustry(cate) {
        this.setState({
            industry: this.state.industry.map(item => item.id === cate ? { ...item, active: true } : { ...item, active: false }),
            Values: cate === '0' ? this.state.allInfos.starValues : this.state.allInfos.domainValues[cate],
            ValueID: cate
        })
    }


    componentDidMount() {
        this.axios.post('/starmarket/policy')
            .then(res => {
                this.setState({ policy: res.data.items })
            })
        this.axios.post('/starmarket/allinfos')
            .then(res => {
                this.setState({
                    allInfos: res.data,
                    Values: res.data.starValues,
                    InfosH: true,
                })
            })

    }
    render() {
        let Tab = this.state.analysis.find(item => item.active ? item : null)
        return (
            <div className="general">
                <BreadComp data={BreadData} />
                <div className="title">科创板概况</div>
                <Sum />
                <div className="title title-border">科创板统计分析</div>
                <div className="wrap-box board-tabcharts">
                    <TabBar list={this.state.analysis} click={this.changeTabs} />
                    <div className="title" style={{ textAlign: 'center' }}>{Tab.title}</div>
                    {this.state.InfosH === true && this.state.activeTab === 'Finance' &&
                        <Finance id="finance" data={this.state.allInfos.invest} />}
                    {this.state.InfosH === true && this.state.activeTab === 'Scale' &&
                        <Scale data={this.state.allInfos.scale} />}
                    {this.state.InfosH === true && this.state.activeTab === 'Intension' &&
                        <Intension data={this.state.allInfos.scale} />}
                    {this.state.InfosH === true && this.state.activeTab === 'Locate' &&
                        <Locate data={Object.values(this.state.allInfos.area)} />}

                </div>
                <div className="section policy-rank">
                    <Card title="政策法规">
                        {this.state.policy && this.state.policy.map(item => <Policy key={item.id} {...item} />)}
                    </Card>
                    <div className="center-border"></div>
                    <Card title="科创板活跃投资机构 TOP5">
                        <Rank list={this.state.allInfos.investSort} head={this.state.vc_head} />
                    </Card>
                </div>
                <div className="title title-border">科创板估值参考</div>
                <div className="wrap-box board-tabcharts">
                    <TabBar list={this.state.industry} click={this.changeIndustry} />
                    {this.state.InfosH === true && <Valuation id={"val" + this.state.ValueID} data={this.state.Values} />}
                    <div className="relate-footer">
                        <div className="oprate" onClick={this.fullScreen}>
                            <img src={require('assets/images/person/full.svg')} alt="full" />
                        </div>
                        <div className="oprate" onClick={this.save}>
                            <img src={require('assets/images/person/down.svg')} alt="down" />
                        </div>
                    </div> 
                </div>
                <Modal
                    title="科创板估值参考"
                    visible={this.state.visible}
                    onOk={this.hideModal}
                    onCancel={this.hideModal}
                    className='echart-full'
                    width={1200}
                    footer={null}
                >
                    <div className="wrap-box" style={{boxShadow: '0 0 0 0'}}>
                        <TabBar list={this.state.industry} click={this.changeIndustry} />
                        <Valuation 
                            id={"vals" + this.state.ValueID} 
                            data={this.state.Values} 
                        />
                        <div className="relate-footer">
                            <div className="oprate" onClick={this.save}>
                                <img src={require('assets/images/person/down.svg')} alt="down" />
                            </div>
                        </div> 
                    </div>
                    
                </Modal>
            </div>
        )
    }
}

export default General