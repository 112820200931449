import React,{Component} from 'react'
import * as echarts from 'echarts'
import { COLORS,FINANCEOBJ } from 'utils/type'

// https://gallery.echartsjs.com/editor.html?c=xHJIPHN9NF
// https://gallery.echartsjs.com/editor.html?c=x6LFNVgmfx

const dataStyle = {
    normal: {
        label: {
            show: false
        },
        labelLine: {
            show: false
        },
        shadowBlur: 15,
        shadowColor: 'white',
    }
}
const placeHolderStyle = {
    normal: {
        color: 'rgba(0,0,0,0)',
        label: {
            show: false
        },
        labelLine: {
            show: false
        }
    },
    emphasis: {
        color: 'rgba(0,0,0,0)'
    }
}
let FunnelOptions = {
    color:COLORS,
    title: {
        text: '近十年企业多轮融资成功率',
        left:'center'
    },
    tooltip: {
        trigger: 'item',
    },
    legend: [{
        left:10,top:40,
        data: Object.values(FINANCEOBJ)
    }] ,
    series: [
        {
            type: 'funnel',
            left: '55%',
            top: 80,
            bottom: 10,
            width: '45%',
            sort: 'descending',
            gap: 2,
            label: { show: true, position: 'inside' },
            emphasis: { label: { fontSize: 16 } },
            data: []
        }
    ]
}
export class FunnelFinance extends Component {
    componentDidMount() {
        // 处理成所需要的数据格式
        let funnelData = [],allPercent = this.props.data['all']
        for (let [k,v] of Object.entries(this.props.data)){
            funnelData.push({value:v,name:FINANCEOBJ[`${k}`]})
        }
        // 去除全部项
        let fiterALl = funnelData.filter(l=>l.name !== '全部')
        // 根据fiterAll push pie
        echarts.util.each(fiterALl, function (list, idx) {
            let len = fiterALl.length,n = (100 / len) 
            let y = n * (len - idx) - 2 ,x = y - 8
            const radiusArr = [`${x}%`,`${y}%`]
            FunnelOptions.series.push({
                type: 'pie',
                left: '5%',
                top: 80,
                right:'55%',
                clockWise: true,
                hoverAnimation: false,
                radius: radiusArr,  // 位置
                itemStyle: dataStyle,
                data: [{
                    value: list.value,
                    name: list.name
                }, {
                    value: allPercent - list.value,
                    name: '总数',
                    tooltip: {
                        show: false
                    },
                    itemStyle: placeHolderStyle
                }]
            })
        })
        // hover事件显示的内容
        FunnelOptions.tooltip.formatter = f =>{
            return f.name + ':' + Number(f.value / allPercent * 100 ).toFixed(2)  + '%'
        }
        // 添加漏斗图的数据
        FunnelOptions.series[0].data = funnelData
        // 绘制
        let myChart = echarts.init(document.getElementById(`${this.props.id}`))
        myChart.setOption(FunnelOptions)
    }
    render() {
        return (
            <div id={`${this.props.id}`} className='barline'></div>
        )
    }
}