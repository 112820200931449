import React, { PureComponent } from 'react'
import classNames from 'classnames'
import { render } from 'less'

const numsData = [
    {num:50,unit:'万+',title:'科技人才'},
    {num:20,unit:'万+',title:'科技企业'},
    {num:1000,unit:'万+',title:'专利数据'},
    {num:500,unit:'万+',title:'论文'},
    {num:1000,unit:'+',title:'技术词条'}
]
const solveData = [
    { title:'企业',type:'company',des:'清晰了解所在行业、产业链的全图景认知，明确企业发展阶段及趋势站位，实时追踪技术竞争情报。'},
    { title:'高校',type:'college',des:'挖掘全球高层次人才，全科技领域教职人员及科研人员招聘；科技市场研究，经管、理工类院系研究的智能分析工具。'},
    { title:'政府/产业园区',type:'park',des:'高成长性企业研究、挖掘，高层次海内外人才引进，全方位提升科技认知结构，纵深补充科技情报来源。'},
    { title:'投资机构',type:'institution',des:'探测下一个投资热潮，深度挖掘投资机会，从技术、企业、人才多维度交叉评价深度竞调标的企业。'}
]

class NumsSection extends PureComponent{
    constructor(props){
        super(props)
        this.state = {
            hoverId : -1
        }
        this.onMouseEnter = this.onMouseEnter.bind(this)
        this.onMouseLeave = this.onMouseLeave.bind(this)
    }
    onMouseEnter(idx){
        this.setState({ hoverId: idx })
    }
    onMouseLeave(idx){
        this.setState({ hoverId: -1 })
    }
    render(){
        return (
            <div className='num-container'>
                {/* 数字统计 */}
                {/* <section className='nums'>
                    <div className='container'>
                        <h1 className='title'>您要找的，不仅是数据 <span>百万级科技数据智能化分析，我们给您结论。</span></h1>
                        {
                            numsData.map((list,index) => <dl key={list.title}>
                                <dt>{list.num}<span>{list.unit}</span></dt>
                                <dd>{list.title}</dd>
                            </dl>)
                        }
                    </div>
                </section> */}
                {/* 解决方案 */}
                <section className='solve' id='solve'>
                    <div className='container'>
                        <h1 className='title'>科技数据智能分析引擎驱动科创服务<span>满足科技生产链条各主体的知识、认知和结果需求</span></h1>
                        {
                            solveData.map((list,index) => <dl key={list.title} 
                                onMouseEnter={this.onMouseEnter.bind(this,index)}
                                onMouseLeave={this.onMouseLeave.bind(this,index)}
                            >
                                <svg className="symbol_icon" aria-hidden="true">
                                    <use xlinkHref={this.state.hoverId === Number(index) ? `#icon-${list.type}-hover` : `#icon-${list.type}-default`}></use>
                                </svg>
                                <dt>{list.title}</dt>
                                <dd>{list.des}</dd>
                            </dl>)
                        }
                    </div>
                </section>
            </div>
        )
    }
}

export default NumsSection