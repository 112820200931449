import React, { Fragment } from 'react'

const Rank = props => {
    return (<div className="ranking">
        <div className="head">
            <div className="order">{props.head.order}</div>
            <div className="rank-name">{props.head.name}</div>
            <div className="nums">{props.head.nums}</div>
        </div>
        <div className="body">
            {props.list.map((item, index) => <Fragment key={index}>
                <div className="order">{index + 1}</div>
                <div className="rank-name">{item.investor_name}</div>
                <div className="nums">{item.num}</div>
            </Fragment>)}

        </div>
    </div>)
}

export default Rank