import React, { useEffect, useState } from 'react'
import { Row, Col, Skeleton } from 'antd'
import { Link } from 'react-router-dom'
import TechCloud from 'pages/echarts/TechCloud'
import RandomImg from 'comps/random-img/RandomImg'
import axios from 'libs/axios'
import formatType from 'utils/formatType'
const CollectionTech = props => {
    const [ collections, setCollections ] = useState([])
    const [ techlists, setTechlists ] = useState([])
    useEffect(()=>{
        // 获取最热专辑
        axios.post('/individuality/collectionnew').then(res=>{
            if (res.code === 10000){
                setCollections(res.data)
            }
        })
        // 获取技术词云
        axios.post('/individuality/technew').then(res=>{
            if (res.code === 10000){
                let techLists = [...res.data]
                techLists.forEach(t => {
                    t.value = Math.random() * 10 // 技术词的随机value 决定词绘制的大小
                })
                setTechlists(techLists)
            }
        })
    },[])
 
    return <Row className='indi-collection-tech' justify='space-between'>
        <Col span={11}>
            <h1 className='indi-title'>热门专辑 <Link to='/collection'>查看更多</Link></h1>
            <ul className="indi-collections flex_box">
                <Skeleton active paragraph={{ rows: 10 }} loading={ collections.length === 0 }>
                    {
                        collections.slice(0,6).map(collect => <Link to={`collection/detail/${collect.id}`} key={collect.id}>
                            {/* 专辑封面图片 */}
                            <RandomImg dataType={collect.data_type} randomName={collect.name} photoUrl={collect.photo} randomWidth={'143px'} randomHeight={'81px'} />
                            <dl>
                                <dt>{collect.name}</dt>
                                <dd>
                                    {
                                        collect.introduce ? collect.introduce 
                                            : formatType(collect.obj_num, collect.data_type, collect.items)
                                    }
                                    
                                </dd>
                            </dl>
                        </Link>)
                    }
                </Skeleton>
            </ul>
        </Col>
        <Col span={11}>
            <h1 className='indi-title'>技术图谱 <Link to='/tech'>查看更多</Link></h1>
            <Skeleton active paragraph={{ rows: 10 }} loading={ techlists.length === 0 }>
                {
                    techlists.length > 0 && <TechCloud id='indi-tech-cloud' data={techlists}/>
                }
            </Skeleton>
        </Col>
    </Row>
}

export default CollectionTech