import Layout from 'pages/layout'
// 园区
import ParkDetail from 'pages/park/ParkDetail'
import ParkList from 'pages/park/ParkList'
import Platform from 'pages/park/Platform'
// 公司
import CompanyList from 'pages/company/CompanyList'
import CompanyDetail from 'pages/company/CompanyDetail'
// 公司财务数据
import Finance from 'pages/company/stock/Finance'
// 产业
import IndustryIndex from 'pages/industry'
// 个人中心
import UserIndex from 'pages/user'

// 人物
import PersonList from 'pages/person/PersonList'
import Detail from 'pages/person/Detail'
import PersonContent from 'pages/person/Content'
import PersonEffect from 'pages/person/PersonEffect' // 20201101 新增 人物影响力模块

// 搜索
import Search from 'pages/search/Search'
// 技术
import TechList from 'pages/tech/TechList'
import TechDetail from 'pages/tech/TechDetail'

//研究
import List from 'pages/research/List'
import ResearchDetail from 'pages/research/ResearchDetail'

// 科创板
import Board from 'pages/board/Board'
import PolicyDetail from 'pages/board/gneral/PolicyDetail'
// 个性化首页
import Individuation from 'pages/individuation/'
import NewsList from 'pages/individuation/NewsList'
import ChartsIndex from 'pages/individuation/ChartsIndex'  // 20201110 新改版的个性化首页

// 专辑
import OtherCollect from 'pages/collection/OtherCollect'
import MyCollect from 'pages/collection/MyCollect'
import OtherCollectDetail from 'pages/collection/CollectionDetail'
import MyCollectDetail from 'pages/collection/MyCollectionDetail'

// 快讯
import ExpressList from 'pages/search/ExpressList'
// vip页面
import Vippage from 'pages/official/Vippage'

// 人物对比ui改版
import PerComparison from 'pages/percomparison'
// 公司对比ui改版
import ComComparison from 'pages/companyparison'
const PrivateRoutes = [{
    path: '/',
    component: Layout,
    childRoutes: [
        { path: '/home', component: ChartsIndex },
        { path: '/home/news', component: NewsList },
        { path: 'park/:id', component: ParkDetail },
        { path: 'park', component: ParkList },
        { path: 'platform', component: Platform },
        { path: 'company', component: CompanyList },
        { path: 'company/:id', component: CompanyDetail },
        { path: 'company/stock/:id', component: Finance },
        { path: 'industry/:id', component: IndustryIndex },
        { path: 'user', component: UserIndex },
        { path: 'person', component: PersonList },
        { path: 'person/detail/:id', component: Detail },
        { path: 'person/thesis/:id', component: PersonContent },
        { path: 'person/patent/:id', component: PersonContent },
        { path: 'person/project/:id', component: PersonContent },
        { path: 'person/effect/:city_id/:direction_id', component: PersonEffect },
        { path: 'tech', component: TechList },
        { path: 'tech/:id', component: TechDetail },
        { path: 'search', component: Search },
        { path: 'board/general', component: Board },
        { path: 'board/graph', component: Board },
        { path: 'board/group', component: Board },
        { path: 'board/general/policy/:id', component: PolicyDetail },
        { path: 'research', component: List },
        { path: 'research/:id', component: ResearchDetail },
        { path: 'collection', component: OtherCollect },
        { path: 'collection/:id', component: OtherCollect },
        { path: 'mycollection', component: MyCollect },
        { path: 'mycollection/:id', component: MyCollect },
        { path: 'collection/detail/:id', component: OtherCollectDetail },
        { path: 'mycollection/detail/:id', component: MyCollectDetail },
        { path: 'express', component: ExpressList },
        { path: '/vippage', component: Vippage },
        // 人物对比ui改版页面
        { path: 'personcomparison', component: PerComparison },
        // 公司对比ui改版页面
        { path: 'companycomparison', component: ComComparison },
    ]
}]

export default PrivateRoutes
