import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Tabs, Modal, Skeleton } from 'antd'
import BtmFcBtn from './BtmFcBtn'
import TreeMap from 'pages/echarts/treemap'
import { EffectGenderPie } from 'pages/echarts/pie'
import { EffectAgeBar } from 'pages/echarts/bar'
import EffectScatter from 'pages/echarts/EffectScatter'
import EffectLine from 'pages/echarts/EffectLine'
import { CustomEmptyImage } from 'comps/common/EmptyComp'
import axios from 'libs/axios'
import { echartSavePic } from 'utils'


const { TabPane } = Tabs

// 产品要求tabs展示的顺序
const TabsTitle = [
    {name:'院校分布',key:'college'},
    {name:'年龄分布',key:'age'},
    {name:'性别分布',key:'gender'},
    {name:'学者分布',key:'scholar'},
    {name:'专利趋势',key:'patent'},
    {name:'论文情况',key:'paper'}
]

const EffectTopTabs = props => {
    const { fullID,propsLocalID,propsFieldID } = props
    const localID = fullID ? propsLocalID : props.match.params.city_id, // 地区ID
        fieldID = fullID ? propsFieldID : props.match.params.direction_id // 领域ID
    const [ collegeData, setCollegeData ] = useState(null) // 院校分布 []
    const [ ageData, setAgeData ] = useState(null) // 年龄分布 {}
    const [ genderData, setGenderData ] = useState(null) // 性别分布 []
    const [ scholarData, setScholarData ] = useState(null) // 学者分布 []
    const [ patentData, setPatentData ] = useState(null) // 专利分布 {}
    const [ paperData, setPaperData ] = useState(null) // 论文分布 []
    const [ currentNum, setCurNum ] = useState(0)  // 当前选中的tab
    const [ visible, setVisible ] = useState(false) // 是否全屏
    useEffect(()=>{
        // 获取院校分布
        axios.post('/expert/school',{ field:fieldID, local:localID }).then(res=>{
            if (res.code === 10000){
                setCollegeData(res.data)
            }
        })
    },[fieldID,localID])
    // tab切换：行业分布 企业形态
    const tabChange = key =>{
        numCausePost(key)
        setCurNum(Number(key))
    }
    // 根据数值的不同 请求的数据不同
    const numCausePost = num => {
        Number(num) === 1 && ageData === null && getAgeData()
        Number(num) === 2 && genderData === null && getGenderData()
        Number(num) === 3 && scholarData === null && getScholarData()
        Number(num) === 4 && patentData === null && getPatentata()
        Number(num) === 5 && paperData === null && getPaperData()
    }
    // 底部左右箭头 切换tab type:reduce or add
    const toggleChartType = type =>{
        const maxNum = 5
        let num = currentNum
        if (type === 'add'){
            num = currentNum === maxNum ? 0 : ++ num
        } else {
            num = currentNum === 0 ? maxNum : -- num
        }
        numCausePost(num)
        setCurNum(num)
    }
    // 获取性别分布数据
    const getGenderData = () => {
        axios.post('/expert/sex',{ field:fieldID, local:localID }).then(res=>{
            if (res.code === 10000){
                let genderArr = []
                // 如果返回的数据 res.data 不是空对象，则处理，否则返回空数组
                if (Object.keys(res.data).length > 0){
                    genderArr = Object.values(res.data)
                    genderArr[1].selected = true
                }
                setGenderData(genderArr)
            }
        })
    }
    // 获取年龄分布
    const getAgeData = () => {
        axios.post('/expert/age',{ field:fieldID, local:localID }).then(res=>{
            if (res.code === 10000){
                setAgeData(res.data)
            }
        })
    }
    // 获取学者分布
    const getScholarData = () => {
        axios.post('/expert/distribution',{ field:fieldID, local:localID }).then(res=>{
            if (res.code === 10000){
                let arr = []
                res.data.forEach(item => {
                    let points = []
                    points.push(item.total_patent)
                    points.push(item.total_paper)
                    points.push(item.name_cn)
                    arr.push(points)
                })
                setScholarData(arr)
            }
        })
    }
    // 专利趋势
    const getPatentata = () => {
        axios.post('/expert/patent',{ field:fieldID, local:localID }).then(res=>{
            if (res.code === 10000){
                setPatentData(res.data)
            }
        })
    }
    // 论文情况
    const getPaperData = () => {
        axios.post('/expert/papermap',{ field:fieldID, local:localID }).then(res=>{
            if (res.code === 10000){
                setPaperData(res.data)
            }
        })
    }
    // 切换全屏
    const fullScreen = () => setVisible( true )
    const hideModal = () => setVisible( false )
    
    // 保存为图片
    const savaTableCsv = () => {
        const saveName = document.title
        const filename = `${saveName}-${TabsTitle[currentNum].name}` // 文件名
        const type = TabsTitle[currentNum].key // 选择数据类型
        const id = fullID ? `${fullID}${type}` : type // ID
        echartSavePic(filename,id) // 下载png格式图片 文件名和唯一ID
    }
    return <div className='tabcharts'>
        <Tabs activeKey={String(currentNum)} onChange={tabChange}>
            {
                TabsTitle.map((tab,index) =>{
                    return (
                        <TabPane tab={tab.name} key={index} >
                            {
                                tab.key === 'college' && <Skeleton active paragraph={{ rows: 15 }} loading={collegeData === null} >
                                    {
                                        collegeData !== null && collegeData.length > 0 ? <TreeMap data={collegeData} id={fullID ? `${fullID}${tab.key}` : tab.key}/>
                                            : <CustomEmptyImage />
                                    }
                                </Skeleton>
                            }
                            {
                                tab.key === 'gender' && <Skeleton active paragraph={{ rows: 15 }} loading={genderData === null} >
                                    {
                                        genderData !== null && genderData.length > 0 ? 
                                            <EffectGenderPie data={genderData} id={fullID ? `${fullID}${tab.key}` : tab.key}/>
                                            : <CustomEmptyImage />
                                    }
                                </Skeleton>
                            }
                            {
                                tab.key === 'age' && <Skeleton active paragraph={{ rows: 15 }} loading={ageData === null} >
                                    {
                                        ageData !== null && Object.keys(ageData).length > 0 ? 
                                            <EffectAgeBar {...ageData} id={fullID ? `${fullID}${tab.key}` : tab.key}/>
                                            : <CustomEmptyImage />
                                    }
                                </Skeleton>
                            }
                            {
                                tab.key === 'scholar' && <Skeleton active paragraph={{ rows: 15 }} loading={scholarData === null} >
                                    {
                                        scholarData !== null && scholarData.length > 0 ?
                                            <EffectScatter data={scholarData} id={fullID ? `${fullID}${tab.key}` : tab.key}/>
                                            : <CustomEmptyImage />
                                    }
                                </Skeleton>
                            }
                            {
                                tab.key === 'patent' && <Skeleton active paragraph={{ rows: 15 }} loading={patentData === null} >
                                    {
                                        patentData !== null && Object.keys(patentData).length > 0 ?
                                            <EffectLine {...patentData} id={fullID ? `${fullID}${tab.key}` : tab.key} yAxisName={tab.name} />
                                            : <CustomEmptyImage />
                                    }
                                </Skeleton>
                            }
                            {
                                tab.key === 'paper' && <Skeleton active paragraph={{ rows: 15 }} loading={paperData === null} >
                                    {
                                        paperData !== null && Object.keys(paperData).length > 0 ?
                                            <EffectLine {...paperData} id={fullID ? `${fullID}${tab.key}` : tab.key} yAxisName={tab.name} />
                                            : <CustomEmptyImage />
                                    }
                                </Skeleton>
                            }
                        </TabPane>
                    )
                })
            }
        </Tabs>
        {/* 底部功能图标 */}
        <BtmFcBtn isDispalyTitle={true} isDispalyRadio={false} isDispalyDown={true}
            TabsTitle={TabsTitle} currentNum={currentNum} fullID={fullID}
            toggleChartType={toggleChartType} savaTableCsv={savaTableCsv} 
            fullScreen={fullScreen}
        />
        {/* 全屏 */}
        <Modal
            title=""
            visible={visible}
            onOk={hideModal}
            onCancel={hideModal}
            className='echart-full'
            width={'80%'}
            footer={null}
        >
            <EffectTopTabs fullID='full' propsLocalID={localID} propsFieldID={fieldID}/>
        </Modal>
    </div>
}

export default withRouter(EffectTopTabs)