import React from 'react'
import { Layout,Skeleton } from 'antd'
import moment from 'moment'
import BreadComp from 'comps/bread'  // 面包屑导航
import FavarList from './FavorList' // 可全部收藏列表
import ExportBtn from 'comps/common/ExportBtn'  // 导出按钮
import DetailList from './DetailList' // 内容详情
import VirtalListHOC from 'comps/common/VirtualList'
import AddCollectBtn from 'comps/collect/AddCollectBtn'
import { COL_TYPE } from 'utils/type'
import { saveWithALink } from 'utils'
import PackUp from 'comps/pack-up/PackUp'
import RandomImg from 'comps/random-img/RandomImg.jsx'
import formatType from 'utils/formatType'

import 'styles/pages/collection.scss'
const { Content } = Layout

const CollectionListHOC = VirtalListHOC(DetailList)

class CollectionDetail extends React.Component{
    constructor(props) {
        super(props)
        this.state = {
            infoLoading : true,
            BreadData : [{ url: '/home', title: '首页' },{ url: '/collection', title: '专辑列表' },{ url: '', title: '专辑详情' }],
            recommendDetailData: [],
            recommendDetailId: this.props.match.params.id,
            detailLoading: true,
        }
        this.saveRef = React.createRef()
    }

    componentDidMount(){
        let recommendDetailId = this.state.recommendDetailId
        this.axios.post('/collection/detail', {id: recommendDetailId}).then(res => {
            if (res.code === 10000){
                this.setState({
                    recommendDetailData: res.data,
                    detailLoading:false,
                    infoLoading:false
                }, () => {
                    if (this.saveRef.current && this.saveRef.current.clientHeight > 80) {
                        this.setState({
                            btnStatus: true,
                            btnIsVisible: true
                        })
                    }
                })
            }
        })
    }
    // id发生变化后重新发起数据请求
    componentDidUpdate(prevProps,prevState){
        if (prevProps.match.params.id !== this.props.match.params.id){
            this.setState({
                recommendDetailId: this.props.match.params.id,
                detailLoading: true
            }, () => {
                this.getRecommendDetailData()
            })
        }
    }

    //取消详情
    handleCancle = ()=> {
        this.props.history.push('/collection/0')
    }

    // 获取专辑详情信息
    getRecommendDetailData = () =>{
        let recommendDetailId = this.state.recommendDetailId
        this.axios.post('/collection/detail', {id: recommendDetailId}).then(res => {
            if (res.code === 10000){
                this.setState({
                    recommendDetailData: res.data,
                    detailLoading:false,
                    infoLoading:false
                })
            }
        })
    }

    // 导出专辑内容功能
    exportList = () => {
        this.axios.post('/collection/export',{ id:this.state.recommendDetailId }).then(res => {
            if (res.code === 10000){
                saveWithALink(res.data)
            }
        })
    }

    render(){
        const { recommendDetailData } = this.state
        const { id, data_type, items } = recommendDetailData
        return (
            <Layout className="collect-box">
                {/* 推荐专辑详情 */}
                <Layout className='detail-collection-con'>
                    <div className="cancel-btn" onClick={this.handleCancle}>
                        <i className="iconfont icon-guanbi"></i>
                    </div>
                    <Layout className='collect-rt'>
                        <BreadComp data={this.state.BreadData} />
                        <div id={this.props.match.params.id}>
                            <Content className='collect-main collectdetail-box' >
                                <div className="collect-basic-right">
                                    <div id="scrollRight">
                                        {/* 基本信息 */}
                                        <section className="collect-basic">
                                            {
                                                Object.keys(recommendDetailData).length > 0 && <Skeleton active avatar paragraph={{ rows: 4 }} loading={this.state.detailLoading}>
                                                    <div className='flex_box collect-introduce-box'>
                                                        {/* 专辑图片 */}
                                                        <RandomImg 
                                                            dataType={recommendDetailData.data_type}
                                                            randomName={recommendDetailData.name} 
                                                            photoUrl={recommendDetailData.photo} randomWidth={'126px'} randomHeight={'70px'} />
                                                        <div className="title-box">
                                                            <h1>{recommendDetailData.name}</h1>
                                                            <p className='statistics'>
                                                                <span>
                                                                    共<strong>{recommendDetailData.count}</strong>
                                                                    {COL_TYPE[`${recommendDetailData.data_type}`].unit}{COL_TYPE[`${recommendDetailData.data_type}`].name}
                                                                </span>
                                                                <span>创建于：{moment(recommendDetailData.release_time * 1000).format('YYYY年MM月DD日')}</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <PackUp 
                                                            introduce={recommendDetailData.introduce ? 
                                                                recommendDetailData.introduce 
                                                                : 
                                                                formatType(recommendDetailData.count, recommendDetailData.data_type,recommendDetailData.items)}
                                                            maxHeight={80} />
                                                    </div>
                                                </Skeleton>
                                            }
                                        </section>
                                        {/* 名单 */}
                                        <section className="collect-lists">
                                            <Skeleton active paragraph={{ rows: 4 }} loading={this.state.detailLoading}>
                                                <h1 className="flex_box title">
                                                    {data_type && (COL_TYPE[`${data_type}`].other_name || COL_TYPE[`${data_type}`].name)  }名单
                                                    <span className='rt-btn'>
                                                        {/* 收藏按钮 */}
                                                        <AddCollectBtn off={false} listId={id} type={data_type}/>
                                                        {/* 导出按钮 */}
                                                        <ExportBtn exportList={this.exportList} />
                                                    </span>
                                                </h1>
                                                <FavarList data={items} type={data_type}/>
                                            </Skeleton>
                                        </section>
                                        {/* 内容详情 */}
                                        <section className="collect-detail">
                                            <Skeleton active paragraph={{ rows: 10 }} loading={this.state.detailLoading}>
                                                <h1 className="flex_box title">内容详情</h1>
                                                { // 长度大于20的列表 使用虚拟列表 否则使用正常渲染列表
                                                    items && (items.length > 20 ? <CollectionListHOC 
                                                        data={items} scorllBoxHeight={1200} itemHeight={160} bufferCount={6}
                                                        type={data_type}
                                                    /> : items.map(item => {
                                                        return <DetailList {...item} type={data_type} key={item.id}/>
                                                    }))
                                                }
                                            </Skeleton>
                                        </section>
                                    </div>
                                </div>
                            </Content>
                        </div>
                    </Layout>
                </Layout>
            </Layout>
        )
    }
}
export default CollectionDetail
