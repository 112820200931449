import React, { Component } from 'react'
import { Table, Modal, message } from 'antd'
import cs from 'classnames'
import CompareBtn from 'comps/compare/CompareBtn'
import { MyCollectCompany, MyCollectExpert, MyCollectPark, MyCollectTech } from 'utils/columns'
import PersonCompare from 'comps/person-compare'
import { Msg } from 'utils'
import { getStorage } from 'utils/storage'
import { TEST_USER } from 'utils/type'
// redux
import { connect } from 'react-redux'
import { addComItem, delComItem } from '@redux/company/action'
import { addItem, deleteItem } from '@redux/person/action'

@connect(state => state, { addComItem, delComItem, addItem, deleteItem })

class MyFavarTable extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    // 删除按钮 所有的表头都要后加入进去
    deleteCon = [{
        title: '', dataIndex: 'delete', key: 'delete',
        // width:60,
        render: (text, record) => (
            <span onClick={() => this.deleteRow(record.collection_id, record.object_id)} className='iconfont icon-collection-del'></span>
        )
    }]
    compateBtn = [{
        title: '对比', dataIndex: 'compare', key: 'compare',
        // width: 80,
        render: (text, record) => {
            const isActive = this.props.compareCompanyItems.filter(item => item.id === record.object_id).length > 0
            return <span className='list-compare'
                onClick={ TEST_USER.includes(getStorage('level')) ? ()=>{this.props.changeModal(true)} : () => this.addCompare(record.object_id, record.object_name)}>
                <i className={cs('iconfont icon-compare', { 'active': isActive })}></i>
            </span>
        }
    }]
    compatePersonBtn = [{
        title: '对比', dataIndex: 'compare', key: 'compare', width: '12%',
        render: (text, record) => {
            const isActive = this.props.compareCompanyItems.filter(item => item.id === record.object_id).length > 0
            return <span className='list-compare'
                onClick={ TEST_USER.includes(getStorage('level')) ? ()=>{this.props.changeModal(true)} : () => this.addComparePerson(record.object_id, record.object_name)}>
                <i className={cs('iconfont icon-compare', { 'active': isActive })}></i>
            </span>
        },
    }]
    state = {
        homeIndex: -1, deleteId: -1, visible: false, col_id: -1
    }
    // 取消删除 所有的id取默认-1
    handleCancel = () => {
        this.setState({ visible: false, deleteId: -1, col_id: -1 })
    }
    // 确定删除
    confirmDelete = () => {
        this.setState({ visible: false })
        const { deleteId, col_id } = this.state
        // 发送删除的id的请求
        this.axios.post('/collection/delobject', { collection_id: col_id, object_id: deleteId })
            .then(res => {
                if (res.code === 10000) {
                    message.success('删除成功！')
                    this.props.getDetail()
                }
            })
    }
    // 要删除的专辑col_id 选中行的id
    deleteRow = (col_id, id) => {
        this.setState({ deleteId: id, col_id, visible: true })
    }
    // 根据是否设置行内class
    setClassName = (record, index) => {
        const { homeIndex } = this.state
        return index === homeIndex ? 'row-active' : ''
    }
    // 行内操作函数
    onRow = (row, index) => {
        return {
            onMouseEnter: event => { // 鼠标移入行
                this.setState({ homeIndex: index })
            },
            onMouseLeave: event => { // 鼠标移除行
                this.setState({ homeIndex: -1 })
            },
        }
    }
    // 添加到对比公司列表
    addCompare = (id, name) => {
        const compareCompanyItems = this.props.compareCompanyItems
        // 判断是否已存在
        if (compareCompanyItems.filter(item => item.id === id).length > 0) {
            this.props.delComItem(id)
            return
        }
        if (compareCompanyItems.length < 3) {
            this.props.addComItem({ id, name })
        } else {
            Msg('info', '最多支持三家企业对比哟！')
        }
    }
    // 人物对比
    addComparePerson = (id, name) => {
        const compareItem = this.props.compareItem
        if (compareItem.filter(item => item.id === id).length > 0) {
            this.props.deleteItem(id)
            return
        }
        if (compareItem.length < 3) {
            this.props.addItem({ id, name })
        } else {
            Msg('info', '最多支持三位专家对比哟！')
        }
    }
    // 根据type的数字返回column的取值
    getColumns = type => {
        let COLUMNS
        if (type === 1) {
            COLUMNS = MyCollectCompany.concat(this.compateBtn)
        } else if (type === 2) {
            COLUMNS = MyCollectExpert.concat(this.compatePersonBtn)
        } else if (type === 3) {
            COLUMNS = MyCollectPark
        } else {
            COLUMNS = MyCollectTech
        }
        return COLUMNS.concat(this.deleteCon)
    }
    render() {
        const { data, type } = this.props
        return <div>
            {/* 仅公司 人物 type===1 || type===2有对比按钮 */}
            {type === 1 && <CompareBtn />}
            {type === 2 && <PersonCompare />}
            <Table rowKey={record => record.id}
                dataSource={data}
                columns={this.getColumns(type)}
                rowClassName={this.setClassName}
                onRow={this.onRow}
                pagination={{
                    pageSize: 20,
                    hideOnSinglePage: true,
                    showSizeChanger: false,
                    position: ['bottomCenter']
                }}
                className='custom-table collect-table'
            />
            <Modal
                visible={this.state.visible}
                onOk={this.confirmDelete}
                onCancel={this.handleCancel}
                className='delete-modal'
                width={360} mask={false}
                getContainer={document.querySelector('.collect-table')}
                cancelText='不了' okText='删除'
            >
                <p>确定删除当前数据？</p>
            </Modal>
        </div>
    }
}
export default MyFavarTable