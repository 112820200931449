
import Login from 'pages/user/login'
import Forgot from 'pages/user/forgot'
import Register from 'pages/user/register'
import Official from 'pages/official/Official'
import Report from 'pages/official/Report'
import Demand from 'pages/official/Demand'
import Digitization from 'pages/official/Digitization'
import Protocol from 'pages/official/Protocol'
import About from 'pages/official/About'
import Regist from 'pages/official/Regist'
import Signup from 'pages/official/Signup'
import Test from 'pages/official/Test'
import AlbumDetails from 'pages/official/AlbumDetails'

import ResearchDetail from 'pages/official/ResearchDetail'
import Home from 'pages/home'
import { Error500,Error404,Error403 } from 'pages/error'

const PublicRoutes = [
    {
        path:'/login',
        component:Login,
        exact:true
    },{
        path: '/test',
        component: Test,
        exact: true
    },{
        path: '/',
        component: Official,
        exact: true
    },{
        path: '/report',
        component: Report,
        exact: true
    },{
        // 首页专辑详情
        path: '/report/detail/:id/:name',
        component: AlbumDetails,
        exact: true
    },{
        path: '/report/research/:id/:name',
        component: ResearchDetail,
        exact: true
    },{
        path: '/demand',
        component: Demand,
        exact: true
    },{
        path: '/digitization',
        component: Digitization,
        exact: true
    },{
        path: '/signup',
        component: Signup,
        exact: true
    },{
        path: '/protocol',
        component: Protocol,
        exact: true
    },{
        path: '/about',
        component: About,
        exact: true
    },{
        path:'/forgot',
        component:Forgot,
        exact:true
    },
    // {
    //     path: '/regist',
    //     component: Regist, 
    //     exact: true
    // },
    // {
    //     path:'/register',
    //     component:Register,
    //     exact:true
    // },
    // {
    //     path:'/',
    //     component:Home,
    //     exact:true
    // }
    {
        path:'/error500',
        component:Error500,
        exact:true
    },{
        path:'/error404',
        component:Error404,
        exact:true
    },{
        path:'/error403',
        component:Error403,
        exact:true
    }
]

export default PublicRoutes